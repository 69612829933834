import React from 'react';
import PropTypes from 'prop-types';

const DefinitionItem = ({ id, label, children }) => (
  <div id={id} className="nls-definition-item">
    <dt className="nls-definition-item-label">{label}</dt>
    <dd className="nls-definition-item-value">{children}</dd>
  </div>
);

DefinitionItem.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node,
  children: PropTypes.node,
};

DefinitionItem.defaultProps = {
  id: null,
  label: null,
  children: null,
};

export default DefinitionItem;
