import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ScrollerPanelListItem';

const NoItems = ({ text }) => (
  <ListItem hideDivider>
    <div className="margin-t-1 margin-b-1 no-items">
      <strong>{text}</strong>
    </div>
    <div className="panel-list-item__divider" />
  </ListItem>
);

NoItems.propTypes = {
  text: PropTypes.string.isRequired,
};

export default NoItems;
