import { combineReducers } from '@reduxjs/toolkit';
import start from 'common/helpers/AppStarter/AppStarter';
import { configureReduxStore, loadStateFromSessionStorage } from 'common/helpers/storage';
import mapLayers from 'common/containers/MapLayerSelector/MapLayerSelectorReducer';
import address from 'common/containers/MapToolbar/AddressReducer';
import search from 'common/containers/SidebarSearch/SearchReducer';
import distance from 'common/containers/MapToolbar/DistanceReducer';
import disableMapTool from 'common/containers/MapToolbar/DisableMapToolReducer';
import area from 'common/containers/MapToolbar/AreaReducer';
import selector from 'common/containers/MapToolbar/SelectorReducer';
import customMarker from 'common/containers/MapToolbar/CustomMarkerReducer';
import feedbackMarker from 'common/containers/MapToolbar/FeedbackMarkerReducer';
import oskariIframe from 'common/containers/OskariMap/OskariMapReducer';
import mobileView from 'common/containers/MobileTools/MobileViewReducer';
import businessPanel from 'common/containers/BusinessPanel/BusinessPanelReducer';
import layout from 'common/containers/KasiApp/LayoutReducer';
import mapStore from 'common/containers/MapStore/MapStoreReducer';
import customMaps from 'common/containers/MapStoreCustomMapsForm/CustomMapsFormReducer';
import readyMaps from 'common/containers/MapStoreReadyMapsForm/ReadyMapsFormReducer';
import appNotifications from 'common/containers/AppNotifications/AppNotificationsReducer';
import accessibleNotifications from 'common/containers/AccessibleNotifications/accessibleNotificationsSlice';
import automaticKeyboardFocus from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusReducer';
import share from 'common/containers/Share/ShareReducer';
import SnapshotPreviewAreaReducer from 'common/containers/SnapshotPreviewArea/SnapshotPreviewAreaReducer';
import KasiAppModeReducer from 'common/containers/KasiAppMode/KasiAppModeReducer';
import shoppingCart from 'common/containers/ShoppingCart/ShoppingCartReducer';
import confirmModal from 'common/containers/ConfirmModal/ConfirmModalReducer';
import fileDownloadService from 'common/containers/FileDownloadService/FileDownloadServiceReducer';
import authentication from 'common/helpers/Authentication/authenticationSlice';
import conf from 'common/helpers/Configurations/ConfigurationsReducer';
import serviceClosed from 'common/containers/ServiceClosedModal/ServiceClosedModalReducer';
import application from 'mapSite/containers/Application/ApplicationReducer';
import geometries from 'common/geometries/geometriesReducer';
import geometry from 'realEstateSite/containers/Realty/common/geometries/geometriesSlice';
import commonBgTasks from 'common/helpers/commonBackgroundTasks';
import authorization from 'common/helpers/Authorization/AuthorizationReducer';
import form from 'common/components/Form/FormReducer';
import createRouter from './Router';

const createRootReducer = () =>
  combineReducers({
    application,
    appNotifications,
    accessibleNotifications,
    automaticKeyboardFocus,
    conf,
    form,
    share,
    mapLayers,
    search,
    oskariIframe,
    mobileView,
    businessPanel,
    layout,
    mapStore,
    readyMaps,
    customMaps,
    shoppingCart,
    confirmModal,
    fileDownloadService,
    authentication,
    geometry,
    appMode: KasiAppModeReducer,
    snapshotPreviewArea: SnapshotPreviewAreaReducer,
    mapToolbar: combineReducers({
      address,
      distance,
      disableMapTool,
      area,
      selector,
      customMarker,
      feedbackMarker,
    }),
    authorization,
    serviceClosed,
    geometries,
  });

const buildReduxStore = initialState =>
  configureReduxStore(window.Cypress ? window.initialState : initialState, createRootReducer());

const startApp = async () => {
  const initialState = await loadStateFromSessionStorage();
  const store = buildReduxStore(initialState);
  if (window.Cypress) {
    window.__store__ = store;
  }
  start(createRouter, store, initialState !== null, commonBgTasks());
};

export default startApp;
