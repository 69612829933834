export const ADD_FEATURE = 'ADD_FEATURE';
export const REMOVE_FEATURE = 'REMOVE_FEATURE';
export const REMOVE_ALL_FEATURES = 'REMOVE_ALL_FEATURES';
export const REMOVE_FEATURES_BY_TYPE = 'REMOVE_FEATURES_BY_TYPE';
export const SET_INFOBOX = 'SET_INFOBOX';
export const SET_LAYER_VISIBILITY = 'SET_LAYER_VISIBILITY';
export const SET_REAL_ESTATE_SITE_FEATURES_REMOVED = 'SET_REAL_ESTATE_SITE_FEATURES_REMOVED';
export const RESET_REAL_ESTATE_SITE_FEATURES_REMOVED = 'RESET_REAL_ESTATE_SITE_FEATURES_REMOVED';
export const ADD_NEIGHBOUR_REAL_ESTATE_FEATURES = 'ADD_NEIGHBOUR_REAL_ESTATE_FEATURES';
export const REMOVE_PARCEL = 'REMOVE_PARCEL';
export const ZOOM_TO_PARCELS = 'ZOOM_TO_PARCELS';

export const REAL_ESTATE_SITE_FEATURE_TYPES = {
  APARTMENTS: 'apartments',
  BOUNDARY_MARKERS: 'boundaryMarkers',
  REAL_ESTATES: 'realEstates',
};
