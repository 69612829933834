import { getConfiguration } from 'common/helpers/Configurations/Configurations';

const MAP_PRODUCT_TYPES = ['pdf', 'plastic-and-pdf', 'canvas-and-pdf', 'paper-and-pdf', 'press', 'print'];

export const hasDeliveryFields = items =>
  items.filter(
    item =>
      item.product.productType !== getConfiguration('map.product.type.pdf') &&
      item.product.productType !== getConfiguration('product.type.certificate') &&
      item.product.productType !== 'fileDownload' &&
      !item.product.targets
  ).length > 0;

export function isLaser5pProduct(name) {
  return ['Laserkeilausaineisto 5 p', 'Laserskannat material 5 p'].includes(name);
}

// avoimet aineistot
export const hasOpenFileDownloadProducts = items =>
  items.filter(item => item.product.productType === 'fileDownload' && !isLaser5pProduct(item.product.name)).length > 0;

export const hasOpenFileDownloadProductsOnly = items =>
  items.filter(item => item.product.productType !== 'fileDownload' || isLaser5pProduct(item.product.name)).length === 0;

export const hasAnyLaser5pProducts = items => items.filter(item => isLaser5pProduct(item.product.name)).length > 0;

export const hasApplicationProducts = items => items.some(item => item.product.productType === 'application');

export const hasMapProducts = items => items.some(item => MAP_PRODUCT_TYPES.includes(item.product.productType));

export const hasCertificates = items => items.some(item => item.product.productType === 'certificate');

export const hasMapsOrCertificates = items => hasMapProducts(items) || hasCertificates(items);

export const hasOnlyLaser5pProducts = items =>
  hasAnyLaser5pProducts(items) && !hasMapProducts(items) && !hasCertificates(items);

export const getLaser5pProductionAreaItems = items =>
  items.filter(item => isLaser5pProduct(item.product.name) && item.product.productionArea);
export const getLaser5pMapSheetItems = items =>
  items.filter(item => isLaser5pProduct(item.product.name) && !item.product.productionArea);
