import React from 'react';
import PropTypes from 'prop-types';

const MainToolbarHeaderComponent = ({ title }) => (
  <div className="main-toolbar__header">
    <h2 className="main-toolbar__heading">{title}</h2>
  </div>
);

MainToolbarHeaderComponent.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MainToolbarHeaderComponent;
