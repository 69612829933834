/**
 * Returns a geojson
 * @param featureCoords coordinates from object that has coords as a property and an id as a property
 * @returns {{type: string, crs: {type: string, properties: {name: string}}, features: Array}}
 */
export default function create(featureCoords, feat_id, featureLabelProperties = []) {
  const geoJson = {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: {
        name: 'EPSG:3067',
      },
    },
    features: [],
  };

  geoJson.features = featureCoords.map(feature =>
    createPolygonFeature(
      feature.coords,
      feature[feat_id],
      featureLabelProperties.map(f => feature[f])
    )
  );
  return geoJson;
}

function createPolygonFeature(coords, id, labels) {
  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [coords],
    },
    properties: {
      feature_id: id,
      feature_label: labels.length > 1 ? labels.join('\n') : labels[0],
    },
  };
}
