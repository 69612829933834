import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { switchMapToolThunk } from './MapToolbarActions';
import MapToolbarComponent from './MapToolbarComponent';
import { removeItemThunk, updateItemThunk } from './CustomMarkerActions';

function mapStateToProps(state) {
  return {
    selectorActive: state.mapToolbar.selector.active,
    addressActive: state.mapToolbar.address.active,
    distanceActive: state.mapToolbar.distance.active,
    areaActive: state.mapToolbar.area.active,
    customMarkerActive: state.mapToolbar.customMarker.active,
    customMarkerDisabled: state.mapToolbar.disableMapTool.customMarker,
    distanceDisabled: state.mapToolbar.disableMapTool.distance,
    areaDisabled: state.mapToolbar.disableMapTool.area,
    addressDisabled: state.mapToolbar.disableMapTool.address,
    layoutMode: state.layout.mode,
    customMarkerInEditor: state.mapToolbar.customMarker.itemInEditor,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    switchMapToolThunk: bindActionCreators(switchMapToolThunk, dispatch),
    customMarkerRemoveItem: bindActionCreators(removeItemThunk, dispatch),
    customMarkerUpdateItem: bindActionCreators(updateItemThunk, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapToolbarComponent);
