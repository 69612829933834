import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { ScrollerPanelListItem, Glyphicon } from 'common/components';

const MapSiteLinkBlockComponent = ({ to, title, desc, glyph, hidden, className, selector }) => (
  <ScrollerPanelListItem
    linkUrl={to}
    className={cls('map-site-index__link', className)}
    hidden={hidden}
    selector={selector}
  >
    <div className="map-site-index__row">
      <Glyphicon glyph={glyph} />
      <div>
        <h2 className="map-site-index__title">{title}</h2>
        <p className="map-site-index__text">{desc}</p>
      </div>
    </div>
  </ScrollerPanelListItem>
);

MapSiteLinkBlockComponent.propTypes = {
  desc: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  glyph: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  className: PropTypes.string,
  selector: PropTypes.string,
};

MapSiteLinkBlockComponent.defaultProps = {
  hidden: false,
  className: '',
  selector: undefined,
};

export default MapSiteLinkBlockComponent;
