import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, useForm } from 'react-final-form';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { isDesktop } from 'common/constants/Layout';
import { ValidationErrorText, ValidatedSelect } from '../../Forms/InputValidation/InputValidationComponents';
import { getThemeOptions, useProductParameters, getProductSelectorPosition } from '../FileDownloadServiceHelpers';
import { FIELD_NAMES, TOPOGRAPHIC_DATABASE } from '../FileDownloadServiceConstants';

const ThemeSelectionComponent = ({ selectedAreaSelectionType, fieldName, fieldType }) => {
  const { t } = useTranslation();
  const { getState } = useForm();
  const themes = getThemeOptions(useProductParameters('themes'));
  const { id } = useParams();
  const layoutMode = useSelector(state => state.layout.mode);
  const selectorPosition = getProductSelectorPosition(id, fieldName);

  const missingThemeSelectionErrorId = useId();

  const { submitFailed } = getState();

  if (id !== TOPOGRAPHIC_DATABASE && isEmpty(themes)) return null;
  return (
    <div className="margin-t-1">
      <label htmlFor={fieldName}>{t('fileDownloadService.themeSelection.title')}</label>
      <Field
        id={fieldName}
        name={fieldName}
        component={ValidatedSelect}
        options={themes}
        ariaLabel={t('fileDownloadService.themeSelection.title')}
        menuPlacement={isDesktop(layoutMode) ? 'bottom' : 'top'}
        disabled={selectedAreaSelectionType !== fieldType}
        selectorPosition={selectorPosition}
        errorId={missingThemeSelectionErrorId}
        required={selectedAreaSelectionType === fieldType}
      />
      {selectedAreaSelectionType === fieldType && (
        <Field
          name={FIELD_NAMES.VALID_THEME_SELECTION}
          component={({ meta }) =>
            submitFailed && meta.error ? (
              <ValidationErrorText id={missingThemeSelectionErrorId} className="margin-t-1" errorText={meta.error} />
            ) : null
          }
        />
      )}
    </div>
  );
};

ThemeSelectionComponent.propTypes = {
  selectedAreaSelectionType: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
};

export default ThemeSelectionComponent;
