import * as types from './BusinessPanelActionTypes';

export function businessPanelToggleAction(isOpen) {
  return {
    type: types.TOGGLE_BUSINESS_PANEL,
    isOpen,
  };
}

export function openBusinessPanel() {
  return {
    type: types.OPEN_BUSINESS_PANEL,
  };
}

export function closeBusinessPanel() {
  return {
    type: types.CLOSE_BUSINESS_PANEL,
  };
}

export function testReject() {}
