export const SRA_FORM_ID = 'sra';
export const SRA_ATTACHMENTS = 'sra-attachments';
export const SRA_FREE_DESC = 'sra-free-description';
export const SRA_MANDATORY_ATTACHEMENTS = 'sra-mandatory-attachments';
export const CONSENT_AND_RESOLUTION_DESC = 'consent-and-resolution-desc';
export const CONSENT_AND_RESOLUTION_ATTACHEMENTS = 'consent-and-resolution-attachments';
export const CONSENT_AND_RESOLUTION_TYPE = '24';

export const SRA_FREE_DESC_MAX_LENGTH = 3000;

export const SUPPLEMENT_REQUEST_TYPE = 'TAYDENNYSPYYNTO';
export const SUPPLEMENT_REMINDER_TYPE = 'TAYDENNYSKEHOTUS';
export const RESOLUTION_REQUEST_TYPE = 'LAUSUMAPYYNTO';
export const CONSENT_REQUEST_TYPE = 'SUOSTUMUSPYYNTO';

export const DELIVERY_METHOD_KIRRE_EMAIL = 'KIRRE_SAHKOPOSTI';
export const DELIVERY_METHOD_EMAIL = 'SAHKOPOSTI';
export const DELIVERY_METHOD_PHONE = 'PUHELIN';
