import { useSelector } from 'react-redux';
import { OSKARI_CHANNEL_STATUS_IDS } from '../../oskari/OskariMap';

const useOskari = () => {
  const channelStatus = useSelector(state => state.layout.oskariChannelStatus);
  const channelReady = channelStatus === OSKARI_CHANNEL_STATUS_IDS.channelReady;

  return {
    channelStatus,
    channelReady,
  };
};

export default useOskari;
