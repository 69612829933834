import React from 'react';
import { Field } from 'react-final-form';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Row } from 'common/components';

import { FormContentBlock } from '../../Forms/Blocks';
import { FIELD_NAMES } from '../FileDownloadServiceConstants';
import { ValidatedSelect } from '../../Forms/InputValidation/InputValidationComponents';
import { useProductParameters } from '../FileDownloadServiceHelpers';

const LevelOfDetailSelectionComponent = () => {
  const { t } = useTranslation();
  const lods = useProductParameters('levelOfDetail');
  const values = lods?.map(value => ({ label: value, value }));

  if (isEmpty(values)) return null;

  return (
    <Row className="margin-t-1">
      <FormContentBlock label={t('fileDownloadService.levelOfDetailSelection.title')} id={FIELD_NAMES.LEVEL_OF_DETAIL}>
        <Field
          name={FIELD_NAMES.LEVEL_OF_DETAIL}
          component={ValidatedSelect}
          options={values}
          ariaLabel={t('fileDownloadService.levelOfDetailSelection.title')}
        />
      </FormContentBlock>
    </Row>
  );
};

export default LevelOfDetailSelectionComponent;
