import {
  SET_MAP_TOOL_BUTTON_ACTIVE,
  MAP_TOOL_FEEDBACK_MARKER_ADD_ITEM,
  MAP_TOOL_FEEDBACK_MARKER_REMOVE_ITEM,
} from './MapToolbarActionTypes';
import { MAP_TOOL_FEEDBACK_MARKER } from './MapToolNames';

const initialState = { active: false, selectedCoordinates: null };

// Reducer
export default function feedbackMarkerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MAP_TOOL_BUTTON_ACTIVE:
      if (action.toolId === MAP_TOOL_FEEDBACK_MARKER) {
        return { ...state, active: action.active };
      }
      return state;

    case MAP_TOOL_FEEDBACK_MARKER_ADD_ITEM:
      return { ...state, selectedCoordinates: action.item };

    case MAP_TOOL_FEEDBACK_MARKER_REMOVE_ITEM:
      return { ...state, selectedCoordinates: null };

    default:
      return state;
  }
}
