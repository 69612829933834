import { INIT_GRID } from 'common/containers/MapStore/MapStoreActionTypes';
import { CANVAS_PRINT, PAPER_PRINT, PDF_PRINT, PLASTIC_PRINT } from './CustomMapsFormConstants';

const customMapDetails = {
  [PLASTIC_PRINT]: {
    productType: 'plastic-and-pdf',
    description: 'customMapsForm.printMaterial.plasticAndPdf',
    productIds: ['77232011069', '77232011068', '77232011067'],
  },
  [CANVAS_PRINT]: {
    productType: 'canvas-and-pdf',
    description: 'customMapsForm.printMaterial.canvasAndPdf',
    productIds: ['77232011069', '77232011068', '77232011067'],
  },
  [PAPER_PRINT]: {
    productType: 'paper-and-pdf',
    description: 'customMapsForm.printMaterial.paperAndPdf',
    productIds: ['77232011066', '77232011065', '77232011064', '77232011063'],
  },
  [PDF_PRINT]: {
    productType: 'pdf',
    description: 'customMapsForm.printMaterial.pdf',
    productIds: ['77232011062', '77232011061', '77232011060', '77232011059', '77232011058'],
  },
};

const initialState = {
  customMapSheets: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_GRID: {
      const initializedCustomMapSheets = Object.fromEntries(
        Object.entries(customMapDetails).map(([type, details]) => {
          const filledInProducts = details.productIds.map(id => action.grid.find(g => g.nimikekoodi === id));
          return [type, { ...details, products: filledInProducts }];
        })
      );
      return { ...state, customMapSheets: initializedCustomMapSheets };
    }

    default:
      return state;
  }
}

export { customMapDetails as customMapDetailsTest };
