import * as DatetimeUtils from 'utils/datetime-utils';
import i18next from 'i18next';
import { isEmpty } from 'lodash';

import {
  buildServicesSiteRoute,
  SERVICES_PROPRIETOR_LIST,
  SERVICES_MORTGAGE_LIST,
  SERVICES_DOC_REGISTRATION,
  SERVICES_DOC_REGISTRATION_MAP,
  SERVICES_DOC_PROPERTY,
  SERVICES_DOC_LEGAL_CERTIFICATE,
  SERVICES_DOC_STRAIN_CERTIFICATE,
  SERVICES_DOC_RENTAL_CERTIFICATE,
  SERVICES_LEGAL_CONFIRMATION,
  SERVICES_FETCH_REAL_ESTATES,
  SERVICES_CADASTRAL_SURVEYS,
  SERVICES_LEASEHOLD_REGISTRATION_APPLICATION,
  SERVICES_OSRA,
  SERVICES_LEASEHOLD_TRANSFER_APPLICATION,
  SERVICES_BUY_CERTIFICATES,
  SERVICES_USUFRUCTS_AND_RESTRICTIONS,
  SERVICES_LEASEHOLDERS,
  SERVICES_REAL_ESTATE_LIST,
  SERVICES_PLEDGES_LIST,
  SERVICES_RESTRICTIONS_LIST,
} from 'realEstateSite/constants/Routes';
import {
  LEGAL_CERT,
  LEGAL_CONFIRMATION,
  MORTGAGES_LIST,
  PROPERTY_DOC,
  PROPRIETOR_LIST,
  REG_DOC,
  REG_MAP_DOC,
  RENTAL_CERT,
  STRAIN_CERT,
  CADASTRAL_SURVEY,
  LEASEHOLD,
  LEASEHOLDERS,
  OSRA,
  BUY_CERTIFICATES,
  USUFRUCTS_AND_RESTRICTIONS,
  PLEDGES_LIST,
  RESTRICTIONS_LIST,
} from 'realEstateSite/constants/Documents';

import {
  ESTATE_PERSON_CATEGORY,
  FOR_COMPANY_ACCOUNT,
  FOR_CONDOMINIUM_ACCOUNT,
  JURIDICAL_PERSON_CATEGORY,
  NATURAL_PERSON_CATEGORY,
  STATE_PERSON_CATEGORY,
  UNKNOWN_PERSON_CATEGORY,
} from 'realEstateSite/constants/Person';

import { currencyMask } from 'common/components/EuroInput';
import { getEuroSign } from 'common/helpers/moneyHelpers';

import { FACILITY_TYPE, PROPERTY_TYPE, REGISTER_UNIT_TYPE } from 'realEstateSite/constants/RealEstate';
import {
  POSSESSION_ROLE_EOVU,
  POSSESSION_ROLE_LHHV,
  POSSESSION_ROLE_LVSA,
  OWNERSHIP_ROLES,
  GRANTEE_ROLES,
} from 'realEstateSite/constants/Roles';
import { validateRealEstateIdentifier } from 'common/containers/Forms/FormValidation';

export const realEstateDataFormatter = realEstate =>
  [
    realEstate.type === PROPERTY_TYPE ? i18next.t('realEstate.descriptor.property') : '',
    realEstate.type === REGISTER_UNIT_TYPE ? realEstate.registerUnitTypeText[i18next.language] : '',
    realEstate.id,
    ...(realEstate.name ? [realEstate.name] : []),
  ].join(' ');

export const realEstateIdentification = realEstate => {
  const municipalityNames = realEstate.municipalityNames
    ? realEstate.municipalityNames[i18next.language]
    : realEstate.locations?.[0].municipalityNames[i18next.language];

  return realEstate.isPropertyWithoutId
    ? `${i18next.t('realEstate.propertyWithoutIdentifier.new.property')}, ${
        realEstate.id.includes('M')
          ? i18next.t('realEstate.propertyWithoutIdentifier.transfer.of.part.property')
          : i18next.t('realEstate.propertyWithoutIdentifier.transfer.of.part.realEstate')
      } ${realEstate.id}`
    : `${realEstateDataFormatter(realEstate)}, ${municipalityNames}`;
};

const REAL_ESTATE_ROUTE_MAP = {
  [SERVICES_PROPRIETOR_LIST]: PROPRIETOR_LIST,
  [SERVICES_MORTGAGE_LIST]: MORTGAGES_LIST,
  [SERVICES_USUFRUCTS_AND_RESTRICTIONS]: USUFRUCTS_AND_RESTRICTIONS,
  [SERVICES_LEASEHOLDERS]: LEASEHOLDERS,
  [SERVICES_BUY_CERTIFICATES]: BUY_CERTIFICATES,
  [SERVICES_DOC_REGISTRATION]: REG_DOC,
  [SERVICES_DOC_REGISTRATION_MAP]: REG_MAP_DOC,
  [SERVICES_DOC_PROPERTY]: PROPERTY_DOC,
  [SERVICES_DOC_LEGAL_CERTIFICATE]: LEGAL_CERT,
  [SERVICES_DOC_STRAIN_CERTIFICATE]: STRAIN_CERT,
  [SERVICES_DOC_RENTAL_CERTIFICATE]: RENTAL_CERT,
  [SERVICES_LEGAL_CONFIRMATION]: LEGAL_CONFIRMATION,
  [SERVICES_CADASTRAL_SURVEYS]: CADASTRAL_SURVEY,
  [SERVICES_LEASEHOLD_REGISTRATION_APPLICATION]: LEASEHOLD,
  [SERVICES_LEASEHOLD_TRANSFER_APPLICATION]: LEASEHOLD,
  [SERVICES_OSRA]: OSRA,
  [SERVICES_PLEDGES_LIST]: PLEDGES_LIST,
  [SERVICES_RESTRICTIONS_LIST]: RESTRICTIONS_LIST,
};

// ASI-8439: Kirjaamishakemukset eivät ole sallittuja lakkautetuille tie- tai liitännäisalueille.
// 12 = Lakkautettu tie- tai liitännäisalue
// 21 = Tie- tai liitännäisalue tieoikeudella
// 22 = Tie- tai liitännäisalue omistusoikeudella
export const RESTRICTED_REGISTER_UNIT_TYPES = ['12', '21', '22'];

export function getRealEstateActionTokenForRoute(action = {}) {
  return REAL_ESTATE_ROUTE_MAP[action] || '';
}

const getRealEstateDescriptorText = realEstate =>
  realEstate.type ? i18next.t(['realEstate.descriptor', realEstate.type].join('.')) : '';

export const getRealEstateTitle = realEstate => {
  if (realEstate.registerUnitType) {
    return realEstate.registerUnitTypeText ? realEstate.registerUnitTypeText[i18next.language] : '';
  }
  if (realEstate.type === FACILITY_TYPE) {
    return realEstate.specialPermissionSortText ? realEstate.specialPermissionSortText[i18next.language] : '';
  }
  return getRealEstateDescriptorText(realEstate);
};

export const getRealEstateIdentifier = realEstate => {
  let identifierDescriptor;
  if (realEstate.registerUnitType) {
    identifierDescriptor = realEstate.registerUnitTypeText[i18next.language] || '';
  } else if (realEstate.specialPermissionSort) {
    identifierDescriptor = realEstate.specialPermissionSortText[i18next.language] || '';
  } else {
    identifierDescriptor = getRealEstateDescriptorText(realEstate);
  }
  const identifier = [identifierDescriptor, realEstate.id].join(' ');
  return realEstate.name ? [identifier, realEstate.name].join(', ') : identifier;
};

export const isEven = number => number % 2 === 0;

// NOTE: this is identical to the below version but simpler to use. The other version exists for compatability. Remove if not needed
export const getRealEstateById = ({ ownRealEstates = [], searchedRealEstates = [], item, pathname }) =>
  pathname.includes(buildServicesSiteRoute(SERVICES_FETCH_REAL_ESTATES))
    ? searchedRealEstates.find(r => r.id === item)
    : ownRealEstates.find(r => r.id === item);

export const getRouteSpecifiedRealEstate = (
  {
    realEstateList: { realEstates: ownRealEstates = [], locationRealEstates = [] } = {},
    searchRealEstates: { realEstates: searchedRealEstates = [] } = {},
    usufructs: { facilities: usufructsFacilities = [] } = {},
    jointPropertyUnitShares: { property: jointPropertyUnitShareProperty = {} } = {},
    realEstateProperties: { realEstateProperties = [] } = {},
    usufructUnits: { units: usufructUnits = [] } = {},
  },
  {
    routeParams = { item: '', secondItem: '', thirdItem: '' },
    route = { path: '' },
    columnNumber = 0,
    registrationId = '',
    certificateComponent,
    isLocationRealEstate,
    isRealEstateProperty,
  }
) => {
  if (columnNumber >= 5 && route.path.includes(SERVICES_USUFRUCTS_AND_RESTRICTIONS)) {
    if (validateRealEstateIdentifier(registrationId, false)) {
      return jointPropertyUnitShareProperty;
    }
    if (validateRealEstateIdentifier(registrationId, true)) {
      return usufructsFacilities.filter(r => r.id === registrationId)[0];
    }
    return usufructUnits.filter(r => r.id.toString() === registrationId)[0];
  }

  if (isLocationRealEstate) {
    if ((!isEven(columnNumber) && routeParams.thirdItem) || (certificateComponent && routeParams.thirdItem))
      return locationRealEstates.find(r => r.id === routeParams.thirdItem);
    return routeParams.secondItem ? locationRealEstates.find(r => r.id === routeParams.secondItem) : undefined;
  }

  if (isRealEstateProperty) {
    if ((!isEven(columnNumber) && routeParams.thirdItem) || (certificateComponent && routeParams.thirdItem)) {
      const selectedRealEstate = realEstateProperties.find(p => routeParams.secondItem === p.id);
      return selectedRealEstate?.properties.find(r => r.id === routeParams.thirdItem);
    }
    const selectedRealEstate = realEstateProperties.find(p => routeParams.item === p.id);
    return routeParams.secondItem
      ? selectedRealEstate?.properties.find(r => r.id === routeParams.secondItem)
      : undefined;
  }

  if (route.path.includes(buildServicesSiteRoute(SERVICES_FETCH_REAL_ESTATES))) {
    return routeParams.item ? searchedRealEstates.find(r => r.id === routeParams.item) : undefined;
  }

  if (route.path.includes(buildServicesSiteRoute(SERVICES_REAL_ESTATE_LIST))) {
    return routeParams.item ? ownRealEstates.find(r => r.id === routeParams.item) : undefined;
  }
  return null;
};

export const getRouteRealEstateIdentifier = routerProps => routerProps.params?.item;

export const ownRealEstateAuthorizationPredicate = ({ realEstateList: { realEstates } }, { realEstateId }) =>
  realEstates && realEstates.find(r => r.id === realEstateId);

export const isCeased = state => state === 2;

const LEASEHOLD_PERMISSION = '0604';

export const isLeaseHoldFacility = (type, specialPermissionSort) =>
  type !== FACILITY_TYPE || specialPermissionSort === LEASEHOLD_PERMISSION;

export function formatDate(timestamp) {
  const stringifiedTimestamp = typeof timestamp !== 'string' ? timestamp.toDateString() : timestamp;

  if (stringifiedTimestamp.match(/\d{2}.\d{2}.\d{4}/))
    return DatetimeUtils.formatDateDMY(DatetimeUtils.parseDatetimeDDMMYYYY(stringifiedTimestamp));
  if (stringifiedTimestamp.match(/\d{2}-\d{2}-\d{4}/))
    return DatetimeUtils.formatDateDMY(DatetimeUtils.parseDatetimeDDMMYYYYDashed(stringifiedTimestamp));
  if (stringifiedTimestamp.match(/\d{4}\d{2}\d{2}/))
    return DatetimeUtils.formatDateDMY(DatetimeUtils.parseDatetimeDDMMYYYYNone(stringifiedTimestamp));
  return DatetimeUtils.formatDateDMY(new Date(stringifiedTimestamp));
}

export const getPropertyLocationTitle = locationId =>
  locationId.includes('M')
    ? i18next.t('realEstate.descriptor.locations.property')
    : i18next.t('realEstate.descriptor.locations.registerUnit');

export const getLocationTitle = locations =>
  locations.some(location => location.type === REGISTER_UNIT_TYPE)
    ? i18next.t('realEstate.descriptor.locations.registerUnit')
    : i18next.t('realEstate.descriptor.locations.property');

export const getLocationInfo = location =>
  location.name
    ? `${location.name}, ${location.municipalityNames[i18next.language] || ''}`
    : location.municipalityNames[i18next.language] || '';

export const getLocationsAsText = locations =>
  !isEmpty(locations) &&
  (locations.length > 1
    ? `${getLocationTitle(locations)} ${i18next.t('realEstate.locations.multiple.text')}`
    : `${getLocationTitle(locations)} ${locations[0].id} ${getLocationInfo(locations[0])}`);

export const handlePreSubmitTasks = ({ closeAllRecipientsAndDonorsForms }) => async ({
  vals,
  checkAndFocusOnSectionErrors,
  form,
}) => {
  const formOpenState = await closeAllRecipientsAndDonorsForms({
    targets: vals.targets,
    mutators: form.mutators,
    checkAndFocusOnSectionErrors,
  });
  if (formOpenState.map(el => el.value).every(({ ssnIsValid }) => ssnIsValid === true)) {
    return { preSubmitTasksOk: true };
  }
  return { preSubmitTasksOk: false };
};

export const handlePreSubmitTasksDeathEstateLCA = ({ closeAllDeadOwnersForms }) => async ({
  vals,
  checkAndFocusOnSectionErrors,
  form,
}) => {
  const formOpenState = await closeAllDeadOwnersForms({
    deadOwners: vals.deadOwners,
    mutators: form.mutators,
    checkAndFocusOnSectionErrors,
  });
  if (
    formOpenState
      .map(el => el.value)
      .every(
        ({ ssnIsValid, shareholders }) =>
          ssnIsValid === true && shareholders.every(shareholder => shareholder.value.ssnIsValid === true)
      )
  ) {
    return { preSubmitTasksOk: true };
  }
  return { preSubmitTasksOk: false };
};

const formatStringToDate = string => {
  const day = string?.substring(0, 2);
  const month = string?.substring(2, 4);
  const year = string?.substring(4);

  return string != null ? `${year}-${month}-${day}` : null;
};

export const concat = (...strings) => strings.filter(Boolean).join(', ');

export const concatStringArray = (stringArray, delimiter = ', ') => stringArray.filter(Boolean).join(delimiter);

// This method is for preventing Firefox new Date() issue with 'd.M.yyyy' format
const formattedDateString = ({ dateString, prefix = null, postfix = null, nonDateValue = dateString }) => {
  try {
    const formattedDate = DatetimeUtils.isValid(DatetimeUtils.parse(dateString, 'd.M.yyyy', new Date()))
      ? dateString
      : DatetimeUtils.formatDateDMYYYY(new Date(dateString));
    return `${prefix || ''}${formattedDate}${postfix || ''}`;
  } catch (e) {
    return nonDateValue;
  }
};

export const formatDobString = dateOfBirth => {
  return dateOfBirth != null
    ? formattedDateString({
        dateString: dateOfBirth,
        prefix: `${i18next.t('realEstate.person.birth')}\xa0`,
        nonDateValue: '',
      })
    : null;
};

export const formatDodString = (dateOfDeath, isEstate = false) => {
  if (dateOfDeath == null) return null;
  return formattedDateString({
    dateString: dateOfDeath,
    prefix: `${i18next.t('realEstate.person.deceased')}\xa0`,
    postfix: isEstate ? ` / ${i18next.t('realEstate.person.estateOfDeceasedPerson')}` : null,
    nonDateValue: '',
  });
};

export const formatPerson = person => {
  const {
    personCategory,
    dateOfBirth,
    dateOfDeath,
    firstName,
    lastName,
    hasDeathEstate,
    name,
    organizationId,
    otherPersonIdentifier,
    behalfActionType,
    deathEstateStakeholders,
    organizationName,
    role,
  } = person;

  let personDetails;

  switch (personCategory) {
    case NATURAL_PERSON_CATEGORY:
    case ESTATE_PERSON_CATEGORY:
      if (!dateOfDeath) {
        if (role) {
          if (GRANTEE_ROLES.includes(role)) personDetails = concat(lastName, firstName, formatDobString(dateOfBirth));
          else personDetails = concat(lastName, firstName);
        } else {
          personDetails = concat(lastName, firstName, formatDobString(dateOfBirth));
        }
        break;
      }
      personDetails = concat(
        lastName,
        firstName,
        formatDodString(dateOfDeath, hasDeathEstate && !isEmpty(deathEstateStakeholders))
      );
      break;
    case JURIDICAL_PERSON_CATEGORY:
      personDetails = concat(name || organizationName, organizationId);
      break;
    case STATE_PERSON_CATEGORY:
      personDetails = i18next.t('realEstate.proprietorList.statePerson');
      break;
    case UNKNOWN_PERSON_CATEGORY: {
      const personIdentifier =
        otherPersonIdentifier && otherPersonIdentifier.includes('.')
          ? otherPersonIdentifier
          : formatStringToDate(otherPersonIdentifier);
      personDetails = concat(name || organizationName, formatDobString(personIdentifier) || '');
      break;
    }
    default:
      personDetails = name || organizationName;
  }

  if (behalfActionType === FOR_COMPANY_ACCOUNT)
    return `${personDetails} / ${i18next.t('realEstate.person.behalfOf.forCompanyAccount')}`;
  if (behalfActionType === FOR_CONDOMINIUM_ACCOUNT)
    return `${personDetails} / ${i18next.t('realEstate.person.behalfOf.forCondominiumAccount')}`;

  return personDetails;
};

export const formatPrice = (price, currency) => {
  let currencySign;
  switch (currency) {
    case 'MK':
      currencySign = 'mk';
      break;
    case 'EUR':
      currencySign = getEuroSign();
      break;
    default:
      currencySign = currency;
  }
  return `${currencyMask(Math.floor(price)).maskedValue} ${currencySign}`;
};

export const fromString = date => {
  if (i18next.language === 'fi') {
    return `${DatetimeUtils.formatDateDMYYYY(new Date(date))} ${i18next.t('realEstate.from')}`;
  }

  return `${i18next.t('realEstate.from')} ${DatetimeUtils.formatDateDMYYYY(new Date(date))}`;
};

export const compareStrings = (a, b) => a.localeCompare(b);

export const compareIds = (a, b) => {
  const groupsA = a.split('-');
  const groupsB = b.split('-');

  for (let i = 0; i < groupsA.length; i++) {
    const valueA = groupsA[i];
    const valueB = groupsB[i];

    if (valueA !== valueB) {
      // Check if either value contains a letter
      const hasLetterA = /[A-Za-z]/.test(valueA);
      const hasLetterB = /[A-Za-z]/.test(valueB);

      // Compare letters alphabetically
      if (hasLetterA && hasLetterB) return valueA.localeCompare(valueB);
      if (hasLetterA) return 1;
      if (hasLetterB) return -1;
      return Number(valueA) - Number(valueB);
    }
  }

  return 0;
};

export const comparePersons = (p1, p2) => compareStrings(formatPerson(p1), formatPerson(p2));

export const getCorrectItem = (columnNumber, item, secondItem, thirdItem, registrationId) =>
  (isEven(columnNumber) && thirdItem) || secondItem || registrationId || item;

const getRole = possessionRoles => {
  if (possessionRoles) {
    if (possessionRoles.includes(POSSESSION_ROLE_EOVU)) {
      if (possessionRoles.some(role => OWNERSHIP_ROLES.includes(role)))
        return i18next.t('realEstateList.roles.owner_leaseholder');
      if (possessionRoles.includes(POSSESSION_ROLE_LHHV))
        return i18next.t('realEstateList.roles.administration_leaseholder');
      return i18next.t('realEstateList.roles.leaseholder');
    }
    if (possessionRoles.includes(POSSESSION_ROLE_LHHV)) return i18next.t('realEstate.descriptor.lhhv');
  }
  return false;
};

const getCity = realEstate => !realEstate.locations && realEstate.municipalityNames[i18next.language];

const getTitle = realEstate =>
  `${getRealEstateTitle(realEstate)} ${realEstate.id}${isCeased(realEstate.state) ? ' X' : ''}`;

const getCeasedText = state => isCeased(state) && i18next.t('realEstateList.ceased.text');

// show status if real estate has no legal confirmation of title
const getStatus = possessionRoles =>
  possessionRoles != null && possessionRoles.includes(POSSESSION_ROLE_LVSA) && i18next.t('realEstateList.status');

export const formatRealEstate = realEstate => ({
  id: realEstate.id,
  type: realEstate.type,
  name: realEstate.name,
  title: getTitle(realEstate),
  city: getCity(realEstate),
  role: getRole(realEstate.possessionRoles),
  status: getStatus(realEstate.possessionRoles),
  ceased: getCeasedText(realEstate.state),
  locations: realEstate.locations,
  uiLocationText: getLocationsAsText(realEstate.locations),
  url: realEstate.url,
});

/**
 * Converts real estate ids to display format
 * 111-011-0011-0001 -> 111-11-11-1
 * 11101100110001 -> 111-11-11-1
 * @param {String} realEstateId
 */
export const formatRealEstateId = realEstateId => {
  const toPart5 = part => part.replace(/(.)(\d{4})/, (_, char, number) => `${char}${Number(number)}`);
  const toPart = part => (Number.isNaN(Number(part)) ? toPart5(part) : Number(part));

  // Remove leading zeroes
  if (realEstateId.includes('-')) {
    const parts = realEstateId.split('-');
    return parts.map(part => toPart(part)).join('-');
  }

  // Convert from DB fromat
  const regex = /(\d{3})(\d{3})(\d{4})(\d{4})(.\d{4})?/;
  const match = realEstateId.match(regex);

  if (match) {
    const [, part1, part2, part3, part4, part5] = match;

    return [part1, part2, part3, part4, part5].reduce((acc, value, index) => {
      if (index === 0) {
        return toPart(value);
      }

      return value !== undefined ? `${acc}-${toPart(value)}` : acc;
    }, '');
  }

  return realEstateId;
};
