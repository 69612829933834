import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { t } from 'i18next';

import { Row, Block } from 'common/components/Grid';
import { Glyphicon } from 'common/components/Icon';
import { uniqueId } from 'lodash';

const DEFAULT_DURATION = 500;

const CollapsibleButton = ({
  id,
  onToggle,
  isOpen,
  isSubHeadingButton,
  isFileDownload,
  headingOpen,
  headingClosed,
}) => {
  const showHeading = headingOpen !== null && headingClosed !== null;

  function getTexts() {
    if (showHeading) return { openText: null, closeText: null };
    if (isFileDownload)
      return { openText: t('button.collapsible.open.metadata'), closeText: t('button.collapsible.close.metadata') };
    return { openText: t('button.collapsible.open.title'), closeText: t('button.collapsible.close.title') };
  }

  const { openText, closeText } = getTexts();

  return (
    <button
      type="button"
      onClick={onToggle}
      className={cls({
        button__collapsible: !showHeading,
        'button__collapsible--inline': showHeading,
        'sub-heading-button': isSubHeadingButton,
      })}
      title={isOpen ? closeText : openText}
      aria-label={isOpen ? closeText : openText}
      aria-expanded={isOpen}
      aria-controls={id}
    >
      {showHeading && isOpen ? headingOpen : headingClosed}&nbsp;
      <Glyphicon
        glyph={cls({
          'open-section': !isOpen && !showHeading,
          'close-section': isOpen && !showHeading,
          'nuoli-alas': !isOpen && showHeading,
          'nuoli-ylos': isOpen && showHeading,
        })}
      />
    </button>
  );
};

CollapsibleButton.propTypes = {
  id: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isSubHeadingButton: PropTypes.bool,
  isFileDownload: PropTypes.bool,
  headingOpen: PropTypes.string,
  headingClosed: PropTypes.string,
};

CollapsibleButton.defaultProps = {
  isOpen: true,
  isSubHeadingButton: false,
  isFileDownload: false,
  headingOpen: null,
  headingClosed: null,
};

const Collapsible = ({
  isOpen,
  customToggle,
  heading,
  unmountOnExit,
  children,
  hideCollapsibleButton,
  hasSubHeading,
  subHeading,
  isFileDownload,
}) => {
  const [isContentOpen, setIsContentOpen] = useState(isOpen);

  const toggle = () => setIsContentOpen(prev => !prev);
  const id = uniqueId('collapsible__content');

  // NOTE: handle Collapsible used as a controlled component
  useEffect(() => {
    setIsContentOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <Row>
        <Block size={6} className="collapsible-toggle">
          {heading}
          {!hasSubHeading && !hideCollapsibleButton && (
            <CollapsibleButton
              id={id}
              onToggle={customToggle || toggle}
              isOpen={isContentOpen}
              isFileDownload={isFileDownload}
            />
          )}
        </Block>
        {hasSubHeading && (
          <Block size={6} className="collapsible-toggle margin-b-2">
            {subHeading}
            {!hideCollapsibleButton && (
              <CollapsibleButton
                id={id}
                onToggle={customToggle || toggle}
                isOpen={isContentOpen}
                isSubHeadingButton
                isFileDownload={isFileDownload}
              />
            )}
          </Block>
        )}
      </Row>
      <CSSTransition
        unmountOnExit={unmountOnExit}
        in={isContentOpen}
        timeout={DEFAULT_DURATION}
        classNames="collapsible"
      >
        <div id={id} style={{ display: isContentOpen ? null : 'none' }} className="collapsible__content">
          {children}
        </div>
      </CSSTransition>
    </>
  );
};

Collapsible.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  customToggle: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isOpen: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hideCollapsibleButton: PropTypes.bool,
  hasSubHeading: PropTypes.bool,
  subHeading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isFileDownload: PropTypes.bool,
};

Collapsible.defaultProps = {
  isOpen: false,
  unmountOnExit: true,
  customToggle: null,
  hideCollapsibleButton: false,
  hasSubHeading: false,
  subHeading: null,
  isFileDownload: false,
};

const InlineCollapsible = ({ isOpen, customToggle, headingClosed, headingOpen, unmountOnExit, children }) => {
  const [isContentOpen, setIsContentOpen] = useState(isOpen);

  const toggle = () => setIsContentOpen(prev => !prev);
  const id = uniqueId('collapsible__content');

  // NOTE: handle Collapsible used as a controlled component
  useEffect(() => {
    setIsContentOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <Row>
        <Block size={6} className="collapsible-toggle">
          <CollapsibleButton
            id={id}
            headingClosed={headingClosed}
            headingOpen={headingOpen}
            onToggle={customToggle || toggle}
            isOpen={isContentOpen}
          />
        </Block>
      </Row>
      <CSSTransition
        unmountOnExit={unmountOnExit}
        in={isContentOpen}
        timeout={DEFAULT_DURATION}
        classNames="collapsible"
      >
        <div id={id} style={{ display: isContentOpen ? null : 'none' }} className="collapsible__content">
          {children}
        </div>
      </CSSTransition>
    </>
  );
};

InlineCollapsible.propTypes = {
  headingClosed: PropTypes.string.isRequired,
  headingOpen: PropTypes.string.isRequired,
  customToggle: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isOpen: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

InlineCollapsible.defaultProps = {
  isOpen: false,
  unmountOnExit: true,
  customToggle: null,
};

export { Collapsible, InlineCollapsible, CollapsibleButton };
