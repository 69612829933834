import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  zoomInThunk,
  zoomOutThunk,
  toggleCoordinateToolThunk,
  trackUserLocationThunk,
} from 'common/containers/OskariMap/OskariMapActions';
import MobileBottomToolbarComponent from './MobileBottomToolbarComponent';

function mapStateToProps(state) {
  return {
    trackingUser: state.oskariIframe.trackingUser,
    currentCoords: state.oskariIframe.coords,
    layoutMode: state.layout.mode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    zoomIn: bindActionCreators(zoomInThunk, dispatch),
    zoomOut: bindActionCreators(zoomOutThunk, dispatch),
    toggleCoordinateTool: bindActionCreators(toggleCoordinateToolThunk, dispatch),
    onTrackUserLocation: bindActionCreators(trackUserLocationThunk, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileBottomToolbarComponent);
