import * as VECTOR_LAYERS from './VectorLayerIds';

// Layer ordering defined here: https://confluence.nls.fi/pages/viewpage.action?pageId=20181525
export const LAYER_ORDER = [
  VECTOR_LAYERS.LAYER_MAP_SELECTION,
  VECTOR_LAYERS.LAYER_SELECTED_MAP_SHEET,
  VECTOR_LAYERS.LAYER_MAP_SHEETS,
  VECTOR_LAYERS.LAYER_LASER5P_PRODUCTION_AREAS,
  VECTOR_LAYERS.LAYER_LASER5P_MAP_SHEET_AVAILABILITY,
  VECTOR_LAYERS.LAYER_ORTHOPHOTO_SHEETS,
  VECTOR_LAYERS.LAYER_3D_BUILDINGS_SHEETS,
  VECTOR_LAYERS.LAYER_MAP_SHEET_AREAS,
  VECTOR_LAYERS.LAYER_TEMPORARY_MARKERS,
  VECTOR_LAYERS.LAYER_CUSTOM_MARKERS,
  VECTOR_LAYERS.LAYER_MEASUREMENTS,
  VECTOR_LAYERS.LAYER_PLACE_NAMES,
  VECTOR_LAYERS.LAYER_REAL_ESTATE_SEARCH,
  VECTOR_LAYERS.LAYER_ADDRESSES,
  VECTOR_LAYERS.LAYER_BENCHMARKS,
  VECTOR_LAYERS.LAYER_PROPERTY_TRANSACTIONS,
  VECTOR_LAYERS.LAYER_APARTMENTS,
  VECTOR_LAYERS.LAYER_BUILDINGS,
  VECTOR_LAYERS.LAYER_FACILITIES,
  VECTOR_LAYERS.LAYER_USUFRUCTS,
  VECTOR_LAYERS.LAYER_PROPERTIES,
  VECTOR_LAYERS.LAYER_REAL_ESTATE_IDS,
  VECTOR_LAYERS.LAYER_BOUNDARY_MARKERS,
  VECTOR_LAYERS.LAYER_REAL_ESTATE_BORDERS,
  VECTOR_LAYERS.LAYER_3D_ABOVE,
  VECTOR_LAYERS.LAYER_PARCELS,
  VECTOR_LAYERS.LAYER_3D_BELOW,
  VECTOR_LAYERS.LAYER_BUILDING_PROHIBITIONS,
  VECTOR_LAYERS.LAYER_DETAILED_PLAN_AREAS,
];

export const VECTOR_LAYER_IDS = LAYER_ORDER;

export * from './VectorLayerIds';

function getMaxResolution(layer) {
  switch (layer) {
    case VECTOR_LAYERS.LAYER_BOUNDARY_MARKERS:
      return 2;
    case VECTOR_LAYERS.LAYER_PARCELS:
    case VECTOR_LAYERS.LAYER_REAL_ESTATE_BORDERS:
      return 32;
    default:
      return null;
  }
}
export const createLayers = channel => {
  VECTOR_LAYER_IDS.toReversed().forEach(layerId =>
    channel.postRequest('VectorLayerRequest', [{ layerId, maxResolution: getMaxResolution(layerId) }])
  );
};
