import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { formatDateDMYYYY } from 'utils/datetime-utils';

// Because this component is passed to iframe as HTML string, we have to use inline styling.
const dlStyle = { marginTop: '0.5rem' };
const dtStyle = { fontWeight: 'bold', marginRight: '0.5rem' };
const ddStyle = { marginBottom: '0.5rem' };
const inlineStyle = { display: 'flex' };

const formatValue = (name, value) => {
  switch (name) {
    case 'keilausPvm':
      return formatDateDMYYYY(value);
    case 'pistetiheys':
      return value.toLocaleString(undefined, { minimumFractionDigits: 1 });
    default:
      return value;
  }
};

const InfoListItem = ({ name, value }) => (
  <div style={inlineStyle}>
    <dt style={dtStyle}>{t(`licencedData.laserkeilausaineisto_5p.infobox.${name}`)}</dt>
    <dd style={ddStyle}>{formatValue(name, value)}</dd>
  </div>
);

InfoListItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const Laser5pInfoboxContent = ['keilausPvm', 'pistetiheys', 'lisatiedot', 'tuotantoalue'];

export const Laser5pInfoBox = ({ properties }) => (
  <dl style={dlStyle}>
    {Laser5pInfoboxContent.map(
      item => properties[item] && <InfoListItem key={item} name={item} value={properties[item]} />
    )}
  </dl>
);

Laser5pInfoBox.propTypes = {
  properties: PropTypes.shape({
    keilausPvm: PropTypes.string.isRequired,
    pistetiheys: PropTypes.number.isRequired,
    lisatiedot: PropTypes.string,
    tuotantoalue: PropTypes.string.isRequired,
  }).isRequired,
};

export const createLaser5pInfoBox = properties =>
  ReactDOMServer.renderToString(<Laser5pInfoBox properties={properties} />);
