import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import MapToolbar from 'common/containers/MapToolbar/MapToolbarContainer';
import MapLayerSelector from 'common/containers/MapLayerSelector/MapLayerSelectorContainer';
import SearchBar from 'common/containers/SidebarSearch/SearchBarComponent';
import SearchResults from 'common/containers/SidebarSearch/SidebarSearchResultsController';
import { AppLogo, Row, Block, CloseButton, VerticalScrollerComponent as VerticalScroller } from 'common/components';

import { EVENTS } from 'common/helpers/Analytics';
import { Glyphicon } from 'common/components/Icon';
import { sidebarToggleAction } from '../KasiApp/KasiAppActions';

import MapFeatureToggleComponent from '../MapFeatureToggle/MapFeatureToggleComponent';

export const PANEL_NAMES = {
  layers: 'layers-panel',
  search: 'search-panel',
  features: 'features-toggle-panel',
};

const SidebarComponent = () => {
  const dispatch = useDispatch();
  const [activePanel, setActivePanel] = useState(null);
  const { t } = useTranslation();

  const appContext = useSelector(state => state.application.applicationContext);
  const isOpen = useSelector(state => state.layout.desktop.sidebar.isOpen);

  const togglePanel = panelName => {
    setActivePanel(panelName === activePanel ? null : panelName);
  };

  const handleLayersPanelButtonClick = () => {
    togglePanel(PANEL_NAMES.layers);
  };

  const handleSearchPanelButtonClick = () => {
    togglePanel(PANEL_NAMES.search);
  };

  const handleFeaturesSearchPanelButtonClick = () => {
    togglePanel(PANEL_NAMES.features);
  };

  const handleToggleSidebarButtonClick = () => {
    EVENTS.trackSidebarToggle(isOpen);

    togglePanel(null);
    dispatch(sidebarToggleAction());
  };

  const getSearchScrollOffset = () => {
    return isOpen === true ? 340 : 240;
  };

  const getHeightOffset = () => {
    return isOpen === true ? 280 : 0;
  };

  const getSidebarPanelClasses = panelName => {
    return classNames(
      { sidebar__panel: !isOpen },
      { relative: isOpen },
      { 'sidebar-panel-open': activePanel === panelName }
    );
  };

  const isActivePanel = panelName => {
    return activePanel === panelName;
  };

  const closePanel = () => {
    setActivePanel(null);
  };

  const menuGlyph = classNames({
    'nuoli-vasemmalle': isOpen,
    'nuoli-oikealle': !isOpen,
  });

  const mapLayerContainerClasses = getSidebarPanelClasses(PANEL_NAMES.layers);
  const searchContainerClasses = getSidebarPanelClasses(PANEL_NAMES.search);
  const mapFeatureToggleContainerClasses = getSidebarPanelClasses(PANEL_NAMES.features);

  const sidebarToggleTooltip = isOpen ? t('sidebar.toggle.tooltip.close') : t('sidebar.toggle.tooltip.open');

  const buttonClasses = classNames('button', 'tab__button', 'button--icon');

  const layersBtnClasses = classNames(buttonClasses, 'margin-t-2', 'only-closed-sidebar-button', 'first', {
    active: isActivePanel(PANEL_NAMES.layers),
  });

  const searchBtnClasses = classNames(buttonClasses, 'only-closed-sidebar-button', {
    active: isActivePanel(PANEL_NAMES.search),
  });

  const featuresBtnClasses = classNames(
    buttonClasses,
    'only-closed-sidebar-button',
    'map-feature-toggle-sidebar-button',
    {
      active: isActivePanel(PANEL_NAMES.features),
    }
  );

  const toggleSidebarButtonClasses = classNames(buttonClasses, 'toggle-main-toolbar');

  return (
    <div className="full-height sidebar__wrapper">
      <Row className="toggle-main-toolbar__wrapper">
        <Block>
          <AppLogo appContext={appContext} logoTitle={t('sidebar.appLogo.title')} />
        </Block>
        <button
          type="button"
          aria-label={sidebarToggleTooltip}
          title={sidebarToggleTooltip}
          id="js-toggle-sidebar"
          className={toggleSidebarButtonClasses}
          onClick={handleToggleSidebarButtonClick}
          aria-controls="leftSidebar"
          aria-expanded={isOpen}
        >
          <Glyphicon glyph={menuGlyph} />
        </button>
      </Row>
      <Row className="maptools__toolbar">
        <button
          type="button"
          className={layersBtnClasses}
          onClick={handleLayersPanelButtonClick}
          aria-label={t('sidebar.mapLayers.tooltip')}
          title={t('sidebar.mapLayers.tooltip')}
          aria-pressed={isActivePanel(PANEL_NAMES.layers)}
        >
          <Glyphicon glyph="karttatasot" />
        </button>
        <button
          type="button"
          className={searchBtnClasses}
          onClick={handleSearchPanelButtonClick}
          aria-label={t('sidebar.search.tooltip')}
          title={t('sidebar.search.tooltip')}
          aria-pressed={isActivePanel(PANEL_NAMES.search)}
        >
          <Glyphicon glyph="hae" />
        </button>
        <button
          type="button"
          className={featuresBtnClasses}
          onClick={handleFeaturesSearchPanelButtonClick}
          aria-label={t('sidebar.features.tooltip')}
          title={t('sidebar.features.tooltip')}
          aria-pressed={isActivePanel(PANEL_NAMES.features)}
        >
          <Glyphicon glyph="roskakori" />
        </button>
      </Row>
      <Row>
        <MapToolbar />
      </Row>
      <Row className="toggle-main-toolbar__wrapper-lower">
        <button
          type="button"
          title={sidebarToggleTooltip}
          aria-label={sidebarToggleTooltip}
          id="js-toggle-sidebar2"
          className="toggle-main-toolbar__lower"
          onClick={handleToggleSidebarButtonClick}
          tabIndex="-1"
          aria-controls="leftSidebar"
          aria-expanded={isOpen}
        />
      </Row>
      <Row className={searchContainerClasses}>
        <CloseButton className="sidebar-panel__close-button" onClick={togglePanel} />
        <Block size={6} className="sidebar-panel__search-header">
          <SearchBar activePanel={activePanel} />
        </Block>
        <SearchResults scrollOffset={getSearchScrollOffset()} />
      </Row>

      {isOpen ? (
        <Row>
          <VerticalScroller heightOffset={getHeightOffset()}>
            <MapLayerSelector />
            <MapFeatureToggleComponent onRemoveAll={closePanel} />
          </VerticalScroller>
        </Row>
      ) : (
        <>
          <Row className={mapLayerContainerClasses}>
            <CloseButton className="sidebar-panel__close-button" onClick={togglePanel} />
            <VerticalScroller heightOffset={getHeightOffset()}>
              <MapLayerSelector />
            </VerticalScroller>
          </Row>

          <Row className={mapFeatureToggleContainerClasses}>
            <CloseButton className="sidebar-panel__close-button" onClick={togglePanel} />
            <VerticalScroller heightOffset={getHeightOffset()}>
              <MapFeatureToggleComponent onRemoveAll={closePanel} />
            </VerticalScroller>
          </Row>
        </>
      )}
    </div>
  );
};

export default SidebarComponent;
