import bindCollection from 'common/helpers/bindCollection';

/*
 * 2Sasi Analytics wrapper.
 * Currently implements Piwik Tracking
 */

/*
 * Parameters:
 *
 * @disableSend Boolean
 *   when TRUE, logs all analytics calls to console instead of sending
 *   them to analytics server
 *
 * @url String
 *   analytics server url
 *
 * @id Number
 *  analytics instance id
 *
 */

export const ANALYTICS_ERROR_NO_WINDOW = 'This script is intended to run in a browser';
export const ANALYTICS_ERROR_NO_ID = 'Piwik ID missing. Exiting';
export const ANALYTICS_ERROR_NO_URL = 'Piwik Url missing. Exiting';
export const ANALYTICS_ERROR_NO_DOC = 'document missing.';

export const DEFAULTS = {
  disableSend: false,
  id: null,
  url: null,
};

let PIWIK = null;

let IGNORE_NEXT_PAGEVIEW = false;

function disableSend() {
  getPiwikQueue().push = function mockerFunc(commandList) {
    // eslint-disable-next-line no-console
    console.log('PIWIK MOCKER:', commandList);
  };

  return this;
}

export class PiwikLoader {
  constructor(params = {}) {
    const parameters = { ...DEFAULTS, ...params };
    this.disableSend = parameters.disableSend;
    this.id = parameters.id;
    this.url = parameters.url;
    this.scriptLoaded = false;
    this.unlistenFromHistory = null;
    this.previousPath = null;
    bindCollection('init loadScript connect trackPage listenToHistory destroy'.split(' '), this);
  }

  init() {
    if (typeof window === 'undefined') {
      throw new Error(ANALYTICS_ERROR_NO_WINDOW);
    }

    if (this.id === null || this.id === false || this.id === '') {
      throw new Error(ANALYTICS_ERROR_NO_ID);
    }

    if (this.url === null || this.url === false || this.url === '') {
      throw new Error(ANALYTICS_ERROR_NO_URL);
    }

    window._paq = window._paq || [];
    /*
     * As this is quite unsafe, it is not called in the constructor
     * TODO: Maybe set a private module flag that can be used to warn about
     * _paq being crippled, or alternatively, always override the push function to
     * actually push when disableSend is not true
     */

    if (this.disableSend === true) {
      // Override push for debugging purposes!
      disableSend();
    }

    return this;
  }

  connect() {
    const queue = getPiwikQueue();
    queue.push(['setTrackerUrl', `${this.url}matomo.php`]);
    queue.push(['setSiteId', this.id]);
    return this;
  }

  loadScript() {
    if (!document) {
      throw new Error(ANALYTICS_ERROR_NO_DOC);
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = `${this.url}matomo.js`;
    document.head.appendChild(script);
    return this;
  }

  listenToHistory(history) {
    this.unlistenFromHistory = history.listen(this.trackPage);
    return this;
  }

  destroy() {
    if (this.unlistenFromHistory) {
      this.unlistenFromHistory();
    }

    return this;
  }
}
/*
 *
 * Static functions
 *
 */

/*
 * getPiwikQueue is used in other static methods to ensure _paq array
 * exist even if piwik is not initialized. Like in test envs.
 *
 */
function track404() {
  const queue = getPiwikQueue();
  queue.push(['setCustomVariable', 1, 'Error Code', 404, 'page']);
  queue.push(['setCustomVariable', 2, 'HTTP Referer', document.referrer, 'page']);
  return this.trackPageView();
}

function getPiwikQueue() {
  window._paq = window._paq || [];
  return window._paq;
}

function trackEvent(params) {
  getPiwikQueue().push(['trackEvent', params.category, params.action, params.label, params.value]);
  return this;
}

function setDocumentTitle(newDocumentTitle) {
  getPiwikQueue().push(['setDocumentTitle', newDocumentTitle]);
}

function trackPageView(newDocumentTitle) {
  if (IGNORE_NEXT_PAGEVIEW === true) {
    IGNORE_NEXT_PAGEVIEW = false;
    return this;
  }

  if (newDocumentTitle) {
    setDocumentTitle(newDocumentTitle);
  }

  getPiwikQueue().push(['trackPageView']);
  getPiwikQueue().push(['enableLinkTracking']);

  return this;
}

function init(params) {
  if (PIWIK !== null) {
    throw new Error('Piwik is already initialized. run destroy() first if you want to reinitialize it');
  }

  PIWIK = new PiwikLoader(params).init().loadScript().connect();

  return this;
}

function destroy() {
  if (PIWIK !== null) {
    PIWIK.destroy();
  }
  PIWIK = null;
  return this;
}

function getPiwikLoaderInstance() {
  return PIWIK;
}

function ignoreNextPageView() {
  IGNORE_NEXT_PAGEVIEW = true;
  return this;
}

const Analytics = () => {
  // For some reason,
  // paq.push override is not maintained over different
  // Analytics API calls ;(
  // Can't tell why but I suspect it's because of
  // babel and imports keeping window in their own closures.
  //
  // This runs it every time
  if (PIWIK && PIWIK.disableSend) {
    disableSend();
  }

  return {
    destroy,
    disableSend,
    getPiwikLoaderInstance,
    getPiwikQueue,
    init,
    trackEvent,
    trackPageView,
    track404,
    setDocumentTitle,
    ignoreNextPageView,
  };
};

export default Analytics;
