// Used for drawing real-estates on map with slightly differing colors so they stand out.
// No pattern in colors. Copied from Kiinteistötietopalvelu

const GEOMETRY_FILL_COLORS = [
  '252,136,79',
  '140,248,200',
  '252,167,206',
  '134,203,245',
  '253,168,61',
  '187,98,32',
  '147,136,238',
  '15,219,206',
  '247,107,110',
  '170,145,29',
  '173,53,37',
  '209,187,252',
  '15,199,227',
  '247,120,103',
  '255,203,135',
  '244,182,249',
  '242,180,47',
  '255,153,139',
  '205,134,240',
  '30,107,159',
  '187,120,33',
  '151,187,249',
  '228,193,31',
  '122,246,244',
  '120,135,228',
  '249,126,181',
  '88,65,179',
  '255,217,136',
  '175,46,76',
  '131,245,224',
  '184,71,37',
  '157,55,167',
  '66,159,220',
  '255,159,176',
  '251,154,75',
  '16,222,184',
  '255,156,125',
  '12,157,162',
  '169,179,251',
  '255,186,135',
  '92,56,164',
  '232,140,239',
  '41,80,149',
  '121,233,251',
  '166,54,128',
  '247,116,147',
  '255,152,155',
  '125,245,238',
  '183,85,36',
  '251,179,227',
  '177,44,65',
  '252,128,90',
  '90,137,217',
  '252,230,128',
  '192,140,28',
  '18,225,157',
  '194,187,254',
  '128,54,167',
  '8,137,166',
  '244,134,207',
  '56,68,154',
  '248,106,130',
  '18,223,228',
  '254,162,183',
  '171,50,103',
  '255,185,133',
  '164,125,240',
  '167,42,46',
];

Object.freeze(GEOMETRY_FILL_COLORS);

const OTHER_REAL_ESTATE_FOR_CADASTRAL = '167,203,87';
Object.freeze(OTHER_REAL_ESTATE_FOR_CADASTRAL);

// Color naming came from https://hexcolor.co/

const TEXT_COLORS = {
  CELERY: '167,203,87',
  WHITE: '255,255,255',
  RED_TEXT: '240,16,4',
  RED_STYLE: '255,0,0',
  RED_STYLE_2: '255,18,7',
  BLACK: '0,0,0',
  CLEMENTINE: '226,105,0',
  FLUSH_ORANGE: '255,128,0',
  NEON_CARROT: '255,153,51',
  MULE_FAWN: '154,67,49',
  AZURE_RADIANCE: '0,148,255',
  LOCHMARA: '0,124,187',
  SALEM: '17,147,74',
};
Object.freeze(TEXT_COLORS);

export { GEOMETRY_FILL_COLORS, OTHER_REAL_ESTATE_FOR_CADASTRAL, TEXT_COLORS };
