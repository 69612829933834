import React from 'react';
import PropTypes from 'prop-types';
import { ScrollerPanelListLoader, ScrollerColumn, ScrollerPanelList } from 'common/components';

const LoaderContent = () => (
  <ScrollerPanelList>
    <ScrollerPanelListLoader size="s" color="darkBlue" />
  </ScrollerPanelList>
);

const Loader = ({ columnNumber }) =>
  columnNumber ? (
    <ScrollerColumn columnNumber={columnNumber}>
      <LoaderContent />
    </ScrollerColumn>
  ) : (
    <LoaderContent />
  );

Loader.propTypes = {
  columnNumber: PropTypes.number,
};

Loader.defaultProps = {
  columnNumber: null,
};

export default Loader;
