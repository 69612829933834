export const VISU_MAP_PRINT_PICTURE_SIZE_PX = 320;

// Redux form ids:
export const CUSTOM_MAPS_FORM_ID = 'customMaps';
export const READY_MAPS_FORM_ID = 'readyMaps';
export const REDUX_FORM_MAP_FEEDBACK_ID = 'mapFeedback';
export const REDUX_FORM_SHOPPING_CART_CHECKOUT_ID = 'shoppingCartCheckout';

export const FEEDBACK_FORM_ID = 'feedback';
// Oskari map related envs:

export const MEASUREMENTS_LAYER = 'MEASUREMENTS_LAYER';
