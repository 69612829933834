import React, { useId } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useField, useForm } from 'react-final-form';

import { RadioButtonBlock } from 'common/containers/Forms/Blocks';
import {
  ValidatedSelect,
  ValidationErrorText,
} from 'common/containers/Forms/InputValidation/InputValidationComponents';

import { isDesktop } from 'common/constants/Layout';
import { AREA_SELECTION_TYPES, FIELD_NAMES } from '../../FileDownloadServiceConstants';
import { useProductParameters, getProductSelectorPosition } from '../../FileDownloadServiceHelpers';
import ThemeSelectionComponent from '../ThemeSelectionComponent';

const { AREA_SELECTION_TYPE, SELECTED_MUNICIPALITY, THEME_MUNICIPALITY } = FIELD_NAMES;

const MunicipalitySelection = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { getState } = useForm();

  const missingMunicipalitySelectionErrorId = useId();

  const { meta: missingMunicipalitySelection } = useField(FIELD_NAMES.VALID_MUNICIPALITY_SELECTION);

  const {
    input: { value: areaSelectionType },
  } = useField(AREA_SELECTION_TYPE);

  const { submitFailed } = getState();

  const productProperties = useProductParameters();
  const isProductParametersError = useSelector(state => state.fileDownloadService.productParametersError);

  const layoutMode = useSelector(state => state.layout.mode);
  const selectorPosition = getProductSelectorPosition(productProperties.id, SELECTED_MUNICIPALITY);

  return (
    <RadioButtonBlock
      id="municipalityAreaSelection"
      value={AREA_SELECTION_TYPES.MUNICIPALITY}
      name={AREA_SELECTION_TYPE}
      label={t('fileDownloadService.areaSelection.municipality.label')}
      className="margin-b-1"
      errorId={submitFailed && missingMunicipalitySelection?.error ? missingMunicipalitySelectionErrorId : null}
      extraBlockContent={
        <>
          {isProductParametersError && (
            <ValidationErrorText
              className="margin-t-1"
              errorText={t('fileDownloadService.areaSelection.municipality.listError')}
            />
          )}
          <p>{t('fileDownloadService.areaSelection.municipality.infoLabel')}</p>
          <Field
            id={SELECTED_MUNICIPALITY}
            name={SELECTED_MUNICIPALITY}
            component={ValidatedSelect}
            menuPlacement={isDesktop(layoutMode) ? 'bottom' : 'top'}
            options={productProperties?.municipalities
              .sort((a, b) => t(a).localeCompare(t(b)))
              .map(municipality => ({
                value: municipality.id,
                label: municipality[language],
              }))}
            ariaLabel={t('fileDownloadService.areaSelection.municipality.label')}
            disabled={areaSelectionType !== AREA_SELECTION_TYPES.MUNICIPALITY}
            isSearchable
            filterOption={{ matchFrom: 'start', ignoreAccents: false }}
            selectorPosition={selectorPosition}
            required={areaSelectionType === AREA_SELECTION_TYPES.MUNICIPALITY}
          />
          <Field
            name={FIELD_NAMES.VALID_MUNICIPALITY_SELECTION}
            component={({ meta }) =>
              submitFailed && meta.error ? (
                <ValidationErrorText
                  id={missingMunicipalitySelectionErrorId}
                  className="margin-t-1"
                  errorText={meta.error}
                />
              ) : null
            }
          />
          <ThemeSelectionComponent
            selectedAreaSelectionType={areaSelectionType}
            fieldName={THEME_MUNICIPALITY}
            fieldType={AREA_SELECTION_TYPES.MUNICIPALITY}
          />
        </>
      }
    />
  );
};

export default MunicipalitySelection;
