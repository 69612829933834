import { getJson, postJson } from 'common/api/AjaxJson';
import * as Routes from 'realEstateSite/constants/Routes';
import { API_PATH_SPATIAL_DATA_FILES } from '../../common/constants/Routes';

export default class ServicesApi {
  static getRoute(apiPath) {
    return Routes.buildServicesSiteRoute(apiPath);
  }

  static fetchRealEstates(signal = null) {
    return getJson(this.getRoute(Routes.API_PATH_FETCH_REAL_ESTATES), signal);
  }

  // MML support user only
  static fetchRealEstatesBySSN(ssn) {
    return getJson(this.getRoute(Routes.API_PATH_FETCH_REAL_ESTATES_BY_SSN(ssn)));
  }

  static getAvailabilityStatus() {
    return getJson(this.getRoute(Routes.API_PATH_AVAILABILITY));
  }

  static getActivityLog(signal = null) {
    return getJson(this.getRoute(Routes.API_PATH_ACTIVITY_LOG), signal);
  }

  static getActivityDecisions(activityId) {
    return getJson(`${this.getRoute(Routes.API_PATH_ACTIVITY_DECISIONS)}/${activityId}`);
  }

  static getActivityDocuments(activityId, documentId) {
    return getJson(`${this.getRoute(Routes.API_PATH_ACTIVITY_DOCUMENTS)}/${activityId}/${documentId}`);
  }

  static getSupplementRequests(activityId) {
    return getJson(`${this.getRoute(Routes.API_PATH_SUPPLEMENT_REQUESTS)}/${activityId}`);
  }

  static getCadastralActivityDocuments(activityId) {
    return getJson(`${this.getRoute(Routes.API_PATH_CADASTRAL_ACTIVITY_DOCUMENTS)}/${activityId}`);
  }

  // MML support user only
  static getCadastralActivityDocumentsBySSN(ssn, activityId) {
    return getJson(this.getRoute(Routes.API_PATH_CADASTRAL_ACTIVITY_DOCUMENTS_BY_SSN(ssn, activityId)));
  }

  // MML support user only
  static getActivityLogBySSN(ssn) {
    return getJson(this.getRoute(Routes.API_PATH_ACTIVITY_LOG_BY_SSN(ssn)));
  }

  static searchRealEstates(realEstateIdentifier) {
    return getJson(`${this.getRoute(Routes.API_PATH_FETCH_REAL_ESTATES)}/${realEstateIdentifier}`);
  }

  static getVTJPersons(ssns) {
    return getJson(`${this.getRoute(Routes.API_PATH_GET_VTJ_PERSONS)}/${ssns}`);
  }

  static isValidVTJPerson(ssn) {
    return getJson(`${this.getRoute(Routes.API_PATH_IS_VALID_VTJ_PERSON)}/${ssn}`);
  }

  static fetchOrganization(organizationId, language) {
    return getJson(`${this.getRoute(Routes.API_PATH_ORGANIZATION)}/${organizationId}/${language}`);
  }

  static fetchMortgages(realEstateIdentifier) {
    return getJson(`${this.getRoute(Routes.API_PATH_MORTGAGES)}/${realEstateIdentifier}`);
  }

  static fetchLegalConfirmationApplicationTransferences(transferenceIdentifier, realEstateIdentifiers, ssn) {
    return getJson(
      `${this.getRoute(Routes.API_PATH_LEGAL_CONFIRMATIONS)}/${transferenceIdentifier}/${realEstateIdentifiers}${
        ssn ? `/${ssn}` : ''
      }`
    );
  }

  static fetchLegalConfirmationTransferences(realEstateIdentifier) {
    return getJson(`${this.getRoute(Routes.API_PATH_LEGAL_CONFIRMATIONS)}/${realEstateIdentifier}/transferences`);
  }

  // MML support user only
  static fetchLegalConfirmationTransferencesBySSN(realEstateIdentifier, ssn) {
    return getJson(
      `${this.getRoute(Routes.API_PATH_LEGAL_CONFIRMATIONS)}/${realEstateIdentifier}/transferences/${ssn}`
    );
  }

  static fetchLegalConfirmation(realEstateIdentifier) {
    return getJson(`${this.getRoute(Routes.API_PATH_LEGAL_CONFIRMATIONS)}/${realEstateIdentifier}`);
  }

  static fetchCountries() {
    return getJson(this.getRoute(Routes.API_PATH_COUNTRIES));
  }

  static fetchTransferTypes() {
    return getJson(this.getRoute(Routes.API_PATH_TRANSFER_TYPES));
  }

  static validateLegalConfirmationApplication(application) {
    return postJson(this.getRoute(Routes.API_PATH_LEGAL_CONFIRMATION_VALIDATE), application);
  }

  static validateGenericLegalConfirmationApplication(application) {
    return postJson(this.getRoute(Routes.API_PATH_GENERIC_LEGAL_CONFIRMATION_VALIDATE), application);
  }

  static submitGenericLegalConfirmationApplication(application) {
    return postJson(this.getRoute(Routes.API_PATH_GENERIC_LEGAL_CONFIRMATION_SUBMIT), application);
  }

  static validateDeathEstateLegalConfirmationApplication(application) {
    return this.validateGenericLegalConfirmationApplication(application);
  }

  static validatePartitioningLegalConfirmationApplication(application) {
    return this.validateGenericLegalConfirmationApplication(application);
  }

  static fetchPublicPurchaseWitnessing() {
    return getJson(this.getRoute(Routes.API_PATH_PUBLIC_PURCHASE_WITNESSING));
  }

  static submitRealEstateTransferAnnouncement(announcement) {
    return postJson(this.getRoute(Routes.API_PATH_RETA_SUBMIT), announcement);
  }

  static fetchApartments(ssn = null, signal = null) {
    return postJson(Routes.getApartmentListRoute(ssn), ssn, undefined, undefined, signal);
  }

  static fetchApartmenOwnership(apartmentId, companyId) {
    return getJson(`${this.getRoute(Routes.API_PATH_APARTMENT_OWNERSHIP)}/${companyId}/${apartmentId}`);
  }

  static fetchApartmentPledges(apartmentId, companyId) {
    return getJson(`${this.getRoute(Routes.API_PATH_APARTMENT_PLEDGES)}/${companyId}/${apartmentId}`);
  }

  static fetchApartmentRestrictions(apartmentId, companyId) {
    return getJson(`${this.getRoute(Routes.API_PATH_APARTMENT_RESTRICTIONS)}/${companyId}/${apartmentId}`);
  }

  static submitCadastralSurveyApplication(application, apiPath) {
    return postJson(this.getRoute(apiPath), application);
  }

  static submitSRA(supplement) {
    return postJson(this.getRoute(Routes.API_PATH_SRA_SUBMIT), supplement);
  }

  static validateSpecialRight(application) {
    return postJson(this.getRoute(Routes.API_PATH_SPECIAL_RIGHT_VALIDATE), application);
  }

  static fetchRealtyUsufructs(realtyId) {
    return getJson(`${this.getRoute(Routes.API_PATH_USUFRUCTS_AND_RESTRICTIONS)}/${realtyId}`);
  }

  static fetchJointPropertyUnitShares(realtyId) {
    return getJson(`${this.getRoute(Routes.API_PATH_JOINT_PROPERTY_UNIT_SHARES)}/${realtyId}`);
  }

  static fetchLeaseholds(realtyId) {
    return getJson(`${this.getRoute(Routes.API_PATH_LEASEHOLDS)}/${realtyId}`);
  }

  static fetchNeighbourParcels(parcelId) {
    return getJson(`${this.getRoute(Routes.API_PATH_NEIGHBOUR_PARCELS)}/${parcelId}`);
  }

  static fetchRegisterUnitForPoint(lat, lon) {
    return getJson(`${this.getRoute(Routes.API_PATH_REGISTER_UNIT_FOR_POINT)}?lon=${lon}&lat=${lat}`);
  }

  static fetchMunicipalMaintenanceForPoint(lat, lon) {
    return getJson(`${this.getRoute(Routes.API_PATH_MUNICIPAL_MAINTENANCE_FOR_POINT)}?lon=${lon}&lat=${lat}`);
  }

  static fetchRealEstateProperties(realEstateIdentifier) {
    return getJson(
      `${Routes.buildServicesSiteRoute(Routes.API_PATH_FETCH_REAL_ESTATE_PROPERTIES)}/${realEstateIdentifier}`
    );
  }

  static fetchUsufructUnits(realtyId) {
    return getJson(`${this.getRoute(Routes.API_PATH_USUFRUCT_UNITS)}/${realtyId}`);
  }

  static fetchLicencedDataProductParameters() {
    return getJson(`${this.getRoute(API_PATH_SPATIAL_DATA_FILES)}/licencedProducts`);
  }

  static fetchPrivateRoadProperties(usufructId) {
    return getJson(`${this.getRoute(Routes.API_PATH_PRIVATE_ROAD)}/${usufructId}`);
  }

  static fetchAssociationContactInfo(associationId, associationType) {
    return getJson(
      `${this.getRoute(
        Routes.API_PATH_ASSOCIATION_CONTACT_INFO
      )}?associationId=${associationId}&associationType=${associationType}`
    );
  }
}
