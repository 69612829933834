import { t } from 'i18next';

export const BORDER_NORMAL = 'real_estate_border_thickness_normal';
export const BORDER_THIN = 'real_estate_border_thickness_thin';
export const BORDER_THICK = 'real_estate_border_thickness_thick';

export const BORDER_RED = 'real_estate_border_color_red';
export const BORDER_BLACK = 'real_estate_border_color_black';

export const ID_TYPE_LONG = 'real_estate_id_type_long';
export const ID_TYPE_SHORT = 'real_estate_id_type_short';

export const HUE_NORMAL = 'ground_info_hue_normal';
export const HUE_PALE = 'ground_info_hue_pale';

const paperTypes = [
  'portrait_0',
  'portrait_1',
  'portrait_2',
  'portrait_3',
  'portrait_4',
  'landscape_0',
  'landscape_1',
  'landscape_2',
  'landscape_3',
  'landscape_4',
];

export const PAPER_TYPE_OPTIONS = () =>
  paperTypes.map(type => ({
    label: t(`customMapsForm.${type}`),
    value: type,
  }));

const scales = [500, 1000, 2000, 5000, 10000, 15000, 20000, 25000, 50000, 100000, 250000];

export const MAP_SCALE_OPTIONS = scales.map(scale => ({
  label: `1:${scale}`,
  value: scale,
}));

export const CUSTOM_MAPS_STEP2_PRINT_HEADER_MAX_LENGTH = 47;

// field names
export const PAPER_TYPE = 'paperType';
export const MAP_SCALE = 'mapScale';

export const MAP_FEATURE_DETAILS = 'mapFeatureDetails';
export const PRINT_HEADER = 'printHeader';
export const PRINT_INFO_LANG = 'printInfoLang';
export const IS_REAL_ESTATE_INFO = 'isRealEstateInfo';
export const REAL_ESTATE_BORDER_COLOR = 'realEstateBorderColor';
export const REAL_ESTATE_BORDER_THICKNESS = 'realEstateBorderThickness';
export const IS_REAL_ESTATE_IDS = 'isRealEstateIds';
export const REAL_ESTATE_ID_TYPE = 'realEstateIdType';
export const IS_BORDER_SEPARATORS = 'isBorderSeparators';
export const IS_CONTOURS = 'isContours';
export const GROUND_INFO_HUE = 'groundInfoHue';
export const IS_COORDINATE_CROSS = 'isCoordinateCross';

export const PRODUCTS = 'products';
export const PLASTIC_PRINT = 'plasticPrint';
export const CANVAS_PRINT = 'canvasPrint';
export const PAPER_PRINT = 'paperPrint';
export const PDF_PRINT = 'pdfPrint';
export const SELECTED = 'selected';
export const AMOUNT = 'amount';

export const PRINT_INFO_LANG_OPTIONS = () => [
  {
    value: 'print_info_lang_fi',
    label: t('customMapsForm.printInfoLangFi'),
  },
  {
    value: 'print_info_lang_sv',
    label: t('customMapsForm.printInfoLangSv'),
  },
  {
    value: 'print_info_lang_en',
    label: t('customMapsForm.printInfoLangEn'),
  },
];

export const REAL_ESTATE_BORDER_COLOR_OPTIONS = () => [
  {
    value: BORDER_RED,
    label: t('customMapsForm.realEstateBorderColorRed'),
  },
  {
    value: BORDER_BLACK,
    label: t('customMapsForm.realEstateBorderColorBlack'),
  },
];

export const REAL_ESTATE_BORDER_THICKNESS_OPTIONS = () => [
  {
    value: BORDER_THIN,
    label: t('customMapsForm.realEstateBorderThin'),
  },
  {
    value: BORDER_NORMAL,
    label: t('customMapsForm.realEstateBorderNormal'),
  },
  {
    value: BORDER_THICK,
    label: t('customMapsForm.realEstateBorderThick'),
  },
];
