import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MobileTopButtonsComponent from 'common/containers/MobileTools/MobileTopButtonsComponent';
import changeMobileViewAction from 'common/containers/MobileTools/MobileViewActions';
import { businessPanelToggleAction } from 'common/containers/BusinessPanel/BusinessPanelActions';
import { t } from 'i18next';

function mapStateToProps(state) {
  return {
    appContext: state.application.applicationContext,
    appLogoTitle: t('sidebar.appLogo.title'),
    currentMobileView: state.mobileView.currentMobileView,
    businessPanelIsOpen: state.businessPanel.isOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleMobileContentChange: bindActionCreators(changeMobileViewAction, dispatch),
    handleBusinessPanelToggle: bindActionCreators(businessPanelToggleAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileTopButtonsComponent);
