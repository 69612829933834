import { connect } from 'react-redux';
import { isMobile } from 'common/constants/Layout';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import { buildMapSiteRoute, getRoute } from 'common/constants/Routes';
import {
  buildServicesSiteRoute,
  SERVICES_REAL_ESTATE_LIST,
  SERVICES_FETCH_REAL_ESTATES,
  LICENCED_DATA_INDEX,
  SERVICES_REAL_ESTATE_LIST_MOBILE,
} from 'realEstateSite/constants/Routes';
import UserMenuHomeButton from 'common/components/UserMenu/UserMenuHomeButton';
import { LICENCED_DATA, LIST_OWN_REAL_ESTATES, NLS_USER } from 'realEstateSite/containers/Authorization/privileges';

const removeTrailingSlash = str => (str.endsWith('/') ? str.slice(0, -1) : str);

const homeRouteRE = onMobile => {
  if (onMobile) return buildServicesSiteRoute(SERVICES_REAL_ESTATE_LIST_MOBILE);
  if (isAuthorized([LIST_OWN_REAL_ESTATES])) {
    return buildServicesSiteRoute(SERVICES_REAL_ESTATE_LIST);
  }
  if (isAuthorized([LICENCED_DATA]) && !isAuthorized([NLS_USER])) {
    return buildServicesSiteRoute(LICENCED_DATA_INDEX);
  }
  return buildServicesSiteRoute(SERVICES_FETCH_REAL_ESTATES);
};

const homeUrl = onMobile => {
  const rootUrl = getRoute();
  return rootUrl === buildServicesSiteRoute() ? homeRouteRE(onMobile) : rootUrl;
};

// Trailing slash has to be removed so isActive matches exactly
const showHome = ({ matchPath, pathname, onMobile }) =>
  !matchPath(pathname, removeTrailingSlash(homeRouteRE(onMobile))) &&
  !matchPath(pathname, removeTrailingSlash(buildMapSiteRoute()));

function mapStateToProps(state) {
  const onMobile = isMobile(state.layout.mode);
  return {
    homeUrl: homeUrl(onMobile),
  };
}

export default connect(mapStateToProps)(UserMenuHomeButton);

export { showHome, homeRouteRE as homeRouteRETest, homeUrl as homeUrlTest };
