import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { Row, Block } from 'common/components/Grid';
import Ruler from 'common/components/Ruler';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';
import { DataWillBeNotSendingNoticeBox } from '../../../containers/Forms/Blocks';

const FormNavigation = ({
  dataWillNotBeSending,
  isFirst,
  isLast,
  onAbort,
  onPrevious,
  submitText,
  setSubmittedUsingEnter,
  hideNavigation,
  isFormReady,
  fileUploads,
  navigationDisabled,
  previousSelector,
  abortSelector,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getState } = useForm();
  const { submitting, validating } = getState();

  if (hideNavigation) return null;

  const hasPendingUploads = Object.values(fileUploads).find(state => state !== 'COMPLETE');

  const onAbortKeyDown = event => {
    if (event?.key === 'Enter' && abortSelector) {
      dispatch(requestKeyboardFocusAction({ selector: abortSelector }));
    }
  };

  const onPreviousKeyDown = event => {
    if (event?.key === 'Enter' && previousSelector) {
      dispatch(requestKeyboardFocusAction({ selector: previousSelector }));
    }
  };

  return (
    <>
      <Ruler className="margin-t-1 margin-b-2" />
      {isLast && dataWillNotBeSending && <DataWillBeNotSendingNoticeBox />}
      <Row>
        <Block size={6} className="navigation__block">
          <button
            disabled={submitting}
            onClick={onAbort}
            onKeyDown={onAbortKeyDown}
            type="button"
            className="button button--light"
          >
            {t('button.abort')}
          </button>
          <div>
            {!isFirst && (
              <Block>
                <button
                  disabled={!!hasPendingUploads || submitting}
                  className="button button--blue"
                  onClick={onPrevious}
                  onKeyDown={onPreviousKeyDown}
                  type="button"
                >
                  {t('button.previous')}
                </button>
              </Block>
            )}
            <Block>
              <button
                className={cls('button', isLast ? 'button--orange' : 'button--blue')}
                disabled={!isFormReady || !!hasPendingUploads || navigationDisabled || submitting || validating}
                onKeyDown={event => {
                  if (event?.key === 'Enter') {
                    setSubmittedUsingEnter(true);
                  }
                }}
                type="submit"
              >
                {isLast ? submitText || t('form.send.text') : t('button.next')}
              </button>
            </Block>
          </div>
        </Block>
      </Row>
    </>
  );
};

FormNavigation.propTypes = {
  dataWillNotBeSending: PropTypes.bool,
  submitText: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onAbort: PropTypes.func.isRequired,
  setSubmittedUsingEnter: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  hideNavigation: PropTypes.bool,
  isFormReady: PropTypes.bool,
  fileUploads: PropTypes.shape({}),
  navigationDisabled: PropTypes.bool,
  previousSelector: PropTypes.string,
  abortSelector: PropTypes.string,
};

FormNavigation.defaultProps = {
  dataWillNotBeSending: false,
  isFirst: false,
  isLast: false,
  submitText: '',
  onPrevious: null,
  hideNavigation: false,
  isFormReady: true,
  navigationDisabled: false,
  fileUploads: {},
  previousSelector: '',
  abortSelector: '',
};

export default FormNavigation;
