import React, { useRef, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import scrollTop, { scrollTo } from 'common/helpers/Scroller/Scroller';
import ScrollerPanelContext from './ScrollerPanelContext';
import getMarginTop from './scrollerPanelHelpers';

const ScrollerColumnContentPositioner = ({ columnNumber, children }) => {
  const isMapSite = useSelector(state => state.application.applicationContext) === 'mapSite';
  const {
    fullWidth,
    activeMenuColumnNumber,
    setActiveMenuColumnNumber,
    activeColumnNumber,
    activeMenuItemTop,
    previousMenuItemTop,
    setPreviousMenuItemTop,
    activeListButtonSelected,
  } = useContext(ScrollerPanelContext);
  const elementRef = useRef(null);
  const isListButtonSelected = activeListButtonSelected && Object.values(activeListButtonSelected).includes(true);

  // Mapsite is always scrolled to top:
  useEffect(() => {
    if (isMapSite) scrollTop();
  }, [isMapSite, columnNumber]);

  useEffect(() => {
    // Remove any position adjustment from active menu column:
    if (columnNumber === activeColumnNumber - 1 && columnNumber !== activeMenuColumnNumber) {
      elementRef.current.style.marginTop = null;
      setActiveMenuColumnNumber(columnNumber);
    }

    if (!isMapSite && !fullWidth && columnNumber === activeColumnNumber && activeMenuItemTop != null) {
      // Column 2 is always scrolled to top without position adjustments:
      if (columnNumber === 2) {
        elementRef.current.style.marginTop = null;
        scrollTop();
      } else if (activeMenuItemTop !== previousMenuItemTop && !isListButtonSelected) {
        // Adjust position of active column:
        setPreviousMenuItemTop(activeMenuItemTop);

        const viewportTop = document.getElementById('maincontent')?.scrollTop || 0;
        const marginTop = getMarginTop(activeMenuItemTop, viewportTop, window.innerHeight);
        elementRef.current.style.marginTop = `${marginTop}px`;

        // Scroll to element:
        scrollTo(marginTop);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapSite, columnNumber, activeColumnNumber, activeMenuItemTop]);

  return (
    <div className="scroller-panel-column__content-positioner" ref={elementRef}>
      {children}
    </div>
  );
};

ScrollerColumnContentPositioner.propTypes = {
  columnNumber: PropTypes.number.isRequired,
  children: PropTypes.node,
};

ScrollerColumnContentPositioner.defaultProps = {
  children: null,
};

export default ScrollerColumnContentPositioner;
