import queryString from 'query-string';
import { Buffer } from 'buffer';
import { roundToNextMultiple } from 'utils/number-utils';

export const END_OF_ENCODED_STRING = '-z';

function parseJSONIfPossible(paramValue, defaultValue) {
  let json;
  try {
    json = JSON.parse(paramValue);
  } catch (e) {
    console.error('layer data invalid in query param, using default layers');
    json = defaultValue;
  }
  return json;
}

export const encodeJSON = jsonObject => {
  try {
    let encodedString = Buffer.from(JSON.stringify(jsonObject)).toString('base64');
    encodedString = encodedString.replaceAll('=', '');
    return `${encodedString}${END_OF_ENCODED_STRING}`;
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const decodeString = originalString => {
  try {
    let encodedString = originalString.replaceAll(END_OF_ENCODED_STRING, '');
    encodedString = encodedString.padEnd(roundToNextMultiple(encodedString.length, 3), '=');
    return JSON.parse(Buffer.from(encodedString, 'base64').toString('ascii'));
  } catch (e) {
    console.error(e);
    return null;
  }
};

export function getParamValue(windowLocation, paramName) {
  return queryString.parse(windowLocation.search)[paramName];
}

export function getUrlParam(windowLocation, paramName, defaultValue) {
  const paramValue = getParamValue(windowLocation, paramName);
  return !paramValue ? defaultValue : paramValue;
}

export function getUrlParamAsNumber(windowLocation, paramName, defaultValue) {
  const paramValue = getParamValue(windowLocation, paramName);
  return !paramValue ? defaultValue : parseFloat(paramValue);
}

export function getUrlParamAsJSON(windowLocation, paramName, defaultValue) {
  const paramValue = getParamValue(windowLocation, paramName);
  return !paramValue ? defaultValue : parseJSONIfPossible(paramValue, defaultValue);
}

export function getEncodedUrlParamAsJSON(windowLocation, paramName, defaultValue) {
  const paramValueAsString = getUrlParam(windowLocation, paramName, '');
  let paramValue = defaultValue;
  if (paramValueAsString.length > 0) {
    paramValue = paramValueAsString.endsWith(END_OF_ENCODED_STRING)
      ? decodeString(paramValueAsString)
      : getUrlParamAsJSON(windowLocation, paramName, defaultValue);
  }
  return paramValue;
}
