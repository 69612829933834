import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'common/components/Icon';

import { ExternalLink, Block, Row } from 'common/components';
import { FormContentGroup } from 'common/containers/Forms/Blocks';
import useOskari from 'common/hooks/useOskari';
import { trackLicencedDataFilesLogin } from 'common/helpers/Analytics/Analytics_eventmap';
import { fetchLaser5pProductionAreas } from '../FileDownloadServiceApi';
import { addMapSheetAreas, removeMapSheetAreas } from '../FileDownloadServiceAction';
import FileDownloadErrorMessage from '../FileDownloadErrorMessage';

const Laser5pComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { channelReady } = useOskari();
  const [mapSheetAreas, setMapSheetAreas] = useState();
  const isError = useSelector(state => state.fileDownloadService.productParametersError);
  const isParametersLoaded = useSelector(state => state.fileDownloadService.isProductParametersLoaded);

  useEffect(() => {
    fetchLaser5pProductionAreas().then(data => {
      setMapSheetAreas(data);
    });
  }, []);

  useEffect(() => {
    if (mapSheetAreas && channelReady) {
      mapSheetAreas?.feature?.forEach(feature => {
        const featureCollection = {
          id: 'laser5pMapSheetAreas',
          type: 'FeatureCollection',
          features: [feature],
        };
        dispatch(addMapSheetAreas(featureCollection, 'laser5pMapSheetAreas'));
      });
    }

    return () => {
      if (channelReady) dispatch(removeMapSheetAreas('laser5pMapSheetAreas'));
    };
  }, [mapSheetAreas, dispatch, channelReady]);

  return (
    <>
      <Row className="margin-t-2">
        <FormContentGroup halfLegendBottomMargin legend={t('fileDownloadService.metadata.additional_info')}>
          <Block size={6}>
            <ExternalLink
              href={t('fileDownloadService.product.laserkeilausaineisto_5p.metadata.link')}
              text={t('fileDownloadService.product.laserkeilausaineisto_5p.name')}
            />
            <br />
            <ExternalLink
              href={t('fileDownloadService.product.laserkeilausaineisto_5p.metadata.demo.link')}
              text={t('fileDownloadService.product.laserkeilausaineisto_5p.metadata.demo.name')}
            />
            <br />
            <ExternalLink
              href={t('fileDownloadService.product.laserkeilausaineisto_5p.metadata.tilannekartta.link')}
              text={t('fileDownloadService.product.laserkeilausaineisto_5p.metadata.tilannekartta.name')}
            />
            <span>&nbsp;{t('fileDownloadService.product.laserkeilausaineisto_5p.metadata.tilannekartta.remark')}</span>
          </Block>
        </FormContentGroup>
      </Row>
      <section>
        <h3>{t('fileDownloadService.product.laserkeilausaineisto_5p.page.subtitle.termsofuse')}</h3>
        <p>
          {t('fileDownloadService.product.laserkeilausaineisto_5p.page.p1')}
          <br />
          <ExternalLink
            href={t('fileDownloadService.product.laserkeilausaineisto_5p.metadata.terms.link')}
            text={t('fileDownloadService.product.laserkeilausaineisto_5p.metadata.terms.name')}
          />
        </p>
      </section>
      <section className="margin-t-2">
        <h3>{t('fileDownloadService.product.laserkeilausaineisto_5p.page.subtitle.orderinfo')}</h3>
        <p>
          {t('fileDownloadService.product.laserkeilausaineisto_5p.page.p2')} <br />
          <ExternalLink
            href={t('fileDownloadService.product.laserkeilausaineisto_5p.pricelist.url')}
            text={t('fileDownloadService.product.laserkeilausaineisto_5p.pricelist.text')}
          />
        </p>
        <p>{t('fileDownloadService.product.laserkeilausaineisto_5p.page.p3')}</p>
        {!isError && isParametersLoaded && (
          <a
            target="_blank"
            href={t('fileDownloadService.product.laserkeilausaineisto_5p.login.url')}
            rel="noopener noreferrer"
            className="button button--blue button--narrow margin-t-2 authentication-link"
            onClick={trackLicencedDataFilesLogin}
          >
            <Glyphicon className="glyphicon-lock" glyph="lukko-auki" /> &nbsp;
            {t('fileDownloadService.product.laserkeilausaineisto_5p.login.text')}
            <Glyphicon glyph="avaa-uuteen-ikkunaan" />
          </a>
        )}
        {isError && <FileDownloadErrorMessage />}
      </section>
    </>
  );
};

export default Laser5pComponent;
