import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import { Glyphicon } from 'common/components/Icon';
import { ToggleSearchResults } from './ToggleSearchResultsBlock';

const ShowResultsOnMapButton = ({ handleShowOnMap }) => (
  <div
    data-testid="showResultsOnMap"
    role="button"
    tabIndex={0}
    onClick={handleShowOnMap}
    onKeyPress={handleShowOnMap}
    className="search-results__show-all-on-map search-results__button"
  >
    {t('search.results.showAllOnMap')}
    <Glyphicon glyph="nuoli-oikealle" />
  </div>
);

ShowResultsOnMapButton.propTypes = {
  handleShowOnMap: PropTypes.func.isRequired,
};

const TooManyResults = () => (
  <div className="search-results-count__too-many" data-testid="TooManyResults">
    {t('search.results.refineSearch')}
  </div>
);

const SearchResultsCount = ({ displayedCount, hasTooManyResults }) => (
  <div className="search-results-count">
    <div className="search-results-count__matches">
      {hasTooManyResults && `${t('search.results.first')} `}
      {displayedCount}&nbsp;
      {displayedCount === 1 ? t('search.results.count.one') : t('search.results.count.many')}
    </div>
    {hasTooManyResults && <TooManyResults />}
  </div>
);

SearchResultsCount.propTypes = {
  displayedCount: PropTypes.number.isRequired,
  hasTooManyResults: PropTypes.bool.isRequired,
};

const SearchResultActions = ({
  showAllOnMap,
  showResults,
  hideResults,
  showing,
  resultsLength,
  displayedCount,
  hasTooManyResults,
  sidebarIsOpen,
}) => (
  <div className="search-result-actions" data-testid="SearchResultActions">
    {sidebarIsOpen && <ToggleSearchResults showing={showing} toggleAction={showing ? hideResults : showResults} />}
    {showing && (
      <>
        <SearchResultsCount displayedCount={displayedCount} hasTooManyResults={hasTooManyResults} />
        {resultsLength > 1 && <ShowResultsOnMapButton handleShowOnMap={showAllOnMap} />}
      </>
    )}
  </div>
);

SearchResultActions.propTypes = {
  showAllOnMap: PropTypes.func.isRequired,
  showResults: PropTypes.func.isRequired,
  hideResults: PropTypes.func.isRequired,
  showing: PropTypes.bool.isRequired,
  resultsLength: PropTypes.number.isRequired,
  displayedCount: PropTypes.number.isRequired,
  hasTooManyResults: PropTypes.bool.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

export default SearchResultActions;
