import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ScrollerPanelListItem as ListItem } from 'common/components/ScrollerPanel';
import { Glyphicon } from 'common/components/Icon';
import ScrollerPanelContext from '../ScrollerPanel/ScrollerPanelContext';

const InfoItem = ({ title = '', hidden = false, regionId, children, itemType }) => {
  const [showContent, setShowContent] = useState(false);
  const { setActiveListButtonSelected } = useContext(ScrollerPanelContext);
  const activitiesLoading = useSelector(state => state.activities.activitiesLoading);
  const realEstatesLoaded = useSelector(state => state.realEstateList.realEstatesLoaded);
  const apartmentsLoaded = useSelector(state => state.apartmentList.apartmentsLoaded);

  const toggleContent = () => {
    setShowContent(prevShowContent => !prevShowContent);
    if (!activitiesLoading && realEstatesLoaded && apartmentsLoaded) {
      setActiveListButtonSelected(prevState => ({
        ...prevState,
        [itemType]: !prevState[itemType],
      }));
    }
  };

  return (
    <ListItem className="info-item">
      <button
        id={`${regionId}-button`}
        type="button"
        className="button button--small info-item__toggle-button-link"
        tabIndex={hidden ? -1 : null}
        onClick={toggleContent}
        aria-controls={regionId}
        aria-expanded={showContent}
      >
        {title}&nbsp;
        <Glyphicon glyph={showContent ? 'nuoli-ylos' : 'nuoli-alas'} />
      </button>
      {showContent && children}
    </ListItem>
  );
};

InfoItem.propTypes = {
  title: PropTypes.string,
  hidden: PropTypes.bool,
  regionId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  itemType: PropTypes.string.isRequired,
};

InfoItem.defaultProps = {
  title: '',
  hidden: false,
};

export default InfoItem;
