import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KasiIconsFontAwesomeLib } from 'common/components/Icon/kasiFontAwesomeIcons';
import cls from 'classnames';

const getIconFlipValue = name => {
  const kasiIcon = KasiIconsFontAwesomeLib.find(icon => icon.iconName === name);
  if (!kasiIcon) {
    console.warn(`Unknown icon name "${name}" given to KasiApp Glyphicon`);
    return undefined;
  }
  return kasiIcon.flip ? { flip: kasiIcon.flip } : {};
};

const Glyphicon = ({ glyph, className, alt, ...rest }) => (
  <FontAwesomeIcon
    icon={glyph}
    className={cls('glyphicon', 'kasi-icon', className)}
    {...rest}
    {...getIconFlipValue(glyph)}
  >
    <desc>{alt}</desc>
  </FontAwesomeIcon>
);

Glyphicon.propTypes = {
  glyph: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
};

Glyphicon.defaultProps = {
  className: '',
  alt: '',
};

export default Glyphicon;
