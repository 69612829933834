import CookieNotice, {
  acceptCookie,
  clearCookieAcceptance,
  cookieIsAccepted,
  STORAGE_KEY,
  IGNORE_LANG,
} from './CookieNotice';

export default CookieNotice;
export { acceptCookie, clearCookieAcceptance, cookieIsAccepted, STORAGE_KEY, IGNORE_LANG };
