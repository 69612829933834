import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'common/components/Icon';
import * as RealEstateTypes from 'realEstateSite/constants/RealEstate';

const GlyphMap = {
  A: 'autopaikka',
  M: 'muuTila',
  H: 'huoneisto',
};

export const getApartmentGlyph = (code, langCode) => `${GlyphMap[code]}-${langCode}`;

export const USUFRUCTS_TYPE = 'usufructs';
export const UNLEGALIZED_USUFRUCT_TYPE = 'unlegalizedUsufruct';

const RealEstateTypeIcon = ({ type, apartmentCode }) => {
  const {
    i18n: { language },
  } = useTranslation();

  if (type === RealEstateTypes.FACILITY_TYPE) {
    return (
      <Glyphicon glyph={`vuokraoikeus-pinni-${language}`} className="real-estate-list-item__lifeline-icon--blue" />
    );
  }
  if (type === RealEstateTypes.PROPERTY_TYPE) {
    return <Glyphicon glyph={`maara-ala-pinni-${language}`} className="real-estate-list-item__lifeline-icon--orange" />;
  }
  if (type === RealEstateTypes.APARTMENT_TYPE) {
    return (
      <Glyphicon
        glyph={getApartmentGlyph(apartmentCode, language)}
        className="real-estate-list-item__lifeline-icon--green"
      />
    );
  }
  if (type === USUFRUCTS_TYPE) {
    return <Glyphicon glyph="kayttooikeusyksikko-pinni" className="real-estate-list-item__lifeline-icon--turquoise" />;
  }
  if (type === UNLEGALIZED_USUFRUCT_TYPE) {
    return <Glyphicon glyph="kayttooikeusyksikko-pinni" className="real-estate-list-item__lifeline-icon--purple" />;
  }
  return <Glyphicon glyph={`kiinteisto-pinni-${language}`} className="real-estate-list-item__lifeline-icon--red" />;
};

RealEstateTypeIcon.propTypes = {
  type: PropTypes.oneOf([
    RealEstateTypes.REGISTER_UNIT_TYPE,
    RealEstateTypes.PROPERTY_TYPE,
    RealEstateTypes.FACILITY_TYPE,
    RealEstateTypes.APARTMENT_TYPE,
    USUFRUCTS_TYPE,
    UNLEGALIZED_USUFRUCT_TYPE,
  ]).isRequired,
  apartmentCode: PropTypes.string,
};

RealEstateTypeIcon.defaultProps = {
  apartmentCode: null,
};

export default RealEstateTypeIcon;
