import React from 'react';
import { t } from 'i18next';

const NoSearchResults = React.forwardRef((props, ref) => (
  <ul ref={ref} className="search-result-list" data-testid="NoSearchResults">
    <li className="search-result__item search-no-results">{t('search.results.noResults')}</li>
  </ul>
));

export default NoSearchResults;
