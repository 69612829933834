import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import GroupedSearchResult from './GroupedSearchResult';
import { groupedResults } from '../groupingHelpers';
import SearchResult from './SearchResult';
import RESULT_PROP_TYPE from '../ResultPropType';

const SearchResultList = React.forwardRef(({ showOnMap, searchString, results }, ref) => (
  <ul ref={ref} className="search-result-list" data-testid="SearchResultList">
    {groupedResults(results, searchString).map(resultSet => {
      const result = resultSet[0];
      return resultSet.length === 1 ? (
        <SearchResult searchString={searchString} key={uniqueId('key-')} result={result} showOnMap={showOnMap} />
      ) : (
        <GroupedSearchResult
          searchString={searchString}
          key={['key', result.region, uniqueId()].join('-')}
          results={resultSet}
          showOnMap={showOnMap}
          resultsLength={results.length}
        />
      );
    })}
  </ul>
));

SearchResultList.propTypes = {
  results: PropTypes.arrayOf(RESULT_PROP_TYPE).isRequired,
  searchString: PropTypes.string.isRequired,
  showOnMap: PropTypes.func.isRequired,
};

export default SearchResultList;
