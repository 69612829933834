import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Block, ExternalLink, Row } from '../../../components';
import { FormContentGroup } from '../../Forms/Blocks';

const MetadataComponent = ({ product }) => {
  const { t } = useTranslation();
  return (
    <Row className="margin-t-2">
      <FormContentGroup halfLegendBottomMargin legend={t('fileDownloadService.metadata.title')}>
        <Block size={6}>
          {product.metadata.map(metadata => (
            <div key={t(metadata.link)} id={metadata.name}>
              <ExternalLink href={t(metadata.link)} text={t(metadata.name)} />
            </div>
          ))}
        </Block>
      </FormContentGroup>
    </Row>
  );
};

MetadataComponent.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    metadata: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string.isRequired, link: PropTypes.string.isRequired })
    ).isRequired,
  }).isRequired,
};

export default MetadataComponent;
