import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import useAutomaticKeyboardFocus from 'common/containers/AutomaticKeyboardFocus/useAutomaticKeyboardFocus';
import { Row, Block } from '../Grid';
import Ruler from '../Ruler';

const Header = ({ text, children, className }) => {
  const titleRef = useRef(null);
  const autoFocus = useSelector(state => state.automaticKeyboardFocus);
  const focusTitle = autoFocus?.selector?.length > 0 && className.includes(autoFocus?.selector);

  useAutomaticKeyboardFocus(focusTitle, titleRef);

  return (
    <h2 className={cls('header-title', 'margin-b-1', className)} tabIndex="-1" ref={titleRef}>
      <span className="header-title__text margin-r-auto">{text}</span>
      {children && <span className="step__indicator">{children}</span>}
    </h2>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Header.defaultProps = {
  children: undefined,
  className: '',
};

const Subtitle = ({ text, className }) => {
  if (text) {
    return <h3 className={cls(className)}>{text}</h3>;
  }
  return null;
};

Subtitle.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};
Subtitle.defaultProps = {
  className: '',
  text: undefined,
};

const MainHeader = ({ text, className, headerClassName, children }) => (
  <Row className={className}>
    <Block size="6">
      <Header text={text} className={headerClassName}>
        {children}
      </Header>
      <Ruler large blue />
    </Block>
  </Row>
);

MainHeader.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  children: PropTypes.node,
};

MainHeader.defaultProps = {
  className: '',
  headerClassName: '',
  children: null,
};

export default Header;
export { MainHeader, Subtitle };
