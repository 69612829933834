import queryString from 'query-string';
import * as types from 'common/containers/OskariMap/OskariMapActionTypes';
import * as OskariMap from 'oskari/OskariMap';
import { t } from 'i18next';
import { MAP_MOVED_LISTENER_COORDINATES } from 'common/constants/EventListenerIds';
import {
  addInfoNotificationAction,
  addErrorNotificationAction,
} from 'common/containers/AppNotifications/AppNotificationsActions';
import { calculateDistance, selectAutomaticZoomLevel } from 'common/helpers/Geography/geographicFunctions';
import { SIGNIFICANT_DISTANCES } from 'common/helpers/Geography/geographicConstants';
import { selectedMapScale, selectedPaperAlign, selectedPaperSize } from '../MapStoreCustomMapsForm/CustomMapsHelpers';
import { APP_MODE_CUSTOM_MAPS } from '../KasiAppMode/KasiAppModes';
import { accessibleNotificationAdded } from '../AccessibleNotifications/accessibleNotificationsSlice';
import { ASSERTIVE, POLITE } from '../AccessibleNotifications/AccessibleNotificationsConstants';

export const toggleMapBackButtonVisibility = visible => ({
  type: types.TOGGLE_MAP_BACK_BUTTON_VISIBILITY,
  visible,
});

export function enableIframeEvents(enabled) {
  return {
    type: types.ENABLE_IFRAME_EVENTS,
    enabled,
  };
}

function setOskariMapCoordinates(coords) {
  return {
    type: types.SET_OSKARI_MAP_COORDINATES,
    coords,
  };
}

export function updateCurrentOskariMapCoordinatesThunk() {
  return dispatch =>
    new Promise(resolve => {
      OskariMap.getCurrentMapPosition(data => {
        dispatch(setOskariMapCoordinates(data));
        resolve();
      });
    });
}

const setOskariIframeOffset = () => {
  const oskariIframe = document.getElementById('js-oskari-iframe');
  return oskariIframe
    ? {
        type: types.SET_OSKARI_MAP_OFFSET,
        width: oskariIframe.offsetWidth,
        height: oskariIframe.offsetHeight,
      }
    : {};
};

function toggleMapIsLoading(isLoading) {
  return {
    type: types.MAP_LOADING,
    isLoading,
  };
}

export function toggleLoadingAnimationThunk(isVisible) {
  return dispatch => {
    dispatch(toggleMapIsLoading(isVisible));
    OskariMap.showLoadAnimation(isVisible);
  };
}

function toggleIsTrackingUser(trackingUser) {
  return {
    type: types.SET_TRACKING_USER,
    trackingUser,
  };
}

export function toggleTrackingStateThunk(isTracking) {
  return dispatch => {
    dispatch(toggleIsTrackingUser(isTracking));
  };
}

export function setPixelMeasuresInScale(measures) {
  return {
    type: types.SET_OSKARI_MAP_PIXEL_MEASURES_IN_SCALE,
    measures,
  };
}

function setVisuMapMeasuresInScale(measures) {
  return {
    type: types.SET_VISU_MAP_MEASURES_IN_SCALE,
    measures,
  };
}

export const toggleAutomaticMapCentering = enabled => ({
  type: types.TOGGLE_AUTOMATIC_MAP_CENTERING,
  enabled,
});

function setOskariMapCenterCoordinates(coords) {
  return {
    type: types.SET_OSKARI_MAP_CENTER_COORDINATES,
    coords,
  };
}

function setOskariMapMovedAutomatically(value) {
  return {
    type: types.SET_OSKARI_MAP_MOVED_AUTOMATICALLY,
    value,
  };
}

export function zoomToThunk(to) {
  return dispatch => {
    dispatch(toggleAutomaticMapCentering(false));
    OskariMap.zoomTo(to);
  };
}

export function zoomInThunk() {
  return dispatch => {
    dispatch(toggleAutomaticMapCentering(false));
    OskariMap.zoomIn();
  };
}

export function zoomOutThunk() {
  return dispatch => {
    dispatch(toggleAutomaticMapCentering(false));
    OskariMap.zoomOut();
  };
}

export function moveMapThunk(x, y, zoomLevel) {
  return dispatch => {
    dispatch(setOskariMapMovedAutomatically(true));
    OskariMap.moveMap(x, y, zoomLevel);
  };
}

export const setOskariPixelMeasuresInScaleThunk = ({ postCallback, paperType, mapScale }) => dispatch => {
  const paperSize = paperType;
  const paperAlignVal = selectedPaperAlign(paperSize);
  const paperSizeVal = selectedPaperSize(paperSize);
  const mapScaleVal = selectedMapScale(mapScale);
  const printPreviewAreaPaperCode = 999;
  OskariMap.getPixelMeasuresInScale(paperAlignVal, mapScaleVal, paperSizeVal, data => {
    dispatch(setPixelMeasuresInScale(data));
    if (postCallback) postCallback();
  });
  OskariMap.getPixelMeasuresInScale(paperAlignVal, mapScaleVal, printPreviewAreaPaperCode, data => {
    dispatch(setVisuMapMeasuresInScale(data));
    if (postCallback) postCallback();
  });
};

export function setZoomLevel(zoomLevel) {
  return {
    type: types.SET_ZOOM_LEVEL,
    zoomLevel,
  };
}

export const addMapMovedListenerThunk = () => (dispatch, getState) => {
  OskariMap.moved(MAP_MOVED_LISTENER_COORDINATES, coords => {
    dispatch(updateCurrentOskariMapCoordinatesThunk());
    dispatch(setZoomLevel(coords.zoom));
    if (getState().appMode?.mode === APP_MODE_CUSTOM_MAPS) dispatch(setOskariIframeOffset());

    if (getState()?.oskariIframe?.mapMovedAutomatically) {
      dispatch(setOskariMapMovedAutomatically(false));
    } else {
      dispatch(toggleAutomaticMapCentering(false));
    }
  });
};

export function toggleCoordinateToolThunk() {
  return () => {
    OskariMap.toggleCoordinateConversionToolbar();
  };
}

export function showCrosshairThunk() {
  return () => OskariMap.showCrosshair();
}

export function disableClickSelectThunk() {
  return () => OskariMap.disableClickSelect(true);
}

export function enableClickSelectThunk() {
  return () => OskariMap.disableClickSelect(false);
}

const userMovedEnough = (coords, centerCoords, zoomLevel = 0) => {
  if (centerCoords == null || centerCoords.lat == null || centerCoords.lon == null) {
    return true;
  }

  return (
    calculateDistance(centerCoords.lat, centerCoords.lon, coords.lat, coords.lon) >= SIGNIFICANT_DISTANCES[zoomLevel]
  );
};

let hasLocation = false;

const showGeolocationError = dispatch => {
  dispatch(addErrorNotificationAction(t('map.controls.currentLocation.error')));
  dispatch(accessibleNotificationAdded({ text: t('map.controls.currentLocation.error'), type: ASSERTIVE }));
  dispatch(toggleLoadingAnimationThunk(false));
};

const handleUserLocationEvent = (dispatch, getState) => data => {
  if (data.error && data.error !== 'timeout') {
    showGeolocationError(dispatch);
  } else if (data.error === 'timeout' && !hasLocation) {
    // Show timeout error only if first location has not found. Getting location can sometimes timeout during tracking
    // so better not show big notification for those errors. However if first attempt to aquire location timeouts
    // there might be some issue with users device.
    showGeolocationError(dispatch);
  } else if (data.lon && data.lat) {
    hasLocation = true;
    const zoomLevel = getState()?.oskariIframe?.zoom;

    if (
      getState()?.oskariIframe?.automaticMapCentering &&
      userMovedEnough(data, getState()?.oskariIframe?.centerCoords, zoomLevel)
    ) {
      dispatch(moveMapThunk(data.lon, data.lat, selectAutomaticZoomLevel(data.accuracy, zoomLevel)));
      dispatch(setOskariMapCenterCoordinates(data));
    }

    if (getState().oskariIframe.isLoading) {
      dispatch(toggleLoadingAnimationThunk(false));
    }
  }
};

export function addUserLocationListenerThunk() {
  return (dispatch, getState) => {
    OskariMap.activateUserLocationEventListener(handleUserLocationEvent(dispatch, getState));
  };
}

export function trackUserLocationThunk(isTracking) {
  return async dispatch => {
    await OskariMap.mapIsReady();
    hasLocation = false;
    dispatch(toggleLoadingAnimationThunk(isTracking));
    dispatch(toggleAutomaticMapCentering(isTracking));
    dispatch(toggleTrackingStateThunk(isTracking));
    OskariMap.trackUserLocation(isTracking);
  };
}

export const SCREENSHOT_MAP_TILE_LOADING_DELAY = 1000;

export function getScreenshotThunk(cb) {
  const infoMsg = t('print.notification');

  return dispatch => {
    const takeScreenshotThenDone = () => {
      OskariMap.getScreenshot(data => {
        cb(data);
        dispatch(toggleLoadingAnimationThunk(false));
      });
    };

    dispatch(toggleLoadingAnimationThunk(true));
    dispatch(addInfoNotificationAction(infoMsg));
    dispatch(accessibleNotificationAdded({ text: infoMsg, type: POLITE }));

    setTimeout(takeScreenshotThenDone, SCREENSHOT_MAP_TILE_LOADING_DELAY);
  };
}

export function setInitialViewFromLinkThunk(windowLocation) {
  return dispatch => {
    const { n, e, zoom } = queryString.parse(windowLocation.search);
    const northCoordinate = parseInt(n, 10);
    const eastCoordinate = parseInt(e, 10);

    if (northCoordinate && eastCoordinate) {
      dispatch(moveMapThunk(eastCoordinate, northCoordinate, parseInt(zoom, 10) || undefined));
    }
  };
}
