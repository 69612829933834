import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Analytics from 'common/helpers/Analytics';
import { useTranslation } from 'react-i18next';

export function makeTitle(title, template) {
  if (!template || template === '') {
    return title;
  }
  return template.replace(/%s/g, title);
}

const usePageTitle = ({ title, template, trackPageView = true }) => {
  // Track back button usage by monitoring pathname:
  const { pathname } = useLocation();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (title) document.title = makeTitle(t(title), t(template));
    if (title && trackPageView === true) {
      Analytics().trackPageView(t(title));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, template, title, trackPageView, pathname]);
};

export default usePageTitle;
