import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Row, FlexGrid } from 'common/components';
import {
  addValidationNotificationAction,
  deleteFormValidationNotification,
} from 'common/containers/AppNotifications/AppNotificationsActions';
import NotificationComponent from './NotificationComponent';
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_INFO,
} from './AppNotificationsActionTypes';
import { accessibleNotificationAdded } from '../AccessibleNotifications/accessibleNotificationsSlice';
import { ASSERTIVE } from '../AccessibleNotifications/AccessibleNotificationsConstants';

export const MAX_VISIBLE = 1;

const AppNotificationsComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errorMsg, formId } = useSelector(state => state.form);
  const appNotifications = useSelector(state => state.appNotifications);

  const showValidationNotification = useCallback(() => {
    dispatch(deleteFormValidationNotification(formId));
    dispatch(addValidationNotificationAction(errorMsg, formId));
    dispatch(accessibleNotificationAdded({ text: errorMsg, type: ASSERTIVE }));
  }, [dispatch, errorMsg, formId]);

  useEffect(() => {
    if (errorMsg) showValidationNotification();
  }, [errorMsg, showValidationNotification]);

  const priorities = [NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_WARNING, NOTIFICATION_TYPE_INFO];
  function comparePriorities(a, b) {
    if (priorities.indexOf(a.type) < priorities.indexOf(b.type)) {
      return -1;
    }
    if (priorities.indexOf(a.type) > priorities.indexOf(b.type)) {
      return 1;
    }
    if (a.type === NOTIFICATION_TYPE_WARNING && b.type === NOTIFICATION_TYPE_WARNING) {
      // non-bulletin warning message gets priority over bulletin message
      if (a.isBulletinMessage === false && b.isBulletinMessage === true) {
        return 1;
      }
      if (b.isBulletinMessage === false && a.isBulletinMessage === true) {
        return -1;
      }
    }
    return 0;
  }

  const visibleAppNotifications = structuredClone(appNotifications)
    .reverse()
    .sort(comparePriorities)
    .filter(n => n.visible === true);
  const isAnyVisible = visibleAppNotifications.length > 0;

  const notificationTagCreator = n => (
    <NotificationComponent
      message={n.message}
      type={n.type}
      visible={n.visible}
      date={n.date}
      link={n.link}
      isBulletinMessage={n.isBulletinMessage}
      autoHide={n.autoHide}
      id={n.id}
      key={`nid_${n.id}`}
      disableCloseButton={n.disableCloseButton}
      isValidationMessage={n.isValidationMessage}
    />
  );

  const notificationTags = visibleAppNotifications.map(notificationTagCreator).slice(0, MAX_VISIBLE);

  const appNotificationClassNames = classNames('app-notifications', 'js-app-notifications', {
    open: isAnyVisible,
    closed: !isAnyVisible,
    more: visibleAppNotifications.length > MAX_VISIBLE,
  });
  const unseenCount = visibleAppNotifications.length - MAX_VISIBLE;
  const countLabel = unseenCount > 0 ? [t('notifications.unseenCountLabel'), ': ', unseenCount].join('') : '';
  return (
    <FlexGrid className={appNotificationClassNames}>
      <Row className="notification__count">
        <span>{countLabel}</span>
      </Row>
      {notificationTags}
    </FlexGrid>
  );
};

export default AppNotificationsComponent;
