import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { compareRealEstatesByCustomId } from 'common/helpers/Sorting/Sorting';
import { fetchPublicRegisterUnitForPoint } from 'common/api/CommonApi';
import ServicesApi from '../../../realEstateSite/api/ServicesApi';
import { setRegisterState } from '../../../realEstateSite/containers/Realty/RealEstate/RealEstateList/RegisterStateActions';
import { getReduxStore } from '../../../common/helpers/storage';

// Because this component is passed to iframe as HTML string, we have to use inline styling.
const containerStyle = { marginBottom: '0.5rem', marginTop: '0.5rem' };
const titleStyle = { paddingBottom: '0' };
const subtitleStyle = { fontWeight: 'normal' };
const dlStyle = { marginTop: '0.5rem' };
const ddStyle = { marginBottom: '0.5rem' };
const listStyle = { listStyle: 'none', marginLeft: '0', paddingLeft: '0.5em', textIndent: '-0.5em' };
const inlineStyle = { display: 'flex' };

const ParcelInfo = ({ properties, neighbourParcels }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <div style={containerStyle}>
      <h4 style={titleStyle}>
        <div>{properties.id}</div>
        {properties.name && <div style={subtitleStyle}>{properties.name}</div>}
        <div style={subtitleStyle}>{properties.municipalityNames[language]}</div>
      </h4>
      <dl style={dlStyle}>
        <div style={inlineStyle}>
          <dt>{`${t('realEstate.parcelNumber.text')}\u00a0`}</dt>
          <dd style={ddStyle}>{properties.parcelNumber}</dd>
        </div>
      </dl>
      {neighbourParcels && (
        <div>
          <div style={{ fontWeight: 'bold' }}>
            {properties.parcelNumber > 1
              ? t('realEstate.neighbourRealEstates.parcel')
              : t('realEstate.neighbourRealEstates.title')}
            :
          </div>
          <ul style={listStyle}>
            {neighbourParcels.map(({ realEstateId, realEstateRelationship }) => (
              <li key={realEstateId}>
                {realEstateId}{' '}
                {realEstateRelationship !== 0 && (
                  <div>{t(`realEstate.realEstateRelationship.${realEstateRelationship}`)}</div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

ParcelInfo.propTypes = {
  properties: PropTypes.shape({
    id: PropTypes.string,
    registerUnitTypeText: PropTypes.shape({
      fi: PropTypes.string,
      sv: PropTypes.string,
    }),
    name: PropTypes.string,
    parcelNumber: PropTypes.number,
    municipalityNames: PropTypes.shape({
      fi: PropTypes.string,
      sv: PropTypes.string,
    }),
  }).isRequired,
  neighbourParcels: PropTypes.arrayOf(
    PropTypes.shape({
      realEstateId: PropTypes.string.isRequired,
      realEstateRelationship: PropTypes.number.isRequired,
    })
  ),
};
ParcelInfo.defaultProps = {
  neighbourParcels: null,
};

export const createParcelInfo = async properties => {
  const store = getReduxStore();
  const registerStateDate = store.getState().realEstateList?.registerStateDate;
  const realtyProperties = await ServicesApi.searchRealEstates(properties.realtyIdentifier);
  store.dispatch(setRegisterState(realtyProperties[0]?.registerStateDate, registerStateDate));
  const neighbourParcels = await ServicesApi.fetchNeighbourParcels(properties.description);
  store.dispatch(setRegisterState(neighbourParcels[0]?.registerStateDate, registerStateDate));
  return {
    body: ReactDOMServer.renderToString(
      <ParcelInfo
        properties={realtyProperties[0]}
        neighbourParcels={[...neighbourParcels].sort(compareRealEstatesByCustomId('realEstateId'))}
      />
    ),
    title: realtyProperties[0].registerUnitTypeText ? realtyProperties[0].registerUnitTypeText[i18next.language] : '',
    neighbourParcels,
  };
};

export const createMapSiteParcelInfo = async coords => {
  const lat = coords.n;
  const lon = coords.e;

  const realtyProperties = await fetchPublicRegisterUnitForPoint(lat, lon);
  return {
    body: ReactDOMServer.renderToString(<ParcelInfo properties={realtyProperties} />),
    title: realtyProperties.registerUnitTypeText
      ? realtyProperties.registerUnitTypeText[i18next.language] || realtyProperties.registerUnitTypeText.fi
      : '',
    numberOfParcels: realtyProperties.parcelNumber,
  };
};
