import React, { useEffect } from 'react';
import { Field, useForm, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Row } from 'common/components';
import { FormContentBlock } from 'common/containers/Forms/Blocks';
import { ValidatedSelect } from 'common/containers/Forms/InputValidation/InputValidationComponents';

import { FIELD_NAMES } from '../FileDownloadServiceConstants';
import { toFileFormatOptions, useProductParameters } from '../FileDownloadServiceHelpers';

const { FILE_FORMAT } = FIELD_NAMES;

const FileFormatSelectionComponent = () => {
  const { t } = useTranslation();
  const { change } = useForm();
  const fileFormats = toFileFormatOptions(useProductParameters('fileTypes'));

  const {
    input: { value: productId },
  } = useField(FIELD_NAMES.PRODUCT_ID);

  useEffect(() => {
    if (fileFormats?.length === 1 || productId === 'korkeusmalli') {
      change(FILE_FORMAT, fileFormats[0]);
    }
  }, [change, fileFormats, productId]);

  return (
    <Row className="margin-t-1" dataCy={FILE_FORMAT}>
      <FormContentBlock label={t('fileDownloadService.fileFormatSelection.title')} id={FILE_FORMAT}>
        <Field
          name={FILE_FORMAT}
          component={ValidatedSelect}
          options={fileFormats}
          ariaLabel={t('fileDownloadService.fileFormatSelection.title')}
        />
      </FormContentBlock>
    </Row>
  );
};

export default FileFormatSelectionComponent;
