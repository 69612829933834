import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideServiceClosedModal } from 'common/containers/ServiceClosedModal/ServiceClosedModalActions';

import ServiceClosedModal from './ServiceClosedModal';

function mapStateToProps(state) {
  return {
    isOpen: state.serviceClosed.isOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: bindActionCreators(hideServiceClosedModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceClosedModal);
