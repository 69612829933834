import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const ScrollerColumnContext = createContext();

export function ScrollerColumnProvider({ targetColumnNumber, hiddenColumn, children }) {
  return (
    <ScrollerColumnContext.Provider
      value={useMemo(() => ({ targetColumnNumber, hiddenColumn }), [targetColumnNumber, hiddenColumn])}
    >
      {children}
    </ScrollerColumnContext.Provider>
  );
}

ScrollerColumnProvider.propTypes = {
  targetColumnNumber: PropTypes.number.isRequired,
  hiddenColumn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ScrollerColumnContext;
