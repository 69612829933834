import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const EuroInput = ({ input, className, error }) => (
  <div className={cls('euro-text-input', className)}>
    <input type="text" className={cls('text-input', 'asiointi-text-input', error && 'error')} {...input} />
    <span className={cls('right-combo', error && 'error')}>€</span>
  </div>
);

EuroInput.propTypes = {
  error: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

EuroInput.defaultProps = {
  className: '',
  error: false,
};

export default EuroInput;
