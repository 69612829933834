import React from 'react';
import PropTypes from 'prop-types';
import { Block, ExternalLink } from 'common/components';
import { useTranslation } from 'react-i18next';

const ExternalListLink = ({ link, text, disabledLangs }) => {
  const {
    i18n: { language },
  } = useTranslation();

  if (disabledLangs.includes(language)) {
    return null;
  }
  return (
    <li>
      <ExternalLink className="link-list__item" href={link} text={text} />
    </li>
  );
};

ExternalListLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabledLangs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const FooterBlock = ({ heading, links }) => (
  <Block desktop="3" mobile="6">
    <h2 className="footer__heading">{heading}</h2>
    <ul className="footer__link-list">
      {links.map(link => (
        <ExternalListLink
          key={link.text}
          link={link.href}
          text={link.text}
          disabledLangs={link.disabledLangs ? link.disabledLangs : []}
        />
      ))}
    </ul>
  </Block>
);

FooterBlock.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      disabledLangs: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  heading: PropTypes.string.isRequired,
};

export default FooterBlock;
