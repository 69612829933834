import React from 'react';
import PropTypes from 'prop-types';
import BusinessPanelComponent from 'common/containers/BusinessPanel/BusinessPanelComponent';
import Footer from 'mapSite/containers/Footer/MapSiteFooter';
import usePageTitle from 'common/hooks/usePageTitle';

const FeedbackMapFormPage = ({ children }) => {
  usePageTitle({
    title: 'feedback.appfeedback.documentTitle',
    template: 'app.documentTitle.template',
  });

  return (
    <BusinessPanelComponent showCurtain>
      {children}
      <Footer />
    </BusinessPanelComponent>
  );
};

FeedbackMapFormPage.propTypes = {
  children: PropTypes.node,
};

FeedbackMapFormPage.defaultProps = {
  children: null,
};

export default FeedbackMapFormPage;
