import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import { Glyphicon } from 'common/components/Icon';
import Ruler from 'common/components/Ruler';
import { ACTIVE_MENU_ITEM } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';
import useAutomaticKeyboardFocus from 'common/containers/AutomaticKeyboardFocus/useAutomaticKeyboardFocus';
import MapButton from 'realEstateSite/containers/Realty/RealEstate/common/MapButtonComponent';
import ListItem from './ScrollerPanelListItem';

const TitleWithIcon = ({ glyph, title, longTitle, className }) => {
  const dispatch = useDispatch();
  const titleRef = useRef(null);
  const autoFocus = useSelector(state => state.automaticKeyboardFocus);
  const Heading = longTitle ? 'h3' : 'h2';
  const focusTitle = autoFocus?.selector?.length > 0 && className.includes(autoFocus?.selector);

  useAutomaticKeyboardFocus(focusTitle, titleRef);

  const onKeyDown = event => {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      dispatch(requestKeyboardFocusAction({ selector: ACTIVE_MENU_ITEM }));
    }
  };

  return (
    <div className={cls('panel-list-item-title__with-icon', className)}>
      <Glyphicon glyph={glyph} />
      <div>
        <Heading ref={titleRef} tabIndex="-1" onKeyDown={onKeyDown}>
          <span>{title}</span>
        </Heading>
        <Ruler className="margin-t-1" />
      </div>
    </div>
  );
};

TitleWithIcon.propTypes = {
  glyph: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  longTitle: PropTypes.bool,
  className: PropTypes.string,
};

TitleWithIcon.defaultProps = {
  longTitle: false,
  className: '',
};

const ListTitle = ({ title, h3, className }) => {
  const dispatch = useDispatch();
  const titleRef = useRef(null);
  const autoFocus = useSelector(state => state.automaticKeyboardFocus);
  const Heading = h3 ? 'h3' : 'h2';
  const focusTitle = autoFocus?.selector?.length > 0 && className.includes(autoFocus?.selector);

  useAutomaticKeyboardFocus(focusTitle, titleRef);

  const onKeyDown = event => {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      dispatch(requestKeyboardFocusAction({ selector: ACTIVE_MENU_ITEM }));
    }
  };

  return (
    <ListItem>
      <Heading ref={titleRef} tabIndex="-1" onKeyDown={onKeyDown} className={cls('panel-list-item__title', className)}>
        <span>{title}</span>
      </Heading>
    </ListItem>
  );
};

ListTitle.propTypes = {
  title: PropTypes.string.isRequired,
  h3: PropTypes.bool,
  className: PropTypes.string,
};

ListTitle.defaultProps = {
  h3: false,
  className: '',
};

const ListSubTitle = ({ title, h4 }) => {
  const Heading = h4 ? 'h4' : 'h3';
  return (
    <ListItem>
      <Heading className="panel-list-item__title panel-list-item__title--sub">{title}</Heading>
    </ListItem>
  );
};

ListSubTitle.propTypes = {
  title: PropTypes.string.isRequired,
  h4: PropTypes.bool,
};

ListSubTitle.defaultProps = {
  h4: false,
};

const ListTitleWithMapButton = ({ title, onMobile, showButton = true, hidden, withLifeline }) => (
  <ListItem className={cls({ 'lifeline-title': withLifeline })}>
    <div className="panel-list-item-title__wrapper">
      <h2 className="panel-list-item__title">{title}</h2>
      {onMobile && showButton && <MapButton tabIndex={hidden ? -1 : 0} />}
    </div>
  </ListItem>
);

ListTitleWithMapButton.propTypes = {
  title: PropTypes.string.isRequired,
  onMobile: PropTypes.bool,
  showButton: PropTypes.bool,
  hidden: PropTypes.bool,
  withLifeline: PropTypes.bool,
};

ListTitleWithMapButton.defaultProps = {
  onMobile: false,
  hidden: false,
  showButton: true,
  withLifeline: false,
};
export default TitleWithIcon;
export { ListTitle, ListSubTitle, ListTitleWithMapButton };
