import {
  IS_BORDER_SEPARATORS,
  IS_COORDINATE_CROSS,
  IS_REAL_ESTATE_IDS,
  IS_REAL_ESTATE_INFO,
  MAP_FEATURE_DETAILS,
  PAPER_TYPE_OPTIONS,
} from './CustomMapsFormConstants';

export const selectedPaperAlign = paperType => (paperType ? paperType.value.split('_')[0] : 'landscape');
export const selectedPaperSize = paperType => (paperType ? Number(paperType.value.split('_')[1]) : 1);
export const selectedMapScale = mapScale => (mapScale ? mapScale.value : 100000);

export const setMapDetailsByScale = (mapScale, change) => {
  setTimeout(() => {
    change(`${MAP_FEATURE_DETAILS}.${IS_BORDER_SEPARATORS}`, mapScale < 5000);
    change(`${MAP_FEATURE_DETAILS}.${IS_REAL_ESTATE_IDS}`, mapScale < 15000);
    change(`${MAP_FEATURE_DETAILS}.${IS_REAL_ESTATE_INFO}`, mapScale < 50000);
    change(`${MAP_FEATURE_DETAILS}.${IS_COORDINATE_CROSS}`, mapScale < 50000);
  });
};

const translatedPaperTypeLabel = paperType =>
  PAPER_TYPE_OPTIONS().find(option => option.value === paperType.value).label;

export const getMapInfoText = (paperType, mapScale) => `${translatedPaperTypeLabel(paperType)}, ${mapScale.label}`;
