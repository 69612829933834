import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { t } from 'i18next';
import { Glyphicon } from 'common/components/Icon';

const ShowMapButtonComponent = ({ className, style, title, onClick, withText, disabled, tabIndex }) => {
  const titleText = title != null ? title : t('button.showOnMap');
  return (
    <button
      type="button"
      className={cls('desktop-hide', 'button', 'button--blue', className, {
        'button--large button--icon-and-text': withText,
        'button--icon-large': !withText,
      })}
      style={style}
      onClick={onClick}
      disabled={disabled}
      title={titleText}
      tabIndex={tabIndex}
    >
      <Glyphicon glyph="nayta-kartalla" />
      {withText && <span>&nbsp;{titleText}</span>}
    </button>
  );
};

ShowMapButtonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  withText: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
};

ShowMapButtonComponent.defaultProps = {
  className: '',
  style: {},
  title: null,
  withText: false,
  disabled: false,
  tabIndex: 0,
};

export default ShowMapButtonComponent;
