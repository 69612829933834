import { FORM_ERROR } from 'final-form';
import { useNavigate, useParams } from 'react-router-dom';

import scrollTop from 'common/helpers/Scroller/Scroller';
import { FORM_SUBMIT_SUCCESS, FORM_SUBMIT_FAILED } from 'common/constants/Routes';
import INPUT_ERROR from 'common/constants/InputValidationConstants';

const useFormNavigationTools = ({
  isLastFormPage,
  formStepCount,
  onSubmit,
  onNext,
  headingRef,
  reset,
  hasNoSucceededPage,
}) => {
  const navigate = useNavigate();
  const { step } = useParams();

  const next = (newValues, page) => {
    navigate(`../${page || Math.min(parseInt(step, 10) + 1, formStepCount)}`, {
      state: { form: { isLegitSubmitPage: !!page } },
    });
    scrollTop();
    onNext(newValues);
    // NOTE: a bit of a hack to reset focus flow to the top of the form, so make it better if you can, I dare you.
    if (headingRef?.current) {
      headingRef.current.focus();
      headingRef.current.blur();
    }
  };

  const previous = () => {
    navigate(`../${Math.max(parseInt(step, 10) - 1, 0)}`);
    // NOTE: a bit of a hack to reset focus flow to the top of the form, so make it better if you can, I dare you.
    if (headingRef?.current) {
      headingRef.current.focus();
      headingRef.current.blur();
    }
  };

  const submitPage = async (vals, form) => {
    if (!isLastFormPage) {
      // NOTE: final-form told me to do this (~"use setTimeout for form.reset in submit")
      setTimeout(() => reset(form)(FORM_ERROR));
      return next(vals);
    }

    try {
      await onSubmit(vals);
      if (hasNoSucceededPage) return null;
      return next(vals, FORM_SUBMIT_SUCCESS);
    } catch (e) {
      console.error(e);
      next(vals, FORM_SUBMIT_FAILED);
      return { [FORM_ERROR]: e || INPUT_ERROR };
    }
  };

  return { previous, submitPage };
};

export default useFormNavigationTools;
