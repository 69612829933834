import { VISU_MAP_PRINT_PICTURE_SIZE_PX } from 'common/constants/Envs';
import { Paper, paperSizes } from 'common/helpers/Paper/Paper';
import wait from 'common/helpers/asyncHelpers';
import OskariRPC from 'oskari-rpc';
import { getConfiguration, getConfigurationAsInteger } from 'common/helpers/Configurations/Configurations';
import { initClickFeatureModule } from 'oskari/oskariModules/ClickFeature';
import initInfoBox, { hideInfobox } from './oskariModules/InfoBox';
import initMapModule, { removeAllMarkerFeatures, removeFeature, clearLayer } from './oskariModules/MapModule';
import initServicesMapModule, { setDisableClickSelect } from './oskariModules/ServicesMapModule';
import initDrawTools, { removeArea, removeDistance } from './oskariModules/DrawTools';
import initSearchModule from './oskariModules/Search';
import {
  createLayers,
  LAYER_APARTMENTS,
  LAYER_BOUNDARY_MARKERS,
  LAYER_PARCELS,
  LAYER_REAL_ESTATE_IDS,
  LAYER_TEMPORARY_MARKERS,
} from './layers/VectorLayers';

export * from './oskariModules/InfoBox';
export * from './oskariModules/MapModule';
export * from './oskariModules/DrawTools';
export * from './oskariModules/ServicesMapModule';
export * from './oskariModules/Search';
export * from './oskariModules/ClickFeature';

const getOskariMapLayerIds = () => ({
  taustakartta: getConfigurationAsInteger('oskari.layers.taustakartta'),
  maastokartta: getConfigurationAsInteger('oskari.layers.maastokartta'),
  ortokuvat: getConfigurationAsInteger('oskari.layers.ortokuvat'),
  vinovalo: getConfigurationAsInteger('oskari.layers.vinovalo'),
  kiinteistojaotus: getConfigurationAsInteger('oskari.layers.kiinteistojaotus'),
  kiinteistotunnukset: getConfigurationAsInteger('oskari.layers.kiinteistotunnukset'),
  selkokartta: getConfigurationAsInteger('oskari.layers.selkokartta'),
});

const getSecondaryOskariMapLayerIds = () => ({
  yksityistiet: getConfigurationAsInteger('oskari.layers.yksityistiet'),
});

let layerVisibilities = {
  taustakartta: false,
  maastokartta: true,
  ortokuvat: false,
  vinovalo: false,
  kiinteistojaotus: false,
  kiinteistotunnukset: false,
  selkokartta: false,
};

export const getOskariMapCadastralLayerIds = () => {
  const oskariMapLayerIds = getOskariMapLayerIds();
  return [oskariMapLayerIds.kiinteistojaotus, oskariMapLayerIds.kiinteistotunnukset];
};

export const getPrivateRoadsLayerId = () => {
  const otherOskariMapLayerIds = getSecondaryOskariMapLayerIds();
  return otherOskariMapLayerIds.yksityistiet;
};

export const OSKARI_CHANNEL_STATUS_IDS = {
  startingChannel: 'Starting channel',
  channelReady: 'Channel ready',
  channelNotReady: 'Channel not ready',
};

export const getOskariLayers = () => {
  return {
    ...getOskariMapLayerIds(),
  };
};

export const getSelectableLayers = () => Object.entries(getOskariLayers()).map(([, id]) => id);

export const initMapLayerVisibilities = visibilities => {
  layerVisibilities = visibilities;
  return layerVisibilities;
};

export const getMapLayerVisibilities = () => layerVisibilities;

export const ZOOM_MIN = 0;
export const ZOOM_MAX = 13;

const DEFAULT_ZOOM_LEVEL = 12;
const ZOOM_LEVELS_BY_PLACE_TYPES = [
  // 10 == JHS 14 ???
  { placeTypeCode: 'geographic-names', level: 10 },
];

let channel;

function channelUrl() {
  return getConfiguration('oskari.path');
}

// print preview area dims:
const visu_map_print_1px_in_mm = 0.28;
const visuMapPaperDims = VISU_MAP_PRINT_PICTURE_SIZE_PX * visu_map_print_1px_in_mm;
paperSizes[999] = new Paper(
  visuMapPaperDims,
  visuMapPaperDims,
  visuMapPaperDims,
  visuMapPaperDims,
  visuMapPaperDims,
  visuMapPaperDims
);

export const MAP_DOM_ID = 'js-oskari-iframe';

export const waitForElement = id => {
  return new Promise(resolve => {
    if (document.getElementById(id)) {
      resolve(document.getElementById(id));
    }
    const observer = new MutationObserver(() => {
      if (document.getElementById(id)) {
        resolve(document.getElementById(id));
        observer.disconnect();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export function destroyChannel() {
  if (channel) channel.destroy();
}

export function startChannel() {
  channel = OskariRPC.connect(document.getElementById(MAP_DOM_ID), channelUrl());

  if (window.location.hostname === 'localhost') {
    window.channel = channel;
  }
}

export function channelIsReady() {
  return channel != null && channel.isReady();
}

function handleRPCError(error, message) {
  console.error('OskariMap Service Error', error, message);
}

const checkOskariChannelStatus = async (delayIndex = 0) => {
  const delays = [1000, 2000, 3000, 5000];
  let channelStatus = OSKARI_CHANNEL_STATUS_IDS.startingChannel;
  if (channelIsReady()) {
    channelStatus = OSKARI_CHANNEL_STATUS_IDS.channelReady;
  } else if (delayIndex === delays.length) {
    channelStatus = OSKARI_CHANNEL_STATUS_IDS.channelNotReady;
  } else {
    await wait(delays[delayIndex]);
    channelStatus = await checkOskariChannelStatus(delayIndex + 1);
  }

  return channelStatus;
};

export function init(applicationContext = 'mapSite') {
  return new Promise(resolve => {
    try {
      startChannel();
    } catch (e) {
      if (channel == null) {
        throw e;
      }
    }
    checkOskariChannelStatus().then(channelStatus => {
      if (channelStatus === OSKARI_CHANNEL_STATUS_IDS.channelReady && channel != null) {
        channel.onReady(() => {
          createLayers(channel);
          initClickFeatureModule(channel, handleRPCError);
          initInfoBox(channel, handleRPCError);
          initMapModule(channel, handleRPCError);
          initServicesMapModule(channel);
          initMapModule(channel);
          initDrawTools(channel);
          initSearchModule(channel, handleRPCError);
          resolve(OSKARI_CHANNEL_STATUS_IDS.channelReady);
        });
      } else if (applicationContext === 'mapSite') {
        window.location.assign('https://www.maanmittauslaitos.fi/palvelu-suljettu');
      } else {
        resolve(OSKARI_CHANNEL_STATUS_IDS.channelNotReady);
      }
    });
  });
}

export function getAllLayers(cb) {
  channel.getAllLayers(data => {
    cb(data);
  });
}

export function showLoadAnimation(isVisible) {
  channel.postRequest('ShowProgressSpinnerRequest', [isVisible]);
}

export function removeAllNonLaturiMarkers(removables) {
  if (removables.distance) {
    removeDistance();
  }
  if (removables.area) {
    removeArea();
  }

  clearLayer(LAYER_PARCELS);
  clearLayer(LAYER_BOUNDARY_MARKERS);
  clearLayer(LAYER_REAL_ESTATE_IDS);
  clearLayer(LAYER_APARTMENTS);
  removeAllMarkerFeatures();
}

// ---------CLICK MAP-----------

const clickListeners = {};

export function clicked(name, callback) {
  // save listener function reference for future removal
  clickListeners[name] = callback;
  channel.handleEvent('MapClickedEvent', callback, handleRPCError);
}

export function removeClicked(name) {
  channel.unregisterEventHandler('MapClickedEvent', clickListeners[name]);
}

export function removeMarkerFeature(id, layer = LAYER_TEMPORARY_MARKERS) {
  removeFeature(id, layer);
  hideInfobox([`${id}_infobox`]);
}

// ----------MOVE FEATURE-------------

const moveListeners = {};

export function moved(name, callback) {
  // save listener function reference for future removal
  moveListeners[name] = callback;
  channel.handleEvent('AfterMapMoveEvent', callback, handleRPCError);
}

export function removeMoved(name) {
  channel.unregisterEventHandler('AfterMapMoveEvent', moveListeners[name]);
}

//-------------------------------------

export function moveMap(x, y, zoomLevel) {
  channel.postRequest('MapMoveRequest', [x, y, zoomLevel]);
}

function getZoomLevel(placeTypeCode) {
  return ZOOM_LEVELS_BY_PLACE_TYPES.find(item => item.placeTypeCode === placeTypeCode)?.level || DEFAULT_ZOOM_LEVEL;
}

export function zoomToPlace(x, y, placeType) {
  moveMap(x, y, getZoomLevel(placeType));
}

export function changeMapLayerOpacity(layerId, opacity) {
  channel.postRequest('ChangeMapLayerOpacityRequest', [layerId, opacity], handleRPCError);
}

export function activateUserLocationEventListener(callback) {
  return new Promise(resolve => {
    channel.handleEvent('UserLocationEvent', callback, handleRPCError);
    resolve();
  });
}

export function getCurrentMapPosition(callback) {
  channel.getMapPosition(callback);
}

export function getFeatures(getLayerIds, callback) {
  const actualLayersIds = typeof getLayerIds === 'boolean' || !getLayerIds ? [getLayerIds] : [];
  channel.getFeatures(actualLayersIds, callback);
}

/**
 * @param {Object} geoJson - GeoJson feature, not FeatureCollection. If missing, all features are returned.
 * @param {Number} layerId
 */
export function getVectorFeatures(geoJson, layerId) {
  return new Promise((resolve, reject) => {
    channel.getVectorFeatures([geoJson, { layers: [layerId] }], resolve, reject);
  });
}

export const getPixelMeasuresInScale = (alignment, scale, paperSizeCode, callback) => {
  const alignmentValue =
    alignment === 'portrait' ? paperSizes[paperSizeCode].getPortrait() : paperSizes[paperSizeCode].getLandscape();
  channel.getPixelMeasuresInScale([alignmentValue, scale], callback);
};

export function zoomIn(cb) {
  const newCb = cb || (() => {});
  channel.zoomIn(newCb);
}

export function zoomOut(cb) {
  const newCb = cb || (() => {});
  channel.zoomOut(newCb);
}

export function zoomTo(level, cb) {
  const newCb = cb || (() => {});
  channel.zoomTo([level], newCb);
}

function sendUIEvent(toolName) {
  channel.sendUIEvent([toolName], (/* data */) => {});
}

export function toggleCoordinateConversionToolbar() {
  sendUIEvent('coordinatetool');
}

export function getUserLocation() {
  const moveMapToTheLocation = false;
  const options = {
    enableHighAccuracy: true,
  };
  channel.postRequest('MyLocationPlugin.GetUserLocationRequest', [moveMapToTheLocation, options]);
}

export function trackUserLocation(track) {
  const options = {
    addToMap: 'location',
    enableHighAccuracy: true,
  };
  if (track) {
    channel.postRequest('StartUserLocationTrackingRequest', [options]);
  } else {
    channel.postRequest('StopUserLocationTrackingRequest');
  }
}

export function getScreenshot(cb) {
  channel.getScreenshot(cb);
}

export function changeMapLayerStyle(layerId, styleName) {
  channel.postRequest('ChangeMapLayerStyleRequest', [layerId, styleName]);
}

export function disableClickSelect(value) {
  setDisableClickSelect(value);
}
