import {
  SET_MAP_TOOL_BUTTON_ACTIVE,
  MAP_TOOL_CUSTOM_MARKER_OPEN_EDITOR,
  MAP_TOOL_CUSTOM_MARKER_CLOSE_EDITOR,
  DISABLE_ALL_MAP_TOOLS,
} from './MapToolbarActionTypes';
import { MAP_TOOL_CUSTOM_MARKER, MAP_TOOL_CUSTOM_MARKER_WITHOUT_EDITOR } from './MapToolNames';

const initialState = { active: false, itemInEditor: null };

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MAP_TOOL_BUTTON_ACTIVE:
      if (action.toolId === MAP_TOOL_CUSTOM_MARKER || action.toolId === MAP_TOOL_CUSTOM_MARKER_WITHOUT_EDITOR) {
        return { ...state, active: action.active };
      }
      return state;

    case MAP_TOOL_CUSTOM_MARKER_OPEN_EDITOR:
      return { ...state, itemInEditor: action.item };
    case MAP_TOOL_CUSTOM_MARKER_CLOSE_EDITOR:
      return { ...state, itemInEditor: null };
    case DISABLE_ALL_MAP_TOOLS:
      return { ...state, active: false };

    default:
      return state;
  }
}
