import { compact, concat, drop, head, uniqBy, uniqueId, last, isEmpty } from 'lodash';
import firstBy from 'thenby';

import { FACILITY_TYPE, PROPERTY_TYPE, REGISTER_UNIT_TYPE } from 'realEstateSite/constants/RealEstate';
import { GRANTEE_ROLES } from 'realEstateSite/constants/Roles';
import {
  ACTIVITY_TYPE_REGISTRATION_ISSUE,
  APPROVED_SOLUTION_TYPE,
  LEGAL_CONFIRMATION_CATEGORIES,
  PENDING_ACTIVITY_STATE,
  RESTING_SOLUTION_TYPE,
  REJECTED_SOLUTION_TYPE,
  NOT_INVESTIGATED_SOLUTION_TYPE,
  LEGALLY_BINDING_SOLUTION_TRACKING_INFO,
  NOT_LEGALLY_BINDING_SOLUTION_TRACKING_INFO,
} from './ActivityConstants';
import { CADASTRAL_ACTIVITY_URL_EVENT_TYPES, CADASTRAL_ACTIVE_PHASES } from './CadastralActivityConstants';
import {
  CONSENT_REQUEST_TYPE,
  RESOLUTION_REQUEST_TYPE,
  SUPPLEMENT_REMINDER_TYPE,
  SUPPLEMENT_REQUEST_TYPE,
  DELIVERY_METHOD_EMAIL,
  DELIVERY_METHOD_PHONE,
} from './RegistrationActivity/SupplementRegistrationActivity/SupplementRegistrationActivityConstants';
import { filterCadastralSurveyPhases, getCorrectLastPhaseTypeCode, isInPreprocessing } from './ActivityHelpers';

const getRegistrationIssueTitle = activity =>
  LEGAL_CONFIRMATION_CATEGORIES.includes(activity.activityCategory)
    ? activity.activityTypeText
    : activity.activityCategoryText;

const getActivityTargetType = target => {
  if (target.registerUnitIdentifier || target.propertyWithoutIdentifier) return REGISTER_UNIT_TYPE;
  if (target.propertyIdentifier) return PROPERTY_TYPE;
  if (target.facilityIdentifier) return FACILITY_TYPE;
  return undefined;
};

const getActivityRegistrationTargets = targets =>
  !isEmpty(targets)
    ? uniqBy(
        targets.map(target => ({
          id:
            target.registerUnitIdentifier ||
            target.propertyIdentifier ||
            target.propertyWithoutIdentifier ||
            target.facilityIdentifier,
          type: getActivityTargetType(target),
        })),
        'id'
      )
    : [];

const getSupplementRequests = supplementRequests => {
  const trueSupplementRequests = supplementRequests
    .filter(supplementRequest =>
      [SUPPLEMENT_REQUEST_TYPE, SUPPLEMENT_REMINDER_TYPE].includes(supplementRequest.supplementRequestType)
    )
    .filter(
      supplementRequest => ![DELIVERY_METHOD_EMAIL, DELIVERY_METHOD_PHONE].includes(supplementRequest.deliveryMethod)
    )
    .sort(
      firstBy((a, b) => b.submissionTime?.localeCompare(a.submissionTime)).thenBy((a, b) =>
        b.supplementRequestId?.localeCompare(a.supplementRequestId)
      )
    );

  return {
    newRequests: compact(
      concat(
        supplementRequests.filter(supplementRequest =>
          [RESOLUTION_REQUEST_TYPE, CONSENT_REQUEST_TYPE].includes(supplementRequest.supplementRequestType)
        ),
        supplementRequests.filter(supplementRequest =>
          [DELIVERY_METHOD_EMAIL, DELIVERY_METHOD_PHONE].includes(supplementRequest.deliveryMethod)
        ),
        [head(trueSupplementRequests)]
      )
    ).sort(
      firstBy((a, b) => b.submissionTime?.localeCompare(a.submissionTime)).thenBy((a, b) =>
        b.supplementRequestId?.localeCompare(a.supplementRequestId)
      )
    ),
    oldRequests: compact(drop(trueSupplementRequests)),
  };
};

const registrationIssueActivityObject = activity => {
  return {
    id: activity.activityIdentifier,
    applicationId: activity.applicationIdentifier,
    url: activity.activityIdentifier.replace(/\//g, '-'),
    title: getRegistrationIssueTitle(activity),
    type: activity.type,
    targets: getActivityRegistrationTargets(activity.activityRegistration.targets),
    isApproved: activity.solutionType === APPROVED_SOLUTION_TYPE,
    donors: activity.activityRegistration?.donors,
    recipients: activity.activityRegistration?.recipients,
    hasSupplementRequests:
      !!activity.supplementRequestResponse && !isEmpty(activity.supplementRequestResponse.supplementRequests),
    isPending: activity.activityState === PENDING_ACTIVITY_STATE,
    isActiveApplication:
      activity.activityState === PENDING_ACTIVITY_STATE ||
      activity.solutionType === RESTING_SOLUTION_TYPE ||
      ([APPROVED_SOLUTION_TYPE, REJECTED_SOLUTION_TYPE, NOT_INVESTIGATED_SOLUTION_TYPE].includes(
        activity.solutionType
      ) &&
        ![LEGALLY_BINDING_SOLUTION_TRACKING_INFO, NOT_LEGALLY_BINDING_SOLUTION_TRACKING_INFO].includes(
          activity.solutionTrackingInfo
        )),
    activityState: activity.activityState,
    activityStartDate: activity.activityStartDate,
    petition: activity.activityRegistration.petition,
    solutionDate: activity.solutionDate,
    solutionType: activity.solutionType,
    solutionTrackingDate: activity.solutionTrackingDate,
    solutionTrackingInfo: activity.solutionTrackingInfo,
    solutionTypeText: activity.solutionTypeText,
    supplementRequestExceptions: !!activity.supplementRequestResponse?.exceptions,
    supplementRequests:
      activity.supplementRequestResponse &&
      !isEmpty(activity.supplementRequestResponse.supplementRequests) &&
      getSupplementRequests(activity.supplementRequestResponse?.supplementRequests),
    roleIdentifier: activity.roleIdentifier,
    isDownloadable: activity.solutionType && GRANTEE_ROLES.includes(activity.roleIdentifier),
  };
};

const getSurveyDetails = details => {
  if (!details) return null;
  const combinedSurvey = details?.combinedSurvey
    ? `${details.combinedSurvey.numberSequence}-${details.combinedSurvey.serialNumber}`
    : null;
  const filteredPhases = filterCadastralSurveyPhases(details.eventTypeCode, details.phases);

  return {
    combinedSurvey,
    engineer: details.engineer,
    eventTypeCode: details.eventTypeCode,
    municipality: details.municipality,
    phases: filteredPhases,
    targets: details.targets,
    inPreprocessing: isInPreprocessing(details.phases),
  };
};

const surveyActivityObject = activity => ({
  id: activity.numberSequence ? `${activity.numberSequence}-${activity.serialNumber}` : uniqueId('survey-without-id-'),
  applicationId: activity.applicationIdentifier || activity.activityId?.replace(/\//g, '-'),
  activityStartDate: activity.activityStartDate,
  isActiveApplication:
    !activity.hasSurvey ||
    (!isEmpty(activity.surveyDetails?.phases) &&
      CADASTRAL_ACTIVE_PHASES.includes(getCorrectLastPhaseTypeCode(activity.surveyDetails?.phases))),
  lastPhaseDate: !isEmpty(activity.surveyDetails?.phases) && last(activity.surveyDetails.phases).date,
  hasSurvey: activity.hasSurvey,
  surveyCategoryText: activity.surveyCategoryText,
  surveyDetails: getSurveyDetails(activity.surveyDetails),
  title: activity.surveyCategoryText,
  type: activity.type,
  targets: [],
  url:
    activity.surveyDetails && CADASTRAL_ACTIVITY_URL_EVENT_TYPES.includes(activity.surveyDetails.eventTypeCode)
      ? `${activity.numberSequence}-${activity.serialNumber}`
      : '',
});

const ActivityObject = activity =>
  activity.type === ACTIVITY_TYPE_REGISTRATION_ISSUE
    ? registrationIssueActivityObject(activity)
    : surveyActivityObject(activity);

export default ActivityObject;
