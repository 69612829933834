/**
 * Checks whether input string contains spaces.
 */
const containsSpaces = targetString => targetString.indexOf(' ') > -1;

/**
 * Slugifies input string and adds optional prefix to the start and optional suffix to the end
 */
const slugify = (sourceString, { prefix = null, suffix = null } = {}) => {
  let baseString = sourceString
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[_/]/g, '-') // replace selected non-alphanumeric characters with hyphens
    .replace(/[^a-z0-9 -]/g, '') // remove other non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens

  if (prefix != null) {
    baseString = `${prefix}-${baseString}`;
  }

  if (suffix != null) {
    baseString = `${baseString}-${suffix}`;
  }

  return baseString;
};

export { containsSpaces, slugify };
