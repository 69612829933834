import React from 'react';
import { useTranslation } from 'react-i18next';

import { Glyphicon } from 'common/components/Icon';
import { ScrollerPanelPage, ExternalLink } from 'common/components';
import HeadingRow from 'common/containers/Forms/Blocks';
import { REAL_PROPERTY_TITLE } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import {
  trackIndividualCustomerLogin,
  trackOrganisationalCustomerLogin,
} from 'common/helpers/Analytics/Analytics_eventmap';

const ToRealEstateSiteComponent = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <ScrollerPanelPage>
      <HeadingRow
        text={t('toRealEstateSiteSection.title')}
        className="with-divider"
        headerClassName={REAL_PROPERTY_TITLE}
      />
      <h3>{t('toRealEstateSiteSection.naturalPerson.title')}</h3>
      <p className="margin-b-1">{t('toRealEstateSiteSection.naturalPerson.desc1')}</p>
      <ExternalLink href={t('toRealEstateSiteSection.readMore.url')}>
        {t('toRealEstateSiteSection.readMore.text')}
      </ExternalLink>
      <p className="margin-t-1 margin-b-1">{t('toRealEstateSiteSection.naturalPerson.desc2')}</p>
      {language === 'en' && <p className="margin-t-1 margin-b-1">{t('toRealEstateSiteSection.naturalPerson.desc3')}</p>}

      <a
        target="_blank"
        href={t('toRealEstateSiteSection.naturalPerson.link.url')}
        rel="noopener noreferrer"
        className="button button--blue button--narrow margin-t-2 authentication-link"
        onClick={trackIndividualCustomerLogin}
      >
        <Glyphicon className="glyphicon-lock" glyph="lukko-auki" /> &nbsp;
        {t('toRealEstateSiteSection.naturalPerson.link.text')}
        <Glyphicon glyph="avaa-uuteen-ikkunaan" />
      </a>
      <a
        target="_blank"
        href={t('toRealEstateSiteSection.organization.link.url')}
        rel="noopener noreferrer"
        className="button button--blue button--narrow margin-t-2 authentication-link"
        onClick={trackOrganisationalCustomerLogin}
      >
        <Glyphicon className="glyphicon-lock" glyph="lukko-auki" /> &nbsp;
        {t('toRealEstateSiteSection.organization.link.text')}
        <Glyphicon glyph="avaa-uuteen-ikkunaan" />
      </a>
    </ScrollerPanelPage>
  );
};

export default ToRealEstateSiteComponent;
