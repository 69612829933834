import React, { useEffect, useId } from 'react';
import { useDispatch } from 'react-redux';
import { Field, useField, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { FormContentGroup, RadioButtonBlock } from 'common/containers/Forms/Blocks';
import {
  ValidationErrorText,
  ValidatedRadioButton,
} from 'common/containers/Forms/InputValidation/InputValidationComponents';
import { useParams } from 'react-router-dom';
import {
  ALL_EXCEPT_ELEVATION_THEME,
  AREA_SELECTION_TYPES,
  CADASTRAL_INDEX_MAP_VECTOR,
  DEFAULT_CRS,
  ELEVATION_THEME,
  EPSG_CODE_NAMES,
  FIELD_NAMES,
  TOPOGRAPHIC_DATABASE,
} from '../../FileDownloadServiceConstants';
import {
  clearMapSheetSelectionThunk,
  drawBboxOfFinlandThunk,
  removeBboxOfFinlandThunk,
} from '../../FileDownloadServiceAction';
import { useAreaSelectionTypes } from '../../FileDownloadServiceHelpers';

const EntireFinlandSelection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { change, getState } = useForm();

  const missingThemeSelectionErrorId = useId();

  const areaSelectionTypes = useAreaSelectionTypes();

  const { submitFailed } = getState();

  const { id } = useParams();
  const showThemeOptions = id === TOPOGRAPHIC_DATABASE;

  const {
    input: { value: productId },
  } = useField(FIELD_NAMES.PRODUCT_ID);

  const {
    input: { value: areaSelectionType },
  } = useField(FIELD_NAMES.AREA_SELECTION_TYPE);

  const fieldLabel =
    productId === CADASTRAL_INDEX_MAP_VECTOR
      ? t('fileDownloadService.areaSelection.entireFinland.etrsTm35fin')
      : t('fileDownloadService.areaSelection.entireFinland.basic');

  useEffect(() => {
    if (areaSelectionType === AREA_SELECTION_TYPES.ENTIRE_FINLAND) dispatch(drawBboxOfFinlandThunk());
    else dispatch(removeBboxOfFinlandThunk());

    return () => {
      dispatch(removeBboxOfFinlandThunk());
      dispatch(clearMapSheetSelectionThunk());
    };
  }, [dispatch, areaSelectionType, change]);

  useEffect(() => {
    if (areaSelectionTypes?.length === 1) {
      change(FIELD_NAMES.AREA_SELECTION_TYPE, AREA_SELECTION_TYPES.ENTIRE_FINLAND);
    }
  }, [change, areaSelectionTypes]);

  useEffect(() => {
    if (productId === CADASTRAL_INDEX_MAP_VECTOR && areaSelectionType === AREA_SELECTION_TYPES.ENTIRE_FINLAND) {
      change(FIELD_NAMES.CRS, { value: DEFAULT_CRS, label: t(EPSG_CODE_NAMES[DEFAULT_CRS]) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change, productId, areaSelectionType]);

  return (
    <RadioButtonBlock
      id="entireFinlandAreaSelection"
      value={AREA_SELECTION_TYPES.ENTIRE_FINLAND}
      name={FIELD_NAMES.AREA_SELECTION_TYPE}
      className="margin-b-1"
      label={fieldLabel}
      extraBlockContent={
        showThemeOptions && (
          <>
            <FormContentGroup
              legend={t(`fileDownloadService.themeSelection.title`)}
              isLegendHidden
              noMargin
              invalid={areaSelectionType === AREA_SELECTION_TYPES.ENTIRE_FINLAND ? submitFailed : null}
              required={areaSelectionType === AREA_SELECTION_TYPES.ENTIRE_FINLAND && showThemeOptions}
            >
              <Field
                id={ALL_EXCEPT_ELEVATION_THEME}
                name={FIELD_NAMES.THEME_ENTIRE_FINLAND}
                component={ValidatedRadioButton}
                label={t(`fileDownloadService.theme.${ALL_EXCEPT_ELEVATION_THEME}`)}
                disabled={areaSelectionType !== AREA_SELECTION_TYPES.ENTIRE_FINLAND}
                value={ALL_EXCEPT_ELEVATION_THEME}
                type="radio"
              />
              <Field
                id={ELEVATION_THEME}
                name={FIELD_NAMES.THEME_ENTIRE_FINLAND}
                component={ValidatedRadioButton}
                label={t(`fileDownloadService.theme.${ELEVATION_THEME}`)}
                disabled={areaSelectionType !== AREA_SELECTION_TYPES.ENTIRE_FINLAND}
                value={ELEVATION_THEME}
                type="radio"
              />
            </FormContentGroup>
            {areaSelectionType === AREA_SELECTION_TYPES.ENTIRE_FINLAND && showThemeOptions && (
              <Field
                name={FIELD_NAMES.VALID_THEME_SELECTION}
                component={({ meta }) =>
                  submitFailed && meta.error ? (
                    <ValidationErrorText
                      id={missingThemeSelectionErrorId}
                      className="margin-t-1"
                      errorText={meta.error}
                    />
                  ) : null
                }
              />
            )}
          </>
        )
      }
    />
  );
};

export default EntireFinlandSelection;
