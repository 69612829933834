import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { t } from 'i18next';
import TimelineItemTypes from './TimelineItemTypes';

const IconPast = Object.freeze({
  className: 'nls-timeline-icon--past',
  glyph: 'check',
  metaDescription: 'timeline.status.past',
});

const IconPresent = Object.freeze({
  className: 'nls-timeline-icon--present',
  glyph: null,
  metaDescription: 'timeline.status.present',
});

const IconFuture = Object.freeze({
  className: 'nls-timeline-icon--future',
  glyph: 'arrow-up',
  metaDescription: 'timeline.status.future',
});

const IconCancelled = Object.freeze({
  className: 'nls-timeline-icon--cancelled',
  glyph: 'times',
  metaDescription: 'timeline.status.cancelled',
});

const getIconType = type => {
  switch (type) {
    case TimelineItemTypes.Present:
      return IconPresent;
    case TimelineItemTypes.Future:
      return IconFuture;
    case TimelineItemTypes.Cancelled:
      return IconCancelled;
    case TimelineItemTypes.Past:
    default:
      return IconPast;
  }
};

const TimelineIcon = ({ type }) => {
  const iconType = getIconType(type);
  return (
    <div className={cls('nls-timeline-icon', iconType.className)}>
      {iconType.glyph && (
        <FontAwesomeIcon icon={iconType.glyph} className="glyphicon kasi-icon">
          <desc>{t(iconType.metaDescription)}</desc>
        </FontAwesomeIcon>
      )}
      <span className="visually-hidden">{t(iconType.metaDescription)}</span>
    </div>
  );
};

TimelineIcon.propTypes = {
  type: PropTypes.oneOf([
    TimelineItemTypes.Past,
    TimelineItemTypes.Present,
    TimelineItemTypes.Future,
    TimelineItemTypes.Cancelled,
  ]),
};

TimelineIcon.defaultProps = {
  type: TimelineItemTypes.Past,
};

export default TimelineIcon;
