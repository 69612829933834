import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Glyphicon } from 'common/components/Icon';

const ScrollerPanelList = ({ children, odd, even, lifelineGlyph, className, ...rest }) => {
  const listWrapperClasses = cls(
    {
      'panel__list--odd': !!odd,
      'panel__list--even': !!even,
    },
    'panel-list__wrapper',
    className
  );
  const lifelineClasses = cls(
    {
      'panel__list--odd': !odd,
      'panel__list--even': !even,
    },
    'panel-list__lifeline'
  );

  return (
    <div {...rest} className={listWrapperClasses}>
      {lifelineGlyph && (
        <div className={lifelineClasses}>
          <Glyphicon glyph={lifelineGlyph} />
          <div className="lifeline__line" />
        </div>
      )}
      <ul className="panel__list">{children}</ul>
    </div>
  );
};

ScrollerPanelList.propTypes = {
  children: PropTypes.node,
  odd: PropTypes.bool,
  even: PropTypes.bool,
  lifelineGlyph: PropTypes.string,
  className: PropTypes.string,
};

ScrollerPanelList.defaultProps = {
  children: null,
  odd: false,
  even: false,
  lifelineGlyph: null,
  className: '',
};

export default ScrollerPanelList;
