/*
  Related stylesheet: objects/objects.grid.styl
  */
import cls from 'classnames';
import div from '../../helpers/Html/divHelper';
import Root from './Root';
import MapView from './MapView';

export { Root, MapView };
export { COLUMN_LAYOUT, STACKED_LAYOUT } from 'common/constants/Layout';
export const MOBILE_MAP_VIEW = 'mobile-map-only';
export const MOBILE_TOOLS_VIEW = 'mobile-tools';
export const MOBILE_MAP_LAYERS_VIEW = 'mobile-layers';
export const MOBILE_SEARCH_VIEW = 'mobile-search';
export const MOBILE_BUSINESS_PANEL_VIEW = 'mobile-business-panel';

export const DesktopLeft = props => div(cls('desktop__left-view', props.className), props.children);

export const DesktopMiddle = props => div(cls('desktop__middle-view', props.className), props.children);

export const DesktopMap = props => DesktopMiddle({ className: 'desktop-map-view', children: props.children });

export const DesktopRight = props => div(cls('desktop__right-view', props.className), props.children);

/**
 * Mobile views (WIP)
 */

export const MobileTop = props => div(cls('mobile-top-view', props.className), props.children);

export const MobileMap = props => div(cls('mobile-map-view', props.className), props.children);

export const MobileMain = props => div(cls('mobile-main-view', props.className), props.children);

export const MobileBottom = props => div(cls('mobile-bottom-view', props.className), props.children);

export const MobileMenuView = props =>
  div(cls('mobile-main-view', 'mobile-menu-view', props.className, { hidden: props.isHidden }), props.children);
