import { accessibleNotificationAdded } from 'common/containers/AccessibleNotifications/accessibleNotificationsSlice';
import { ASSERTIVE } from 'common/containers/AccessibleNotifications/AccessibleNotificationsConstants';
import { addErrorNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';

let dispatch;

export function init(dispatchFunction) {
  dispatch = dispatchFunction;
}

export function error(msg, errorObject) {
  console.error('TAPAHTUI VIRHE', msg, errorObject);
  if (errorObject) {
    console.error(errorObject.stack);
  }
  showGeneralErrorToTheUser(msg);
}

function showGeneralErrorToTheUser(msg) {
  dispatch(addErrorNotificationAction(msg));
  dispatch(accessibleNotificationAdded({ text: msg, type: ASSERTIVE }));
}
