import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeLayoutThunk } from 'common/containers/KasiApp/KasiAppActions';
import { logoutThunk } from 'common/helpers/Authentication/authenticationSlice';
import LayoutComponent from './LayoutComponent';

function mapStateToProps(state) {
  return {
    layoutState: state.layout,
    session: state.layout.session,
    showCurtain: state.layout.showCurtain,
    logoutModalOpen: state.authentication.logoutModalOpen,
    applicationContext: state.application.applicationContext,
    shoppingCartItems: state.shoppingCart?.items?.length || 0,
    oskariChannelStatus: state.layout.oskariChannelStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeLayout: bindActionCreators(changeLayoutThunk, dispatch),
    logout: bindActionCreators(logoutThunk, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
