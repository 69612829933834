import { t } from 'i18next';
import {
  AREA_SELECTION_TYPES,
  DEFAULT_CRS,
  EPSG_CODE_NAMES,
  MAP_LAYERS,
  YEAR_SELECTION_TYPES,
} from './FileDownloadServiceConstants';

const { ENTIRE_FINLAND, SELECT_SHEETS, FREE_SELECTION, PRODUCTION_AREA } = AREA_SELECTION_TYPES;

/** @typedef {Object} FileDownloadProductMetadata
 *  @property {string} name
 *  @property {string} link
 */

/**
 * @enum {SpatialDataType}
 * @property {String} RASTER
 * @property {String} VECTOR
 */
export const SpatialDataType = {
  RASTER: 'RASTER',
  VECTOR: 'VECTOR',
  LICENCED_DATA: 'LICENCED_DATA',
};

/**
 *  @typedef {Object} FileDownloadProduct
 *  @property {string} id  Product id
 *  @property {Array.<String>} [types] Available product types, if not configured in backend
 *  @property {Array.<FileDownloadProductMetadata>} metadata Links to metadata pages
 *  @property {Object|String} [previewImage]
 *  @property {String} [defaultAreaSelectionType]
 *  @property {String} [defaultProductType]
 *  @property {Object.<String, Number>} [mapLayers]
 *  @property {Boolean} [hidden] Don't show product on the list if set true
 *  @property {Boolean} [restricted] Restricted products requires authentication.
 *  @property {SpatialDataType} category
 */
export default [
  {
    id: 'korkeusmalli',
    category: SpatialDataType.RASTER,
    metadata: [
      {
        name: 'fileDownloadService.product.korkeusmalli.metadata1.name',
        link: 'fileDownloadService.product.korkeusmalli.metadata1.link',
      },
      {
        name: 'fileDownloadService.product.korkeusmalli.metadata2.name',
        link: 'fileDownloadService.product.korkeusmalli.metadata2.link',
      },
    ],
    mapLayers: { '2m': MAP_LAYERS['1:10000'], '10m': MAP_LAYERS['1:25000'] },
    types: ['2m', '10m'],
    previewImage: {
      '2m':
        'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/053a0a20-abfa-4bf9-ac74-270e845654d1/attachments/korkeusmalli2m.png',
      '10m':
        'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/77bee4d9-a052-4a38-a72e-92b2af46a082/attachments/10m_mallikuva.png?approved=true',
    },
    defaultAreaSelectionType: SELECT_SHEETS,
    defaultProductType: '2m',
  },
  {
    id: 'laserkeilausaineisto_05',
    category: SpatialDataType.VECTOR,
    metadata: [
      {
        name: 'fileDownloadService.product.laserkeilausaineisto_05.metadata2.name',
        link: 'fileDownloadService.product.laserkeilausaineisto_05.metadata2.link',
      },
      {
        name: 'fileDownloadService.product.laserkeilausaineisto_05.metadata1.name',
        link: 'fileDownloadService.product.laserkeilausaineisto_05.metadata1.link',
      },
    ],
    mapLayers: {
      Uusin: MAP_LAYERS['1:5000'],
      '05p_2020-': MAP_LAYERS.laserScanning05p,
      '05p_2008-2019': MAP_LAYERS['1:5000'],
    },
    previewImage: {
      '05p':
        'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/a495a8ac-96ca-4ebf-927a-72a0e4881795/attachments/laserkeilausaineisto_0,5p.PNG',
      '2008-2019':
        'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/9ca2d437-c08e-4f3b-b5dd-f101efe87d1f/attachments/laserkeilausaineist_2008-2019.png',
      Uusin:
        'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/9ca2d437-c08e-4f3b-b5dd-f101efe87d1f/attachments/laserkeilausaineist_2008-2019.png',
    },
    defaultAreaSelectionType: SELECT_SHEETS,
    defaultProductType: 'Uusin',
  },
  {
    id: 'maastotietokanta',
    category: SpatialDataType.VECTOR,
    metadata: [
      {
        name: 'fileDownloadService.product.maastotietokanta.metadata.name',
        link: 'fileDownloadService.product.maastotietokanta.metadata.link',
      },
    ],
    mapLayers: { GML: MAP_LAYERS['1:25000H'], 'ESRI shapefile': MAP_LAYERS['1:25000H'] },
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/ddad3347-05ca-401a-b746-d883d4110180/attachments/Naytekuva_MTK.png',
    defaultAreaSelectionType: FREE_SELECTION,
    selectors: [
      { id: 'freeSelectionTheme', top: 616, left: 54 },
      { id: 'selectedMunicipality', top: 736, left: 54 },
      { id: 'municipalityTheme', top: 802, left: 54 },
    ],
  },
  {
    id: 'korkeusvyohykkeet',
    category: SpatialDataType.RASTER,
    types: ['korkeusvyohykkeet_32m', 'korkeusvyohykkeet_64m', 'korkeusvyohykkeet_128m', 'hila_512m'],
    metadata: [
      {
        name: 'fileDownloadService.product.korkeusvyohykkeet.metadata.name',
        link: 'fileDownloadService.product.korkeusvyohykkeet.metadata.link',
      },
    ],
    mapLayers: {
      korkeusvyohykkeet_32m: MAP_LAYERS['1:100000'],
      korkeusvyohykkeet_64m: MAP_LAYERS['1:200000'],
      korkeusvyohykkeet_128m: MAP_LAYERS['1:200000'],
    },
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/66619eca-42bb-47b2-9d8d-364fc839e0d7/attachments/korkeusvyohykerasteri_2m_s.png?approved=true',
    defaultAreaSelectionType: SELECT_SHEETS,
    defaultProductType: 'korkeusvyohykkeet_32m',
  },
  {
    id: 'rinnevarjoste',
    category: SpatialDataType.RASTER,
    types: [
      'rinnevarjoste_2m',
      'rinnevarjoste_8m',
      'rinnevarjoste_32m',
      'rinnevarjoste_64m',
      'rinnevarjoste_128m',
      'hila_512m',
    ],
    mapLayers: {
      rinnevarjoste_2m: MAP_LAYERS['1:10000'],
      rinnevarjoste_8m: MAP_LAYERS['1:25000'],
      rinnevarjoste_32m: MAP_LAYERS['1:100000'],
      rinnevarjoste_64m: MAP_LAYERS['1:200000'],
      rinnevarjoste_128m: MAP_LAYERS['1:200000'],
    },
    metadata: [
      {
        name: 'fileDownloadService.product.rinnevarjoste.metadata.name',
        link: 'fileDownloadService.product.rinnevarjoste.metadata.link',
      },
    ],
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/4de42057-1457-4280-b765-0057a1834905/attachments/vinovalovarjosterasteri_s.png?approved=true',
    defaultAreaSelectionType: SELECT_SHEETS,
    defaultProductType: 'rinnevarjoste_2m',
  },
  {
    id: 'hallinnolliset_aluejaot_vektori',
    category: SpatialDataType.VECTOR,
    metadata: [
      {
        name: 'fileDownloadService.product.hallinnolliset_aluejaot_vektori.name',
        link: 'fileDownloadService.product.hallinnolliset_aluejaot_vektori.metadata.link',
      },
    ],
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/b314bb40-6db8-4341-9e0f-8b7f6e38ec33/attachments/N%C3%A4ytekuva_Kuntajako.png?approved=true',
    defaultAreaSelectionType: ENTIRE_FINLAND,
    defaultProductType: 'kuntajako_10k',
    selectors: [{ id: 'year', top: 496, left: 18 }],
  },
  {
    id: 'hallinnolliset_aluejaot_rasteri',
    category: SpatialDataType.RASTER,
    metadata: [
      {
        name: 'fileDownloadService.product.hallinnolliset_aluejaot_rasteri.name',
        link: 'fileDownloadService.product.hallinnolliset_aluejaot_rasteri.metadata.link',
      },
    ],
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/dfd1d7fb-0acb-433e-95fe-45be691a567a/attachments/N%C3%A4ytekuva_Kuntajako.png?approved=true',
    defaultAreaSelectionType: ENTIRE_FINLAND,
    defaultProductType: 'kuntajako_10k',
    selectors: [{ id: 'year', top: 496, left: 18 }],
  },
  {
    id: 'karttalehtijako',
    category: SpatialDataType.VECTOR,
    metadata: [
      {
        name: 'fileDownloadService.product.karttalehtijako.name',
        link: 'fileDownloadService.product.karttalehtijako.metadata.link',
      },
    ],
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/7581db43-4ed7-4a69-9a02-c662d0941cb9/attachments/karttalehtijako2.png',
    types: ['kaikki'],
    defaultAreaSelectionType: ENTIRE_FINLAND,
    defaultProductType: 'kaikki',
  },
  {
    id: 'taustakartta_rasteri',
    category: SpatialDataType.RASTER,
    metadata: [
      {
        name: 'fileDownloadService.product.taustakartta_rasteri.name',
        link: 'fileDownloadService.product.taustakartta_rasteri.metadata.link',
      },
    ],
    type: [
      'taustakartta_rasteri_5k',
      'taustakartta_rasteri_10k',
      'taustakartta_rasteri_20k',
      'taustakartta_rasteri_40k',
      'taustakartta_rasteri_80k',
      'taustakartta_rasteri_160k',
    ],
    mapLayers: {
      taustakartta_rasteri_5k: MAP_LAYERS['1:10000'],
      taustakartta_rasteri_10k: MAP_LAYERS['1:25000H'],
      taustakartta_rasteri_20k: MAP_LAYERS['1:25000'],
      taustakartta_rasteri_40k: MAP_LAYERS['1:100000H'],
      taustakartta_rasteri_80k: MAP_LAYERS['1:200000H'],
      taustakartta_rasteri_160k: MAP_LAYERS['1:200000'],
    },
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/aa5d9171-1f47-4434-bd28-183ebe2117a1/attachments/Naytekuvat_Taustakarttasarja.png?approved=true',
    defaultAreaSelectionType: SELECT_SHEETS,
    defaultProductType: 'taustakartta_rasteri_5k',
    selectors: [{ id: 'productType', top: 428, left: 18 }],
  },
  {
    id: 'maastokartta_rasteri',
    category: SpatialDataType.RASTER,
    metadata: [
      {
        name: 'fileDownloadService.product.maastokartta_rasteri.name',
        link: 'fileDownloadService.product.maastokartta_rasteri.metadata.link',
      },
    ],
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/a959b07c-16ff-4ed0-9a3b-72d6bbc026ff/attachments/maastokartta_rasteri.png',
    defaultAreaSelectionType: SELECT_SHEETS,
    defaultProductType: 'maastokartta_rasteri_10k_painovari',
    mapLayers: {
      maastokartta_rasteri_10k_painovari: MAP_LAYERS['1:25000H'],
      maastokartta_rasteri_10k_taustavari: MAP_LAYERS['1:25000H'],
      maastokartta_rasteri_10k_painovari_ei_pehmennysta: MAP_LAYERS['1:25000H'],
      maastokartta_rasteri_50k_painovari: MAP_LAYERS['1:50000'],
      maastokartta_rasteri_100k_painovari: MAP_LAYERS['1:100000H'],
      maastokartta_rasteri_250k_painovari: MAP_LAYERS['1:200000H'],
      maastokartta_rasteri_500k_painovari: MAP_LAYERS['1:200000'],
    },
    selectors: [{ id: 'productType', top: 430, left: 18 }],
  },
  {
    id: 'maastokartta_vektori',
    category: SpatialDataType.VECTOR,
    metadata: [
      {
        name: 'fileDownloadService.product.maastokartta_vektori.name',
        link: 'fileDownloadService.product.maastokartta_vektori.metadata.link',
      },
    ],
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/7e491d51-9227-48b3-8d61-3bdbef49f37f/attachments/maastokartta_vektori.png',
    defaultAreaSelectionType: SELECT_SHEETS,
    defaultProductType: 'maastokartta_vektori_100k',
    mapLayers: {
      maastokartta_vektori_100k: MAP_LAYERS['1:100000'],
      maastokartta_vektori_250k: MAP_LAYERS['1:200000H'],
    },
  },
  {
    id: 'nimisto',
    category: SpatialDataType.VECTOR,
    metadata: [
      {
        name: 'fileDownloadService.product.nimisto.name',
        link: 'fileDownloadService.product.nimisto.metadata.link',
      },
    ],
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/40917b7b-df60-4289-b628-3326000f3790/attachments/n%C3%A4ytekuva_nimist%C3%B6_taustalla_maastokartta_pieni.png',
    defaultAreaSelectionType: ENTIRE_FINLAND,
    defaultProductType: 'paikat',
    selectors: [{ id: 'productType', top: 428, left: 18 }],
  },
  {
    id: 'ortoilmakuva',
    category: SpatialDataType.RASTER,
    mapLayers: {
      ortokuva: MAP_LAYERS['1:10000'],
      vääräväriortokuva: MAP_LAYERS['1:10000'],
    },
    defaultYearSelectionType: YEAR_SELECTION_TYPES.CURRENT,
    defaultProductType: 'ortokuva',
    defaultAreaSelectionType: SELECT_SHEETS,
    previewImage: {
      ortokuva:
        'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/8bc5dfd6-752a-40bc-9620-d67e43ab07ae/attachments/Ortokuva.png?approved=true',

      vääräväriortokuva:
        'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/8bc5dfd6-752a-40bc-9620-d67e43ab07ae/attachments/ortokuva_vaaravari.PNG',
    },
    metadata: [
      {
        name: 'fileDownloadService.product.ortoilmakuva.name',
        link: 'fileDownloadService.product.ortoilmakuva.metadata.link',
      },
    ],
    selectors: [{ id: 'year', top: 585, left: 18 }],
  },
  {
    id: 'kiinteistorekisterikartta_vektori',
    category: SpatialDataType.VECTOR,
    mapLayers: { GML: MAP_LAYERS['1:10000'], 'ESRI shapefile': MAP_LAYERS['1:10000'] },
    defaultFileFormat: 'GPKG',
    defaultAreaSelectionType: FREE_SELECTION,
    defaultCRS: { value: DEFAULT_CRS, label: t(EPSG_CODE_NAMES[DEFAULT_CRS]) },
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/c45b705b-63c6-4b27-ba9d-1e25a0d043ef/attachments/Naytekuva_KRK.png',
    metadata: [
      {
        name: 'fileDownloadService.product.kiinteistorekisterikartta_vektori.name',
        link: 'fileDownloadService.product.kiinteistorekisterikartta_vektori.metadata.link',
      },
    ],
    selectors: [
      { id: 'crs', top: 794, left: 18 },
      { id: 'selectedMunicipality', top: 670, left: 54 },
    ],
  },
  {
    id: 'kiinteistorekisterikartta_rasteri',
    category: SpatialDataType.RASTER,
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/c45b705b-63c6-4b27-ba9d-1e25a0d043ef/attachments/Naytekuva_KRK.png',
    metadata: [
      {
        name: 'fileDownloadService.product.kiinteistorekisterikartta_rasteri.name',
        link: 'fileDownloadService.product.kiinteistorekisterikartta_rasteri.metadata.link',
      },
    ],
    defaultProductType: 'ktj_kiinteistorajat',
    defaultAreaSelectionType: SELECT_SHEETS,
    mapLayers: {
      ktj_kiinteistorajat: MAP_LAYERS['1:10000'],
      ktj_kiinteistotunnukset: MAP_LAYERS['1:10000'],
    },
  },
  {
    id: 'laserkeilausaineisto_5p',
    category: SpatialDataType.LICENCED_DATA,
    hidden: true,
    restricted: true,
    // TODO: change this to SELECT_SHEETS when ASI-11302 goes to production
    defaultAreaSelectionType: PRODUCTION_AREA,
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/50da696b-f436-4146-a1df-fa228f1d1da4/attachments/laserkeilausaineisto_5p.PNG',
    metadata: [
      {
        name: 'fileDownloadService.product.laserkeilausaineisto_5p.name',
        link: 'fileDownloadService.product.laserkeilausaineisto_5p.metadata.link',
      },
    ],
    mapLayers: { LAZ: MAP_LAYERS.laserScanning5p },
  },
  {
    id: 'kiintopisteet',
    category: SpatialDataType.VECTOR,
    defaultAreaSelectionType: FREE_SELECTION,
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/09f09ed4-8f84-42dd-b678-febe2d09f6a0/attachments/kiintopisteet.png?approved=true',
    metadata: [
      {
        name: 'fileDownloadService.product.kiintopisteet.name',
        link: 'fileDownloadService.product.kiintopisteet.metadata.link',
      },
    ],
  },
  {
    id: '3d-rakennukset',
    category: SpatialDataType.VECTOR,
    previewImage:
      'https://www.paikkatietohakemisto.fi/geonetwork/srv/api/records/c58273f9-a0c1-486e-9bd0-4cf78b5c74d3/attachments/rakennukset_3d.png?approved=true',
    metadata: [
      {
        name: 'fileDownloadService.product.3d-rakennukset.name',
        link: 'fileDownloadService.product.3d-rakennukset.metadata.link',
      },
    ],
    mapLayers: {
      CityGML: MAP_LAYERS['1:10000'],
      CityJSON: MAP_LAYERS['1:10000'],
    },
    defaultAreaSelectionType: SELECT_SHEETS,
  },
];
