import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const OrderedList = ({ className, wideIndent, children }) => (
  <ol className={cls('nls-ordered-list', { 'nls-ordered-list--wide-indent': wideIndent }, className)}>{children}</ol>
);

OrderedList.propTypes = {
  className: PropTypes.string,
  wideIndent: PropTypes.bool,
  children: PropTypes.node,
};

OrderedList.defaultProps = {
  className: '',
  wideIndent: false,
  children: null,
};

export default OrderedList;
