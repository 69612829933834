import React, { Suspense, lazy } from 'react';

import Footer from 'mapSite/containers/Footer/MapSiteFooter';
import BusinessPanelComponent from 'common/containers/BusinessPanel/BusinessPanelComponent';
import { LoaderSpinner } from 'common/components';

const CustomMapsForm = lazy(() => import('common/containers/MapStoreCustomMapsForm/CustomMapsFormComponent'));

const CustomMapsFormPage = () => {
  return (
    <BusinessPanelComponent showCurtain viewSize="full">
      <Suspense fallback={<LoaderSpinner size="s" className="full-panel-loader margin-t-2" />}>
        <CustomMapsForm />
      </Suspense>
      <Footer />
    </BusinessPanelComponent>
  );
};

export default CustomMapsFormPage;
