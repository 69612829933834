import React from 'react';

import { ScrollerColumn } from 'common/components';
import usePageTitle from 'common/hooks/usePageTitle';
import ToRealEstateSiteComponent from '../components/ToRealEstateSiteComponent';

const ToRealEstateSitePage = () => {
  usePageTitle({
    title: 'toRealEstateSiteSection.documentTitle',
    template: 'app.documentTitle.template',
  });
  return (
    <ScrollerColumn columnNumber={2} lastColumn>
      <ToRealEstateSiteComponent />
    </ScrollerColumn>
  );
};

export default ToRealEstateSitePage;
