import SAVE_AUTHORIZATION from './AuthorizationActionTypes';

const initialState = { authenticationMethod: '', privileges: [] };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_AUTHORIZATION:
      return {
        ...state,
        authenticationMethod: action.authorization.authenticationMethod,
        privileges: action.authorization.privileges,
      };
    default:
      return state;
  }
}
