import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { setToLocalStorage, getFromLocalStorage, removeFromLocalStorage } from 'common/helpers/storage';
import CloseButtonComponent from '../Button/CloseButtonComponent';

const STORAGE_KEY = 'cookieAccepted';
const IGNORE_LANG = '';
const cookieIsAccepted = () => !isEmpty(getFromLocalStorage(STORAGE_KEY, IGNORE_LANG));
const acceptCookie = () => setToLocalStorage(STORAGE_KEY, '1', IGNORE_LANG);
const clearCookieAcceptance = () => removeFromLocalStorage(STORAGE_KEY, IGNORE_LANG);

const CookieNotice = () => {
  const [accepted, setAccepted] = useState(null);

  const handleCloseClick = () => {
    acceptCookie();
    setAccepted(true);
  };

  useEffect(() => {
    setAccepted(cookieIsAccepted());
  }, [setAccepted]);

  return accepted ? null : (
    <div className="cookie-notice">
      <span>{t('cookieNotice')}</span>
      <CloseButtonComponent onClick={handleCloseClick} className="cookie-notice__button" glyph="sulje-pallo-outline" />
    </div>
  );
};

export default CookieNotice;
export { acceptCookie, clearCookieAcceptance, cookieIsAccepted, STORAGE_KEY, IGNORE_LANG };
