import { isEmpty } from 'lodash';
import { buildRoute, pathsJoin, FILE_DOWNLOAD_SERVICE, ROUTE_SERVICES_SITE_ID } from 'common/constants/Routes';
import {
  REGISTRATION_MAP_DOCUMENT,
  APARTMENT_PRINT_OUT,
  SHARE_LIST,
} from 'realEstateSite/containers/Realty/RealEstate/Documents/CertificateConstants';

const SERVICES_SITE = buildRoute(ROUTE_SERVICES_SITE_ID);

export const buildServicesSiteRoute = appRoutePath => buildRoute(SERVICES_SITE, appRoutePath);

// [SERVER]/kiinteistoasiat/omat
export const SERVICES_REAL_ESTATE_LIST = 'omat';

// [SERVER]/kiinteistoasiat/omat/[kiinteistöid]/pyynto
export const SERVICES_APPLICATION_REQUEST = 'pyynto';

// [SERVER]/kiinteistoasiat/omat/[kiinteistöid]/pyynto/tuloste
export const SERVICES_APPLICATION_REQUEST_PRINT = 'tuloste';

// [SERVER]/kiinteistoasiat/etusivu
export const SERVICES_REAL_ESTATE_LIST_MOBILE = 'etusivu';

// [SERVER]/kiinteistoasiat/kiinteistot
export const SERVICES_FETCH_REAL_ESTATES = 'kiinteistot';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/omistajat
export const SERVICES_PROPRIETOR_LIST = 'omistajat';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/kiinnitykset
export const SERVICES_MORTGAGE_LIST = 'kiinnitykset';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/panttaukset
export const SERVICES_PLEDGES_LIST = 'panttaukset';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/rajoitukset
export const SERVICES_RESTRICTIONS_LIST = 'rajoitukset';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/kayttooikeudet-ja-rajoitukset
export const SERVICES_USUFRUCTS_AND_RESTRICTIONS = 'kayttooikeudet-ja-rajoitukset';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/vuokralaiset
export const SERVICES_LEASEHOLDERS = 'vuokralaiset';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/osta-otteita-ja-todistuksia
export const SERVICES_BUY_CERTIFICATES = 'osta-otteita-ja-todistuksia';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/kiinteistorekisteriote
export const SERVICES_DOC_REGISTRATION = 'kiinteistorekisteriote';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/karttaote
export const SERVICES_DOC_REGISTRATION_MAP = 'karttaote';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/maaraalaote
export const SERVICES_DOC_PROPERTY = 'maaraalaote';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/lainhuutotodistus
export const SERVICES_DOC_LEGAL_CERTIFICATE = 'lainhuutotodistus';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/rasitustodistus
export const SERVICES_DOC_STRAIN_CERTIFICATE = 'rasitustodistus';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/vuokraoikeustodistus
export const SERVICES_DOC_RENTAL_CERTIFICATE = 'vuokraoikeustodistus';

// [SERVER]/kiinteistoasiat/[hakemusid]/hae-lainhuutoa
export const SERVICES_LEGAL_CONFIRMATION = 'lainhuudon-hakeminen';
export const SERVICES_LEGAL_CONFIRMATION_APPLICATION = 'lainhuutohakemus';
export const SERVICES_LCA = 'kvsaanto';
export const SERVICES_LCAG = 'yleinen';
export const SERVICES_DEATH_ESTATE_LCA = 'kuolinpesa';
export const SERVICES_LCA_PARTITIONING = 'ositus';
export const SERVICES_ANNOUNCEMENTS = 'ilmoitukset';
export const SERVICES_REAL_ESTATE_TRANSFER_ANNOUNCEMENT = 'kiinteistonluovutusilmoitus';
export const SERVICES_PRIVATE_ROAD_SURVEY = 'yksityistietoimitus';
export const SERVICES_OTHER_CADASTRAL_SURVEY = 'muu-toimitus';
export const SERVICES_EASEMENT_SURVEY = 'rasitetoimitus';
export const SERVICES_BOUNDARY_DEMARCATION = 'rajankaynti';
export const SERVICES_PLOT_SUBDIVISION_SURVEY = 'tonttijako';
export const SERVICES_RELICTION_AREA = 'vesijaton-lunastaminen';
export const SERVICES_AMALGAMATION_OF_REAL_ESTATES = 'kiinteistojen-yhdistaminen';
export const SERVICES_CADASTRAL_SURVEYS = 'toimitushakemukset';

export const SERVICES_REAL_ESTATE_LOCATIONS = 'sijaintikohteet';
export const SERVICES_REAL_ESTATE_PROPERTIES = 'maaraalat';

// [SERVER]/kiinteistoasiat/kirjaamisasiat/[asiaid]/taydennys
export const SERVICES_REGISTRATION_MATTERS = 'kirjaamisasiat';
export const SERVICES_SUPPLEMENT_REGISTRATION_MATTER = 'taydennys';
export const SERVICES_SUPPLEMENT_REGISTRATION_MATTER_RECEIVED = 'valmis';
export const SERVICES_SUPPLEMENT_REGISTRATION_MATTER_FAILED = 'epaonnistui';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/rekistetoi-vuokraoikeus
export const SERVICES_OSRA = 'muu-oikeus';
// [SERVER]/kiinteistoasiat/[kiinteistöid]/siirra-vuokraoikeus
export const SERVICES_LEASEHOLD_TRANSFER_APPLICATION = 'siirra-vuokraoikeus';

// [SERVER]/kiinteistoasiat/[kiinteistöid]/rekisteroi-vuokraoikeus
export const SERVICES_LEASEHOLD_REGISTRATION_APPLICATION = 'rekisteroi-vuokraoikeus';

// [SERVER]/kiinteistoasiat/aineistot
export const LICENCED_DATA_INDEX = 'aineistot';

// API_PATHS
export const API_PATH_AVAILABILITY = 'api/availability';
export const API_PATH_FETCH_REAL_ESTATES = 'api/realestates';
export const API_PATH_FETCH_REAL_ESTATE_PROPERTIES = 'api/realestateproperties';
export const API_PATH_CERTIFICATE_CATALOG = 'api/certificateCatalog';
export const API_PATH_FETCH_REAL_ESTATES_BY_SSN = ssn => `api/realestates/customer/${ssn}`;
export const API_PATH_ACTIVITY_LOG = 'api/activities';
export const API_PATH_ACTIVITY_LOG_BY_SSN = ssn => `api/activities/customer/${ssn}`;
export const API_PATH_ACTIVITY_DECISIONS = 'api/activities/documents/list';
export const API_PATH_ACTIVITY_DOCUMENTS = 'api/activities/documents/download';
export const API_PATH_SUPPLEMENT_REQUESTS = 'api/activities/supplementrequests/';
export const API_PATH_CADASTRAL_ACTIVITY_DOCUMENTS = 'api/activities/survey/documents/list/';
export const API_PATH_CADASTRAL_ACTIVITY_DOCUMENTS_BY_SSN = (ssn, activityId) =>
  `api/activities/customer/${ssn}/survey/documents/list/${activityId}`;
export const API_PATH_CADASTRAL_ACTIVITY_DOCUMENT_DOWNLOAD = 'api/activities/survey/documents/download';
export const API_PATH_GET_VTJ_PERSONS = 'api/getVTJPersons';
export const API_PATH_IS_VALID_VTJ_PERSON = 'api/isValidPerson';
export const API_PATH_ORGANIZATION = 'api/organization';
export const API_PATH_MORTGAGES = 'api/mortgages';
export const API_PATH_LEGAL_CONFIRMATIONS = 'api/legalconfirmations';
export const API_PATH_COUNTRIES = 'api/geography/countries';
export const API_PATH_LEGAL_CONFIRMATION_SUBMIT = 'api/legalconfirmations';
export const API_PATH_GENERIC_LEGAL_CONFIRMATION_SUBMIT = 'api/legalconfirmations/generic';
export const API_PATH_LEGAL_CONFIRMATION_VALIDATE = 'api/legalconfirmations/validation';
export const API_PATH_GENERIC_LEGAL_CONFIRMATION_VALIDATE = 'api/legalconfirmations/generic/validation';
export const API_PATH_PUBLIC_PURCHASE_WITNESSING = 'api/publicpurchasewitnessing';
export const API_PATH_RETA_SUBMIT = 'api/realestatetransferannouncement';
export const API_PATH_PRS_SUBMIT = 'api/cadastralsurveys/privateroadsurvey';
export const API_PATH_OCS_SUBMIT = 'api/cadastralsurveys/othersurvey';
export const API_PATH_PSS_SUBMIT = 'api/cadastralsurveys/plotsubdivisionsurvey';
export const API_PATH_BD_SUBMIT = 'api/cadastralsurveys/boundarydemarcation';
export const API_PATH_ES_SUBMIT = 'api/cadastralsurveys/easementsurvey';
export const API_PATH_RAS_SUBMIT = 'api/cadastralsurveys/relictionareasurvey';
export const API_PATH_AOR_SUBMIT = 'api/cadastralsurveys/amalgamation';
export const API_PATH_SRA_SUBMIT = 'api/activities/supplementRegistration';
export const API_PATH_SPECIAL_RIGHT_SUBMIT = 'api/specialright';
export const API_PATH_SPECIAL_RIGHT_VALIDATE = 'api/specialright/validation';
export const API_PATH_USUFRUCTS_AND_RESTRICTIONS = 'api/usufructs';
export const API_PATH_JOINT_PROPERTY_UNIT_SHARES = 'api/propertyinformationquery/jointpropertyunitshare';
export const API_PATH_LEASEHOLDS = 'api/leasehold';

export const API_PATH_UPLOADER = 'api/upload';

export const API_PATH_CERTIFICATES = '/api/certificates';

export const API_PATH_TRANSFER_TYPES = 'api/realestatetransferannouncement/transfertypes';

export const API_PATH_APARTMENTS = 'api/apartments';
export const API_PATH_APARTMENT_OWNERSHIP = 'api/apartment/owner/basic/';
export const API_PATH_APARTMENT_PLEDGES = 'api/apartment/pledge/';
export const API_PATH_APARTMENT_RESTRICTIONS = 'api/apartment/restriction/';
export const API_PATH_SHARE_LIST = 'api/apartment/shareListPdf';
export const API_PATH_APARTMENT_PRINT_OUT = 'api/apartment/apartmentPrintoutPdf';

export const API_PATH_NEIGHBOUR_PARCELS = 'api/propertyinformationquery/neighbourparcels';
export const API_PATH_REGISTER_UNIT_FOR_POINT = 'api/propertyinformationquery/point';
export const API_PATH_USUFRUCT_UNITS = 'api/propertyinformationquery/usufructs';
export const API_PATH_PRIVATE_ROAD = 'api/propertyinformationquery/usufructs/privateroad';
export const API_PATH_ASSOCIATION_CONTACT_INFO = 'api/propertyinformationquery/associationcontactinfo';
export const API_PATH_MUNICIPAL_MAINTENANCE_FOR_POINT = 'api/propertyinformationquery/maintainedbymunicipality/point';

export const API_PATH_PURCHASE_ORDER_DOWNLOADABLES = 'api/purchaseorders/downloadables';

export const getApartmentListRoute = ssn =>
  ssn ? pathsJoin([buildServicesSiteRoute(API_PATH_APARTMENTS)], { ssn }) : buildServicesSiteRoute(API_PATH_APARTMENTS);

export const getRealEstateRoute = (realEstateId, appRoutePath = SERVICES_REAL_ESTATE_LIST) =>
  buildRoute(buildServicesSiteRoute(appRoutePath), realEstateId);

export const getLegalConfirmationApplicationRoute = legalConfirmationId =>
  buildRoute(
    buildServicesSiteRoute(pathsJoin([SERVICES_LEGAL_CONFIRMATION_APPLICATION, SERVICES_LCA])),
    legalConfirmationId
  );

export const DownloadCertificateRoutes = {
  getApiRoute: ({ certificateType, realEstateId, companyId, ssn, lang, startPage, endPage }) => {
    if (certificateType === REGISTRATION_MAP_DOCUMENT) {
      return pathsJoin([SERVICES_SITE, API_PATH_CERTIFICATES, certificateType, realEstateId, lang, startPage, endPage]);
    }
    if (certificateType === SHARE_LIST) {
      return pathsJoin([SERVICES_SITE, API_PATH_SHARE_LIST, companyId, lang]);
    }
    if (certificateType === APARTMENT_PRINT_OUT) {
      return pathsJoin([SERVICES_SITE, API_PATH_APARTMENT_PRINT_OUT, companyId, realEstateId, lang]);
    }
    return pathsJoin([SERVICES_SITE, API_PATH_CERTIFICATES, certificateType, realEstateId, ssn, lang]);
  },
};

export const PrivateRoadSurveyRoute = {
  getRoute: id => {
    return pathsJoin([SERVICES_SITE, SERVICES_CADASTRAL_SURVEYS, id, SERVICES_PRIVATE_ROAD_SURVEY]);
  },
};

export const OtherCadastralSurveyRoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_CADASTRAL_SURVEYS, id, SERVICES_OTHER_CADASTRAL_SURVEY]),
};

export const EasementSurveyRoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_CADASTRAL_SURVEYS, id, SERVICES_EASEMENT_SURVEY]),
};

export const BoundaryDemarcationRoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_CADASTRAL_SURVEYS, id, SERVICES_BOUNDARY_DEMARCATION]),
};

export const PlotSubdivisionSurveyRoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_CADASTRAL_SURVEYS, id, SERVICES_PLOT_SUBDIVISION_SURVEY]),
};

export const RelictionAreaSurveyRoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_CADASTRAL_SURVEYS, id, SERVICES_RELICTION_AREA]),
};

export const AmalgamationOfRealEstatesRoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_CADASTRAL_SURVEYS, id, SERVICES_AMALGAMATION_OF_REAL_ESTATES]),
};

export const SupplementRegistrationMatter = {
  getRoute: id =>
    pathsJoin([SERVICES_SITE, SERVICES_REGISTRATION_MATTERS, id, SERVICES_SUPPLEMENT_REGISTRATION_MATTER]),
};

export const LCAGRoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_LEGAL_CONFIRMATION_APPLICATION, SERVICES_LCAG, id]),
};

export const DeathEstateLCARoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_LEGAL_CONFIRMATION_APPLICATION, SERVICES_DEATH_ESTATE_LCA, id]),
};

export const PartitioningLCARoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_LEGAL_CONFIRMATION_APPLICATION, SERVICES_LCA_PARTITIONING, id]),
};

export const LeaseholdTransferRoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_LEASEHOLD_TRANSFER_APPLICATION, id]),
};

export const OSRATransferRoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_OSRA, id]),
};

export const LeaseholdRegistrationRoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_LEASEHOLD_REGISTRATION_APPLICATION, id]),
};

export const supplementRequestRoute = {
  getRoute: id => pathsJoin([SERVICES_SITE, SERVICES_REAL_ESTATE_LIST, id, SERVICES_APPLICATION_REQUEST]),
};

export const supplementRequestPrintRoute = {
  getRoute: id =>
    pathsJoin([
      SERVICES_SITE,
      SERVICES_REAL_ESTATE_LIST,
      id,
      SERVICES_APPLICATION_REQUEST,
      SERVICES_APPLICATION_REQUEST_PRINT,
    ]),
};

export const LCDetailsRoute = {
  getRoute: (id, item, propertyId) => {
    return propertyId
      ? pathsJoin([getRealEstateRoute(item, SERVICES_REAL_ESTATE_LIST), SERVICES_PROPRIETOR_LIST, id], { propertyId })
      : pathsJoin([getRealEstateRoute(item, SERVICES_REAL_ESTATE_LIST), SERVICES_PROPRIETOR_LIST, id]);
  },
};

export const LCDetailsNestedRoute = {
  getRoute: (id, item, secondItem) => {
    return pathsJoin([
      getRealEstateRoute(item, SERVICES_REAL_ESTATE_LIST),
      SERVICES_REAL_ESTATE_PROPERTIES,
      secondItem,
      SERVICES_PROPRIETOR_LIST,
      id,
    ]);
  },
};

export const LCDetailsDeeplyNestedRoute = {
  getRoute: (id, item, secondItem, thirdItem) => {
    return pathsJoin([
      getRealEstateRoute(item, SERVICES_REAL_ESTATE_LIST),
      SERVICES_REAL_ESTATE_LOCATIONS,
      secondItem,
      SERVICES_REAL_ESTATE_PROPERTIES,
      thirdItem,
      SERVICES_PROPRIETOR_LIST,
      id,
    ]);
  },
};

export const MortgageDetailsRoute = {
  getRoute: (id, item) => {
    return pathsJoin([getRealEstateRoute(item, SERVICES_REAL_ESTATE_LIST), SERVICES_MORTGAGE_LIST, id]);
  },
};

export const PledgeDetailsRoute = {
  getRoute: (id, item) => {
    return pathsJoin([getRealEstateRoute(item, SERVICES_REAL_ESTATE_LIST), SERVICES_PLEDGES_LIST, id]);
  },
};

export const RestrictionDetailsRoute = {
  getRoute: (id, item) => {
    return pathsJoin([getRealEstateRoute(item, SERVICES_REAL_ESTATE_LIST), SERVICES_RESTRICTIONS_LIST, id]);
  },
};

export const UsufructsAndRestrictionsDetailsRoute = {
  getRoute: (id, item) => {
    return pathsJoin([getRealEstateRoute(item, SERVICES_REAL_ESTATE_LIST), SERVICES_USUFRUCTS_AND_RESTRICTIONS, id]);
  },
};

export const resolveActiveColumn = pathname => {
  const urlExtraParts = pathname
    .split('/')
    .filter(p => p.includes(SERVICES_REAL_ESTATE_LOCATIONS) || p.includes(SERVICES_REAL_ESTATE_PROPERTIES));

  // RealEstateSite FileDownloadService needs some special handling column positioning
  if (
    (pathname.includes(FILE_DOWNLOAD_SERVICE) && pathname.includes(ROUTE_SERVICES_SITE_ID)) ||
    pathname.includes(SERVICES_REAL_ESTATE_LIST_MOBILE)
  ) {
    return pathname.split('/').filter(Boolean).length - 1;
  }

  if (!isEmpty(urlExtraParts)) return pathname.split('/').filter(Boolean).length - urlExtraParts.length;
  return pathname.split('/').filter(Boolean).length;
};
