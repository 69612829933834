import * as types from './MapStoreActionTypes';
import * as viewNames from './MapStoreComponentViewNames';

export const initialState = {
  header: 'Karttakauppa',
  sheets: [],
  discounts: [],
  view: viewNames.STORE_MODE_INDEX,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.INIT_GRID: {
      const validSheets = action.grid.filter(g => g.karttalehdenNumero !== null && g.varastosaldo > 9);
      const latestSheets = {};
      validSheets.forEach(l => {
        if (
          latestSheets[l.karttalehdenNumero] === undefined ||
          l.painovuosi > latestSheets[l.karttalehdenNumero].painovuosi
        ) {
          latestSheets[l.karttalehdenNumero] = l;
          latestSheets[l.karttalehdenNumero].mittakaava =
            latestSheets[l.karttalehdenNumero].mittakaava &&
            latestSheets[l.karttalehdenNumero].mittakaava.replace(' ', '');
        }
      });
      return { ...state, sheets: Object.values(latestSheets) };
    }
    case types.INIT_DISCOUNTS:
      return { ...state, discounts: action.discounts };

    default:
      return state;
  }
}
