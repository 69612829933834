import { useCallback, useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import {
  AREA_SELECTION_TYPES,
  FIELD_NAMES,
  LASER_SCANNING_DATA_5P,
  ORTHOPHOTO,
} from '../../FileDownloadServiceConstants';
import { fetchMapSheetAreaInfo } from '../../FileDownloadServiceApi';
import { addMapSheetAreas } from '../../FileDownloadServiceAction';
import {
  clearMapSheetAvailabilityThunk,
  removeMapSheetAreas,
  setMapSheetAvailabilityThunk,
} from '../../FileDownloadServiceAction/MapActions';

const useMapSheetAreas = () => {
  const dispatch = useDispatch();
  const [mapSheetAreas, setMapSheetAreas] = useState();

  const {
    input: { value: productId },
  } = useField(FIELD_NAMES.PRODUCT_ID);

  const {
    input: { value: selectedYear },
  } = useField(FIELD_NAMES.YEAR);

  const {
    input: { value: productType },
  } = useField(FIELD_NAMES.PRODUCT_TYPE);

  const {
    input: { value: areaSelectionType },
  } = useField(FIELD_NAMES.AREA_SELECTION_TYPE);

  useEffect(() => {
    if (productId === ORTHOPHOTO) {
      fetchMapSheetAreaInfo().then(areas => setMapSheetAreas(areas));
    }
  }, [productId]);

  const laser5pProductionAreas = useSelector(state => state.fileDownloadService.laser5pProductionAreas);

  const currentZoomLevel = useSelector(state => state.oskariIframe.zoom);
  const productTypeName = productType.value === 'vääräväriortokuva' ? 'vaaravari_ortokuva' : productType.value;

  const toggleMapSheetAvailability = useCallback(() => {
    if (
      !isEmpty(laser5pProductionAreas) &&
      productId === LASER_SCANNING_DATA_5P &&
      areaSelectionType === AREA_SELECTION_TYPES.SELECT_SHEETS
    ) {
      if (currentZoomLevel > 4) {
        dispatch(setMapSheetAvailabilityThunk(laser5pProductionAreas, true));
      } else {
        dispatch(setMapSheetAvailabilityThunk(laser5pProductionAreas, false));
      }
    } else {
      dispatch(clearMapSheetAvailabilityThunk());
    }
  }, [areaSelectionType, currentZoomLevel, laser5pProductionAreas, productId, dispatch]);

  useEffect(() => {
    if (mapSheetAreas && selectedYear && selectedYear !== 9999 && currentZoomLevel < 3) {
      const feature = mapSheetAreas.features.find(
        ({ properties }) =>
          properties.yearOfPhotography === selectedYear.value && properties.datasetVersion === productTypeName
      );

      if (feature) {
        const featureCollection = { id: 'mapSheetAreas', type: 'FeatureCollection', features: [feature] };
        dispatch(addMapSheetAreas(featureCollection, 'mapSheetAreas'));
      }
    }

    return () => {
      dispatch(removeMapSheetAreas('mapSheetAreas'));
    };
  }, [productId, mapSheetAreas, selectedYear, dispatch, currentZoomLevel, productTypeName]);

  return toggleMapSheetAvailability;
};

export default useMapSheetAreas;
