import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { uniq } from 'lodash';
import { t } from 'i18next';

export const YearOfPhotographyInfoBox = ({ years }) => (
  <div>
    <div style={{ marginBottom: '5px' }}>{t('fileDownloadService.mapSheetInfo.yearOfPhotography')}</div>
    <ul style={{ marginLeft: '1rem' }}>
      {uniq(years)
        .sort()
        .reverse()
        .map(year => (
          <li key={year}>{year}</li>
        ))}
    </ul>
  </div>
);

YearOfPhotographyInfoBox.propTypes = {
  years: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const createYearOfPhotographyInfoBox = years =>
  ReactDOMServer.renderToString(<YearOfPhotographyInfoBox years={years} />);
