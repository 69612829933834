import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const ScrollerPanelContent = ({ children, className, panelWithLink }) => {
  return (
    <div className={cls('panel-list__wrapper', { 'scroller-panel__content': !panelWithLink }, className)}>
      {children}
    </div>
  );
};

ScrollerPanelContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  panelWithLink: PropTypes.bool,
};

ScrollerPanelContent.defaultProps = {
  children: null,
  className: '',
  panelWithLink: false,
};

export default ScrollerPanelContent;
