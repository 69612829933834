import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import cls from 'classnames';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ACCEPTED_FILES_JPG_PDF } from './UploaderConstants';
import { Glyphicon } from '../Icon';

const newFileId = () => uniqueId(['upload', new Date().getTime()].join('-'));

const UploaderInput = ({
  accept,
  name,
  disabled,
  maxSize,
  id,
  isError,
  errorText,
  ariaDescribedby,
  setUploads,
  errorId,
  fileRequired,
}) => {
  const { t } = useTranslation();
  const onDropAccepted = useCallback(
    files => {
      setUploads(prevUploads => [...prevUploads, ...files.map(file => ({ id: newFileId(), file }))]);
    },
    [setUploads]
  );
  // eslint-disable-next-line no-alert, react-hooks/exhaustive-deps
  const onDropRejected = useCallback(() => alert(t(errorText)), [errorText]);

  const wrapperStyles = useCallback(
    () =>
      cls('uploader__dropzone', {
        'file-input__error': isError,
        'file-input__disabled': disabled,
      }),
    [disabled, isError]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxSize,
    disabled,
    accept,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <div
      {...getRootProps({
        className: wrapperStyles(),
        // Note: This is needed so clicking doesn't trigger file selection twice when uploader is wrapped in a label
        onClick: evt => evt.preventDefault(),
      })}
      aria-describedby={errorId}
    >
      <input {...getInputProps({ id, name })} aria-describedby={ariaDescribedby} aria-invalid={isError} />
      <button tabIndex="-1" type="button" className="button button--link">
        {t('uploader.helpText1')}
      </button>
      &nbsp;{t('uploader.helpText2')}
      {fileRequired && <Glyphicon glyph="required" className="validation--required" />}
    </div>
  );
};

UploaderInput.propTypes = {
  id: PropTypes.string.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  maxSize: PropTypes.number,
  isError: PropTypes.bool,
  errorText: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  setUploads: PropTypes.func.isRequired,
  errorId: PropTypes.string,
  fileRequired: PropTypes.bool,
};

UploaderInput.defaultProps = {
  accept: ACCEPTED_FILES_JPG_PDF,
  disabled: false,
  name: 'file',
  maxSize: 20000000,
  isError: false,
  errorText: 'uploader.defaultFilesError',
  ariaDescribedby: null,
  errorId: null,
  fileRequired: false,
};

export default UploaderInput;
