import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { Glyphicon } from 'common/components/Icon';
import cls from 'classnames';
import Slider from 'rc-slider';
import * as Routes from 'common/constants/Routes';
import { SCREENSHOT_STORAGE_KEY } from 'common/constants/LocalStorageKeys';
import { useTranslation } from 'react-i18next';
import idb from 'common/helpers/idb';

async function saveScreenshotAndPrint(data) {
  (await idb.print)
    .put('printmap', data, SCREENSHOT_STORAGE_KEY)
    .then(() => {
      sessionStorage.setItem('lang', i18next.language);
      window.open(Routes.getRoute(Routes.MAP_PRINT_PAGE));
    })
    .catch(err => {
      console.error('error: ', err);
    });
}

const btnClasses = 'button button--icon button--dark';

const DesktopMapControlsComponent = ({
  trackingUser,
  onTrackUserLocation,
  enableIframeEvents,
  takeScreenshot,
  toggleCoordinateTool,
  zoomLevel,
  zoomTo,
}) => {
  const { t } = useTranslation();

  const trackUserLocation = () => {
    onTrackUserLocation(!trackingUser);
  };

  const handleCoordinateToolClick = () => {
    toggleCoordinateTool();
  };

  const handleZoomOut = () => {
    let parsedZoomLevel = parseInt(zoomLevel, 10);
    parsedZoomLevel = parsedZoomLevel > 0 ? parsedZoomLevel - 1 : parsedZoomLevel;
    zoomTo(parsedZoomLevel);
  };

  const handleZoomIn = () => {
    let parsedZoomLevel = parseInt(zoomLevel, 10);
    parsedZoomLevel = parsedZoomLevel < 13 ? parsedZoomLevel + 1 : parsedZoomLevel;
    zoomTo(parsedZoomLevel);
  };

  const handlePrint = () => {
    takeScreenshot(saveScreenshotAndPrint);
  };

  const handleSliderChange = zoomToLevel => {
    zoomTo(zoomToLevel);
    enableIframeEvents(false);
  };

  const handleSliderAfterChange = () => {
    enableIframeEvents(true);
  };

  const sliderProps = {
    min: 0,
    max: 13,
    vertical: true,
    value: zoomLevel,
    onChange: handleSliderChange,
    onAfterChange: handleSliderAfterChange,
    tipFormatter: null,
  };

  const currentLocationTooltip = t('map.controls.automaticTracking');
  const zoomInTooltip = t('map.controls.zoomin.tooltip');
  const zoomOutTooltip = t('map.controls.zoomout.tooltip');
  const coordinatesTooltip = t('map.controls.coordinates.tooltip');
  const printMapTooltip = t('map.controls.print.tooltip');
  const handleTooltip = t('map.controls.handle.tooltip');

  return (
    <div className="desktop-map-controls">
      <div className="controls-align">
        <button
          type="button"
          title={printMapTooltip}
          onClick={handlePrint}
          className={btnClasses}
          aria-label={printMapTooltip}
        >
          <Glyphicon glyph="tulosta" alt={printMapTooltip} />
        </button>
      </div>
      <div className="map-zoom-controls controls-align">
        <button
          type="button"
          title={zoomInTooltip}
          className={btnClasses}
          onClick={handleZoomIn}
          aria-label={zoomInTooltip}
        >
          <Glyphicon glyph="plus-pallo" alt={zoomInTooltip} />
        </button>
        <Slider className="zoom-slider" ariaLabelForHandle={handleTooltip} {...sliderProps} />
        <button
          type="button"
          title={zoomOutTooltip}
          onClick={handleZoomOut}
          className={btnClasses}
          aria-label={zoomOutTooltip}
        >
          <Glyphicon glyph="miinus" alt={zoomOutTooltip} />
        </button>
        <button
          type="button"
          title={currentLocationTooltip}
          className={cls(btnClasses, { 'dark-selected': trackingUser })}
          onClick={trackUserLocation}
          aria-label={currentLocationTooltip}
        >
          <Glyphicon glyph="gps-sijainti" alt={currentLocationTooltip} />
        </button>
      </div>
      <div className="controls-align">
        <button
          type="button"
          title={coordinatesTooltip}
          onClick={handleCoordinateToolClick}
          className={btnClasses}
          aria-label={coordinatesTooltip}
        >
          <Glyphicon glyph="koordinaatit" alt={coordinatesTooltip} />
        </button>
      </div>
    </div>
  );
};

export default DesktopMapControlsComponent;

DesktopMapControlsComponent.propTypes = {
  trackingUser: PropTypes.bool.isRequired,
  onTrackUserLocation: PropTypes.func.isRequired,
  enableIframeEvents: PropTypes.func.isRequired,
  takeScreenshot: PropTypes.func.isRequired,
  toggleCoordinateTool: PropTypes.func.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  zoomTo: PropTypes.func.isRequired,
};
