import { t } from 'i18next';

export const fieldIsReady = meta => meta.error === undefined && (meta.touched === true || meta.dirty === true);

export const fieldHasError = meta => meta && meta.error;

export const fieldHasErrorOnBlur = meta => fieldHasError(meta) && meta.touched;

export const fieldHasErrorOnSubmit = meta => fieldHasError(meta) && meta.touched && meta.submitFailed;

export const validateNonEmptyValues = (meta, value) =>
  (value && value.length > 0 && fieldHasErrorOnBlur(meta)) || fieldHasErrorOnSubmit(meta);

export const handleOnChange = (e, normalOnChange, customOnChange) => {
  normalOnChange(e); // running standard onChange with default event
  if (typeof customOnChange === 'function') {
    customOnChange(e); // custom onChange event before value set
  }
};

export const defaultAriaLiveMessages = {
  guidance: props => {
    const { context, isDisabled, isSearchable, isMulti } = props;
    switch (context) {
      case 'menu':
        return isDisabled != null
          ? `${isDisabled ? '' : t('form.select.guidance.menu.enter')}. ${t('form.select.guidance.menu.esc')}.`
          : '';
      case 'input':
        return `${t('form.select.guidance.input.onTop').replace(
          '%1',
          props['aria-label'] || t('form.select.guidance.input.default')
        )}.
          ${t('form.select.guidance.input.openMenu')}.
          ${isSearchable ? `${t('form.select.guidance.input.searcheable')}.` : ''}
          ${isMulti ? t('form.select.guidance.input.multi') : ''}`;
      case 'value':
        return `${t('form.select.guidance.value.browse')} ${t('form.select.guidance.value.delete')}`;
      default:
        return '';
    }
  },

  onChange: props => {
    const { action, label = '', labels, isDisabled } = props;
    switch (action) {
      case 'deselect-option':
      case 'pop-value':
      case 'remove-value':
        return `${t('form.select.onChange.deselected').replace('%1', label)}.`;
      case 'clear':
        return t('form.select.onChange.clear');
      case 'initial-input-focus':
        return labels.length > 1
          ? `${t('form.select.onChange.initialFocus.plural').replace('%1', labels.join(', '))}.`
          : `${t('form.select.onChange.initialFocus.single').replace('%1', labels[0])}.`;
      case 'select-option':
        return isDisabled
          ? `${t('form.select.onChange.selectOption.disabled').replace('%1', label)}.`
          : `${t('form.select.onChange.selectOption.enabled').replace('%1', label)}.`;
      default:
        return '';
    }
  },

  onFocus: props => {
    const { context, focused, options, label = '', selectValue, isDisabled, isSelected } = props;

    const getArrayIndex = (arr, item) =>
      arr && arr.length
        ? t('form.select.onFocus.array.index')
            .replace('%1', arr.indexOf(item) + 1)
            .replace('%2', arr.length)
        : '';

    if (context === 'value' && selectValue) {
      return `${t('form.select.onFocus.value').replace('%1', label)} (${getArrayIndex(selectValue, focused)}).`;
    }

    if (context === 'menu') {
      const disabled = isDisabled ? `, ${t('form.select.onFocus.menu.disabled')}` : '';
      const status = `${
        isSelected
          ? t('form.select.onFocus.menu.selected').replace('%1', label)
          : t('form.select.onFocus.menu.focused').replace('%1', label)
      }${disabled}`;
      return `${status} (${getArrayIndex(options, focused)}).`;
    }
    return '';
  },

  onFilter: props => {
    const { inputValue, resultsMessage } = props;
    const searchTerm =
      inputValue?.length > 0 ? ` ${t('form.select.onFilter.search.term').replace('%1', inputValue)}` : '';
    if (searchTerm == null || searchTerm.length === 0) return '';
    return resultsMessage == null || resultsMessage.length === 0
      ? `${t('form.select.search.noResults.text')}${searchTerm}.`
      : `${resultsMessage}${searchTerm}.`;
  },
};

export const getSearchResults = ({ count }) => {
  return count === 1
    ? t('form.select.search.one.result')
    : t('form.select.search.several.results').replace('%1', count);
};
