import React from 'react';
import { t } from 'i18next';

import { MainHeader } from '../Header';
import { FlexGrid, Block, Row } from '../Grid';

const NotFoundComponent = () => (
  <FlexGrid className="form__container-bg">
    <MainHeader text={t('notFound.pageHeader')} />
    <Row>
      <Block size={6} className="notfound-text">
        40<span className="notfound-fallen">4</span>
      </Block>
    </Row>
  </FlexGrid>
);

export default NotFoundComponent;
