import React from 'react';
import { Field, useField } from 'react-final-form';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import firstBy from 'thenby';

import { Row, Block } from 'common/components';
import { FormContentBlock } from 'common/containers/Forms/Blocks';
import { ValidatedSelect } from 'common/containers/Forms/InputValidation/InputValidationComponents';

import { FIELD_NAMES } from '../FileDownloadServiceConstants';
import { getProductTypeOptions, useProductParameters, getProductSelectorPosition } from '../FileDownloadServiceHelpers';

const { PRODUCT_ID, PRODUCT_TYPE } = FIELD_NAMES;

const ProductTypeSelectionComponent = () => {
  const {
    input: { value: productId },
  } = useField(PRODUCT_ID);

  const {
    input: { value: type },
  } = useField(PRODUCT_TYPE);

  const { t } = useTranslation();
  const parameters = useProductParameters();

  const sortDatasets = data => {
    switch (productId) {
      case 'nimisto':
        return Object.keys(data)
          .reduce((values, key) => [...data[key], ...values], [])
          .sort(
            firstBy((a, b) => b.startsWith('paikat') - a.startsWith('paikat')).thenBy(
              (a, b) => b.startsWith('paikannimet') - a.startsWith('paikannimet')
            )
          );
      case 'maastokartta_rasteri':
        return Object.keys(data)
          .reduce((values, key) => [...data[key], ...values], [])
          .sort((a, b) => b.includes('10k_painovari') - a.includes('10k_painovari'));
      default:
        return Object.keys(data).reduce((values, key) => [...data[key], ...values], []);
    }
  };

  const datasets = sortDatasets(parameters.datasets);

  const selectorPosition = getProductSelectorPosition(productId, PRODUCT_TYPE);
  const productTypes = getProductTypeOptions(productId, datasets);

  if (isEmpty(productTypes)) return null;
  return (
    <Row className="margin-t-1" dataCy={PRODUCT_TYPE}>
      <FormContentBlock label={t('fileDownloadService.productTypeSelection.title')} id={PRODUCT_TYPE}>
        <Field
          menuPlacement="bottom"
          name={PRODUCT_TYPE}
          component={ValidatedSelect}
          options={productTypes}
          ariaLabel={t('fileDownloadService.productTypeSelection.title')}
          selectorPosition={selectorPosition}
        />
      </FormContentBlock>
      <Block size={6}>
        {t(`fileDownloadService.product.${productId}.${type.value}.info`) !==
          `fileDownloadService.product.${productId}.${type.value}.info` && (
          <p>{t(`fileDownloadService.product.${productId}.${type.value}.info`)}</p>
        )}
      </Block>
    </Row>
  );
};

export default ProductTypeSelectionComponent;
