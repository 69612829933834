import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_SERVICES_SITE_ID, getRoute, SHOPPING_CART, API_PATH_CHANGE_ROLE } from 'common/constants/Routes';
import CartNotificationBubbleComponent from 'common/containers/UserMenu/CartNotificationBubbleContainer';
import { showBack } from 'common/containers/UserMenu/UserMenuBackButtonContainer';
import UserMenuHomeButton, { showHome } from 'common/containers/UserMenu/UserMenuHomeButtonContainer';
import { clearCart, toggleCartNotificationBubble } from 'common/containers/ShoppingCart/ShoppingCartActions';
import UserMenuBackButton from './UserMenuBackButton';
import { FlexGrid, Row } from '../Grid';
import UserMenuDropDown from './UserMenuDropDown';
import LanguageSelector from './LanguageSelector';
import CartButton from './CartButton';

const UserMenu = ({ showCartButton, cartItemCount, rootUrl, authentication, onMobile, openBubble, logoutHandler }) => {
  const { pathname } = useLocation();
  const backShown = showBack({ pathname, onMobile });
  const homeShown = showHome({ matchPath, pathname, onMobile });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToShoppingCart = useCallback(() => {
    navigate(getRoute(SHOPPING_CART));
  }, [navigate]);

  const changeRole = useCallback(() => {
    dispatch(clearCart());
    dispatch(toggleCartNotificationBubble());
    window.location.assign(getRoute(API_PATH_CHANGE_ROLE));
  }, [dispatch]);

  return (
    <FlexGrid className={cls('user-menu__wrapper', backShown ? 'back' : '')}>
      <Row className="user-menu">
        {backShown && <UserMenuBackButton isMobile={onMobile} />}
        {backShown && <div className="user-menu__item-divider" />}
        {homeShown && <UserMenuHomeButton isMobile={onMobile} />}
        {homeShown && <div className="user-menu__item-divider" />}
        <LanguageSelector className="user-menu__item-block no-hover" />
        <div className="user-menu__item-divider" />
        {authentication?.uid && (
          <UserMenuDropDown
            authentication={authentication}
            onLogout={logoutHandler}
            floatRight={rootUrl.includes(ROUTE_SERVICES_SITE_ID)}
            isMobile={onMobile}
            cartItemCount={cartItemCount}
            goToShoppingCart={goToShoppingCart}
            changeRole={changeRole}
          />
        )}
        {authentication?.uid && <div className="user-menu__item-divider" />}
        {showCartButton && (
          <CartButton cartItemCount={cartItemCount} shoppingCartUrl={getRoute(SHOPPING_CART)} rootUrl={rootUrl}>
            {openBubble && <CartNotificationBubbleComponent />}
          </CartButton>
        )}
      </Row>
    </FlexGrid>
  );
};
UserMenu.propTypes = {
  showCartButton: PropTypes.bool,
  authentication: PropTypes.shape({
    uid: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organizationName: PropTypes.string,
    organizationRepresentative: PropTypes.bool,
  }),
  cartItemCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  rootUrl: PropTypes.string,
  logoutHandler: PropTypes.func,
  onMobile: PropTypes.bool,
  openBubble: PropTypes.bool,
};

UserMenu.defaultProps = {
  logoutHandler: () => null,
  rootUrl: '',
  authentication: null,
  showCartButton: true,
  onMobile: false,
  openBubble: false,
};

export default UserMenu;
