import React from 'react';

import { ScrollerColumn } from 'common/components';
import usePageTitle from 'common/hooks/usePageTitle';
import MapStoreIndexComponent from '../components/MapStoreIndexComponent';

const MapStoreIndexPage = () => {
  usePageTitle({
    title: 'mapStoreIndex.documentTitle',
    template: 'app.documentTitle.template',
  });

  return (
    <ScrollerColumn columnNumber={2} lastColumn>
      <MapStoreIndexComponent />
    </ScrollerColumn>
  );
};

export default MapStoreIndexPage;
