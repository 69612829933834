import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import VerticalScroller from 'common/components/VerticalScroller';
import { SEARCH_MIN_CHARS, REAL_ESTATE_SOURCE_CHANNEL, showResultsOnMapThunk } from './SearchActions';
import {
  NoSearchResults,
  SearchError,
  SearchLoader,
  SearchResultToggleWrapper,
  SearchResultList,
  ToggleSearchResultsBlock,
  SearchResultActions,
} from './components';

const SidebarSearchResultsController = ({ scrollOffset }) => {
  const dispatch = useDispatch();
  const [showing, setShowing] = useState(true);
  const resultList = useRef(null);

  const { params: searchParams, results, loading: searchIsLoading, hasMore: hasTooManyResults } = useSelector(
    state => state.search
  );
  const sidebarIsOpen = useSelector(state => state.layout.desktop.sidebar.isOpen);
  const isMapSite = useSelector(state => state.application.applicationContext) === 'mapSite';
  const { searchString } = searchParams;

  useEffect(() => {
    if (resultList.current) {
      resultList.current.scrollTop = 0;
    }
  }, []);

  const showResults = () => {
    setShowing(true);
  };

  const hideResults = () => {
    setShowing(false);
  };

  const showOnMap = resultsToShow => {
    dispatch(showResultsOnMapThunk(resultsToShow, isMapSite));
  };

  const showAllOnMap = () => {
    showOnMap(results);
  };

  const errorOnSearch = results === undefined;
  const hasResults = results?.length > 0;
  const isShowing = showing || searchIsLoading;
  const resultsLength = results?.length;
  const scrollerProps = {
    ...(scrollOffset && { heightOffset: scrollOffset, shouldScrollToTop: () => true }),
  };
  const sanitizedSearchString = searchString && searchString.replace(/\?/g, '');

  if (searchIsLoading) {
    return (
      <SearchResultToggleWrapper>
        <SearchLoader ref={resultList} />
      </SearchResultToggleWrapper>
    );
  }

  if (errorOnSearch) {
    return (
      <SearchResultToggleWrapper>
        <SearchError errorMessage="search.error" />
      </SearchResultToggleWrapper>
    );
  }

  if (!hasResults && sanitizedSearchString?.length >= SEARCH_MIN_CHARS) {
    return (
      <SearchResultToggleWrapper>
        <NoSearchResults ref={resultList} />
      </SearchResultToggleWrapper>
    );
  }

  if (hasResults) {
    return (
      <SearchResultToggleWrapper>
        <SearchResultActions
          showAllOnMap={showAllOnMap}
          showing={isShowing}
          resultsLength={resultsLength}
          showResults={showResults}
          hideResults={hideResults}
          displayedCount={results[0].source === REAL_ESTATE_SOURCE_CHANNEL ? 1 : resultsLength}
          sidebarIsOpen={sidebarIsOpen}
          hasTooManyResults={hasTooManyResults}
        />
        {isShowing && (
          <VerticalScroller {...scrollerProps}>
            <SearchResultList
              results={results}
              searchString={sanitizedSearchString}
              showOnMap={showOnMap}
              ref={resultList}
            />
          </VerticalScroller>
        )}
        <ToggleSearchResultsBlock showing={isShowing} toggleAction={isShowing ? hideResults : showResults} />
      </SearchResultToggleWrapper>
    );
  }
  // render empty container if some keys are typed and there are no results
  if (sanitizedSearchString?.length > 0 && results.length === 0) {
    return <SearchResultToggleWrapper />;
  }

  return null;
};

SidebarSearchResultsController.propTypes = {
  scrollOffset: PropTypes.number,
};

SidebarSearchResultsController.defaultProps = {
  scrollOffset: 0,
};

export default SidebarSearchResultsController;
