import i18next from 'i18next';

import maaraAla_fi from 'styles/markers/maara-ala-fi.svg';
import maaraAla_sv from 'styles/markers/maara-ala-sv.svg';
import kiinteisto_fi from 'styles/markers/kiinteisto-fi.svg';
import kiinteisto_sv from 'styles/markers/kiinteisto-sv.svg';
import vuokraoikeuslaitos_fi from 'styles/markers/vuokraoikeuslaitos-fi.svg';
import vuokraoikeuslaitos_sv from 'styles/markers/vuokraoikeuslaitos-sv.svg';
import huoneisto_fi from 'styles/markers/huoneisto-fi.svg';
import huoneisto_sv from 'styles/markers/huoneisto-sv.svg';
import autopaikka_fi from 'styles/markers/autopaikka-fi.svg';
import autopaikka_sv from 'styles/markers/autopaikka-sv.svg';
import muuTila_fi from 'styles/markers/muuTila-fi.svg';
import muuTila_sv from 'styles/markers/muuTila-sv.svg';
import rajapyykki from 'styles/markers/rajapyykki.svg';

import { TEXT_COLORS, OTHER_REAL_ESTATE_FOR_CADASTRAL } from 'oskari/ColorConstants';
import ColorProducer from '../ColorProducer';
import {
  LAYER_3D_ABOVE,
  LAYER_3D_BELOW,
  LAYER_APARTMENTS,
  LAYER_BOUNDARY_MARKERS,
  LAYER_PARCELS,
  LAYER_REAL_ESTATE_BORDERS,
  LAYER_REAL_ESTATE_IDS,
  LAYER_USUFRUCTS,
} from '../layers/VectorLayers';

const LANG_SV = 'sv';

const TEXT_STYLE = {
  scale: 1,
  font: 'bold 14px "Open Sans", sans-serif',
  stroke: {
    color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.WHITE }),
    width: 2,
  },
  labelProperty: 'description',
  offsetX: 11,
  offsetY: 11,
};

export const serviceLayersInOrder = [
  LAYER_PARCELS,
  LAYER_BOUNDARY_MARKERS,
  LAYER_REAL_ESTATE_IDS,
  LAYER_APARTMENTS,
  LAYER_USUFRUCTS,
];

const CONFIGS = {
  registerUnitId: () => ({
    style: {
      image: {
        shape: {
          data: i18next.language === LANG_SV ? kiinteisto_sv : kiinteisto_fi,
          x: 16,
          y: 1,
        },
        size: 3,
      },
      text: {
        ...TEXT_STYLE,
        fill: {
          color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.RED_TEXT }),
        },
      },
    },
    layer: LAYER_REAL_ESTATE_IDS,
    priority: 130,
  }),
  [LAYER_REAL_ESTATE_BORDERS]: () => ({
    style: {
      fill: {
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.RED_STYLE_2 }),
      },
      stroke: {
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.RED_STYLE_2 }),
        width: 4,
      },
    },
    layer: LAYER_REAL_ESTATE_BORDERS,
    priority: 150,
  }),
  [LAYER_BOUNDARY_MARKERS]: () => ({
    style: {
      image: {
        shape: {
          data: rajapyykki,
          x: 10,
          y: 22,
        },
        size: 3,
      },
      text: {
        ...TEXT_STYLE,
        fill: {
          color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.BLACK }),
        },
      },
    },
    layer: LAYER_BOUNDARY_MARKERS,
    priority: 140,
  }),
  registerUnitLot: (shouldDrawOtherRealEstates, color) => ({
    style: {
      fill: {
        color: shouldDrawOtherRealEstates
          ? ColorProducer.generateRGBAString({ color: OTHER_REAL_ESTATE_FOR_CADASTRAL, alpha: 0.4 })
          : ColorProducer.generateRGBAString({ color: color || ColorProducer.getNextFillColor(), alpha: 0.4 }),
      },
      stroke: {
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.RED_STYLE }),
        width: 1,
      },
    },
    layer: LAYER_PARCELS,
    priority: 160,
  }),
  projectedLotBelow: () => ({
    style: {
      fill: {
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.AZURE_RADIANCE, alpha: 0.4 }),
      },
      stroke: {
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.RED_STYLE }),
        width: 1,
      },
    },
    layer: LAYER_3D_BELOW,
    priority: 160,
  }),
  projectedLotAbove: () => ({
    style: {
      fill: {
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.MULE_FAWN, alpha: 0.4 }),
      },
      stroke: {
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.RED_STYLE }),
        width: 1,
      },
    },
    layer: LAYER_3D_ABOVE,
    priority: 160,
  }),
  propertyId: () => ({
    style: {
      image: {
        shape: {
          data: i18next.language === LANG_SV ? maaraAla_sv : maaraAla_fi,
          x: 16,
          y: 1,
        },
        size: 3,
      },
      text: {
        ...TEXT_STYLE,
        fill: {
          color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.CLEMENTINE }),
        },
      },
    },
    layer: LAYER_REAL_ESTATE_IDS,
    priority: 120,
  }),
  projectedPropertyIdAbove: () => ({
    style: {
      image: {
        shape: {
          data: i18next.language === LANG_SV ? maaraAla_sv : maaraAla_fi,
          x: 16,
          y: 1,
        },
        size: 3,
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.FLUSH_ORANGE }),
        stroke: ColorProducer.generateRGBAString({ color: TEXT_COLORS.FLUSH_ORANGE }),
      },
      text: {
        ...TEXT_STYLE,
        fill: {
          color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.MULE_FAWN }),
        },
      },
    },
    layer: LAYER_REAL_ESTATE_IDS,
    priority: 120,
  }),
  projectedPropertyIdBelow: () => ({
    style: {
      image: {
        shape: {
          data: i18next.language === LANG_SV ? maaraAla_sv : maaraAla_fi,
          x: 16,
          y: 1,
        },
        size: 3,
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.FLUSH_ORANGE }),
        stroke: ColorProducer.generateRGBAString({ color: TEXT_COLORS.FLUSH_ORANGE }),
      },
      text: {
        ...TEXT_STYLE,
        fill: {
          color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.AZURE_RADIANCE }),
        },
      },
    },
    layer: LAYER_REAL_ESTATE_IDS,
    priority: 120,
  }),
  propertyArea: (shouldDrawOtherRealEstates, color) => ({
    style: {
      fill: {
        color: shouldDrawOtherRealEstates
          ? ColorProducer.generateRGBAString({ color: OTHER_REAL_ESTATE_FOR_CADASTRAL, alpha: 0.8 })
          : ColorProducer.generateRGBAString({ color: color || ColorProducer.getNextFillColor(), alpha: 0.8 }),
      },
      stroke: {
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.RED_STYLE }),
        width: 1,
      },
    },
    layer: LAYER_PARCELS,
    priority: 125,
  }),
  facility: () => ({
    style: {
      fill: {
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.NEON_CARROT, alpha: 0.8 }),
      },
      stroke: {
        color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.RED_STYLE }),
        width: 1,
      },
      image: {
        shape: {
          data: i18next.language === LANG_SV ? vuokraoikeuslaitos_sv : vuokraoikeuslaitos_fi,
          x: 16,
          y: 1,
        },
        size: 3,
      },
      text: {
        ...TEXT_STYLE,
        fill: {
          color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.LOCHMARA }),
        },
      },
    },
    layer: LAYER_REAL_ESTATE_IDS,
    priority: 110,
  }),
  apartment: () => ({
    style: {
      image: {
        shape: {
          data: i18next.language === LANG_SV ? huoneisto_sv : huoneisto_fi,
          x: 16,
          y: 1,
        },
        size: 3,
      },
      text: {
        ...TEXT_STYLE,
        fill: {
          color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.SALEM }),
        },
      },
    },
    layer: LAYER_APARTMENTS,
    priority: 130,
  }),
  parkingSpace: () => ({
    style: {
      image: {
        shape: {
          data: i18next.language === LANG_SV ? autopaikka_sv : autopaikka_fi,
          x: 16,
          y: 1,
        },
        size: 3,
      },
      text: {
        ...TEXT_STYLE,
        fill: {
          color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.SALEM }),
        },
      },
    },
    layer: LAYER_APARTMENTS,
    priority: 130,
  }),
  otherApartment: () => ({
    style: {
      image: {
        shape: {
          data: i18next.language === LANG_SV ? muuTila_sv : muuTila_fi,
          x: 16,
          y: 1,
        },
        size: 3,
      },
      text: {
        ...TEXT_STYLE,
        fill: {
          color: ColorProducer.generateRGBAString({ color: TEXT_COLORS.SALEM }),
        },
      },
    },
    layer: LAYER_APARTMENTS,
    priority: 130,
  }),
};

export const config = (type, shouldDrawOtherRealEstates, color) => CONFIGS[type](shouldDrawOtherRealEstates, color);
