import PropTypes from 'prop-types';

const RESULT_PROP_TYPE = PropTypes.shape({
  name: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
});

export default RESULT_PROP_TYPE;
