import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon } from 'common/components/Icon';
import classNames from 'classnames';
import { AppLogo, Row, Block, FlexGrid } from 'common/components';
import { t } from 'i18next';
import * as mobileViews from 'common/containers/MobileTools/MobileViewNames';

export const translationkeys = {
  maptoolsButtonTitle: 'mobiletools.maptoolsButtonTitle',
  searchButtonTitle: 'mobiletools.searchButtonTitle',
  mapLayersButtonTitle: 'mobiletools.mapLayersButtonTitle',
  mapButtonTitle: 'mobiletools.mapButtonTitle',
  menuButtonTitle: 'mobiletools.menuButtonTitle',
  featureToggleTitle: 'application.heading.targets',
};

function getButtonClass(isActive, classnames = []) {
  return classNames(
    'button--dark',
    'button',
    'button--icon',
    {
      'dark-selected': isActive,
    },
    ...classnames
  );
}

const MobileToolsComponent = ({
  appContext,
  appLogoTitle,
  businessPanelIsOpen,
  currentMobileView,
  handleBusinessPanelToggle,
  handleMobileContentChange,
}) => {
  const isInSubPage = () => businessPanelIsOpen && currentMobileView === mobileViews.MOBILE_MAP_VIEW;
  const isBusinessPanelOpen = isInSubPage() || currentMobileView === mobileViews.MOBILE_BUSINESS_PANEL_VIEW;
  const selectButtonClasses = getButtonClass(currentMobileView === mobileViews.MOBILE_TOOLS_VIEW);
  const searchButtonClasses = getButtonClass(currentMobileView === mobileViews.MOBILE_SEARCH_VIEW);
  const layerButtonClasses = getButtonClass(currentMobileView === mobileViews.MOBILE_MAP_LAYERS_VIEW);
  const featuresButtonClasses = getButtonClass(currentMobileView === mobileViews.MOBILE_FEATURES_VIEW, [
    'mobile-feature-toggle-btn',
  ]);
  const glyphTitle = isBusinessPanelOpen ? t(translationkeys.mapButtonTitle) : t(translationkeys.menuButtonTitle);
  const glyph = isBusinessPanelOpen ? 'nayta-kartalla' : 'hampurilainen';
  const menuButtonClasses = getButtonClass(false);

  const handleMapLayersButtonClick = () => {
    handleMobileContentChange(mobileViews.MOBILE_MAP_LAYERS_VIEW);
    if (businessPanelIsOpen) {
      handleBusinessPanelToggle(false);
    }
  };

  const handleSearchButtonClick = () => {
    handleMobileContentChange(mobileViews.MOBILE_SEARCH_VIEW);
    if (businessPanelIsOpen) {
      handleBusinessPanelToggle(false);
    }
  };

  const handleMapToolsButtonClick = () => {
    handleMobileContentChange(mobileViews.MOBILE_TOOLS_VIEW);
    if (businessPanelIsOpen) {
      handleBusinessPanelToggle(false);
    }
  };

  const handleMapFeatureToggleButtonClick = () => {
    handleMobileContentChange(mobileViews.MOBILE_FEATURES_VIEW);
    if (businessPanelIsOpen) {
      handleBusinessPanelToggle(false);
    }
  };

  const handleBusinessPanelButtonClick = () => {
    const isAlreadyAtBusinessPanelView = isInSubPage() || currentMobileView === mobileViews.MOBILE_BUSINESS_PANEL_VIEW;

    const mobileView = isAlreadyAtBusinessPanelView
      ? mobileViews.MOBILE_MAP_VIEW
      : isInSubPage() || mobileViews.MOBILE_BUSINESS_PANEL_VIEW;

    handleMobileContentChange(mobileView);
    handleBusinessPanelToggle(!isAlreadyAtBusinessPanelView);
  };

  return (
    <FlexGrid className="mobile-tools mobile-tools--top">
      <Row className="mobile-tools__bar">
        <Block>
          <AppLogo appContext={appContext} logoTitle={appLogoTitle} />
        </Block>
        <h1 className="visually-hidden">{t('businessPanelControls.title')}</h1>
        <Block className="right mobile-tools__button-row">
          <button
            type="button"
            title={t(translationkeys.maptoolsButtonTitle)}
            onClick={handleMapToolsButtonClick}
            className={selectButtonClasses}
            aria-label={t(translationkeys.maptoolsButtonTitle)}
            aria-pressed={currentMobileView === mobileViews.MOBILE_TOOLS_VIEW}
          >
            <Glyphicon glyph="karttatyokalut" alt={t(translationkeys.maptoolsButtonTitle)} />
          </button>

          <button
            type="button"
            title={t(translationkeys.mapLayersButtonTitle)}
            onClick={handleMapLayersButtonClick}
            className={layerButtonClasses}
            aria-label={t(translationkeys.mapLayersButtonTitle)}
            aria-pressed={currentMobileView === mobileViews.MOBILE_MAP_LAYERS_VIEW}
          >
            <Glyphicon glyph="karttatasot" alt={t(translationkeys.mapLayersButtonTitle)} />
          </button>

          <button
            type="button"
            title={t(translationkeys.searchButtonTitle)}
            onClick={handleSearchButtonClick}
            className={searchButtonClasses}
            aria-label={t(translationkeys.searchButtonTitle)}
            aria-pressed={currentMobileView === mobileViews.MOBILE_SEARCH_VIEW}
          >
            <Glyphicon glyph="hae" alt={t(translationkeys.searchButtonTitle)} />
          </button>

          <button
            type="button"
            title={t(translationkeys.featureToggleTitle)}
            onClick={handleMapFeatureToggleButtonClick}
            className={featuresButtonClasses}
            aria-label={t(translationkeys.featureToggleTitle)}
            aria-pressed={currentMobileView === mobileViews.MOBILE_FEATURES_VIEW}
          >
            <Glyphicon glyph="roskakori" alt={t(translationkeys.featureToggleTitle)} />
          </button>

          <button
            type="button"
            title={glyphTitle}
            onClick={handleBusinessPanelButtonClick}
            className={menuButtonClasses}
            aria-label={glyphTitle}
            aria-controls="businessPanel"
            aria-expanded={isBusinessPanelOpen}
          >
            <Glyphicon glyph={glyph} alt={glyphTitle} />
          </button>
        </Block>
      </Row>
    </FlexGrid>
  );
};

MobileToolsComponent.propTypes = {
  appContext: PropTypes.string,
  appLogoTitle: PropTypes.string,
  businessPanelIsOpen: PropTypes.bool.isRequired,
  currentMobileView: PropTypes.string.isRequired,
  handleBusinessPanelToggle: PropTypes.func.isRequired,
  handleMobileContentChange: PropTypes.func.isRequired,
};

MobileToolsComponent.defaultProps = {
  appContext: 'realEstateSite',
  appLogoTitle: t('app.logo.title'),
};

export default MobileToolsComponent;
