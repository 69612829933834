export const SHOW_SERVICE_CLOSED_MODAL = 'SHOW_SERVICE_CLOSED_MODAL';
export const HIDE_SERVICE_CLOSED_MODAL = 'HIDE_SERVICE_CLOSED_MODAL';

export function showServiceClosedModal() {
  return {
    type: SHOW_SERVICE_CLOSED_MODAL,
  };
}

export function hideServiceClosedModal() {
  return {
    type: HIDE_SERVICE_CLOSED_MODAL,
  };
}
