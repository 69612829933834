import { LOCALES } from 'utils/datetime-utils';

import i18n from 'i18next';

const getDateFnsLocale = () => {
  const locales = {
    fi: LOCALES.fi,
    en: LOCALES.enUS,
    sv: LOCALES.sv,
  };

  return locales[i18n.language];
};

export default getDateFnsLocale;
