import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MobileMainViewComponent = ({ children, className, isHidden }) => {
  const mainViewClasses = classNames('mobile-main-view', 'mobile-menu-view', className, {
    hidden: isHidden,
  });

  return <div className={mainViewClasses}>{children}</div>;
};

MobileMainViewComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isHidden: PropTypes.bool,
};

MobileMainViewComponent.defaultProps = {
  className: '',
  isHidden: true,
};

export default MobileMainViewComponent;
