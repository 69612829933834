export const UPDATE_FORM_ERROR_MESSAGE = 'UPDATE_FORM_ERROR_MESSAGE';
export const RESET_FORM_MESSAGE = 'RESET_FORM_MESSAGE';

export const updateFormErrorMsg = (errorMsg, formId) => ({
  type: UPDATE_FORM_ERROR_MESSAGE,
  errorMsg,
  formId,
});

export const resetFormMessage = () => ({
  type: RESET_FORM_MESSAGE,
});
