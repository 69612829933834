import { t } from 'i18next';
import { INFO_BOX_BREAKPOINT } from '../../common/constants/Layout';

const infoboxActionListeners = {};

let _channel;
let _handleRPCError;

export default function initInfoBox(channel, handleRPCError) {
  _channel = channel;
  _handleRPCError = handleRPCError;
}

export function addInfoboxActionListener(name, callback) {
  // save listener function reference for future removal
  infoboxActionListeners[name] = callback;
  _channel.handleEvent('InfoboxActionEvent', callback, _handleRPCError);
}

export function hideInfobox(ids) {
  ids.forEach(id => _channel.postRequest('InfoBox.HideInfoBoxRequest', [id]));
}

export function hideAllInfoboxes() {
  _channel.postRequest('InfoBox.HideInfoBoxRequest');
}

/**
 * @typedef OskariCoordinates
 * @property {Number} n
 * @property {Number} e
 */

/**
 * @typedef InfoBoxInput
 * @property {String} id
 * @property {String} title
 * @property {OskariCoordinates} coords
 * @property {boolean} [hideCoordinates]
 * @property {String} desc
 * @property {Array} [actions]
 */

/**
 * Add InfoBox to the map.
 * @param {InfoBoxInput} input
 * @param {boolean} [disableMobileBreakpoint]
 */
export function infoBox(input, disableMobileBreakpoint) {
  const params = getParamsForShowInfoBoxRequest(input, disableMobileBreakpoint);
  _channel.postRequest('InfoBox.ShowInfoBoxRequest', params);
}

export function getParamsForShowInfoBoxRequest(input, disableMobileBreakpoint) {
  return [
    input.id,
    createInfoBoxTitle(input.title),
    createInfoBoxContent(input),
    createInfoBoxCoords(input.coords),
    createInfoBoxAdditionalConfig(disableMobileBreakpoint),
  ];
}

function createInfoBoxCoords(coords) {
  return {
    lat: coords.n,
    lon: coords.e,
  };
}

function createInfoBoxAdditionalConfig(disableMobileBreakpoint) {
  return {
    mobileBreakpoints: {
      width: disableMobileBreakpoint ? 0 : INFO_BOX_BREAKPOINT,
    },
    hidePrevious: true,
    colourScheme: {
      titleColour: '#fff',
      bgColour: '#424343',
      iconCls: 'icon-close-white',
    },
  };
}

function createInfoBoxContent(input) {
  const content = input.hideCoordinates
    ? [createInfoBoxDesc(input.desc)]
    : [createInfoBoxDesc(input.desc), createInfoBoxLocation(input.coords)];

  if (input.actions) {
    content.push(createInfoBoxButtons(input.actions));
  }
  return content;
}

export function createInfoBoxTitle(text) {
  return `<b>${text}</b>`;
}

export function createInfoBoxLocation(coords) {
  return {
    html: `${
      '<p style="border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; ' +
      'padding: 5px 0 3px 0;line-height: 108%;">'
    }${t('infobox.coordinates')}: N=${coords.n.toFixed(3)}, E=${coords.e.toFixed(
      3
    )}<br/><span style="font-size:88%;font-style:italic;">${t('infobox.coordinateSystem')}</span></p>`,
  };
}

export function createInfoBoxDesc(text) {
  return {
    html: `<p>${text}</p>`,
  };
}

export function createInfoBoxButtons(actions) {
  return {
    actions: actions.map(createInfoBoxButton),
  };
}

export function createInfoBoxButton(action = {}) {
  return { ...action, type: 'link' };
}
