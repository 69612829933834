import { isEmpty } from 'lodash';
import { LAYER_TEMPORARY_MARKERS } from '../layers/VectorLayers';

let _channel;

export default function initMapModule(channel) {
  _channel = channel;
}
export function showCrosshair() {
  _channel.sendUIEvent(['mapmodule.crosshair'], () => {});
}

function pointFeature(lon, lat, id, msg = '') {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [lon, lat],
    },
    properties: {
      id,
      label_property: msg,
    },
  };
}

function addSvgGeoJsonToMap(svg, centerX, centerY, geojsonObject, params, priority, layerId = LAYER_TEMPORARY_MARKERS) {
  const options = {};
  const mergedParams = [
    geojsonObject,
    {
      layerId,
      clearPrevious: false,
      layerOptions: options,
      centerTo: false,
      featureStyle: {
        image: {
          shape: {
            data: svg,
            x: centerX,
            y: centerY,
          },
          size: 3,
          color: '#63b8d4',
          stroke: '#000000',
        },
        text: {
          scale: 1.3,
          fill: {
            color: 'rgba(255,255,255,1)',
          },
          stroke: {
            color: 'rgba(0,0,0,1)',
            width: 2,
          },
          labelProperty: 'label_property',
          offsetX: 0,
          offsetY: -45,
        },
      },
      cursor: 'pointer',
      prio: priority,
      ...params,
    },
  ];

  _channel.postRequest('MapModulePlugin.AddFeaturesToMapRequest', mergedParams);
}

export function markerWithSvg(lon, lat, id, { svg, centerX, centerY, layer }, priority, msg = '') {
  const geojsonObject = {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: {
        name: 'EPSG:3067',
      },
    },
    features: [pointFeature(lon, lat, id, msg)],
  };
  addSvgGeoJsonToMap(svg, centerX, centerY, geojsonObject, {}, priority, layer);
}

export function zoomToFeatures(ids, layers) {
  const features = isEmpty(ids) ? {} : { id: ids };
  _channel.postRequest('MapModulePlugin.ZoomToFeaturesRequest', [{ layer: layers }, features]);
}

export function removeAllVectorFeatures() {
  // for example search results
  _channel.postRequest('MapModulePlugin.RemoveFeaturesFromMapRequest', []);
}

export function removeAllMarkerFeatures() {
  // for example xy coodinate points
  _channel.postRequest('MapModulePlugin.RemoveMarkersRequest', []);
}

export function mapIsReady() {
  return new Promise(resolve => {
    // setTimout is used to defer the channel.OnReady until the current call stack has cleared
    // This is so channel has time to be instantiated.
    setTimeout(() =>
      _channel?.onReady(() => {
        resolve();
      })
    );
  });
}

export function clearLayer(id) {
  _channel.postRequest('MapModulePlugin.RemoveFeaturesFromMapRequest', ['', null, id]);
}

export function removeFeature(id, layer) {
  _channel.postRequest('MapModulePlugin.RemoveFeaturesFromMapRequest', ['id', id, layer]);
}

export function removeFeaturesByProperty(property, value, layer) {
  _channel.postRequest('MapModulePlugin.RemoveFeaturesFromMapRequest', [property, value, layer]);
}

export function changeMapLayerVisibility(layerId, isVisible) {
  _channel.postRequest('MapModulePlugin.MapLayerVisibilityRequest', [layerId, isVisible]);
}

/**
 * @typedef DrawGeoJSONOptions
 * @property {boolean} [clearPrevious]
 * @property {Object} [layerOptions]
 * @property {Object} [featureStyle] - Oskari JSON style object https://oskari.org/documentation/examples/oskari-style
 * @property {boolean} [centerTo]
 * @property {String} [cursor]
 * @property {Number} [prio]
 * @property {Number} [minScale]
 * @property {String} [layer]
 */

/**
 * @param {String|Number} id
 * @param {Object} geoJsonObject
 * @param {DrawGeoJSONOptions} options
 */
export function drawGeoJson(id, geoJsonObject, options = {}) {
  const defaultOptions = {
    layerId: options.layer || id,
    clearPrevious: false,
    layerOptions: {},
    featureStyle: { fill: {} },
    centerTo: false,
    cursor: 'pointer',
    prio: 4,
    minScale: 1451336,
  };

  const params = [
    geoJsonObject,
    {
      ...defaultOptions,
      ...options,
    },
  ];

  _channel.postRequest('MapModulePlugin.AddFeaturesToMapRequest', params);
}
