import React, { useEffect, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FormContentBlock, FormContentGroup, RadioButtonBlock } from 'common/containers/Forms/Blocks';
import { ValidatedSelect } from 'common/containers/Forms/InputValidation/InputValidationComponents';
import { Row } from 'common/components';
import * as OskariMap from 'oskari/OskariMap';

import { isDesktop } from 'common/constants/Layout';
import { isEmpty, uniq } from 'lodash';
import {
  FIELD_NAMES,
  YEAR_SELECTION_PRODUCTS,
  LATURI_MAP_LAYERS,
  YEAR_SELECTION_TYPES,
  ORTHOPHOTO,
} from '../FileDownloadServiceConstants';
import { toggleLoadingAnimationThunk } from '../../OskariMap/OskariMapActions';
import { getProductSelectorPosition, getProduct, getMapLayerId } from '../FileDownloadServiceHelpers';
import { clearMapSheetSelectionThunk, setAdministrativeAreaYears } from '../FileDownloadServiceAction';
import { fetchAdministrativeAreas, fetchMapSheetsForYear, fetchMapSheetAreaInfo } from '../FileDownloadServiceApi';
import { toggleMapLayerVisibilityThunk } from '../../MapLayerSelector/MapLayerSelectorActions';
import { LAYER_ORTHOPHOTO_SHEETS } from '../../../../oskari/layers/VectorLayerIds';

const featureStyle = {
  fill: {
    color: 'rgba(100, 100, 100, 0)',
  },
  stroke: {
    color: '#FF8C00',
    width: 3,
  },
  text: {
    labelProperty: 'mapSheetNumber',
  },
};

const { YEAR, PRODUCT_ID } = FIELD_NAMES;

const YearSelectionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mapLayers = useSelector(state => state.mapLayers);

  const { change } = useForm();

  const {
    input: { value: selectedYear },
  } = useField(YEAR);

  const {
    input: { value: productId },
  } = useField(PRODUCT_ID);

  const {
    input: { value: type },
  } = useField(FIELD_NAMES.PRODUCT_TYPE);

  const {
    input: { value: fileFormat },
  } = useField(FIELD_NAMES.FILE_FORMAT);

  const {
    input: { value: yearSelectionType },
  } = useField(FIELD_NAMES.YEAR_SELECTION_TYPE);

  const [orthophotoYears, setOrthophotoYears] = useState([]);

  const product = getProduct(productId);
  const mapSheetLayer = product.mapLayers && (product.mapLayers[type.value] || product.mapLayers[fileFormat.value]);

  const correctMapLayers = productId === ORTHOPHOTO ? LATURI_MAP_LAYERS : mapLayers;
  const { minZoom } = (mapSheetLayer && correctMapLayers.find(layer => layer.id === mapSheetLayer)) || {};
  const currentZoomLevel = useSelector(state => state.oskariIframe.zoom);
  const isAboveMinZoomLevel = currentZoomLevel >= minZoom;
  const layoutMode = useSelector(state => state.layout.mode);

  const selectorPosition = getProductSelectorPosition(productId, YEAR);

  const administrativeAreaYears = useSelector(state => state.fileDownloadService.administrativeAreaYears);
  const administrativeYears = isEmpty(administrativeAreaYears) ? [] : administrativeAreaYears;

  const ORTHOPHOTO_START_YEAR = 2008;

  const getOrthophotoYears = featureCollection => {
    const oYears = featureCollection?.features
      ?.flatMap(f => f.properties)
      .map(({ yearOfPhotography }) => yearOfPhotography)
      .filter(y => y >= ORTHOPHOTO_START_YEAR);
    return uniq(oYears).reverse();
  };

  const years = productId === ORTHOPHOTO ? orthophotoYears : administrativeYears;

  const options = years?.map(year => ({ label: year, value: year.toString() }));

  useEffect(() => {
    if (productId === ORTHOPHOTO) {
      fetchMapSheetAreaInfo().then(data => {
        setOrthophotoYears(getOrthophotoYears(data));
      });
    }
  }, [productId]);

  useEffect(() => {
    if (productId === ORTHOPHOTO && yearSelectionType === YEAR_SELECTION_TYPES.YEAR) {
      dispatch(toggleMapLayerVisibilityThunk(getMapLayerId(getProduct(ORTHOPHOTO).mapLayers.ortokuva), false));
      dispatch(toggleMapLayerVisibilityThunk(LAYER_ORTHOPHOTO_SHEETS, true));

      if (selectedYear.value && isAboveMinZoomLevel) {
        dispatch(toggleLoadingAnimationThunk(true));
        fetchMapSheetsForYear(selectedYear.value).then(data => {
          OskariMap.drawGeoJson(LAYER_ORTHOPHOTO_SHEETS, data, { featureStyle, clearPrevious: true });
          dispatch(toggleLoadingAnimationThunk(false));
        });
      } else {
        dispatch(toggleMapLayerVisibilityThunk(LAYER_ORTHOPHOTO_SHEETS, false));
      }
    } else if (productId === ORTHOPHOTO && yearSelectionType === YEAR_SELECTION_TYPES.CURRENT) {
      dispatch(toggleMapLayerVisibilityThunk(getMapLayerId(getProduct(ORTHOPHOTO).mapLayers.ortokuva), true));
      dispatch(toggleMapLayerVisibilityThunk(LAYER_ORTHOPHOTO_SHEETS, false));
      OskariMap.clearLayer(LAYER_ORTHOPHOTO_SHEETS);
      change(FIELD_NAMES.YEAR, undefined);
    } else {
      OskariMap.clearLayer(LAYER_ORTHOPHOTO_SHEETS);
    }
  }, [dispatch, selectedYear, productId, isAboveMinZoomLevel, yearSelectionType, change]);

  useEffect(() => {
    if (productId === ORTHOPHOTO && yearSelectionType === YEAR_SELECTION_TYPES.YEAR) {
      dispatch(clearMapSheetSelectionThunk());
    } else if (productId === ORTHOPHOTO && yearSelectionType === YEAR_SELECTION_TYPES.CURRENT) {
      dispatch(clearMapSheetSelectionThunk());
    }
  }, [productId, yearSelectionType, dispatch]);

  useEffect(() => {
    if (yearSelectionType === YEAR_SELECTION_TYPES.YEAR) {
      dispatch(clearMapSheetSelectionThunk());
    }
  }, [yearSelectionType, dispatch, selectedYear]);

  useEffect(() => {
    if (['hallinnolliset_aluejaot_rasteri', 'hallinnolliset_aluejaot_vektori'].includes(productId)) {
      fetchAdministrativeAreas().then(data => {
        const yearDataPNG = data?.features
          .flatMap(d => d.properties)
          .filter(({ tiedostomuoto }) => tiedostomuoto === 'PNG')
          .map(({ vuosi }) => vuosi);
        dispatch(setAdministrativeAreaYears(yearDataPNG));
      });
    }
  }, [dispatch, productId]);

  return (
    <Row>
      {productId === ORTHOPHOTO ? (
        <FormContentGroup legend={t('fileDownloadService.product.ortoilmakuva.vuosivalinta.title')}>
          <RadioButtonBlock
            id="yearSelectionNewest"
            name={FIELD_NAMES.YEAR_SELECTION_TYPE}
            value={YEAR_SELECTION_TYPES.CURRENT}
            label={t('fileDownloadService.product.ortoilmakuva.vuosivalinta.uusin')}
          />
          <RadioButtonBlock
            id="yearSelectionByYear"
            name={FIELD_NAMES.YEAR_SELECTION_TYPE}
            value={YEAR_SELECTION_TYPES.YEAR}
            label={t('fileDownloadService.product.ortoilmakuva.vuosivalinta.vuodelta')}
          />
          <FormContentBlock
            id={FIELD_NAMES.YEAR}
            label={t('fileDownloadService.yearSelection.title')}
            size={3}
            className="margin-t-1"
          >
            <Field
              name={FIELD_NAMES.YEAR}
              component={ValidatedSelect}
              options={options}
              ariaLabel={t('fileDownloadService.yearSelection.title')}
              menuPlacement={isDesktop(layoutMode) ? 'bottom' : 'top'}
              selectorPosition={selectorPosition}
              disabled={yearSelectionType !== YEAR_SELECTION_TYPES.YEAR}
            />
          </FormContentBlock>
        </FormContentGroup>
      ) : (
        <FormContentBlock id={FIELD_NAMES.YEAR} label={t('fileDownloadService.yearSelection.title')}>
          <Field
            name={FIELD_NAMES.YEAR}
            component={ValidatedSelect}
            options={options}
            ariaLabel={t('fileDownloadService.yearSelection.title')}
            menuPlacement={isDesktop(layoutMode) ? 'bottom' : 'top'}
            selectorPosition={selectorPosition}
            defaultValue={YEAR_SELECTION_PRODUCTS.includes(productId) && options[0]}
          />
        </FormContentBlock>
      )}
    </Row>
  );
};

export default YearSelectionComponent;
