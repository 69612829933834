import { SHARE_OPEN, SHARE_CLOSE } from './ShareActionTypes';

export const SHARED_MARKER_LAYERS_SCHEMA = {
  title: 'Layers',
  type: 'array',
  items: {
    title: 'Layer',
    type: 'object',
    properties: {
      id: {
        type: 'number',
      },
      opacity: {
        type: 'number',
        minimum: 0,
        maximum: 100,
      },
    },
    required: ['id', 'opacity'],
  },
};
export const SHARED_MARKER_DEFAULT_LAYERS = [{ id: 2, opacity: 100 }];

export function shareOpenAction(url) {
  return {
    type: SHARE_OPEN,
    url,
  };
}

export function shareCloseAction() {
  return {
    type: SHARE_CLOSE,
  };
}
