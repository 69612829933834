import * as OskariMap from 'oskari/OskariMap';
import { FEATURE_TYPES } from 'common/constants/MapConstants';
import svg from 'styles/markers/FeedbackMarker.svg';
import { MAP_TOOL_FEEDBACK_MARKER_ADD_ITEM, MAP_TOOL_FEEDBACK_MARKER_REMOVE_ITEM } from './MapToolbarActionTypes';
import { addMarkerFeature, removeFeatureThunk } from '../../geometries/geometriesActions';
import {
  FEEDBACK_MARKER,
  FEEDBACK_MARKER_CLICK_EVENT_LISTENER_ID,
  FEEDBACK_MARKER_PRIORITY,
} from './FeedbackMarkerConstants';
import { LAYER_TEMPORARY_MARKERS } from '../../../oskari/layers/VectorLayers';

const addItemAction = item => ({
  type: MAP_TOOL_FEEDBACK_MARKER_ADD_ITEM,
  item,
});

const removeItemAction = () => ({
  type: MAP_TOOL_FEEDBACK_MARKER_REMOVE_ITEM,
});

const marker = {
  centerX: 16,
  centerY: 1,
  id: FEEDBACK_MARKER,
  svg,
  layer: LAYER_TEMPORARY_MARKERS,
};

const toFeedbackMarker = data => ({
  lat: data.lat,
  lon: data.lon,
  id: FEEDBACK_MARKER,
  featureType: FEATURE_TYPES.FEEDBACK_MARKER,
  name: '',
});

const addFeedbackMarkerThunk = item => dispatch => {
  dispatch(addItemAction(item));
  dispatch(addMarkerFeature(toFeedbackMarker(item), marker, FEEDBACK_MARKER_PRIORITY));
};

function addOrChangeFeedbackMarker(data) {
  // must be bound to an object with state
  const { getState, dispatch } = this;
  if (getState().mapToolbar.feedbackMarker.active) {
    dispatch(addFeedbackMarkerThunk(data));
  }
}

export const turnFeedbackOn = (dispatch, getState) => {
  // Set callbacks for oskari map click event
  const reduxContextObj = { getState, dispatch };
  const clickHandler = addOrChangeFeedbackMarker.bind(reduxContextObj);
  OskariMap.clicked(FEEDBACK_MARKER_CLICK_EVENT_LISTENER_ID, clickHandler);
};

export const turnFeedbackOff = () => {
  OskariMap.removeClicked(FEEDBACK_MARKER_CLICK_EVENT_LISTENER_ID);
};

export const removeFeedbackMarkerThunk = () => dispatch => {
  dispatch(removeFeatureThunk(FEEDBACK_MARKER));
  dispatch(removeItemAction());
};
