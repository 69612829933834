export const ACTIVE_MENU_ITEM = 'active-menu-item';

export const ACTIVITY_LOG_TITLE = 'activity-log-title';
export const AMALGAMATION_1_TITLE = 'amalgamation-1-title';
export const AMALGAMATION_2_TITLE = 'amalgamation-2-title';
export const AMALGAMATION_3_TITLE = 'amalgamation-3-title';
export const AMALGAMATION_4_TITLE = 'amalgamation-4-title';
export const BOUNDARY_DEMARCATION_1_TITLE = 'boundary-demarcation-1-title';
export const BOUNDARY_DEMARCATION_2_TITLE = 'boundary-demarcation-2-title';
export const BOUNDARY_DEMARCATION_3_TITLE = 'boundary-demarcation-3-title';
export const BOUNDARY_DEMARCATION_4_TITLE = 'boundary-demarcation-4-title';
export const BUY_CERTIFICATES_TITLE = 'buy-certificates-title';
export const CADASTRAL_SURVEY_TITLE = 'cadastral-survey-title';
export const CHECKOUT_1_TITLE = 'checkout-1-title';
export const CHECKOUT_2_TITLE = 'checkout-2-title';
export const COMPLEMENTARY_APPLICATION_TITLE = 'complementary-application-title';
export const COMPLEMENTARY_APPLICATION_RECEIVED_TITLE = 'complementary-application-received-title';
export const CONFIRMATION_TITLE = 'confirmation-title';
export const EASEMENT_SURVEY_1_TITLE = 'easement-survey-1-title';
export const EASEMENT_SURVEY_2_TITLE = 'easement-survey-2-title';
export const EASEMENT_SURVEY_3_TITLE = 'easement-survey-3-title';
export const EASEMENT_SURVEY_4_TITLE = 'easement-survey-4-title';
export const GEOSPATIAL_DATA_TITLE = 'geospatial-data-title';
export const LCA_DEATH_ESTATE_1_TITLE = 'lca-death-estate-1-title';
export const LCA_DEATH_ESTATE_2_TITLE = 'lca-death-estate-2-title';
export const LCA_DEATH_ESTATE_3_TITLE = 'lca-death-estate-3-title';
export const LCA_DEATH_ESTATE_4_TITLE = 'lca-death-estate-4-title';
export const LCA_DEATH_ESTATE_5_TITLE = 'lca-death-estate-5-title';
export const LCA_GENERIC_1_TITLE = 'lca-generic-1-title';
export const LCA_GENERIC_2_TITLE = 'lca-generic-2-title';
export const LCA_GENERIC_3_TITLE = 'lca-generic-3-title';
export const LCA_GENERIC_4_TITLE = 'lca-generic-4-title';
export const LCA_GENERIC_5_TITLE = 'lca-generic-5-title';
export const LCA_PARTITIONING_1_TITLE = 'lca-partitioning-1-title';
export const LCA_PARTITIONING_2_TITLE = 'lca-partitioning-2-title';
export const LCA_PARTITIONING_3_TITLE = 'lca-partitioning-3-title';
export const LCA_PARTITIONING_4_TITLE = 'lca-partitioning-4-title';
export const LCA_PARTITIONING_5_TITLE = 'lca-partitioning-5-title';
export const LCA_PUBLIC_PURCHASE_WITNESS_1_TITLE = 'lca-public-purchase-witness-1-title';
export const LCA_PUBLIC_PURCHASE_WITNESS_2_TITLE = 'lca-public-purchase-witness-2-title';
export const LCA_PUBLIC_PURCHASE_WITNESS_3_TITLE = 'lca-public-purchase-witness-3-title';
export const LCA_PUBLIC_PURCHASE_WITNESS_4_TITLE = 'lca-public-purchase-witness-4-title';
export const LCA_PUBLIC_PURCHASE_WITNESS_5_TITLE = 'lca-public-purchase-witness-5-title';
export const LEASEHOLD_APPLICATION_TITLE = 'leasehold-application-title';
export const LEASEHOLDERS_TITLE = 'leaseholders-title';
export const LEASEHOLD_REGISTRATION_1_TITLE = 'leasehold-registration-1-title';
export const LEASEHOLD_REGISTRATION_2_TITLE = 'leasehold-registration-2-title';
export const LEASEHOLD_REGISTRATION_3_TITLE = 'leasehold-registration-3-title';
export const LEASEHOLD_REGISTRATION_4_TITLE = 'leasehold-registration-4-title';
export const LEASEHOLD_REGISTRATION_5_TITLE = 'leasehold-registration-5-title';
export const LEASEHOLD_REGISTRATION_6_TITLE = 'leasehold-registration-6-title';
export const LEASEHOLD_TRANSFER_1_TITLE = 'leasehold-transfer-1-title';
export const LEASEHOLD_TRANSFER_2_TITLE = 'leasehold-transfer-2-title';
export const LEASEHOLD_TRANSFER_3_TITLE = 'leasehold-transfer-3-title';
export const LEASEHOLD_TRANSFER_4_TITLE = 'leasehold-transfer-4-title';
export const LEASEHOLD_TRANSFER_5_TITLE = 'leasehold-transfer-5-title';
export const LEGAL_CONFIRMATION_TITLE = 'legal-confirmation-title';
export const LICENCED_DATA_TITLE = 'licenced-data-title';
export const MAP_STORE_TITLE = 'map-store-title';
export const MORTGAGE_TITLE = 'mortgage-title';
export const MORTGAGES_LIST_TITLE = 'mortgages-list-title';
export const OTHER_CADASTRAL_SURVEY_1_TITLE = 'other-cadastral-survey-1-title';
export const OTHER_CADASTRAL_SURVEY_2_TITLE = 'other-cadastral-survey-2-title';
export const OTHER_CADASTRAL_SURVEY_3_TITLE = 'other-cadastral-survey-3-title';
export const OTHER_CADASTRAL_SURVEY_4_TITLE = 'other-cadastral-survey-4-title';
export const OTHER_SPECIAL_RIGHT_APPLICATION_TITLE = 'other-special-right-application-title';
export const OTHER_SPECIAL_RIGHT_1_TITLE = 'other-special-right-1-title';
export const OTHER_SPECIAL_RIGHT_2_TITLE = 'other-special-right-2-title';
export const OTHER_SPECIAL_RIGHT_3_TITLE = 'other-special-right-3-title';
export const OTHER_SPECIAL_RIGHT_4_TITLE = 'other-special-right-4-title';
export const OTHER_SPECIAL_RIGHT_5_TITLE = 'other-special-right-5-title';
export const OTHER_SPECIAL_RIGHT_6_TITLE = 'other-special-right-6-title';
export const OTHER_SPECIAL_RIGHT_7_TITLE = 'other-special-right-7-title';
export const PLEDGES_TITLE = 'pledges-title';
export const PLOT_SUBDIVISION_SURVEY_1_TITLE = 'plot-subdivision-survey-1-title';
export const PLOT_SUBDIVISION_SURVEY_2_TITLE = 'plot-subdivision-survey-2-title';
export const PLOT_SUBDIVISION_SURVEY_3_TITLE = 'plot-subdivision-survey-3-title';
export const PLOT_SUBDIVISION_SURVEY_4_TITLE = 'plot-subdivision-survey-4-title';
export const PRINTED_MAPS_TITLE = 'printed-maps-title';
export const PRIVATE_ROAD_SURVEY_1_TITLE = 'private-road-survey-1-title';
export const PRIVATE_ROAD_SURVEY_2_TITLE = 'private-road-survey-2-title';
export const PRIVATE_ROAD_SURVEY_3_TITLE = 'private-road-survey-3-title';
export const PRIVATE_ROAD_SURVEY_4_TITLE = 'private-road-survey-4-title';
export const PRODUCTS_ADDED_TITLE = 'products-added-title';
export const PROPRIETOR_TITLE = 'proprietor-title';
export const PROPRIETORS_TITLE = 'proprietors-title';
export const PROVIDE_FEEDBACK_TITLE = 'provide-feedback-title';
export const PROVIDE_FEEDBACK_ABOUT_MAP_TITLE = 'provide-feedback-about-map-title';
export const REAL_ESTATE_TRANSFER_ANNOUNCEMENT_TITLE = 'real-estate-transfer-announcement-title';
export const REAL_ESTATE_TRANSFER_ANNOUNCEMENT_1_TITLE = 'real-estate-transfer-announcement-1-title';
export const REAL_ESTATE_TRANSFER_ANNOUNCEMENT_2_TITLE = 'real-estate-transfer-announcement-2-title';
export const REAL_ESTATE_TRANSFER_ANNOUNCEMENT_3_TITLE = 'real-estate-transfer-announcement-3-title';
export const REAL_ESTATE_TRANSFER_ANNOUNCEMENT_4_TITLE = 'real-estate-transfer-announcement-4-title';
export const REAL_ESTATE_TRANSFER_ANNOUNCEMENT_5_TITLE = 'real-estate-transfer-announcement-5-title';
export const REAL_ESTATE_TRANSFER_ANNOUNCEMENT_6_TITLE = 'real-estate-transfer-announcement-6-title';
export const REAL_ESTATE_TRANSFER_ANNOUNCEMENT_7_TITLE = 'real-estate-transfer-announcement-7-title';
export const REAL_ESTATE_TRANSFER_ANNOUNCEMENT_8_TITLE = 'real-estate-transfer-announcement-8-title';
export const REAL_PROPERTY_TITLE = 'real-property-title';
export const REALTY_TITLE = 'realty-title';
export const RELICTION_AREA_SURVEY_1_TITLE = 'reliction-area-survey-1-title';
export const RELICTION_AREA_SURVEY_2_TITLE = 'reliction-area-survey-2-title';
export const RELICTION_AREA_SURVEY_3_TITLE = 'reliction-area-survey-3-title';
export const RELICTION_AREA_SURVEY_4_TITLE = 'reliction-area-survey-4-title';
export const RESTRICTION_TITLE = 'restriction-title';
export const RESTRICTIONS_TITLE = 'restrictions-title';
export const SEARCH_REAL_ESTATES_TITLE = 'search-real-estates-title';
export const SHARE_MAP_LINK_TITLE = 'share-map-link-title';
export const SHOPPING_CART_TITLE = 'shopping-cart-title';
export const THANK_YOU_FOR_FEEDBACK_TITLE = 'thank-you-for-feedback-title';
export const USUFRUCT_OR_RESTRICTION_TITLE = 'usufruct-or-restriction-title';
export const USUFRUCTS_AND_RESTRICTIONS_TITLE = 'usufructs-and-restrictions-title';
export const YOUR_MAP_TITLE_1 = 'your-map-title-1';
export const YOUR_MAP_TITLE_2 = 'your-map-title-2';
export const YOUR_MAP_TITLE_3 = 'your-map-title-3';
export const SUPPLEMENT_REQUEST_TITLE = 'supplement-request-title';
