import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pickBy, isEmpty, last, uniq } from 'lodash';

import { getCorrectGeometriesForType } from 'realEstateSite/containers/Realty/common/geometries/geometriesHelpers';
import { ALL } from 'realEstateSite/containers/Realty/common/geometries/geometriesConstants';
import { hideInfobox } from 'oskari/OskariMap';
import { SERVICES_USUFRUCTS_AND_RESTRICTIONS } from 'realEstateSite/constants/Routes';
import { REAL_ESTATE_SITE_FEATURE_TYPES } from 'common/geometries/geometriesActionTypes';
import { clearInfoBox } from '../MapToolbar/SelectorActions';
import {
  LAYER_PARCELS,
  LAYER_REAL_ESTATE_BORDERS,
  LAYER_REAL_ESTATE_IDS,
  LAYER_USUFRUCTS,
  LAYER_BOUNDARY_MARKERS,
} from '../../../oskari/layers/VectorLayers';

const useHideInfoBox = ({ channelReady }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const urlparts = pathname.split('/').filter(Boolean);

  const isUsufructsAndRestrictionsPage =
    pathname.includes(SERVICES_USUFRUCTS_AND_RESTRICTIONS) && last(urlparts) !== SERVICES_USUFRUCTS_AND_RESTRICTIONS;

  const geometries = useSelector(state => state.geometry || {});
  const { layerId, infoBoxId, id } = useSelector(state => state.mapToolbar.selector);
  const { layerVisibility, removedRealEstateSiteFeatures } = useSelector(state => state.geometries || {});

  const allGeometries = getCorrectGeometriesForType(ALL, geometries);
  const realEstateIds = uniq(allGeometries.map(geo => geo.id));

  const selectorId =
    [LAYER_PARCELS, LAYER_REAL_ESTATE_IDS, LAYER_REAL_ESTATE_BORDERS].includes(layerId) &&
    realEstateIds.find(realEstatesId => realEstatesId === infoBoxId);
  const shownOnMap = geometries?.shownOnMap;
  const realEstatesOnMap = Object.keys(pickBy(shownOnMap));

  const shouldCloseRealEstateInfoBox =
    !!selectorId && !realEstatesOnMap.includes(selectorId) && (!isEmpty(shownOnMap) || isUsufructsAndRestrictionsPage);

  const isBoundaryMarkersLayerHidden = !isEmpty(layerVisibility) && layerVisibility[LAYER_BOUNDARY_MARKERS] === false;
  const isBoundaryMarkerLayerRemoved =
    !isEmpty(removedRealEstateSiteFeatures) &&
    removedRealEstateSiteFeatures[REAL_ESTATE_SITE_FEATURE_TYPES.BOUNDARY_MARKERS] === true;

  const shouldCloseUsufructLayerInfoBox =
    layerId === LAYER_USUFRUCTS &&
    (!urlparts.includes(SERVICES_USUFRUCTS_AND_RESTRICTIONS) ||
      (last(urlparts) !== SERVICES_USUFRUCTS_AND_RESTRICTIONS && id && !urlparts.includes(id.toString())));
  const shouldCloseBoundaryMarkerLayerInfoBox =
    layerId === LAYER_BOUNDARY_MARKERS && (isBoundaryMarkersLayerHidden || isBoundaryMarkerLayerRemoved);

  const shouldCloseUsufructInfoBox = shouldCloseUsufructLayerInfoBox || shouldCloseBoundaryMarkerLayerInfoBox;

  useEffect(() => {
    if (channelReady) {
      if (infoBoxId && (shouldCloseRealEstateInfoBox || shouldCloseUsufructInfoBox)) {
        hideInfobox([infoBoxId]);
        dispatch(clearInfoBox());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelReady, realEstatesOnMap, selectorId]);
};

export default useHideInfoBox;
