let _dispatch;

export function init(dispatch) {
  _dispatch = dispatch;
  return Promise.resolve();
}

export const dispatch = action => {
  if (!_dispatch) {
    console.error('dispatch not initialized!');
  }
  return _dispatch(action);
};
