/*
 * Common names for analytics categories
 * This is to prevent typos and other very much expected human errors
 * from contaminating the data.
 */
/*
 * Categories
 */
import Analytics from './Analytics';
/*
 * Generic tags
 */

const CLICK = 'klikattu';
const TOUCH = 'kosketettu';
const ERROR = 'virhe';
// Categories
const STARTUP = 'Käynnistys';
const SYSTEM_ERROR = 'Järjestelmävirhe';
const MAPTOOLS = 'Karttatyökalut';
const SIDEBAR = 'Karttasivupalkki';
const LAYERS = 'Karttatasot';
const MOBILETOP = 'Mobiili ylävalikko';
const SEARCH = 'Haku';
const MAP_CONTROLS_DESKTOP = 'Karttaohjaimet - työpöytä';
const MAP_CONTROLS_MOBILE = 'Karttaohjaimet - mobiili';
const ERROR_GENERIC = 'yleinen virhesivu';
const LAYOUT = 'Layout';
const REAL_ESTATES = 'Kiinteistöt';
const SPATIAL_DATA_FILES = 'Avoimien aineistojen lataus';
const LICENCED_DATA_FILES = 'Käyttörajoitetut aineistot';
const REAL_PROPERTY = 'Hoida kiinteistöasioita';

export const CATEGORIES = {
  MAPTOOLS,
  SIDEBAR,
  LAYERS,
  LAYOUT,
  MOBILETOP,
  SEARCH,
  MAP_CONTROLS_MOBILE,
  MAP_CONTROLS_DESKTOP,
  STARTUP,
  SYSTEM_ERROR,
  SPATIAL_DATA_FILES,
  LICENCED_DATA_FILES,
  REAL_PROPERTY,
};

/*
 * Actions
 */

const SIDEBAR_TOGGLE = 'Koko muutettu';
const CUSTOM_MARKER_TOOL = 'Karttamerkintätyökalu';
const DISTANCE_TOOL = 'Etäisyystyökalu';
const AREA_TOOL = 'Pinta-alatyökalu';
const ADDRESS_TOOL = 'Osoitteenhakutyökalu';
const REMOVE_ALL_TOOL = 'Merkintöjen poistotyökalu';
const CHANGE = 'vaihto';
const SEARCH_REAL_ESTATES = 'Kiinteistöhaku kiinteistötunnuksella';
const INDIVIDUAL_CUSTOMER_LOGIN = 'Kirjaudu henkilöasiakkaana';
const ORGANISATIONAL_CUSTOMER_LOGIN = 'Kirjaudu organisaatioasiakkaana';

export const ACTIONS = {
  CLICK,
  TOUCH,
  ERROR,
  SIDEBAR_TOGGLE,
  CUSTOM_MARKER_TOOL,
  DISTANCE_TOOL,
  AREA_TOOL,
  ADDRESS_TOOL,
  REMOVE_ALL_TOOL,
  ERROR_GENERIC,
  CHANGE,
};

export const SPATIAL_DATA_FILES_ACTIONS = {
  SELECT_PRODUCT: 'Tuotteen valinta',
  SELECTION_ERROR: 'Virhe tuotteen valinnassa',
};

export const SPATIAL_DATA_FILES_ERRORS = {
  areaSelection: 'Aluevalinta liian suuri tai silmukka',
  areaSelectionValid: 'Aluevalinta puuttuu',
  theme: 'Teemavalinta puuttuu',
  selectedMunicipality: 'Kuntavalinta puuttuu',
  selectedMapSheets: 'Liian monta karttalehteä',
};

export const LICENCED_DATA_FILES_ACTIONS = {
  SELECT: 'Käyttörajoitetut aineiston valinta',
  LOGIN: 'Käyttörajoitetut aineistot kirjautuminen',
};

// LABELS
const NO_ERROR_MESSAGE = 'empty error message';
const OPEN_LABEL_SIDEBAR = 'Avataan karttapalkki';
const CLOSE_LABEL_SIDEBAR = 'Suljetaan karttapalkki';
const LABEL_ON = 'päälle';
const LABEL_OFF = 'pois päältä';
const JS_ERROR = 'Javascriptin suoritusvirhe';
const MOBILE_LAYOUT = 'Mobile Layout';
const DESKTOP_LAYOUT = 'Desktop Layout';

export const LABELS = {
  OPEN_LABEL_SIDEBAR,
  CLOSE_LABEL_SIDEBAR,
  LABEL_ON,
  LABEL_OFF,
  CLICK,
  TOUCH,
  JS_ERROR,
  ERROR,
  NO_ERROR_MESSAGE,
  DESKTOP_LAYOUT,
  MOBILE_LAYOUT,
};

export function onOffText(isOn, label) {
  const parts = [];

  if (label) {
    parts.push(label);
  }

  parts.push(isOn ? LABEL_OFF : LABEL_ON);

  return parts.join(' ');
}

export function trackCustomMarkerToolClick(value) {
  Analytics().trackEvent({
    category: MAPTOOLS,
    action: CUSTOM_MARKER_TOOL,
    label: onOffText(value),
  });
}

export function trackDistanceToolClick(value) {
  Analytics().trackEvent({
    category: MAPTOOLS,
    action: DISTANCE_TOOL,
    label: onOffText(value),
  });
}

export function trackAreaToolClick(value) {
  Analytics().trackEvent({
    category: MAPTOOLS,
    action: AREA_TOOL,
    label: onOffText(value),
  });
}

export function trackAddressToolClick(value) {
  Analytics().trackEvent({
    category: MAPTOOLS,
    action: ADDRESS_TOOL,
    label: onOffText(value),
  });
}

export function trackRemoveAllClick() {
  Analytics().trackEvent({
    category: MAPTOOLS,
    action: REMOVE_ALL_TOOL,
    label: CLICK,
  });
}

export function trackSidebarToggle(isOpen) {
  Analytics().trackEvent({
    category: SIDEBAR,
    action: SIDEBAR_TOGGLE,
    label: isOpen ? CLOSE_LABEL_SIDEBAR : OPEN_LABEL_SIDEBAR,
  });
}

export function trackSystemError() {
  Analytics().trackEvent({
    category: SYSTEM_ERROR,
    action: ERROR_GENERIC,
  });
}

export function trackStartError(phase, errorText) {
  Analytics().trackEvent({
    category: STARTUP,
    action: phase,
    label: errorText,
  });
}

export function track404() {
  Analytics().track404();
}

export function trackLayoutChange(label) {
  Analytics().trackEvent({
    category: LAYOUT,
    action: CHANGE,
    label,
  });
}

export function trackLayoutChangeToMobile() {
  trackLayoutChange(MOBILE_LAYOUT);
}

export function trackLayoutChangeToDesktop() {
  trackLayoutChange(DESKTOP_LAYOUT);
}

export function trackRealEstateSearch(realEstateIdentifier) {
  Analytics().trackEvent({
    category: REAL_ESTATES,
    action: SEARCH_REAL_ESTATES,
    label: realEstateIdentifier,
  });
}

export function trackTooManyMunicipalitiesInCart() {
  Analytics().trackEvent({
    category: SPATIAL_DATA_FILES,
    action: SPATIAL_DATA_FILES_ACTIONS.SELECTION_ERROR,
    label: 'Liian monta kuntatuotetta ostoskorissa',
  });
}

export function trackSpatialDataFilesFormValidationErrors(label) {
  Analytics().trackEvent({
    category: SPATIAL_DATA_FILES,
    action: SPATIAL_DATA_FILES_ACTIONS.SELECTION_ERROR,
    label,
  });
}

export function trackLicencedDataFilesSelect() {
  Analytics().trackEvent({
    category: LICENCED_DATA_FILES,
    action: LICENCED_DATA_FILES_ACTIONS.SELECT,
  });
}

export function trackLicencedDataFilesLogin() {
  Analytics().trackEvent({
    category: LICENCED_DATA_FILES,
    action: LICENCED_DATA_FILES_ACTIONS.LOGIN,
  });
}

export function trackIndividualCustomerLogin() {
  Analytics().trackEvent({
    category: REAL_PROPERTY,
    action: INDIVIDUAL_CUSTOMER_LOGIN,
  });
}

export function trackOrganisationalCustomerLogin() {
  Analytics().trackEvent({
    category: REAL_PROPERTY,
    action: ORGANISATIONAL_CUSTOMER_LOGIN,
  });
}
