import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

const SearchError = ({ errorMessage }) => (
  <div className="search-error-msg" data-testid="SearchError">
    {t(errorMessage)}
  </div>
);

SearchError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default SearchError;
