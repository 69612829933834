import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';

import { REAL_ESTATE_SOURCE_CHANNEL } from '../SearchActions';
import RESULT_PROP_TYPE from '../ResultPropType';
import SearchResultName from './SearchResultName';

const DESKTOP_MAX_ROW_LENGTH = '28';

const isLongResult = ({ name, municipality }) => `${name}${t(municipality)}`.length >= DESKTOP_MAX_ROW_LENGTH;

const SearchResult = ({ result, showOnMap, searchString }) => (
  <li className="search-result__item" data-testid="SearchResult">
    <button type="button" onClick={() => showOnMap(result)} className="button search-result__show-on-map-button">
      <div
        className={classNames('search-result-data', {
          'long-result': isLongResult(result),
        })}
      >
        <SearchResultName resultName={result.name} searchString={searchString} />
        <span className="search-result-municipality">{result.region && `, ${result.region}`}</span>
      </div>
      {result.type && (
        <div className="search-result-data">
          {result.source === REAL_ESTATE_SOURCE_CHANNEL ? t('search.results.realEstate') : result.type}
        </div>
      )}
    </button>
  </li>
);

SearchResult.propTypes = {
  result: RESULT_PROP_TYPE.isRequired,
  searchString: PropTypes.string.isRequired,
  showOnMap: PropTypes.func.isRequired,
};

export default SearchResult;
