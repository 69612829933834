import cls from 'classnames';
import PropTypes from 'prop-types';
import div from '../../helpers/Html/divHelper';

const FLEX = 'flex-grid-container';
const ROW = 'grid-row';
const BLOCK = 'grid-block';
const DESKTOP_BLOCK = 'desktop-block';
const MOBILE_BLOCK = 'mobile-block';

const joiner = (className, size) => (size ? `${className}-${size}` : '');

const blockClass = size => joiner(BLOCK, size);
const mobileBlockClass = size => joiner(MOBILE_BLOCK, size);
const desktopBlockClass = size => joiner(DESKTOP_BLOCK, size);

export const FlexGrid = props => div(cls(FLEX, props.className), props.children);

export const Row = props => div(cls(ROW, props.className), props.children, props.dataCy);

export const Block = props => {
  const classes = cls(
    BLOCK,
    props.className,
    blockClass(props.size),
    mobileBlockClass(props.mobile),
    desktopBlockClass(props.desktop)
  );
  return div(classes, props.children, null, props.dataTestId);
};

Block.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mobile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  desktop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
