import {
  ADD_NOTIFICATION,
  HIDE_NOTIFICATION,
  DELETE_FORM_VALIDATION_NOTIFICATION,
} from './AppNotificationsActionTypes';

// Reducer
export default function appNotificationsReducer(state = [], action = {}) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const notificationExists = !!state.find(notification => notification?.id === action.notification.id);
      return !notificationExists ? [...state, action.notification] : state;
    }
    case HIDE_NOTIFICATION:
      return state.map(n => (n.id === action.id ? { ...n, visible: false } : n));
    case DELETE_FORM_VALIDATION_NOTIFICATION:
      return state.filter(n => n.id !== action.id);
    default:
      return state;
  }
}
