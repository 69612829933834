import * as Routes from 'common/constants/Routes';
import { isWebUri } from 'valid-url';

const URL_MAX_LENGTH = 2000;

export default function validateUrl(url) {
  if (!isWebUri(url) || url.length > URL_MAX_LENGTH) {
    console.error('INVALID URL');
    window.location.assign(Routes.getRoute());
  }
}
