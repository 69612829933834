import { getJson } from 'common/api/AjaxJson';
import geoJsonCreate from 'common/helpers/GeoJson';
import * as OskariMap from 'oskari/OskariMap';
import { t } from 'i18next';
import * as routes from 'common/constants/Routes';
import { initGrid } from 'common/containers/MapStore/MapStoreActions';
import { error } from 'common/helpers/Error';
import inside from 'point-in-polygon';
import { LAYER_MAP_SHEETS, LAYER_SELECTED_MAP_SHEET } from '../../../oskari/layers/VectorLayerIds';
import {
  readyMapSelectSheet,
  removeReadyMapsSheet,
  selectScale,
  setReadyMapSheetsLoaded,
  setReadyMapsLoading,
} from './ReadyMapsFormReducer';

function _cleanAllReadyMapLayers() {
  OskariMap.clearLayer(LAYER_MAP_SHEETS);
  OskariMap.clearLayer(LAYER_SELECTED_MAP_SHEET);
}

function renderSheetHighlights() {
  return (dispatch, getState) => {
    const sheetsByScale = getState().readyMaps.selectedSheets.filter(
      ({ mittakaava }) => mittakaava === getState().readyMaps.selectedScale
    );

    OskariMap.clearLayer(LAYER_SELECTED_MAP_SHEET);

    if (sheetsByScale.length > 0) {
      OskariMap.drawGeoJson('highlightedId', geoJsonCreate(sheetsByScale, 'karttalehdenNumero'), {
        layer: LAYER_SELECTED_MAP_SHEET,
        featureStyle: {
          fill: {
            color: 'rgba(145,0,0,0.5)',
          },
        },
      });
    }
  };
}

function sheetClicked(mapSheets, cb) {
  OskariMap.clicked('gridClick', sourceData => {
    const clickedSheet = mapSheets.find(nimike => inside([sourceData.lon, sourceData.lat], nimike.coords));
    if (clickedSheet) {
      return cb(clickedSheet);
    }
    return null;
  });
}

export function selectSheetThunk(sheet) {
  return dispatch => {
    dispatch(readyMapSelectSheet({ sheet }));
    dispatch(renderSheetHighlights());
  };
}

export function removeSelectedSheetThunk(sheet) {
  return dispatch => {
    dispatch(removeReadyMapsSheet({ sheet }));
    dispatch(renderSheetHighlights());
  };
}

export function cleanMapThunk() {
  return dispatch => {
    dispatch(() => {
      _cleanAllReadyMapLayers();
      OskariMap.removeClicked('gridClick');
    });
  };
}

export function getReadyMapsSheetsThunk(sheetsCount = 0) {
  return (dispatch, getState) => {
    if (sheetsCount > 0 || getState().readyMaps.sheetsLoading) {
      return Promise.resolve();
    }
    dispatch(setReadyMapsLoading({ isLoading: true }));
    return getJson(routes.getRoute(routes.API_PATH_TITLES))
      .then(response => {
        dispatch(initGrid(response));
        dispatch(setReadyMapsLoading({ isLoading: false }));
        dispatch(setReadyMapSheetsLoaded({ isLoaded: true }));
      })
      .catch(err => {
        error(t('mapStoreIndex.readyMaps.mapSheetsLoadError'), err);
        dispatch(setReadyMapsLoading({ isLoading: false }));
      });
  };
}

export function drawSheetsByScale(scale) {
  const bgColors = [
    { id: '1:25000', color: 'rgba(255,78,0,0.5)' }, // oranssi
    { id: '1:50000', color: 'rgba(51,204,255,0.5)' }, // turkoosi
  ];

  return (dispatch, getState) => {
    if (getState().mapStore.sheets.length > 0) {
      const sheetsByScale = getState().mapStore.sheets.filter(sheet => sheet.mittakaava === scale);
      const geoJsonMapSheetId = geoJsonCreate(sheetsByScale, 'karttalehdenNumero', ['karttalehdenNumero']);
      dispatch(() => {
        _cleanAllReadyMapLayers();

        OskariMap.drawGeoJson('mapSheetBg', geoJsonMapSheetId, {
          layer: LAYER_MAP_SHEETS,
          featureStyle: {
            fill: {
              color: bgColors.filter(bg => bg.id === scale)[0].color,
            },
          },
        });
      });

      dispatch(renderSheetHighlights());

      OskariMap.removeClicked('gridClick');
      sheetClicked(sheetsByScale, clickedSheet => {
        if (
          getState().readyMaps.selectedSheets.find(
            ({ karttalehdenNumero }) => karttalehdenNumero === clickedSheet.karttalehdenNumero
          )
        )
          dispatch(removeSelectedSheetThunk(clickedSheet));
        else dispatch(selectSheetThunk(clickedSheet));
      });
    }
  };
}

export function selectScaleThunk(scale) {
  return dispatch => {
    dispatch(selectScale({ scale }));
    dispatch(drawSheetsByScale(scale));
  };
}
