import React, { useState } from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import { uniqueId } from 'lodash';
import SearchResult from './SearchResult';
import SearchResultName from './SearchResultName';
import { REAL_ESTATE_SOURCE_CHANNEL } from '../SearchActions';
import RESULT_PROP_TYPE from '../ResultPropType';

const resolveCountText = (results, isRealEstate) => {
  const postfix = t('search.results.parcels');
  const resultCountPostfix = postfix === 'search.results.parcels' ? '' : postfix;
  const count = isRealEstate ? resultCountPostfix : t('search.results.resultCountLabel');

  return `${results.length} ${count}`;
};

const GroupedSearchResult = ({ results, showOnMap, searchString }) => {
  const [open, setOpen] = useState(false);

  const isRealEstate = results[0].source === REAL_ESTATE_SOURCE_CHANNEL;
  const resultCountText = resolveCountText(results, isRealEstate);
  return (
    <li className="search-result__item" data-testid="GroupedSearchResult">
      <button type="button" onClick={() => showOnMap(results)} className="button search-result__show-on-map-button">
        <div className="search-result-data grouped">
          <SearchResultName resultName={results[0].name} searchString={searchString} />
          <span className="search-result-municipality">{results[0].region && `, ${results[0].region}`}</span>
        </div>
        <div className="search-result-data">{`${
          isRealEstate ? t('search.results.realEstate') : ''
        } ${resultCountText}`}</div>
      </button>
      {!isRealEstate && (
        <button
          type="button"
          aria-label={open ? t('sidebar.toggle.tooltip.close') : t('sidebar.toggle.tooltip.open')}
          title={open ? t('sidebar.toggle.tooltip.close') : t('sidebar.toggle.tooltip.open')}
          className={cls('button', 'button--icon', 'search-result-item__expand-toggle', { open })}
          onClick={() => setOpen(prev => !prev)}
        />
      )}
      {open && (
        <ul className="search-result-list-group">
          {results.map(result => (
            <SearchResult
              searchString={searchString}
              key={['key', result.id, uniqueId()].join('-')}
              result={result}
              showOnMap={showOnMap}
              grouped
            />
          ))}
        </ul>
      )}
    </li>
  );
};

GroupedSearchResult.propTypes = {
  results: PropTypes.arrayOf(RESULT_PROP_TYPE).isRequired,
  searchString: PropTypes.string.isRequired,
  showOnMap: PropTypes.func.isRequired,
};

export default GroupedSearchResult;
