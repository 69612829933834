import { useMemo } from 'react';
import { resolveFormPageCount, resolveValidFormStep, isFirst, isLastFormStep, isReceived } from './FormHelpers';

const useFormRouting = (children, step, hasNoSucceededPage) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formStepCount = useMemo(() => resolveFormPageCount(children) - (hasNoSucceededPage ? 0 : 1), [
    children?.length,
  ]);
  const currentPageNumber = useMemo(() => resolveValidFormStep(step, formStepCount), [formStepCount, step]);
  const isFirstPage = useMemo(() => isFirst(currentPageNumber), [currentPageNumber]);
  const isLastFormPage = useMemo(() => {
    if (hasNoSucceededPage && isFirstPage) {
      return false;
    }
    return isLastFormStep(currentPageNumber, formStepCount);
  }, [currentPageNumber, formStepCount, isFirstPage, hasNoSucceededPage]);
  const isReceivedPage = useMemo(() => {
    if (hasNoSucceededPage) {
      return false;
    }
    return isReceived(currentPageNumber);
  }, [currentPageNumber, hasNoSucceededPage]);

  return { formStepCount, currentPageNumber, isFirstPage, isLastFormPage, isReceivedPage };
};

export default useFormRouting;
