import AuthorizationApi from 'common/api/AuthorizationApi';
import saveAuthorization from './AuthorizationActions';

const requestAuthorization = dispatch =>
  AuthorizationApi.getPrivileges()
    .then(authorization => dispatch(saveAuthorization(authorization)))
    .catch(error => {
      console.error('getPrivileges threw error:', error);
    });

export default requestAuthorization;
