import React from 'react';
import { difference, map } from 'lodash';
import {
  LIST_LEGAL_CONFIRMATIONS,
  LIST_OWN_REAL_ESTATES,
  REALESTATES_BY_SSN,
} from 'realEstateSite/containers/Authorization/privileges';

let getState = () => false;

export const initAuthorization = getStateFunction => {
  getState = getStateFunction;
};

const stringifyPrivileges = privileges => map(privileges, 'identifier');

export const isAuthorized = requiredPrivileges =>
  difference(requiredPrivileges, stringifyPrivileges(getState().authorization?.privileges)).length === 0;

const bindToPrivilege = (ComponentBefore, ComponentAfter, requiredPrivileges) => props => {
  if (getState().authorization === undefined) {
    throw new Error(
      'Authorization component used without authorization state. State must contain authorization object.'
    );
  }
  if (isAuthorized(requiredPrivileges)) {
    return <ComponentAfter {...props} />;
  }
  return <ComponentBefore {...props} />;
};

export const Authorization = (Component, requiredPrivileges) => props =>
  bindToPrivilege(() => null, Component, requiredPrivileges)(props);

export const StateAuthorization = (Component, statePredicate) => props =>
  statePredicate(getState(), props) ? <Component {...props} /> : null;

export const validVTJPerson = () => {
  if (isAuthorized([REALESTATES_BY_SSN])) {
    return !getState().supportUserView.client
      ? true
      : getState().supportUserView.client.validVTJPerson || getState().supportUserView.client.organizationId;
  }
  if (isAuthorized([LIST_OWN_REAL_ESTATES])) {
    return getState().authentication.validVTJPerson;
  }
  return false;
};

export const supportUserViewValidSearchVTJPerson = () =>
  isAuthorized([REALESTATES_BY_SSN]) &&
  isAuthorized([LIST_LEGAL_CONFIRMATIONS]) &&
  getState().supportUserView.client &&
  (getState().supportUserView.client.validVTJPerson || getState().supportUserView.client.organizationId != null);

export const legalConfirmationAuthorization = () =>
  supportUserViewValidSearchVTJPerson() ||
  (isAuthorized([LIST_OWN_REAL_ESTATES]) &&
    isAuthorized([LIST_LEGAL_CONFIRMATIONS]) &&
    getState().authentication.validVTJPerson);
