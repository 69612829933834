/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { getRealEstateRoute, SERVICES_FETCH_REAL_ESTATES } from 'realEstateSite/constants/Routes';
import { asyncValidateRealEstateSearch } from 'realEstateSite/containers/Forms/FormValidation/AsyncValidation';
import ServicesApi from 'realEstateSite/api/ServicesApi';
import { fetchGeometries, zoomToGeometriesThunk } from '../../common/geometries/geometriesSlice';
import { SEARCHED } from '../../common/geometries/geometriesConstants';
import { setRegisterState } from '../RealEstateList/RegisterStateActions';
import { formatRealEstateId } from '../RealEstateHelpers';

const searchRealestatesSlice = createSlice({
  name: 'searchRealestates',
  initialState: {
    searchRealEstatesInProgress: false,
    realEstates: [],
    searchRealEstatesLoaded: false,
    onClickError: false,
  },
  reducers: {
    searchRealEstatesInProgress(state, action) {
      state.searchRealEstatesInProgress = action.payload;
    },
    searchRealEstatesResultsCreated(state, action) {
      state.realEstates = [...action.payload, ...state.realEstates];
      state.searchRealEstatesLoaded = true;
    },
    searchRealEstatesOnClickErrorToggled(state, action) {
      state.onClickError = action.payload;
    },
    searchRealEstatesResultsCleared(state) {
      state.realEstates = [];
    },
  },
});

export const {
  searchRealEstatesInProgress,
  searchRealEstatesResultsCreated,
  searchRealEstatesOnClickErrorToggled,
  searchRealEstatesResultsCleared,
} = searchRealestatesSlice.actions;

const navigateIfNecessary = (navigate, currentPath) => {
  if (currentPath.split('/').length > 3) {
    navigate(getRealEstateRoute('', SERVICES_FETCH_REAL_ESTATES));
  }
};

const handleRealEstateSearch = async (dispatch, fetcher, registerStateDate) => {
  try {
    dispatch(searchRealEstatesInProgress(true));
    const realties = await fetcher();
    realties.length > 0 && dispatch(setRegisterState(realties[0].registerStateDate, registerStateDate));
    await dispatch(fetchGeometries(realties, SEARCHED));
    dispatch(searchRealEstatesResultsCreated(realties));
    dispatch(zoomToGeometriesThunk(SEARCHED, realties[0].id));
  } finally {
    dispatch(searchRealEstatesInProgress(false));
  }
};

export const searchRealEstatesThunk = (identifierInput, navigate, currentPath) => async (dispatch, getState) => {
  const identifier = formatRealEstateId(identifierInput);
  const isAlreadySearched = getState()
    .searchRealEstates.realEstates.map(({ id }) => id)
    .includes(identifier);

  if (!isAlreadySearched) {
    navigateIfNecessary(navigate, currentPath);
    const fetchRealEstate = () => asyncValidateRealEstateSearch(identifier);
    await handleRealEstateSearch(dispatch, fetchRealEstate, getState().realEstateList?.registerStateDate);
  } else {
    navigate(getRealEstateRoute(identifier, SERVICES_FETCH_REAL_ESTATES));
  }
};

export const searchRealEstateFromMapClickThunk = (lat, lon, registerStateDate) => async dispatch => {
  const fetchRealEstate = async () => {
    const registerUnit = await ServicesApi.fetchRegisterUnitForPoint(lat, lon);
    registerUnit && dispatch(setRegisterState(registerUnit.registerStateDate, registerStateDate));
    return [registerUnit];
  };
  await handleRealEstateSearch(dispatch, fetchRealEstate);
};

export default searchRealestatesSlice.reducer;
