const PRODUCTION_HOST = 'asiointi.maanmittauslaitos.fi';
const KARTTAPAIKKA_TESTER = /^\/?$|^\/karttapaikka\/?/;
const KIINTEISTO_TESTER = /^\/kiinteistoasiat\/?/;

const PRODUCTION_PIWIK_HOST = '//matomo.nls.fi/';
const DEV_PIWIK_HOST = '//matomotesti.nls.fi/';

const ACCOUNTS = {
  production: [
    {
      host: PRODUCTION_HOST,
      tester: KARTTAPAIKKA_TESTER,
      id: 10,
      url: PRODUCTION_PIWIK_HOST,
    },
    {
      tester: KIINTEISTO_TESTER,
      host: PRODUCTION_HOST,
      id: 8,
      url: PRODUCTION_PIWIK_HOST,
    },
  ],
  development: [
    {
      tester: KARTTAPAIKKA_TESTER,
      host: '',
      id: 9,
      url: DEV_PIWIK_HOST,
    },
    {
      tester: KIINTEISTO_TESTER,
      host: '',
      id: 8,
      url: DEV_PIWIK_HOST,
    },
  ],
};

/*
 * Choose piwik config according to window.location object
 */

const getEnvByHostname = hostname => (hostname === PRODUCTION_HOST ? ACCOUNTS.production : ACCOUNTS.development);

const getAccount = ({ pathname, hostname }) =>
  getEnvByHostname(hostname).find(account => account.tester.test(pathname));

export default getAccount;
