import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const NOT_BUILD = '3';
const RECENT_SURVEY = '60';

export const BoundaryMarkerDetails = ({ properties, coordinates }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>{t('boundaryMarker.infobox.number')}</td>
            <td>{properties.description}</td>
          </tr>
          <tr>
            <td>{t('boundaryMarker.infobox.structure')}</td>
            <td>{properties.rakenne[language] || properties.rakenne.fi}</td>
          </tr>
          <tr>
            <td>{t('boundaryMarker.infobox.location')}</td>
            <td>
              N: {coordinates.n}
              <br /> E: {coordinates.e}
            </td>
          </tr>
          {properties.tasosijaintitarkkuus && (
            <tr>
              <td>
                {properties.lahdeaineisto.code === RECENT_SURVEY
                  ? t('boundaryMarker.infobox.accuracy')
                  : t('boundaryMarker.infobox.estimatedAccuracy')}
              </td>
              <td>{properties.tasosijaintitarkkuus} m</td>
            </tr>
          )}
          {properties.olemassaolo.code !== NOT_BUILD && (
            <tr>
              <td>{t('boundaryMarker.infobox.relationToGround')}</td>
              <td>{properties.suhdeMaanpintaan[language] || properties.suhdeMaanpintaan.fi}</td>
            </tr>
          )}
          <tr>
            <td>{t('boundaryMarker.infobox.existence')}</td>
            <td>{properties.olemassaolo[language] || properties.olemassaolo.fi}</td>
          </tr>
          <tr>
            <td>{t('boundaryMarker.infobox.source')}</td>
            <td>{properties.lahdeaineisto[language] || properties.lahdeaineisto.fi}</td>
          </tr>
        </tbody>
      </table>
      {properties.lahdeaineisto.code !== RECENT_SURVEY ? t('boundaryMarker.infobox.unconfirmed') : ''}
    </>
  );
};

BoundaryMarkerDetails.propTypes = {
  coordinates: PropTypes.shape({ n: PropTypes.number.isRequired, e: PropTypes.number.isRequired }).isRequired,
  properties: PropTypes.shape({
    description: PropTypes.string,
    rakenne: PropTypes.shape({ fi: PropTypes.string, sv: PropTypes.string }),
    suhdeMaanpintaan: PropTypes.shape({ fi: PropTypes.string, sv: PropTypes.string }),
    olemassaolo: PropTypes.shape({ fi: PropTypes.string, sv: PropTypes.string, code: PropTypes.string }),
    lahdeaineisto: PropTypes.shape({ fi: PropTypes.string, sv: PropTypes.string, code: PropTypes.string }),
    tasosijaintitarkkuus: PropTypes.number,
  }).isRequired,
};

const createBoundaryMarkerDetails = (properties, coordinates) => {
  return ReactDOMServer.renderToString(<BoundaryMarkerDetails coordinates={coordinates} properties={properties} />);
};

export default createBoundaryMarkerDetails;
