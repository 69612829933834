import Analytics, {
  PiwikLoader,
  ANALYTICS_ERROR_NO_WINDOW,
  ANALYTICS_ERROR_NO_ID,
  ANALYTICS_ERROR_NO_URL,
  DEFAULTS,
} from './Analytics';
import getAccount from './piwik-accounts';
import * as EVENTS from './Analytics_eventmap';

export default Analytics;
export {
  EVENTS,
  getAccount,
  PiwikLoader,
  ANALYTICS_ERROR_NO_WINDOW,
  ANALYTICS_ERROR_NO_ID,
  ANALYTICS_ERROR_NO_URL,
  DEFAULTS,
};
