import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { clearAreaSelectionThunk } from 'common/containers/FileDownloadService/FileDownloadServiceAction';
import usePageTitle from 'common/hooks/usePageTitle';
import FileDownloadServiceComponent from '../../common/containers/FileDownloadService/FileDownloadServiceComponent';

const FileDownloadServicePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAreaSelectionThunk());
    };
  }, [dispatch]);

  usePageTitle({
    title: 'fileDownloadService.pageTitle',
    template: 'app.documentTitle.template',
  });

  return <FileDownloadServiceComponent />;
};

export default FileDownloadServicePage;
