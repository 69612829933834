import { t } from 'i18next';
import * as routes from 'common/constants/Routes';
import { addOrUpdateProductThunk } from 'common/containers/ShoppingCart/ShoppingCartActions';
import { getConfiguration } from 'common/helpers/Configurations/Configurations';
import { getJson } from 'common/api/AjaxJson';
import { createMapDescription, createScaleConfigurationKey } from './mapStoreHelpers';

import * as types from './MapStoreActionTypes';

export function initGrid(grid) {
  return {
    type: types.INIT_GRID,
    grid,
  };
}

export function initDiscounts(discounts) {
  return {
    type: types.INIT_DISCOUNTS,
    discounts,
  };
}

export function initDiscountsThunk() {
  return dispatch =>
    getJson(routes.getRoute(routes.API_PATH_DISCOUNTS)).then(response => {
      dispatch(initDiscounts(response));
    });
}

function addSheetToCart(sheet) {
  return dispatch =>
    dispatch(
      addOrUpdateProductThunk(
        sheet.karttalehdenNimi + sheet.karttalehdenNumero,
        {
          productId: sheet.nimikekoodi,
          productType: getConfiguration('map.product.type.press'),
          name: t(createScaleConfigurationKey(sheet.mittakaava)),
          description: createMapDescription(sheet),
          scale: sheet.mittakaava === null ? 0 : parseInt(sheet.mittakaava.replace(' ', '').substring(2), 10),
        },
        String(sheet.verollinenHinta),
        sheet.amount
      )
    );
}

export function addSheetsToCart(sheets) {
  return dispatch => {
    sheets.forEach(sheet => {
      dispatch(addSheetToCart(sheet));
    });
  };
}
