import usufruct from 'styles/markers/usufruct.svg';
import usufructUnlegalized from 'styles/markers/usufruct_unlegalized.svg';
import { UNLEGALIZED_RIGHT_OF_WAY_TYPE } from './UsufructUnitTypes';

const USUFRUCT_COLOR = '#009075';
const UNLEGALIZED_RIGHT_OF_WAY_COLOR = '#7030a0';

export const POINT_STYLE = {
  text: {
    fill: {
      color: USUFRUCT_COLOR,
    },
    stroke: {
      color: '#FFFFFFBB',
      width: 2,
    },
    font: 'bold 9px "Open Sans", sans-serif',
    labelProperty: 'kayttooikeusyksikonOsanTunnuksenEsitysmuoto',
    textAlign: 'left',
    offsetX: 8,
    offsetY: 8,
  },
  image: {
    shape: {
      data: usufruct,
      x: 5,
      y: 28,
    },
    size: 3,
  },
};

export const UNLEGALIZED_POINT_STYLE = [
  {
    property: {
      key: 'kayttooikeusyksikkolaji',
      value: UNLEGALIZED_RIGHT_OF_WAY_TYPE,
    },
    text: {
      fill: {
        color: UNLEGALIZED_RIGHT_OF_WAY_COLOR,
      },
      labelProperty: 'unlegalizedId',
    },
    image: {
      shape: {
        data: usufructUnlegalized,
        x: 5,
        y: 28,
      },
      size: 3,
    },
  },
];

export const LINE_STYLE = {
  stroke: {
    color: USUFRUCT_COLOR,
    width: 4,
  },
};

export const UNLEGALIZED_LINE_STYLE = [
  {
    property: {
      key: 'kayttooikeusyksikkolaji',
      value: UNLEGALIZED_RIGHT_OF_WAY_TYPE,
    },
    stroke: {
      color: UNLEGALIZED_RIGHT_OF_WAY_COLOR,
      lineDash: 'dash',
    },
  },
];

export const POLYGON_STYLE = {
  stroke: {
    color: USUFRUCT_COLOR,
    width: 2,
  },
  fill: {
    color: 'rgba(0,144,117,0.5)',
  },
};

export const UNLEGALIZED_POLYGON_STYLE = [
  {
    property: {
      key: 'kayttooikeusyksikkolaji',
      value: UNLEGALIZED_RIGHT_OF_WAY_TYPE,
    },
    stroke: {
      color: UNLEGALIZED_RIGHT_OF_WAY_COLOR,
    },
    fill: {
      color: 'rgba(112,48,160,0.5)',
    },
  },
];
