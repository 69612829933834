import { createSlice } from '@reduxjs/toolkit';

import MapSiteApi from 'common/api/CommonApi';
import * as Routes from 'common/constants/Routes';
import { removeStateFromSessionStorage } from 'common/helpers/storage';
import { removeReloadListener } from 'common/helpers/AppStarter/Reload';

const initialState = {
  authenticated: false,
  uid: '',
  hetu: '',
  email: '',
  streetAddress: '',
  postalCode: '',
  city: '',
  organizationId: '',
  organizationName: '',
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    authenticate(state, action) {
      return action.payload;
    },
    logout() {
      return initialState;
    },
  },
});

export const { authenticate, logout } = authenticationSlice.actions;

export function authenticateThunk() {
  return dispatch => {
    MapSiteApi.getAuthentication()
      .then(authentication => {
        dispatch(authenticate(authentication));
      })
      .catch(error => {
        console.error('getAuthentication failed:', error);
        return false;
      });
  };
}

export function logoutThunk({ hardLogout = true } = {}) {
  return async dispatch => {
    removeReloadListener(window);

    const abortEvent = new Event('AbortPromises');
    document.dispatchEvent(abortEvent);

    await dispatch(logout());

    removeStateFromSessionStorage();
    // remove supplement request print material from local storage
    localStorage.removeItem('supplementRequest');

    sessionStorage.setItem('LOGGING_OUT_FROM_THIS_TAB', '1');

    const channel = new BroadcastChannel('logout-channel');
    channel.postMessage('user-logged-out');
    channel.close();

    if (hardLogout) {
      window.location.assign(Routes.getRoute(Routes.API_PATH_LOGOUT));
    } else {
      window.location.replace(Routes.MML_SITE_ESERVICE_URL);
    }
  };
}

export function softLogoutThunk() {
  return async dispatch => {
    const loggingOutFromThisTab = sessionStorage.getItem('LOGGING_OUT_FROM_THIS_TAB');
    if (loggingOutFromThisTab == null) {
      removeReloadListener(window);

      const abortEvent = new Event('AbortPromises');
      document.dispatchEvent(abortEvent);

      await dispatch(logout());
      removeStateFromSessionStorage();

      window.location.replace(Routes.MML_SITE_LOGOUT_URL);
    } else {
      sessionStorage.removeItem('LOGGING_OUT_FROM_THIS_TAB');
    }
  };
}

export default authenticationSlice.reducer;
