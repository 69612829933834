export const focusInput = input => {
  if (input != null && typeof input.focus === 'function') {
    input.focus();
  }
};

export const addUploadedFile = ({ onChange, value }, callback = () => {}) => doneUploads => {
  // NOTE: needed to account for the way multiple uploads at once are handled by the Uploader when unmount+remounting the Uploader
  const dedupedDoneUploads = [...value, ...doneUploads].filter(
    (upload, i, arr) => arr.findIndex(u => u.responseFilename === upload.responseFilename) >= i
  );
  onChange(dedupedDoneUploads);
  callback();
};

export const setUploadPending = (id, setFileUploads) => setFileUploads(previous => ({ ...previous, [id]: 'PENDING' }));
export const setUploadComplete = (id, setFileUploads) =>
  setFileUploads(previous => ({ ...previous, [id]: 'COMPLETE' }));
