import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const UnorderedList = ({ className, showBullets, children }) => (
  <ul className={cls('nls-unordered-list', { 'nls-unordered-list--bullets': showBullets }, className)}>{children}</ul>
);

UnorderedList.propTypes = {
  className: PropTypes.string,
  showBullets: PropTypes.bool,
  children: PropTypes.node,
};

UnorderedList.defaultProps = {
  className: '',
  showBullets: false,
  children: null,
};

export default UnorderedList;
