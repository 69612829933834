import { isEmpty } from 'lodash';

let _channel;
let _distanceMeasurementEventHandler;
let _areaMeasurementEventHandler;

const lineDrawStyle = {
  draw: {
    stroke: {
      color: 'rgba(86,193,255,1)',
      width: 3,
    },
  },
  modify: {
    stroke: {
      color: 'rgba(75,39,253,1)',
      width: 3,
    },
  },
};

const drawingRequestStyle = {
  draw: {
    fill: {
      color: 'rgba(238,0,0,0.3)',
    },
    stroke: {
      color: 'rgba(0,0,0,1)',
      width: 2,
    },
    image: {
      radius: 4,
      fill: {
        color: 'rgba(0,0,0,1)',
      },
    },
  },
  modify: {
    fill: {
      color: 'rgba(153,102,255,0.3)',
    },
    stroke: {
      color: 'rgba(0,0,0,1)',
      width: 2,
    },
    image: {
      radius: 4,
      fill: {
        color: 'rgba(0,0,0,1)',
      },
    },
  },
  intersect: {
    fill: {
      color: 'rgba(255,255,255,0.3)',
    },
    stroke: {
      color: 'rgba(0,0,0,1)',
      width: 2,
      lineDash: 5,
    },
    image: {
      radius: 4,
      fill: {
        color: 'rgba(0,0,0,1)',
      },
    },
  },
};

export default function initDrawTools(channel) {
  _channel = channel;
}

function handleAreaMeasurement(event) {
  if (event.isFinished && event.id === 'areaMeasurement' && _distanceMeasurementEventHandler) {
    _areaMeasurementEventHandler(event);
  }
}

/**
 * Activates area measurement tool.
 *
 * @param {Function} eventHandler
 * @param {Array} previousMeasurements
 */
export function startArea(eventHandler, previousMeasurements) {
  const data = [
    'areaMeasurement',
    'Polygon',
    {
      showMeasureOnMap: true,
      style: drawingRequestStyle,
      ...(!isEmpty(previousMeasurements) && {
        geojson: { type: 'FeatureCollection', features: previousMeasurements.map(feature => feature.geoJson) },
      }),
    },
  ];
  _areaMeasurementEventHandler = eventHandler;

  // Unregister previous event handler
  _channel.unregisterEventHandler('DrawingEvent', handleAreaMeasurement);
  _channel.handleEvent('DrawingEvent', handleAreaMeasurement);

  _channel.postRequest('DrawTools.StartDrawingRequest', data);
}

/**
 * Deactivates area measurement tool without deleting measurement geometries.
 */
export function stopArea() {
  _channel.postRequest('DrawTools.StopDrawingRequest', ['areaMeasurement']);
}

function handleDistanceMeasurement(event) {
  if (event.isFinished && event.id === 'distanceMeasurement' && _distanceMeasurementEventHandler) {
    _distanceMeasurementEventHandler(event);
  }
}

/**
 * Activates distance measurement tool.
 *
 * @param {Function} eventHandler
 * @param {Array} previousMeasurements
 */
export function startDistance(eventHandler, previousMeasurements) {
  const data = [
    'distanceMeasurement',
    'LineString',
    {
      showMeasureOnMap: true,
      style: lineDrawStyle,
      ...(!isEmpty(previousMeasurements) && {
        geojson: { type: 'FeatureCollection', features: previousMeasurements.map(feature => feature.geoJson) },
      }),
    },
  ];
  _distanceMeasurementEventHandler = eventHandler;

  // Unregister previous event handler
  _channel.unregisterEventHandler('DrawingEvent', handleDistanceMeasurement);
  _channel.handleEvent('DrawingEvent', handleDistanceMeasurement);

  _channel.postRequest('DrawTools.StartDrawingRequest', data);
}

/**
 * Deactivates distance measurement tool without deleting measurement geometries.
 */
export function stopDistance() {
  _channel.postRequest('DrawTools.StopDrawingRequest', ['distanceMeasurement']);
}

/**
 * Deactivates distance measurement tool and removes measurement
 * geometries from the map.
 */
export function removeDistance() {
  _channel.postRequest('DrawTools.StopDrawingRequest', ['distanceMeasurement', true]);
  _channel.unregisterEventHandler('DrawingEvent', handleDistanceMeasurement);
}

/**
 * Deactivates area measurement tool and removes measurement
 * geometries from the map.
 */
export function removeArea() {
  _channel.postRequest('DrawTools.StopDrawingRequest', ['areaMeasurement', true]);
  _channel.unregisterEventHandler('DrawingEvent', handleAreaMeasurement);
}

let _areaSelectionEventHandler;
const AREA_SELECTION_TOOL = 'drawAreaTool';

function areaSelectionEventHandler(event) {
  if (event.id === AREA_SELECTION_TOOL && _areaSelectionEventHandler) _areaSelectionEventHandler(event);
}

/**
 * @typedef {"Polygon" | "Circle" | "Point" | "Box" | "Square" | "LineString"} Shape
 */

/**
 * @typedef AreaSelectionOptions
 * @property {Shape} selectionType
 * @property {boolean=} showMeasureOnMap
 * @property {number|null=} maxArea
 * @property {boolean=} validateSelfIntersection
 */

/**
 * Starts area drawing tool on the map.
 *
 * @param {function} eventHandler         - Event handler to be registered to listen DrawingEvents
 * @param {AreaSelectionOptions} options
 */
export function startAreaSelection(eventHandler, options) {
  const {
    selectionType = 'Polygon',
    showMeasureOnMap = true,
    maxArea = null,
    validateSelfIntersection = true,
  } = options;

  const data = [
    AREA_SELECTION_TOOL,
    selectionType,
    {
      showMeasureOnMap,
      style: drawingRequestStyle,
      allowMultipleDrawing: false,
      modifyControl: selectionType,
      limits: {
        area: maxArea,
        length: maxArea,
        selfIntersection: validateSelfIntersection,
      },
    },
  ];
  _areaSelectionEventHandler = eventHandler;

  // Unregister previous event handler before adding new
  _channel.unregisterEventHandler('DrawingEvent', areaSelectionEventHandler);

  _channel.postRequest('DrawTools.StartDrawingRequest', data);
  _channel.handleEvent('DrawingEvent', areaSelectionEventHandler);
}

/**
 * Disables area drawing tool.
 * Unregisters possible event handler set on activation.
 *
 * @param {boolean} clearArea
 */
export function stopAreaSelection(clearArea = false) {
  _channel.unregisterEventHandler('DrawingEvent', areaSelectionEventHandler);
  _channel.postRequest('DrawTools.StopDrawingRequest', [AREA_SELECTION_TOOL, clearArea]);
}

/**
 * Disables area drawing tool and removes drawn geometries from the map.
 */
export function clearAreaSelection() {
  _channel.postRequest('DrawTools.StopDrawingRequest', [AREA_SELECTION_TOOL, true]);
}
