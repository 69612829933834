import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import scrollTop from 'common/helpers/Scroller/Scroller';
import { resolveBackUrl } from 'common/containers/UserMenu/UserMenuBackButtonContainer';
import { Glyphicon } from 'common/components/Icon';
import { geometryTypeCleared } from 'realEstateSite/containers/Realty/common/geometries/geometriesSlice';
import { resetFormMessage } from 'common/components/Form/FormActions';
import { deleteFormValidationNotification } from 'common/containers/AppNotifications/AppNotificationsActions';
import { SELECTED } from 'realEstateSite/containers/Realty/common/geometries/geometriesConstants';
import { Block } from '../Grid';

const UserMenuBackButton = ({ isMobile }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const formId = useSelector(state => state.form.formId);
  const isMapSite = useSelector(state => state.application.applicationContext) === 'mapSite';

  const handleClick = useCallback(() => {
    dispatch(geometryTypeCleared(SELECTED));
    dispatch(deleteFormValidationNotification(formId));
    dispatch(resetFormMessage());
    isMapSite && setTimeout(scrollTop, 0);
  }, [dispatch, formId, isMapSite]);

  const backUrl = resolveBackUrl(pathname, isMobile);
  return (
    <Link className={cls('button', 'button--flexible', 'user-menu__item-block')} to={backUrl} onClick={handleClick}>
      <Block className="item-block__content">
        <Glyphicon glyph="nuoli-vasemmalle" className="user-menu__back-arrow" />
        <span className={cls('indent-block-1', { 'visually-hidden': isMobile })}>{t('button.back')}</span>
      </Block>
    </Link>
  );
};

UserMenuBackButton.propTypes = {
  isMobile: PropTypes.bool,
};

UserMenuBackButton.defaultProps = {
  isMobile: false,
};

export default UserMenuBackButton;
