import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cls from 'classnames';

import { Glyphicon } from 'common/components/Icon';
import { Row, Block } from 'common/components/Grid';

const ErrorMessage = ({ msg, errorId }) => (
  <Block size={6}>
    <p className={cls('validation-message', 'validation-message--error')}>
      <Glyphicon glyph="huomio" />
      <span id={errorId} className="indent-block-1">
        {msg}
      </span>
    </p>
  </Block>
);

ErrorMessage.propTypes = {
  msg: PropTypes.string.isRequired,
  errorId: PropTypes.string,
};

ErrorMessage.defaultProps = {
  errorId: null,
};

const Error = ({ dirty, touched, error, hasNoRow, modified, forCheckBox, submitFailed, errorId }) => {
  const Wrapper = hasNoRow ? 'span' : Row;
  return (!forCheckBox && (modified || touched) && error) || (forCheckBox && (submitFailed || dirty) && error) ? (
    <Wrapper>
      {(Array.isArray(error) ? error : [error]).map((msg, _i, arr) => (
        <ErrorMessage key={msg} msg={msg} noMargin={arr.length < 2} errorId={errorId} />
      ))}
    </Wrapper>
  ) : null;
};

Error.propTypes = {
  touched: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  hasNoRow: PropTypes.bool.isRequired,
  forCheckBox: PropTypes.bool.isRequired,
  modified: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  errorId: PropTypes.string,
};

Error.defaultProps = {
  error: null,
  errorId: null,
};

const FormSectionErrorWrapper = ({ name, hasNoRow, children, modified, forCheckBox, errorId }) => (
  <Field name={name}>
    {({ meta }) => (
      <>
        <Error {...meta} modified={modified} hasNoRow={hasNoRow} forCheckBox={forCheckBox} errorId={errorId} />
        {children}
      </>
    )}
  </Field>
);

FormSectionErrorWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hasNoRow: PropTypes.bool,
  modified: PropTypes.bool,
  forCheckBox: PropTypes.bool,
  errorId: PropTypes.string,
};

FormSectionErrorWrapper.defaultProps = {
  hasNoRow: false,
  modified: false,
  forCheckBox: false,
  errorId: null,
};

export default FormSectionErrorWrapper;
