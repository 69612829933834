import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'common/components/Icon';

const LayerControls = ({
  id,
  visible,
  disabled,
  opacity,
  minZoom,
  maxZoom,
  zoomLevel,
  onToggleLayer,
  enableIframeEvents,
  onMapLayerOpacityChange,
}) => {
  const { t } = useTranslation();
  const toggleVisibility = () => {
    onToggleLayer(id, !visible);
  };

  const handleSliderChange = opac => {
    enableIframeEvents(false);
    onMapLayerOpacityChange(opac);
  };

  const layerNotInRange = () => !(zoomLevel >= minZoom && zoomLevel <= maxZoom);

  return (
    <li className="layer-controls__li">
      <button
        type="button"
        className={cls('layer-controls__button', { visible })}
        onClick={toggleVisibility}
        title={visible ? t('mapLayerSelector.toggle.tooltip.close') : t('mapLayerSelector.toggle.tooltip.open')}
        disabled={disabled}
      >
        <Glyphicon glyph={visible ? 'silma-auki' : 'silma-kiinni'} />
        <span className="layer-controls__name">{t(`mapLayerSelector.layer${id}.name`)}</span>
      </button>
      {visible && (
        <div className="layer-controls__slider">
          {layerNotInRange() ? (
            <div className="layer-controls__not-in-range">{t('mapLayerSelector.layerNotInRange')}</div>
          ) : (
            <Slider
              value={opacity}
              onChange={handleSliderChange}
              onAfterChange={() => enableIframeEvents(true)}
              disabled={disabled}
              ariaLabelForHandle={`${t(`mapLayerSelector.layer${id}.name`)} ${t('mapLayerSelector.slider.label')}`}
            />
          )}
        </div>
      )}
    </li>
  );
};

LayerControls.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.number.isRequired,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,
  onMapLayerOpacityChange: PropTypes.func.isRequired,
  onToggleLayer: PropTypes.func.isRequired,
  opacity: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  enableIframeEvents: PropTypes.func,
};

LayerControls.defaultProps = {
  disabled: false,
  maxZoom: 13,
  minZoom: 0,
  enableIframeEvents: () => null,
};

export default LayerControls;
