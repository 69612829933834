import React, { Suspense, lazy } from 'react';
import Footer from 'mapSite/containers/Footer/MapSiteFooter';
import BusinessPanelComponent from 'common/containers/BusinessPanel/BusinessPanelComponent';
import { LoaderSpinner } from 'common/components';

const ReadyMaps = lazy(() => import('common/containers/MapStoreReadyMapsForm/ReadyMapsFormComponent'));

function ReadyMapsFormPage() {
  return (
    <BusinessPanelComponent showCurtain viewSize="full">
      <Suspense fallback={<LoaderSpinner size="s" className="full-panel-loader margin-t-2" />}>
        <ReadyMaps />
      </Suspense>
      <Footer />
    </BusinessPanelComponent>
  );
}

export default ReadyMapsFormPage;
