export const REGISTRATION_DOCUMENT = 'registrationDocument';
export const PROPERTY_DOCUMENT = 'propertyDocument';
export const LEGAL_CERTIFICATE = 'legalCertificate';
export const STRAIN_CERTIFICATE = 'strainCertificate';
export const REGISTRATION_MAP_DOCUMENT = 'registrationMapDocument';
export const RENTAL_CERTIFICATE = 'rentalCertificate';
export const USUFRUCT_UNIT_CERTIFICATE = 'usufructUnitCertificate';
export const APARTMENT_PRINT_OUT = 'apartmentPrintout';
export const SHARE_LIST = 'shareList_public';

export const REGISTRATION_CERTIFICATE_INFO_TYPES = ['9', '17', '18'];
