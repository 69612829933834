import React from 'react';
import { isEmpty } from 'lodash';
import { Field, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import firstBy from 'thenby';

import { Row } from 'common/components';

import { useProductParameters, getProductSelectorPosition } from '../FileDownloadServiceHelpers';
import { FormContentBlock } from '../../Forms/Blocks';
import { ValidatedSelect } from '../../Forms/InputValidation/InputValidationComponents';
import {
  AREA_SELECTION_TYPES,
  DEFAULT_CRS,
  EPSG_CODE_NAMES,
  FIELD_NAMES,
  CADASTRAL_INDEX_MAP_VECTOR,
} from '../FileDownloadServiceConstants';

const { AREA_SELECTION_TYPE, CRS, PRODUCT_ID } = FIELD_NAMES;

const CrsSelectionComponent = () => {
  const { t } = useTranslation();
  let crsValues = useProductParameters('crsOutput');

  const {
    input: { value: productId },
  } = useField(PRODUCT_ID);

  const {
    input: { value: areaSelectionType },
  } = useField(AREA_SELECTION_TYPE);

  if (productId === CADASTRAL_INDEX_MAP_VECTOR && areaSelectionType === AREA_SELECTION_TYPES.ENTIRE_FINLAND)
    crsValues = [DEFAULT_CRS];

  const selectorPosition = getProductSelectorPosition(productId, CRS);

  if (isEmpty(crsValues)) return null;

  const options = crsValues
    .map(value => ({
      value,
      label: EPSG_CODE_NAMES[value] ? t(EPSG_CODE_NAMES[value]) : value,
    }))
    .sort(
      firstBy((a, b) => b.label.includes('ETRS-TM35FIN') - a.label.includes('ETRS-TM35FIN'))
        .thenBy((a, b) => b.label.includes('TM') - a.label.includes('TM'))
        .thenBy((a, b) => b.label.includes('GK') - a.label.includes('GK'))
        .thenBy((a, b) => b.label.includes('ETRS') - a.label.includes('ETRS'))
    );

  return (
    <Row className="margin-t-1">
      <FormContentBlock label={t('fileDownloadService.crs')} id={CRS}>
        <Field
          name={CRS}
          component={ValidatedSelect}
          options={options}
          ariaLabel={t('fileDownloadService.crs')}
          selectorPosition={selectorPosition}
        />
      </FormContentBlock>
    </Row>
  );
};

export default CrsSelectionComponent;
