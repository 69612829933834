import * as OskariMap from 'oskari/OskariMap';
import { FEATURE_LISTENER_SELECTOR, INFOBOX_ACTION_LISTENER_SELECTOR } from 'common/constants/EventListenerIds';
import { FEATURE_TYPES } from 'common/constants/MapConstants';
import {
  handleRealEstateInfoboxEvent,
  handleMapSiteParcelInfoBoxEvent,
} from 'realEstateSite/helpers/infoboxEventHandlers';
import { showAddressInfoBox, handleAddressInfoBoxActionsThunk } from './AddressActions';
import { showSearchResultInfoBox, handleSearchResultInfoBoxActionsThunk } from '../SidebarSearch/SearchActions';
import { showCustomMarkerInfoBox, handleCustomMarkerInfoBoxActionsThunk } from './CustomMarkerActions';
import { MAP_TOOL_SELECTOR_INFO_BOX_OPENED, CLEAR_MAP_TOOL_SELECTOR_INFO_BOX } from './MapToolbarActionTypes';
import { isDesktop } from '../../constants/Layout';
import { LAYER_PARCELS, LAYER_REAL_ESTATE_IDS } from '../../../oskari/layers/VectorLayers';

function infoBoxOpened(id, layerId, infoBoxId) {
  return {
    type: MAP_TOOL_SELECTOR_INFO_BOX_OPENED,
    id,
    layerId,
    infoBoxId,
  };
}

export const clearInfoBox = () => ({
  type: CLEAR_MAP_TOOL_SELECTOR_INFO_BOX,
});

const getFeaturesByType = (getState, type) =>
  getState().geometries.features.filter(({ featureType }) => featureType === type);

const placeContexts = getState => [
  {
    places: getFeaturesByType(getState, FEATURE_TYPES.ADDRESS_MARKER),
    showInfoBox: showAddressInfoBox(isDesktop(getState().layout.mode)),
    handleActions: handleAddressInfoBoxActionsThunk,
  },
  {
    places: getFeaturesByType(getState, FEATURE_TYPES.SEARCH_MARKER),
    showInfoBox: showSearchResultInfoBox(isDesktop(getState().layout.mode)),
    handleActions: handleSearchResultInfoBoxActionsThunk,
  },
  {
    places: getFeaturesByType(getState, FEATURE_TYPES.CUSTOM_MARKER),
    showInfoBox: showCustomMarkerInfoBox(isDesktop(getState().layout.mode)),
    handleActions: handleCustomMarkerInfoBoxActionsThunk,
  },
  {
    places: getFeaturesByType(getState, FEATURE_TYPES.REAL_ESTATE_SEARCH_RESULT_MARKER),
    showInfoBox: showSearchResultInfoBox(isDesktop(getState().layout.mode)),
    handleActions: handleSearchResultInfoBoxActionsThunk,
  },
];

// ----------------MARKER CLICK HANDLING----------------------

export function turnSelectorOn(dispatch, getState) {
  OskariMap.addFeatureListener(FEATURE_LISTENER_SELECTOR, handleFeatureEvent(dispatch, getState));
}

export function turnSelectorOff() {
  OskariMap.removeFeatureListener(FEATURE_LISTENER_SELECTOR);
}

export function handleFeatureEvent(dispatch, getState) {
  return data => {
    if (data.operation === 'click') {
      const { id, realtyIdentifier } = data.features[0].geojson.features[0].properties;
      const { layerId, geojson } = data.features[0];
      const isRealEstateInfoBox = layerId === LAYER_REAL_ESTATE_IDS || layerId === LAYER_PARCELS;
      const infoBoxId = isRealEstateInfoBox ? realtyIdentifier : geojson.features[0]?.id;
      dispatch(infoBoxOpened(id, layerId, infoBoxId));
      placeContexts(getState).forEach(context => {
        findPlaceAndShowInfoBox(id, context.places, context.showInfoBox);
      });
    }
  };
}

export function findPlaceAndShowInfoBox(id, places, showInfoBox) {
  places
    .filter(place => place.id === id)
    .forEach(place => {
      showInfoBox(place);
    });
}

// --------------INFOBOX BUTTON CLICK HANDLING-------------------------

export function addInfoboxActionListenerThunk() {
  return (dispatch, getState) => {
    OskariMap.addInfoboxActionListener(INFOBOX_ACTION_LISTENER_SELECTOR, handleInfoboxEvent(dispatch, getState));
    OskariMap.addInfoboxActionListener('MAP_SITE_PARCEL_INFOBOX_LISTENER', data => {
      handleMapSiteParcelInfoBoxEvent(data)(dispatch, getState);
    });
    if (getState().application.applicationContext === 'realEstateSite') {
      OskariMap.addInfoboxActionListener('REAL_ESTATE_SITE_INFOBOX_LISTENER', data => {
        handleRealEstateInfoboxEvent(data)(dispatch, getState);
      });
    }
  };
}

export function handleInfoboxEvent(dispatch, getState) {
  return data => {
    const id = data.actionParams.itemId;
    const { actionId } = data.actionParams;
    placeContexts(getState).forEach(context =>
      findPlaceAndHandleInfoBoxAction(id, context.places, context.handleActions, actionId, dispatch)
    );
  };
}

export function findPlaceAndHandleInfoBoxAction(id, places, handleActions, actionId, dispatch) {
  places
    .filter(place => place.id === id)
    .forEach(place => {
      dispatch(handleActions(actionId, place));
    });
}
