import { useEffect } from 'react';
import PropTypes from 'prop-types';

const RenderReady = ({ children, ready }) => {
  useEffect(() => {
    ready();
  }, [ready]);

  return children;
};

export default RenderReady;

RenderReady.propTypes = {
  children: PropTypes.node.isRequired,
  ready: PropTypes.func.isRequired,
};
