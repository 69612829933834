import * as viewNames from 'common/containers/MobileTools/MobileViewNames';
import CHANGE_MOBILE_VIEW from './MobileViewActionTypes';

const initialState = {
  currentMobileView: viewNames.MOBILE_MAP_VIEW,
};

export default function mobileViewReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_MOBILE_VIEW: {
      let newView = action.currentMobileView;
      if (state.currentMobileView === newView) {
        newView = viewNames.MOBILE_MAP_VIEW;
      }
      return { ...state, currentMobileView: newView };
    }
    default:
      return state;
  }
}
