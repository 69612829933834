import React, { Suspense, lazy } from 'react';

import Footer from 'mapSite/containers/Footer/MapSiteFooter';
import usePageTitle from 'common/hooks/usePageTitle';
import { LoaderSpinner } from 'common/components/index';
import BusinessPanelComponent from 'common/containers/BusinessPanel/BusinessPanelComponent';

const DeliveryError = lazy(() =>
  import('common/containers/ShoppingCart/ConfirmationComponents/DeliveryErrorComponent')
);

function DeliveryErrorPage() {
  usePageTitle({
    title: 'shoppingCart.checkout.delivery.error.documentTitle',
    template: 'app.documentTitle.template',
    trackPageView: false,
  });

  return (
    <BusinessPanelComponent viewSize="full">
      <Suspense fallback={<LoaderSpinner size="s" className="full-panel-loader margin-t-2" />}>
        <DeliveryError>
          <Footer />
        </DeliveryError>
      </Suspense>
    </BusinessPanelComponent>
  );
}

export default DeliveryErrorPage;
