import { isEmpty, uniqueId } from 'lodash';
import { t } from 'i18next';
import { NLS_USER, REALESTATES_BY_SSN } from 'realEstateSite/containers/Authorization/privileges';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import {
  ALL_EXCEPT_ELEVATION_THEME,
  AREA_SELECTION_TYPES,
  BOUNDING_BOX,
  ELEVATION_MODEL,
  ELEVATION_THEME,
  FIELD_NAMES,
  LASER_SCANNING_DATA_5P,
  LATURI_MAP_LAYERS,
  POLYGON,
  TOPOGRAPHIC_DATABASE,
  YEAR_SELECTION_PRODUCTS,
} from '../FileDownloadServiceConstants';
import { getAreaSelectionType, getProduct, toThemeOption } from '../FileDownloadServiceHelpers';
import {
  addOrUpdateProductThunk,
  mapSheetProductInCart,
  productionAreaProductInCart,
  productionAreaOrPatchAreaProductInCart,
  isPatchDataProductionArea,
  showShoppingCartNotification,
} from '../../ShoppingCart/ShoppingCartActions';
import PRODUCT_LIST from '../ProductList';
import { fetchLaser5pMapSheets } from '../FileDownloadServiceApi';
import { SET_LASER5P_PRODUCTION_AREA_MAP_SHEETS_LOADING } from '../FileDownloadServiceActionTypes';
import {
  ALREADY_HAS_PRODUCTION_AREAS_IN_CART,
  ALREADY_HAS_PRODUCTION_AREAS_OR_MAP_SHEETS_IN_CART,
  TOO_MANY_MAP_SHEETS_IN_CART,
} from '../../UserMenu/CartNotificationBubbleConstants';
import { isLaser5pProduct } from '../../ShoppingCart/Checkout/CheckoutHelpers';

const { FREE_SELECTION, MUNICIPALITY, ENTIRE_FINLAND, SELECT_SHEETS, PRODUCTION_AREA } = AREA_SELECTION_TYPES;

const NUMBER_LOCALE = 'fi-FI';

const {
  AREA_SELECTION,
  AREA_SELECTION_TYPE,
  FILE_FORMAT,
  FREE_SELECTION_TYPE,
  PRODUCT_ID,
  PRODUCT_TYPE,
  SELECTED_MUNICIPALITY,
  SELECTED_MAP_SHEETS,
  THEME_FREE_SELECTION,
  THEME_MUNICIPALITY,
  THEME_ENTIRE_FINLAND,
  CRS,
  YEAR,
  LEVEL_OF_DETAIL,
  SELECTED_PRODUCTION_AREA,
} = FIELD_NAMES;

const getEntireFinlandThemeOptions = theme =>
  theme && theme === ELEVATION_THEME
    ? toThemeOption(theme)
    : {
        value: null,
        label: t(`fileDownloadService.theme.${ALL_EXCEPT_ELEVATION_THEME}`),
      };

const getThemeValue = values => {
  const type = values[AREA_SELECTION_TYPE];
  switch (type) {
    case FREE_SELECTION:
      return values[THEME_FREE_SELECTION];
    case MUNICIPALITY:
      return values[THEME_MUNICIPALITY];
    case ENTIRE_FINLAND:
      return values[PRODUCT_ID] === TOPOGRAPHIC_DATABASE && getEntireFinlandThemeOptions(values[THEME_ENTIRE_FINLAND]);
    default:
      return null;
  }
};

const getProductDescription = (values, getState, laser5pMapSheets, getSize = false) => {
  const type = values[AREA_SELECTION_TYPE];

  const areaDescription = () => {
    switch (type) {
      case ENTIRE_FINLAND:
        return t('fileDownloadService.entireFinland');
      case FREE_SELECTION:
        return t('fileDownloadService.ownAreaSelection');
      case MUNICIPALITY:
        return values[FIELD_NAMES.SELECTED_MUNICIPALITY].label;
      case SELECT_SHEETS:
        return values[FIELD_NAMES.SELECTED_MAP_SHEETS].join(', ');
      case PRODUCTION_AREA:
        return values[FIELD_NAMES.SELECTED_PRODUCTION_AREA].label;
      default:
        return '';
    }
  };

  const themeDescription = () => {
    const themes = getThemeValue(values);
    if (themes) {
      return Array.isArray(themes) ? themes.map(theme => theme.label).join(', ') : themes.label;
    }
    return undefined;
  };

  const areaSize = (includeUnit = false) => {
    switch (type) {
      case FREE_SELECTION: {
        const { area } = values[FIELD_NAMES.AREA_SELECTION].features[0].properties;
        const size = area / 1000000;
        return includeUnit
          ? `${size.toLocaleString(NUMBER_LOCALE, { maximumFractionDigits: 5 })} km\u00B2`
          : size.toPrecision(5);
      }
      case SELECT_SHEETS: {
        if (values[PRODUCT_ID] === LASER_SCANNING_DATA_5P) return `${laser5pMapSheets} km\u00B2`;
        const sheetAmount = values[FIELD_NAMES.SELECTED_MAP_SHEETS].length;
        const id = values[PRODUCT_ID];
        const productType =
          id === TOPOGRAPHIC_DATABASE || id === LASER_SCANNING_DATA_5P
            ? values[FILE_FORMAT]?.value
            : values[PRODUCT_TYPE]?.value;

        const layerId = getProduct(id).mapLayers[productType];
        const { sheetSize } = LATURI_MAP_LAYERS.find(layer => layer.id === layerId) || {};

        if (sheetSize != null) {
          const size = sheetAmount * sheetSize;
          return includeUnit ? `${size.toLocaleString(NUMBER_LOCALE)} km\u00B2` : size;
        }
        return undefined;
      }
      case PRODUCTION_AREA:
        return `${laser5pMapSheets} km\u00B2`;
      case ENTIRE_FINLAND:
      case MUNICIPALITY:
      default:
        return undefined;
    }
  };

  const fileSize = () => {
    const id =
      values[PRODUCT_ID] === ELEVATION_MODEL
        ? values[PRODUCT_ID].concat('_', values[PRODUCT_TYPE].value)
        : values[PRODUCT_ID];
    const sizeData = getState().fileDownloadService.productParameters[id].fileSize[getAreaSelectionType(values)];

    if (!sizeData || sizeData.size === null) return undefined;
    const unit = t(`fileDownloadService.fileSize.${sizeData.unit}`);

    switch (type) {
      case ENTIRE_FINLAND: {
        return `${sizeData.size?.toLocaleString(NUMBER_LOCALE, { maximumFractionDigits: 2 })} ${unit}`;
      }
      case FREE_SELECTION: {
        const size = (areaSize() * sizeData.size).toFixed(0);
        return `${size} ${unit}`;
      }
      case SELECT_SHEETS: {
        if (values[PRODUCT_ID] === LASER_SCANNING_DATA_5P) return undefined;
        const size = (areaSize() * sizeData.size).toFixed(0);
        return `${size} ${unit}`;
      }
      case PRODUCTION_AREA:
      case MUNICIPALITY:
      default:
        return undefined;
    }
  };

  const sizeDescription = [areaSize(true), fileSize()].filter(size => size !== undefined).join(', ') || undefined;
  const levelOfDetailDescription = values[LEVEL_OF_DETAIL]?.value ? ` ${values[LEVEL_OF_DETAIL].value}` : '';
  const yearDescription =
    YEAR_SELECTION_PRODUCTS.includes(values[PRODUCT_ID]) && values[YEAR]
      ? ` ${t('shoppingCartItem.year.abbreviation')} ${values[YEAR].value}`
      : '';

  const firstLine = `${areaDescription()} - ${
    values[FIELD_NAMES.FILE_FORMAT].label
  }${levelOfDetailDescription}${yearDescription}`;

  if (getSize) return [themeDescription(), sizeDescription].filter(line => line !== undefined).join('\n');

  return [firstLine, themeDescription(), sizeDescription].filter(line => line !== undefined).join('\n');
};
const getProductName = values => {
  return values[PRODUCT_TYPE]
    ? values[PRODUCT_TYPE].label
    : t(`fileDownloadService.product.${values[PRODUCT_ID]}.name`);
};

const toPolygon = featureCollection => featureCollection?.features[0]?.geometry.coordinates[0];

const toBbox = featureCollection => {
  const coordinates = [...(featureCollection?.features[0]?.geometry.coordinates[0] || [])].sort();
  return [...coordinates[0], ...coordinates[4]];
};

const isMunicipalitySelection = values => values[AREA_SELECTION_TYPE] === MUNICIPALITY;
const isMapSheetSelection = values => values[AREA_SELECTION_TYPE] === SELECT_SHEETS;
const isFreeSelection = values => values[AREA_SELECTION_TYPE] === FREE_SELECTION;
const isPolygonSelection = values => values[FREE_SELECTION_TYPE] === POLYGON;
const isBboxSelection = values =>
  values[AREA_SELECTION_TYPE] === FREE_SELECTION && values[FREE_SELECTION_TYPE] === BOUNDING_BOX;

const toProductId = (values, getState) => {
  if (values[PRODUCT_ID] === TOPOGRAPHIC_DATABASE && values[AREA_SELECTION_TYPE] === ENTIRE_FINLAND) {
    return values[THEME_ENTIRE_FINLAND] === ELEVATION_THEME ? ELEVATION_THEME : values[PRODUCT_ID];
  }

  if (values[PRODUCT_ID] === ELEVATION_MODEL) return values[PRODUCT_ID].concat('_', values[PRODUCT_TYPE].value);
  if (values[PRODUCT_ID] === LASER_SCANNING_DATA_5P) {
    return getState().realEstateList.productCatalog[values[PRODUCT_ID]].nimikekoodi;
  }

  return values[PRODUCT_ID];
};

const getlaser5pMapSheetsLength = async productionArea => {
  const { features } = await fetchLaser5pMapSheets(productionArea.value);
  return features.length;
};

const setLaser5plaser5pMapSheetsLoading = isLoading => ({
  type: SET_LASER5P_PRODUCTION_AREA_MAP_SHEETS_LOADING,
  isLoading,
});

export const getPrice = (id, getState, laser5pMapSheets) => {
  if (PRODUCT_LIST.find(product => product.id === id)?.restricted) {
    const unitPrice = getState().realEstateList.productCatalog[id].verollinenHinta;
    const totalPrice = unitPrice * laser5pMapSheets;
    return totalPrice.toFixed(2).toString();
  }

  return '0';
};

const getLaser5pTotalAmountFromShoppingCart = shoppingCartItems =>
  shoppingCartItems
    .filter(item => isLaser5pProduct(item.product.name))
    .map(item => item.product.laser5pMapSheets)
    .reduce((total, singleValue) => total + singleValue, 0);

const tooManyLaser5pMapSheetsInCart = (shoppingCartItems, sheetAmount) =>
  getLaser5pTotalAmountFromShoppingCart(shoppingCartItems) + sheetAmount > 4000;

export const toProduct = (values, getState, price, laser5pMapSheets) => ({
  productType: 'fileDownload',
  description: getProductDescription(values, getState, laser5pMapSheets),
  size: getProductDescription(values, getState, laser5pMapSheets, true),
  price,
  unmodifiable: true,
  name: getProductName(values),
  productId: toProductId(values, getState),
  areaSelectionType: values[AREA_SELECTION_TYPE],
  polygon: isPolygonSelection(values) ? toPolygon(values[AREA_SELECTION]) : null,
  bbox: isBboxSelection(values) ? toBbox(values[AREA_SELECTION]) : null,
  freeSelectionType: isFreeSelection(values) ? values[FREE_SELECTION_TYPE] : null,
  fileFormat: values[FILE_FORMAT]?.value,
  theme: getThemeValue(values)?.value,
  mapSheets: isMapSheetSelection(values) ? values[SELECTED_MAP_SHEETS] : null,
  municipality: isMunicipalitySelection(values) ? values[SELECTED_MUNICIPALITY]?.value : null,
  type: values[PRODUCT_TYPE]?.value,
  year: (YEAR_SELECTION_PRODUCTS.includes(values[PRODUCT_ID]) && values[YEAR]?.value) || 9999,
  crs: values[CRS]?.value,
  levelOfDetail: values[LEVEL_OF_DETAIL]?.value,
  productionArea: values[SELECTED_PRODUCTION_AREA]?.value,
  laser5pMapSheets,
});

const getProductId = () => `${uniqueId('fileDownloadService')}-${new Date().getTime()}`;

const fetchLaser5pMapSheetCount = async (values, dispatch) => {
  if (PRODUCT_LIST.find(product => product.id === values[PRODUCT_ID])?.restricted) {
    const mapSheets = values[SELECTED_MAP_SHEETS];
    if (isEmpty(mapSheets)) {
      dispatch(setLaser5plaser5pMapSheetsLoading(true));
      const mapSheetCount = await getlaser5pMapSheetsLength(values[SELECTED_PRODUCTION_AREA]);
      dispatch(setLaser5plaser5pMapSheetsLoading(false));
      return mapSheetCount;
    }
    return mapSheets.length;
  }

  return 0;
};

export const addProductToCartThunk = values => async (dispatch, getState) => {
  const { shoppingCart } = getState();
  if (
    (values.areaSelectionType === AREA_SELECTION_TYPES.PRODUCTION_AREA && mapSheetProductInCart(shoppingCart)) ||
    (values.areaSelectionType === AREA_SELECTION_TYPES.SELECT_SHEETS &&
      productionAreaOrPatchAreaProductInCart(shoppingCart))
  ) {
    showShoppingCartNotification(dispatch, shoppingCart, ALREADY_HAS_PRODUCTION_AREAS_OR_MAP_SHEETS_IN_CART);
    return;
  }

  if (
    productionAreaProductInCart(shoppingCart) &&
    !isPatchDataProductionArea(values[FIELD_NAMES.SELECTED_PRODUCTION_AREA]?.label)
  ) {
    showShoppingCartNotification(dispatch, shoppingCart, ALREADY_HAS_PRODUCTION_AREAS_IN_CART);
    return;
  }

  if (
    values[PRODUCT_ID] === LASER_SCANNING_DATA_5P &&
    values.areaSelectionType === AREA_SELECTION_TYPES.SELECT_SHEETS &&
    tooManyLaser5pMapSheetsInCart(shoppingCart.items, values[SELECTED_MAP_SHEETS].length)
  ) {
    showShoppingCartNotification(dispatch, shoppingCart, TOO_MANY_MAP_SHEETS_IN_CART);
    return;
  }

  const laser5pMapSheetCount = await fetchLaser5pMapSheetCount(values, dispatch);
  const isNLSUser = isAuthorized([NLS_USER]);
  const isSupportUser = isAuthorized(REALESTATES_BY_SSN);
  const price = isNLSUser && !isSupportUser ? '0' : getPrice(values[PRODUCT_ID], getState, laser5pMapSheetCount);

  dispatch(addOrUpdateProductThunk(getProductId(), toProduct(values, getState, price, laser5pMapSheetCount), price, 1));
};
