export const CLEAR_REAL_ESTATES = 'CLEAR_REAL_ESTATES';
export const REAL_ESTATES_LOADING = 'REAL_ESTATES_LOADING';
export const CREATE_REAL_ESTATES = 'CREATE_REAL_ESTATES';
export const CREATE_OWN_REAL_ESTATES = 'CREATE_OWN_REAL_ESTATES';
export const GEOMETRIES_LOADING = 'GEOMETRIES_LOADING';
export const SELECT_REAL_ESTATE = 'SELECT_REAL_ESTATE';
export const SELECT_CERTIFICATE = 'SELECT_CERTIFICATE';
export const REGISTERATION_MAP_DOCUMENT_PDF_LINKS = 'REGISTERATION_MAP_DOCUMENT_PDF_LINKS';
export const CLEAR_REGISTRATION_MAP_DOCUMENT_LINKS = 'CLEAR_REGISTRATION_MAP_DOCUMENT_LINKS';
export const LOAD_PRODUCT_CATALOG = 'LOAD_PRODUCT_CATALOG';
export const REAL_ESTATE_LIST_API_ERROR = 'REAL_ESTATE_LIST_API_ERROR';
export const CREATE_LOCATION_REAL_ESTATE = 'CREATE_LOCATION_REAL_ESTATE';
export const LOCATION_REAL_ESTATE_LOADING = 'LOCATION_REAL_ESTATE_LOADING';
export const SET_REGISTER_STATE_DATE = 'SET_REGISTER_STATE_DATE';
