import { flatten, isEmpty, without } from 'lodash';
import { flow, groupBy, toArray } from 'lodash/fp';
import { validateNumber } from 'common/containers/Forms/FormValidation';

// TODO: https://jira.nls.fi/browse/ASI-6872 - clarify grouping code.
//  Now results are sorted first at backend then here,
//  because groupBy is used to make arrays of similar results (same name and municipality code).

const streetName = resultName => without(resultName.split(/(\s+\S*)$/), '')[0];

// Results are sorted by street name and municipality, not by street number: https://jira.nls.fi/browse/ASI-8663
const sortingName = result => `${streetName(result.name)}-${result.region}`;

const sortByLocaleCompare = results => results.sort((a, b) => sortingName(a[0]).localeCompare(sortingName(b[0]), 'fi'));

const groupByNameAndMunicipalityCode = results =>
  sortByLocaleCompare(
    flow(
      groupBy(a => [a.name, a.region].join('-')),
      toArray
    )(results)
  );

export const groupedResults = results => flatten([groupByNameAndMunicipalityCode(results)]);

export const getSearchStringArray = searchString =>
  without(searchString.split(',').shift().trim().toLowerCase().split('*'), '');

const getResultStringArray = (regex, searchS, resultString) =>
  resultString ? without(resultString.split(regex(searchS)), '') : [];

const searchFirst = s => new RegExp(`(${s})(.*)`, 'i');
const searchLast = s => new RegExp(`(${s})$`, 'i');

// if search string doesn't have a number in the end but resultName is an address with number,
// only the search string part is highlighted
const searchResultEndsWithNumber = (searchString, resultPart) =>
  resultPart && !validateNumber(searchString.substr(-1)) && validateNumber(resultPart.substr(-1));

// if result name ends with number and search string doesn't, split before number and return parts
const getResultEndingWithNumber = (searchString, resultPart) => {
  const arrHelp = without(resultPart.split(/(\s+\S*)$/), '');
  return [...getResultStringArray(searchLast, searchString, arrHelp[0]), arrHelp[1]];
};

// Method is used for wildcard search highlighting.
// It compares result name with search string parts, eg '*vaar*i' => 'Alavaaranmäki'
// It splits result name to parts according the search string array, eg ['vaar', 'i'] => ['Ala', 'vaar', 'anmäk', 'i' ].
export const getResultNameParts = (searchString, resultName) => {
  const searchStringArray = getSearchStringArray(searchString);
  // if search is done with only "*" as search word or search word is an apostrophe "'"
  // apostrophe is a special case, explained in ticket https://jira.nls.fi/browse/ASI-4009
  if (isEmpty(searchStringArray) || searchString === "'") {
    return [resultName];
  }
  const parts = [];
  let arrHelp = [];
  let resultPart = resultName;
  for (let i = 0; i < searchStringArray.length; i++) {
    if (i === searchStringArray.length - 1) {
      // search first occurrence if search string contains asterisk at end
      if (searchString.endsWith('*')) {
        arrHelp = getResultStringArray(searchFirst, searchStringArray[i], resultPart);
      } else if (searchResultEndsWithNumber(searchStringArray[i], resultPart)) {
        // Check if result number ends with number. Number should not be highlighted if it is not in search
        // string but the part before the number should be highlighted.
        arrHelp = getResultEndingWithNumber(searchStringArray[i], resultPart);
      } else {
        // search last occurrence if search string does not contain asterisk at end
        arrHelp = getResultStringArray(searchLast, searchStringArray[i], resultPart);
      }
      parts.push(...arrHelp);
    } else {
      arrHelp = getResultStringArray(searchFirst, searchStringArray[i], resultPart);
      if (arrHelp[1] && arrHelp[1].toLowerCase() === searchStringArray[i]) {
        parts.push(arrHelp[0]);
        parts.push(arrHelp[1]);
        [, , resultPart] = arrHelp;
      } else {
        [, resultPart] = arrHelp;
        parts.push(arrHelp[0]);
      }
    }
    arrHelp = [];
  }
  return parts;
};
