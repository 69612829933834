import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EVENTS } from 'common/helpers/Analytics';
import MapTool from './MapToolComponent';
import CustomMarkerEditorComponent from './CustomMarkerEditorComponent';

import { MAP_TOOL_DISTANCE, MAP_TOOL_ADDRESS, MAP_TOOL_AREA, MAP_TOOL_CUSTOM_MARKER } from './MapToolNames';

const MapToolbarComponent = props => {
  const {
    switchMapToolThunk,
    customMarkerActive,
    customMarkerDisabled,
    distanceDisabled,
    areaDisabled,
    addressDisabled,
    distanceActive,
    areaActive,
    addressActive,
    customMarkerInEditor,
    customMarkerRemoveItem,
    customMarkerUpdateItem,
  } = props;

  const { t } = useTranslation();
  const handleCustomMarkerToolClick = () => EVENTS.trackCustomMarkerToolClick(customMarkerActive);
  const handleDistanceToolClick = () => EVENTS.trackDistanceToolClick(distanceActive);
  const handleAreaToolClick = () => EVENTS.trackAreaToolClick(areaActive);
  const handleAddressToolClick = () => EVENTS.trackAddressToolClick(addressActive);

  const makeMapToolSwitcher = toolId => enable => switchMapToolThunk(toolId, enable);

  return (
    <div className="maptools__toolbar">
      <MapTool
        active={customMarkerActive}
        disabled={customMarkerDisabled}
        enable={makeMapToolSwitcher(MAP_TOOL_CUSTOM_MARKER)}
        title={t('maptoolbar.marker.title')}
        description={t('maptoolbar.marker.description')}
        glyph="oma-merkinta"
        onClick={handleCustomMarkerToolClick}
      />

      <MapTool
        active={distanceActive}
        disabled={distanceDisabled}
        enable={makeMapToolSwitcher(MAP_TOOL_DISTANCE)}
        title={t('maptoolbar.distance.title')}
        description={t('maptoolbar.distance.description')}
        glyph="etaisyys"
        onClick={handleDistanceToolClick}
      />

      <MapTool
        active={areaActive}
        disabled={areaDisabled}
        enable={makeMapToolSwitcher(MAP_TOOL_AREA)}
        title={t('maptoolbar.area.title')}
        description={t('maptoolbar.area.description')}
        glyph="pinta-ala"
        onClick={handleAreaToolClick}
      />

      <MapTool
        active={addressActive}
        disabled={addressDisabled}
        enable={makeMapToolSwitcher(MAP_TOOL_ADDRESS)}
        title={t('maptoolbar.address.title')}
        description={t('maptoolbar.address.description')}
        glyph="lahin-osoite"
        onClick={handleAddressToolClick}
      />

      <CustomMarkerEditorComponent
        item={customMarkerInEditor}
        close={customMarkerRemoveItem}
        save={customMarkerUpdateItem}
      />
    </div>
  );
};

MapToolbarComponent.propTypes = {
  addressActive: PropTypes.bool.isRequired,
  areaActive: PropTypes.bool.isRequired,
  customMarkerInEditor: PropTypes.shape({}),
  customMarkerRemoveItem: PropTypes.func.isRequired,
  customMarkerUpdateItem: PropTypes.func.isRequired,
  customMarkerActive: PropTypes.bool.isRequired,
  distanceActive: PropTypes.bool.isRequired,
  switchMapToolThunk: PropTypes.func.isRequired,
  customMarkerDisabled: PropTypes.bool.isRequired,
  distanceDisabled: PropTypes.bool.isRequired,
  areaDisabled: PropTypes.bool.isRequired,
  addressDisabled: PropTypes.bool.isRequired,
};

MapToolbarComponent.defaultProps = {
  customMarkerInEditor: {},
};

export default MapToolbarComponent;
