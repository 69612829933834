import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { START_PHASE_CHANNEL_READY } from 'common/containers/KasiAppMode/KasiAppModes';
import { getOskariLayers } from 'oskari/OskariMap';
import { toggleMapLayerVisibilityThunk } from 'common/containers/MapLayerSelector/MapLayerSelectorActions';

const useFileDownloadServiceLayer = () => {
  const mapReady = useSelector(state => state.appMode.startPhase >= START_PHASE_CHANNEL_READY);
  const dispatch = useDispatch();
  const oskariMapLayers = getOskariLayers();

  const MAP_LAYERS_TO_DISABLE = Object.keys(oskariMapLayers)
    .filter(key => key !== 'taustakartta')
    .map(key => oskariMapLayers[key]);

  useEffect(() => {
    if (mapReady) {
      MAP_LAYERS_TO_DISABLE.forEach(id => dispatch(toggleMapLayerVisibilityThunk(id, false)));
      dispatch(toggleMapLayerVisibilityThunk(oskariMapLayers.taustakartta, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useFileDownloadServiceLayer;
