import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Ruler = ({ className, small, large, dark }) => (
  <div
    className={cls(
      'ui-ruler--blue',
      { 'ui-ruler--dark-blue': dark },
      { 'ui-ruler--small': small },
      { 'ui-ruler--large': large && !small },
      className
    )}
  />
);

Ruler.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  dark: PropTypes.bool,
};

Ruler.defaultProps = {
  className: '',
  small: false,
  large: true,
  dark: false,
};

export default Ruler;
