const getScroller = () => document.getElementsByClassName('vertical-scroller')[0]?.firstChild;

const scroll = scrollPosition => {
  const scroller = getScroller();
  if (scroller?.scrollTo) scroller.scrollTo(0, scrollPosition);
};

const scrollTo = scrollPosition => {
  scroll(scrollPosition);
  return scrollPosition;
};

const scrollTop = () => {
  scroll(0);
};

const getScrolledAmount = () => getScroller()?.scrollTop || 0;

export default scrollTop;
export { scrollTo, getScrolledAmount };
