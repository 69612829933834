import { set } from 'lodash';
import { t } from 'i18next';

import { isEmpty } from 'common/helpers/isEmpty';
import INPUT_ERROR from 'common/constants/InputValidationConstants';

import {
  AREA_SELECTION_TYPES,
  FIELD_NAMES,
  LASER_SCANNING_DATA_5P,
  TOPOGRAPHIC_DATABASE,
  YEAR_SELECTION_TYPES,
} from '../FileDownloadServiceConstants';
import { getAreaSelectionType, getProduct } from '../FileDownloadServiceHelpers';

export const validateAreaSelectionFeature = (feature, errors) => {
  if (feature && feature.properties?.valid === false) {
    set(errors, FIELD_NAMES.AREA_SELECTION, feature.properties.area);
  }

  return errors;
};

const validateFreeAreaSelection = (values, errors) => {
  if (values[FIELD_NAMES.AREA_SELECTION_TYPE] === AREA_SELECTION_TYPES.FREE_SELECTION) {
    const areaSelection = values[FIELD_NAMES.AREA_SELECTION];
    validateAreaSelectionFeature(areaSelection?.features[0], errors);
  }

  return errors;
};

const validateAreaSelection = (values, errors) => {
  if (
    values[FIELD_NAMES.AREA_SELECTION_TYPE] === AREA_SELECTION_TYPES.FREE_SELECTION &&
    values[FIELD_NAMES.VALID_AREA_SELECTION] === false
  )
    set(errors, FIELD_NAMES.VALID_AREA_SELECTION, t('fileDownloadService.areaSelection.selectArea.polygon.noArea'));

  if (values[FIELD_NAMES.AREA_SELECTION_TYPE] === undefined) set(errors, FIELD_NAMES.AREA_SELECTION_TYPE, INPUT_ERROR);

  if (values[FIELD_NAMES.AREA_SELECTION_TYPE] === AREA_SELECTION_TYPES.FREE_SELECTION) {
    validateFreeAreaSelection(values, errors);
  }

  if (values[FIELD_NAMES.AREA_SELECTION_TYPE] === AREA_SELECTION_TYPES.MUNICIPALITY) {
    if (!values[FIELD_NAMES.SELECTED_MUNICIPALITY]) set(errors, FIELD_NAMES.SELECTED_MUNICIPALITY, INPUT_ERROR);
    if (isEmpty(values[FIELD_NAMES.SELECTED_MUNICIPALITY]))
      set(
        errors,
        FIELD_NAMES.VALID_MUNICIPALITY_SELECTION,
        t('fileDownloadService.areaSelection.municipality.error.empty')
      );
  }

  if (values[FIELD_NAMES.AREA_SELECTION_TYPE] === AREA_SELECTION_TYPES.SELECT_SHEETS) {
    if (isEmpty(values[FIELD_NAMES.SELECTED_MAP_SHEETS]))
      set(errors, FIELD_NAMES.NO_MAP_SHEETS, t('fileDownloadService.areaSelection.selectSheets.error.empty'));
    else if (values[FIELD_NAMES.PRODUCT_ID] === LASER_SCANNING_DATA_5P) {
      if (values[FIELD_NAMES.SELECTED_MAP_SHEETS].length > 4000) {
        set(
          errors,
          FIELD_NAMES.SELECTED_MAP_SHEETS,
          t('fileDownloadService.areaSelection.selectSheets.error.tooMany', { amount: '4000' })
        );
      }
    } else if (values[FIELD_NAMES.SELECTED_MAP_SHEETS].length > 100)
      set(
        errors,
        FIELD_NAMES.SELECTED_MAP_SHEETS,
        t('fileDownloadService.areaSelection.selectSheets.error.tooMany', { amount: '100' })
      );
  }

  if (values[FIELD_NAMES.AREA_SELECTION_TYPE] === AREA_SELECTION_TYPES.PRODUCTION_AREA) {
    if (isEmpty(values[FIELD_NAMES.SELECTED_PRODUCTION_AREA]))
      set(errors, FIELD_NAMES.NO_PRODUCTION_AREAS, t('fileDownloadService.areaSelection.productionArea.error.empty'));
  }
};

const setThemeErrors = (errors, fieldName) => {
  set(errors, fieldName, INPUT_ERROR);
  set(errors, FIELD_NAMES.VALID_THEME_SELECTION, t('fileDownloadService.themeSelection.error.empty'));
};

const setThemeValueError = (values, errors) => {
  const type = values[FIELD_NAMES.AREA_SELECTION_TYPE];
  switch (type) {
    case AREA_SELECTION_TYPES.FREE_SELECTION:
      if (!values[FIELD_NAMES.THEME_FREE_SELECTION]) setThemeErrors(errors, FIELD_NAMES.THEME_FREE_SELECTION);
      break;
    case AREA_SELECTION_TYPES.MUNICIPALITY:
      if (!values[FIELD_NAMES.THEME_MUNICIPALITY]) setThemeErrors(errors, FIELD_NAMES.THEME_MUNICIPALITY);
      break;
    case AREA_SELECTION_TYPES.ENTIRE_FINLAND:
      if (!values[FIELD_NAMES.THEME_ENTIRE_FINLAND]) setThemeErrors(errors, FIELD_NAMES.THEME_ENTIRE_FINLAND);
      break;
    default:
      break;
  }
};

const validateTheme = (values, errors, parameters) => {
  const hasThemes =
    !isEmpty(parameters[values[FIELD_NAMES.PRODUCT_ID]]?.themes[getAreaSelectionType(values)]) ||
    (values[FIELD_NAMES.PRODUCT_ID] === TOPOGRAPHIC_DATABASE &&
      values[FIELD_NAMES.AREA_SELECTION_TYPE] === AREA_SELECTION_TYPES.ENTIRE_FINLAND);
  if (hasThemes) setThemeValueError(values, errors);
};

const validateFileFormat = (values, errors) => {
  if (!values[FIELD_NAMES.FILE_FORMAT]) set(errors, FIELD_NAMES.FILE_FORMAT, INPUT_ERROR);
};

const validateProductType = (values, errors) => {
  const { types } = getProduct(values[FIELD_NAMES.PRODUCT_ID]);
  if (types && !values[FIELD_NAMES.PRODUCT_TYPE]) set(errors, FIELD_NAMES.PRODUCT_TYPE, INPUT_ERROR);
};

const validateOutputCrs = (values, errors, parameters) => {
  if (!isEmpty(parameters[values[FIELD_NAMES.PRODUCT_ID]])) {
    const { crsOutput } = parameters[values[FIELD_NAMES.PRODUCT_ID]];
    const hasCrsSelection = crsOutput && !isEmpty(crsOutput[getAreaSelectionType(values)]);
    if (hasCrsSelection && isEmpty(values[FIELD_NAMES.CRS])) set(errors, FIELD_NAMES.CRS, INPUT_ERROR);
  }
};

const validateLevelOfDetail = (values, errors, parameters) => {
  if (!isEmpty(parameters[values[FIELD_NAMES.PRODUCT_ID]])) {
    const { levelOfDetail } = parameters[values[FIELD_NAMES.PRODUCT_ID]];
    const hasLodSelection = levelOfDetail && !isEmpty(levelOfDetail[getAreaSelectionType(values)]);
    if (hasLodSelection && isEmpty(values[FIELD_NAMES.LEVEL_OF_DETAIL]))
      set(errors, FIELD_NAMES.LEVEL_OF_DETAIL, INPUT_ERROR);
  }
};

const validateYearSelection = (values, errors) => {
  if (values[FIELD_NAMES.YEAR_SELECTION_TYPE] === YEAR_SELECTION_TYPES.YEAR && values[FIELD_NAMES.YEAR] === undefined)
    set(errors, FIELD_NAMES.YEAR, INPUT_ERROR);
};

const step1validate = parameters => (values = {}) => {
  const errors = {};

  validateAreaSelection(values, errors);
  validateTheme(values, errors, parameters);
  validateFileFormat(values, errors);
  validateProductType(values, errors);
  validateOutputCrs(values, errors, parameters);
  validateLevelOfDetail(values, errors, parameters);
  validateYearSelection(values, errors);

  return errors;
};

export default step1validate;
