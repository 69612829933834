import { OPEN_BUSINESS_PANEL, TOGGLE_BUSINESS_PANEL } from 'common/containers/BusinessPanel/BusinessPanelActionTypes';
import { MOBILE_MAP_VIEW, MOBILE_BUSINESS_PANEL_VIEW } from 'common/containers/MobileTools/MobileViewNames';
import CHANGE_MOBILE_VIEW from 'common/containers/MobileTools/MobileViewActionTypes';
import { STACKED_LAYOUT } from 'common/constants/Layout';
import { OSKARI_CHANNEL_STATUS_IDS } from 'oskari/OskariMap';
import {
  CHANGE_LAYOUT,
  USE_SESSION,
  UPDATE_SHOW_CURTAIN,
  RERENDER_STICKY_FOOTER,
  CLOSE_MAPTOOLS_SIDEBAR,
  TOGGLE_MAPTOOLS_SIDEBAR,
  OPEN_MAPTOOLS_SIDEBAR,
  UPDATE_OSKARI_CHANNEL_STATUS,
} from './KasiAppActionTypes';

const initialState = {
  session: false,
  mode: STACKED_LAYOUT,
  showCurtain: false,
  oskariChannelStatus: OSKARI_CHANNEL_STATUS_IDS.startingChannel,
  desktop: {
    view: 'desktop-two',
    sidebar: { isOpen: false },
    businessPanel: { isOpen: true },
  },
  mobile: { view: MOBILE_MAP_VIEW },
  stickyFooterRenderTime: Date.now(),
};

export default function reducer(state = initialState, action = {}) {
  const toggleBusinessPanel = isOpen => {
    const desktop = { ...state.desktop, businessPanel: { isOpen } };
    return { ...state, desktop };
  };

  switch (action.type) {
    case CHANGE_LAYOUT:
      return { ...state, mode: action.layout };
    case TOGGLE_MAPTOOLS_SIDEBAR: {
      const desktop = { ...state.desktop, sidebar: { isOpen: !state.desktop.sidebar.isOpen } };

      return { ...state, desktop };
    }
    case CLOSE_MAPTOOLS_SIDEBAR:
      return { ...state, desktop: { ...state.desktop, sidebar: { isOpen: false } } };
    case OPEN_MAPTOOLS_SIDEBAR:
      return { ...state, desktop: { ...state.desktop, sidebar: { isOpen: true } } };
    case OPEN_BUSINESS_PANEL:
      return toggleBusinessPanel(true);
    case TOGGLE_BUSINESS_PANEL: {
      const actualIsOpen = typeof action.isOpen === 'boolean' ? action.isOpen : !state.isOpen;
      return toggleBusinessPanel(actualIsOpen);
    }
    case USE_SESSION:
      return { ...state, session: true };
    case UPDATE_SHOW_CURTAIN:
      return { ...state, showCurtain: action.showCurtain };
    case CHANGE_MOBILE_VIEW: {
      /**
       * Application works like this:
       *
       * - If new state is equal to current state, user has closed mobile view in question; so MOBILE_MAP_VIEW should
       * be selected.
       * - If new state is equal to current state and MOBILE_BUSINESS_PANEL_VIEW, user has navigated through
       * application routes; so MOBILE_BUSINESS_PANEL_VIEW should be kept selected.
       */

      let newView = action.currentMobileView;
      if (state.mobile.view === newView && newView !== MOBILE_BUSINESS_PANEL_VIEW) {
        newView = MOBILE_MAP_VIEW;
      }
      return { ...state, mobile: { view: newView } };
    }
    case RERENDER_STICKY_FOOTER:
      return { ...state, stickyFooterRenderTime: Date.now() };
    case UPDATE_OSKARI_CHANNEL_STATUS:
      return { ...state, oskariChannelStatus: action.oskariChannelStatus };
    default:
      return state;
  }
}
