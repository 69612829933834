import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { I18NEXT_KEY } from '../common/constants/LocalStorageKeys';

import translationsFI from './fi/translations.json';
import translationsSV from './sv/translations.json';
import translationsEN from './en/translations.json';

const options = {
  // order and from where user language should be detected
  order: ['querystring', 'sessionStorage', 'cookie', 'localStorage'],

  // keys or params to lookup language from
  lookupQuerystring: 'lang',
  lookupCookie: 'i18next',
  lookupSessionStorage: I18NEXT_KEY,
  lookupLocalStorage: I18NEXT_KEY,

  // cache user language on
  caches: ['localStorage', 'cookie'],
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['fi', 'sv', 'en'],
    fallbackLng: 'fi',
    cleanCode: true,
    detection: options,
    interpolation: {
      escapeValue: false,
      // eslint-disable-next-line no-unused-vars
      format(value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      },
    },
    resources: {
      fi: {
        translation: translationsFI,
      },
      sv: {
        translation: translationsSV,
      },
      en: {
        translation: translationsEN,
      },
    },
  });
