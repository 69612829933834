import * as types from './ShoppingCartActionTypes';
import { isLaser5pProduct } from './Checkout/CheckoutHelpers';

export const initialState = {
  items: [],
  orderNumber: '',
  notificationBubble: { type: null, isOpen: false, reset: false },
  hasOnlyFreeProducts: false,
  previousCart: undefined,
  redirectUrl: undefined,
  sessionId: undefined,
  sessionValidationFailed: false,
  paymentError: false,
};

const clearCheckoutSession = state => ({ ...state, hasOnlyFreeProducts: false });

const createPreviousCart = (state, { purchaseOrderId, hasOnlyFreeProducts, orderNumber, licencedDataIdentifier }) => ({
  items: state?.items?.map(
    ({ id, product: { name, description, productType, targets, applicationProduct, transferType } }) => ({
      id,
      product: {
        name,
        description,
        productType,
        targets: targets != null,
        ...(applicationProduct?.applicationType != null && {
          applicationProduct: { applicationType: applicationProduct.applicationType },
        }),
        transferType,
      },
    })
  ),
  purchaseOrderId,
  hasOnlyFreeProducts,
  orderNumber,
  licencedDataIdentifier,
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.ADD_PRODUCT_TO_CART: {
      const newItemId = action.id;
      const existingItem = state.items.find(item => item.id === newItemId);
      const items = Array.from(state.items);
      if (existingItem) {
        existingItem.amount = String(action.amount);
      } else {
        const cartItem = {
          id: newItemId,
          product: action.product,
          amount: action.amount,
        };
        cartItem.product.price = action.price;
        items.push(cartItem);
      }
      return clearCheckoutSession({ ...state, items });
    }

    case types.REMOVE_PRODUCT_FROM_CART: {
      const { itemId } = action;
      const newItems = state.items.filter(item => item.id !== itemId);
      return clearCheckoutSession({ ...state, items: newItems });
    }

    case types.TOGGLE_CART_NOTIFICATION_BUBBLE:
      return { ...state, notificationBubble: { type: action.bubbleType, isOpen: action.open, reset: action.reset } };

    case types.UPDATE_AMOUNT: {
      const itemInCart = item => item.id === action.itemId;
      const updatedItems = state.items.map(item => (itemInCart(item) ? { ...item, amount: action.amount } : item));
      return clearCheckoutSession({ ...state, items: updatedItems });
    }

    case types.UPDATE_MAPSHEET_AMOUNT: {
      const updatedItems = state.items.map(item =>
        isLaser5pProduct(item?.product?.name)
          ? {
              ...item,
              product: {
                ...item.product,
                mapSheets: action.mapSheets,
                description: `${action.mapSheets.join(', ')} - LAZ \n${action.mapSheets.length} km\u00B2`,
                laser5pMapSheets: action.mapSheets.length,
              },
            }
          : item
      );
      return clearCheckoutSession({ ...state, items: updatedItems });
    }

    case types.CLEAR_REDIRECT_URL:
      return { ...state, redirectUrl: undefined };

    case types.CLEAR_CART:
      return { ...state, items: [], sessionId: undefined };

    case types.INITIALIZE_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrderId: action.purchaseOrder.purchaseOrderId,
        hasOnlyFreeProducts: action.purchaseOrder.hasOnlyFreeProducts,
        previousCart: createPreviousCart(state, action.purchaseOrder),
        redirectUrl: action.purchaseOrder.redirectUrl,
        orderNumber: action.purchaseOrder.orderNumber,
      };

    case types.READY_TO_PAY:
      return {
        ...state,
        readyToPay: action.ready,
      };
    case types.UPDATE_SESSION_ID:
      return {
        ...state,
        sessionId: action.id,
      };
    case types.SESSION_VALIDATION_FAILED:
      return { ...state, sessionValidationFailed: action.failed };
    case types.PAYMENT_ERROR:
      return { ...state, paymentError: action.error };

    default:
      return state;
  }
}
