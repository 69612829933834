// map tools general
export const SET_MAP_TOOL_BUTTON_ACTIVE = 'SET_MAP_TOOL_BUTTON_ACTIVE';

// nearest address
export const MAP_TOOL_ADDRESS_REST_CALL_STATUS = 'MAP_TOOL_ADDRESS_REST_CALL_STATUS';

// selector
export const MAP_TOOL_SELECTOR_INFO_BOX_OPENED = 'MAP_TOOL_SELECTOR_INFO_BOX_OPENED';
export const CLEAR_MAP_TOOL_SELECTOR_INFO_BOX = 'CLEAR_MAP_TOOL_SELECTOR_INFO_BOX';

// custom marker
export const MAP_TOOL_CUSTOM_MARKER_OPEN_EDITOR = 'MAP_TOOL_CUSTOM_MARKER_OPEN_EDITOR';
export const MAP_TOOL_CUSTOM_MARKER_CLOSE_EDITOR = 'MAP_TOOL_CUSTOM_MARKER_CLOSE_EDITOR';

// feedback marker
export const MAP_TOOL_FEEDBACK_MARKER_ADD_ITEM = 'MAP_TOOL_FEEDBACK_MARKER_ADD_ITEM';
export const MAP_TOOL_FEEDBACK_MARKER_REMOVE_ITEM = 'MAP_TOOL_FEEDBACK_MARKER_REMOVE_ITEM';

export const DISABLE_ALL_MAP_TOOLS = 'DISABLE_ALL_MAP_TOOLS';
export const DISABLE_MAP_TOOLS = 'DISABLE_MAP_TOOLS';

export const CLEAN_DISTANCE = 'CLEAN_DISTANCE';
export const CLEAN_AREA = 'CLEAN_AREA';
