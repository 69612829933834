import { isEmpty } from 'lodash';
import queryString from 'query-string';
import * as OskariMap from 'oskari/OskariMap';
import { FEATURE_TYPES } from 'common/constants/MapConstants';
import { initialState } from 'common/containers/SidebarSearch/SearchReducer';
import { INFO_BOX_ACTION_ID_REMOVE_SEARCH_RESULT } from 'common/constants/InfoBoxActionIds';
import debounce from 'es6-promise-debounce';
import i18next from 'i18next';
import { isNumber } from 'utils/number-utils';
import { containsSpaces } from 'utils/string-utils';
import changeMobileViewAction from 'common/containers/MobileTools/MobileViewActions';
import { MOBILE_SEARCH_VIEW } from 'common/containers/MobileTools/MobileViewNames';
import addressSvg from 'styles/markers/AddressMarker.svg';
import searchSvg from 'styles/markers/SearchMarker.svg';
import realEstateSvg from 'styles/markers/RealEstateMarker.svg';
import {
  fetchGeometries,
  geometryTypeCleared,
  zoomToGeometriesThunk,
} from 'realEstateSite/containers/Realty/common/geometries/geometriesSlice';
import { SEARCHED } from 'realEstateSite/containers/Realty/common/geometries/geometriesConstants';
import { fetchPublicRegisterUnitForPoint } from 'common/api/CommonApi';
import {
  SEARCH_ACTION,
  SEARCH_RESULTS,
  CLEAR_SEARCH_RESULTS,
  SEARCH_LOADING,
  IS_CADASTRAL_UNIT_SEARCH,
  ADD_DRAWN_REAL_ESTATE_MARKERS,
  CLEAR_DRAWN_REAL_ESTATE_MARKERS,
} from './SearchActionTypes';
import {
  addMarkerFeature,
  removeFeatureThunk,
  removeSearchFeatureThunk,
  setLayerVisibilityThunk,
} from '../../geometries/geometriesActions';
import { openSidePanel } from '../KasiApp/KasiAppActions';
import { isDesktop, isMobile } from '../../constants/Layout';
import { LAYER_ADDRESSES, LAYER_PLACE_NAMES, LAYER_REAL_ESTATE_SEARCH } from '../../../oskari/layers/VectorLayers';

const SEARCH_MARKER_PRIORITY = 3;

const createMarker = (svg, layer) => ({
  svg,
  centerX: 16,
  centerY: 1,
  layer,
});

export const DEBOUNCE_TIME = 500;
export const SEARCH_MIN_CHARS = 1;
export const REAL_ESTATE_SOURCE_CHANNEL = 'cadastral-units';
export const ADDRESS_SOURCE_CHANNEL = 'interpolated-road-addresses';
export const PLACE_NAMES_SOURCE_CHANNEL = 'geographic-names';
export const searchMarker = createMarker(searchSvg, LAYER_PLACE_NAMES);
export const addressMarker = createMarker(addressSvg, LAYER_ADDRESSES);
export const realEstateMarker = createMarker(realEstateSvg, LAYER_REAL_ESTATE_SEARCH);

export const markers = {
  address: addressMarker,
  realEstate: realEstateMarker,
  place: searchMarker,
};

const featureTypes = {
  [ADDRESS_SOURCE_CHANNEL]: FEATURE_TYPES.ADDRESS_MARKER,
  [REAL_ESTATE_SOURCE_CHANNEL]: FEATURE_TYPES.REAL_ESTATE_SEARCH_RESULT_MARKER,
  [PLACE_NAMES_SOURCE_CHANNEL]: FEATURE_TYPES.SEARCH_MARKER,
};

const doSearch = debounce((searchObject, cb) => OskariMap.search(searchObject, cb), DEBOUNCE_TIME);

const validateSearchString = searchString =>
  searchString != null && searchString.length >= SEARCH_MIN_CHARS && !['*', '?'].includes(searchString);

const formatSearchString = searchString => {
  const commaIndex = searchString.indexOf(',');

  let firstPart = commaIndex > -1 ? searchString.substring(0, commaIndex).trim() : searchString;
  let secondPart = commaIndex > -1 ? searchString.substring(commaIndex + 1).trim() : '';

  const firstPartRequiresQuotes =
    containsSpaces(firstPart) && !isNumber(firstPart.substring(firstPart.lastIndexOf(' ') + 1));

  if (firstPartRequiresQuotes) {
    firstPart = `"${firstPart}"`;
  }

  if (firstPartRequiresQuotes || containsSpaces(secondPart)) {
    secondPart = `"${secondPart}"`;
  }

  return commaIndex > -1 ? `${firstPart},${secondPart}` : firstPart;
};

export function updateSearchAction(searchObj) {
  return {
    type: SEARCH_ACTION,
    params: searchObj,
  };
}

export function searchIsLoading(loading) {
  return {
    type: SEARCH_LOADING,
    loading,
  };
}

export function searchResultsAction(resultArray, hasMore) {
  return {
    type: SEARCH_RESULTS,
    results: resultArray,
    hasMore,
  };
}

export function clearSearchResults() {
  return {
    type: CLEAR_SEARCH_RESULTS,
  };
}

export function clearDrawnRealEstateMarkers() {
  return {
    type: CLEAR_DRAWN_REAL_ESTATE_MARKERS,
  };
}

export function addDrawnRealEstateMarkers(drawnRealEstateMarker) {
  return {
    type: ADD_DRAWN_REAL_ESTATE_MARKERS,
    drawnRealEstateMarker,
  };
}

export function clearSearchThunk() {
  return dispatch => {
    dispatch(clearSearchResults());
    dispatch(updateSearchAction(initialState.params));
    dispatch(searchIsLoading(false));
  };
}

export function showSearchResultInfoBox(disableMobileBreakpoint) {
  return place => {
    const descs = [
      `${i18next.t('infobox.type')}: ${place.type}`,
      `${i18next.t('infobox.municipality')}: ${place.region}`,
      `${i18next.t('infobox.parcels')}: ${i18next.t(place.parcelsCount)}`,
    ];

    const infoBoxDefinitions = {
      id: `${place.id}_infobox`,
      title: place.name,
      coords: { n: place.lat, e: place.lon },
      desc: place.type === 'realEstate' ? descs.slice(0, 3).join('<br>') : descs.slice(0, 2).join('<br>'),
      actions: [
        {
          name: i18next.t('button.remove'),
          action: {
            itemId: place.id,
            actionId: INFO_BOX_ACTION_ID_REMOVE_SEARCH_RESULT,
          },
        },
      ],
    };
    OskariMap.infoBox(infoBoxDefinitions, disableMobileBreakpoint);
  };
}

export function setCadastralUnitSearch(cadastralUnitSearch) {
  return {
    type: IS_CADASTRAL_UNIT_SEARCH,
    cadastralUnitSearch,
  };
}

async function searchAddress(dispatch, searchObject, showOnMap, isMapsite) {
  await doSearch(searchObject, ({ result }) => {
    dispatch(searchResultsAction(result.locations, result.hasMore));
    const isCadastralUnitSearch = result.locations?.every(({ source }) => source === 'cadastral-units');
    if (isMapsite) {
      dispatch(setCadastralUnitSearch(isCadastralUnitSearch));
    }

    if (showOnMap) {
      dispatch(showResultsOnMapThunk(result.locations));
    }

    dispatch(searchIsLoading(false));
  });
}

export function searchThunk(searchParams, forceSearch, showOnMap = false, isMapSite = false) {
  return async (dispatch, getState) => {
    dispatch(updateSearchAction(searchParams));
    if (validateSearchString(searchParams.searchString) || forceSearch) {
      const searchObject = {
        searchString: formatSearchString(getState().search.params.searchString),
        lang: i18next.language,
      };

      dispatch(searchIsLoading(true));
      if (isMapSite) {
        /* for some reason mapsite gets into a race condition when changing language sometimes so 
          we need to wait a bit before searching */
        setTimeout(async () => {
          await searchAddress(dispatch, searchObject, showOnMap, isMapSite);
        }, '500');
      } else {
        await searchAddress(dispatch, searchObject, showOnMap, isMapSite);
      }
    } else {
      dispatch(searchResultsAction([], false));
    }
  };
}

function getMarker(place) {
  if (place.source === REAL_ESTATE_SOURCE_CHANNEL) return markers.realEstate;
  if (place.source === ADDRESS_SOURCE_CHANNEL) return markers.address;
  return searchMarker;
}

function showSingleResultOnMap(dispatch, selectedPlace, getState) {
  const place = { ...selectedPlace, featureType: featureTypes[selectedPlace.source] || FEATURE_TYPES.SEARCH_MARKER };
  const marker = getMarker(place);

  if (place.name == null) {
    place.name = i18next.t(selectedPlace.municipality);
  } else {
    place.name = place.name ? place.name : '';
  }

  if (getState().geometries?.layerVisibility[marker.layer] === false)
    dispatch(setLayerVisibilityThunk(marker.layer, true));

  dispatch(addMarkerFeature(place, marker, SEARCH_MARKER_PRIORITY));
}

function processSingleSearchResult(dispatch, selectedPlace, getState) {
  showSingleResultOnMap(dispatch, selectedPlace, getState);
  OskariMap.zoomToPlace(selectedPlace.lon, selectedPlace.lat, selectedPlace.source);
  showSearchResultInfoBox(isDesktop(getState().layout.mode))(selectedPlace);
}

const getParcelId = (place, index) => `${place.id}_${index}`;

function processGroupSearchResult(dispatch, selectedPlaces, getState) {
  selectedPlaces.forEach((p, index) => {
    showSingleResultOnMap(dispatch, { ...p, id: getParcelId(p, index) }, getState);
  });
  OskariMap.zoomToFeatures(selectedPlaces.map(getParcelId), [
    LAYER_REAL_ESTATE_SEARCH,
    LAYER_PLACE_NAMES,
    LAYER_ADDRESSES,
  ]);
}

async function fetchCadastalUnitForPoint(dispatch, selectedPlace, drawnFeatureIds) {
  const { id, lat, lon } = selectedPlace;
  try {
    const registerUnit = await fetchPublicRegisterUnitForPoint(lat, lon);
    if (drawnFeatureIds.includes(registerUnit.id)) dispatch(removeSearchFeatureThunk(registerUnit.id));
    await dispatch(handleRealEstateDrawing(registerUnit));
    dispatch(zoomToGeometriesThunk(SEARCHED, id));
  } catch (e) {
    console.error(e);
  }
}

export const drawPrevRealtyResultMarkers = missingRealEstateMarkers => {
  return (dispatch, getState) => {
    if (missingRealEstateMarkers.length > 1) {
      missingRealEstateMarkers.forEach((p, index) => {
        showSingleResultOnMap(dispatch, { ...p, id: getParcelId(p, index) }, getState);
      });
    } else {
      showSingleResultOnMap(dispatch, missingRealEstateMarkers.pop(), getState);
    }
  };
};

export function showResultsOnMapThunk(selectedPlaces, isMapSite = false) {
  return async (dispatch, getState) => {
    if (isMapSite) {
      if (getState().search.isCadastralUnitSearch) {
        const selectedPlace = Array.isArray(selectedPlaces) ? selectedPlaces[0] : selectedPlaces;
        const drawnFeatureIds = getState().geometries.features.map(f => f.name);
        const drawnRealEstateMarkers = getState().search.drawnRealEstateMarkers.filter(
          m => m.id !== getState().search.params.searchString
        );
        const missingRealEstateMarkers = drawnRealEstateMarkers.filter(m => !drawnFeatureIds.includes(m.id));
        await fetchCadastalUnitForPoint(dispatch, selectedPlace, drawnFeatureIds);

        if (Array.isArray(selectedPlaces)) {
          selectedPlaces.forEach(p => {
            if (!drawnRealEstateMarkers.includes(m => m.name === p.id)) {
              dispatch(addDrawnRealEstateMarkers(p));
            }
          });
        } else if (!drawnRealEstateMarkers.includes(m => m.name === selectedPlaces.id)) {
          dispatch(addDrawnRealEstateMarkers(selectedPlaces));
        }

        if (!isEmpty(missingRealEstateMarkers)) {
          dispatch(drawPrevRealtyResultMarkers(missingRealEstateMarkers));
        }
      }

      if (Array.isArray(selectedPlaces) && !getState().search.isCadastralUnitSearch)
        processGroupSearchResult(dispatch, selectedPlaces, getState);
      if (!Array.isArray(selectedPlaces) && !getState().search.isCadastralUnitSearch)
        processSingleSearchResult(dispatch, selectedPlaces, getState);
    }

    if (!isMapSite) {
      if (Array.isArray(selectedPlaces)) {
        processGroupSearchResult(dispatch, selectedPlaces, getState);
      } else {
        processSingleSearchResult(dispatch, selectedPlaces, getState);
      }
    }
  };
}

export function handleSearchResultInfoBoxActionsThunk(actionId, item) {
  return dispatch => {
    if (actionId === INFO_BOX_ACTION_ID_REMOVE_SEARCH_RESULT) {
      dispatch(removeFeatureThunk(item.id));
    }
  };
}

export function processSearchLinksThunk(windowLocation) {
  return (dispatch, getState) => {
    const { search: searchString, showOnMap } = queryString.parse(windowLocation.search);

    if (searchString) {
      dispatch(searchThunk({ searchString }, true, showOnMap === 'true'));

      if (isDesktop(getState().layout.mode)) dispatch(openSidePanel());
      else if (isMobile(getState().layout.mode)) dispatch(changeMobileViewAction(MOBILE_SEARCH_VIEW));
    }
  };
}

export function handleRealEstateDrawing(registerUnit) {
  return async (dispatch, getState) => {
    const isAlreadySearched = getState().geometry.searched.includes(registerUnit.id);
    if (isAlreadySearched) return;
    OskariMap.hideAllInfoboxes();
    dispatch(geometryTypeCleared(SEARCHED));
    await dispatch(fetchGeometries([registerUnit], SEARCHED));
  };
}
