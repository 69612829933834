import { CHANGE_APP_MODE, CHANGE_START_PHASE } from './KasiAppModeActionTypes';
import * as Modes from './KasiAppModes';

export default function reducer(
  state = {
    mode: Modes.APP_MODE_NORMAL,
    layers: [],
    startPhase: Modes.START_PHASE_INIT,
  },
  action = {}
) {
  switch (action.type) {
    case CHANGE_APP_MODE:
      return {
        ...state,
        mode: action.mode,
        ...(action.layers ? { layers: action.layers } : { layers: [] }),
      };
    case CHANGE_START_PHASE:
      return { ...state, startPhase: action.startPhase };
    default:
      return state;
  }
}
