import { throttle, isFunction } from 'lodash';

export default class SessionObserver {
  static get UI_EVENTS() {
    return [
      'popstate',
      'mousemove',
      'mousedown',
      'keypress',
      'DOMMouseScroll',
      'mousewheel',
      'touchmove',
      'MSPointerMove',
    ];
  }

  static WARNING_TIME = 1500000;

  constructor({ warningCallback, expireCallback, warningTime, expireTime } = {}) {
    if (!warningCallback) throw new Error('SessionObserver initiated without callbacks.');

    this._params = { warningCallback, expireCallback, warningTime, expireTime };

    // event handler
    this.userIsActive = throttle(this.userIsActive.bind(this), 300);
  }

  _startSessionWarning() {
    return new Promise((resolve, reject) => {
      this._timerId = setTimeout(resolve, this._params.warningTime || SessionObserver.WARNING_TIME);
      this._timerReject = reject;
    });
  }

  userIsActive() {
    return this.stop().start();
  }

  start() {
    const lastActive = new Date();

    return this._startSessionWarning()
      .then(() => {
        this._params.warningCallback(lastActive);
      })
      .catch(() => {
        // console.info('Session timer canceled.', err);
      });
  }

  stop() {
    isFunction(this._timerReject) && this._timerReject('Stopping current session timer.');
    this._timerId = clearTimeout(this._timerId);
    return this;
  }

  destroy() {
    return this.unbindEventListeners().stop();
  }

  bindEventListeners() {
    SessionObserver.UI_EVENTS.forEach(eventName => window.addEventListener(eventName, this.userIsActive, false));
    return this;
  }

  unbindEventListeners() {
    SessionObserver.UI_EVENTS.forEach(eventName => window.removeEventListener(eventName, this.userIsActive));
    return this;
  }
}
