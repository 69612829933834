import {
  DISABLE_ALL_MAP_TOOLS,
  SET_MAP_TOOL_BUTTON_ACTIVE,
  MAP_TOOL_SELECTOR_INFO_BOX_OPENED,
  CLEAR_MAP_TOOL_SELECTOR_INFO_BOX,
} from './MapToolbarActionTypes';
import { MAP_TOOL_SELECTOR } from './MapToolNames';

const initialState = { active: false, id: null, layerId: '', infoBoxId: '' };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MAP_TOOL_BUTTON_ACTIVE:
      if (action.toolId === MAP_TOOL_SELECTOR) {
        return { ...state, active: action.active };
      }
      return state;
    case MAP_TOOL_SELECTOR_INFO_BOX_OPENED:
      return { ...state, id: action.id, layerId: action.layerId, infoBoxId: action.infoBoxId };
    case DISABLE_ALL_MAP_TOOLS:
      return { ...state, active: false };
    case CLEAR_MAP_TOOL_SELECTOR_INFO_BOX:
      return { ...state, id: null, layerId: '', infoBoxId: '' };
    default:
      return state;
  }
}
