import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CartNotificationBubble from 'common/components/UserMenu/CartNotificationBubble';
import { t } from 'i18next';

const CartNotificationBubbleComponent = ({
  cartBubbleType,
  toggleCartBubble,
  resetTimeouts,
  clearPoliteNotifications,
}) => {
  const [showCartBubble, setShowCartBubble] = useState(true);
  const [timeouts, setTimeouts] = useState([]);

  const cartBubbleText = type => t(`togglableUserMenuComponent.${type}`);

  const closeCartBubble = () => {
    toggleCartBubble();
    clearPoliteNotifications();
    setShowCartBubble(false);
  };

  const fadeOut = () => {
    setTimeouts([
      ...timeouts,
      setTimeout(() => setShowCartBubble(false), 6000),
      setTimeout(() => {
        toggleCartBubble();
        clearPoliteNotifications();
      }, 7000),
    ]);
  };

  useEffect(() => {
    fadeOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetTimeouts === true) {
      timeouts.forEach(clearTimeout);
      fadeOut();
      toggleCartBubble(cartBubbleType, true);
    }

    return () => timeouts.forEach(clearTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetTimeouts]);

  return (
    <CartNotificationBubble
      cartBubbleText={cartBubbleText(cartBubbleType)}
      trigger={showCartBubble}
      close={closeCartBubble}
    />
  );
};

CartNotificationBubbleComponent.propTypes = {
  cartBubbleType: PropTypes.string.isRequired,
  toggleCartBubble: PropTypes.func.isRequired,
  resetTimeouts: PropTypes.bool.isRequired,
  clearPoliteNotifications: PropTypes.bool.isRequired,
};

export default CartNotificationBubbleComponent;
