import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cls from 'classnames';
import ScrollerPanelContext from './ScrollerPanelContext';
import ScrollerColumnContext from './ScrollerColumnContext';

const ScrollerPanelActiveItem = ({ dataTestId, even, proprietorListStyles }) => {
  const { pathname } = useLocation();
  const activeItemRef = useRef(null);
  const { activeMenuColumnNumber, activeColumnNumber, isMobile, setActiveMenuItemTop, scrollerHeight } = useContext(
    ScrollerPanelContext
  );
  const { targetColumnNumber, hiddenColumn } = useContext(ScrollerColumnContext);

  useEffect(() => {
    if (!isMobile && targetColumnNumber === activeMenuColumnNumber && targetColumnNumber === activeColumnNumber - 1) {
      const { top: elementTop } = activeItemRef.current.getBoundingClientRect();
      const viewportTop = document.getElementById('maincontent')?.scrollTop || 0;
      setActiveMenuItemTop(Math.round(viewportTop + elementTop));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenColumn, targetColumnNumber, activeMenuColumnNumber, activeColumnNumber, scrollerHeight, pathname]);

  return (
    <div
      ref={activeItemRef}
      data-testid={dataTestId}
      data-activelistitem="active"
      className={cls('active', { 'realty-item-even': even, 'proprietor-item-odd': proprietorListStyles })}
    />
  );
};

ScrollerPanelActiveItem.propTypes = {
  dataTestId: PropTypes.string,
  even: PropTypes.bool,
  proprietorListStyles: PropTypes.bool,
};

ScrollerPanelActiveItem.defaultProps = {
  dataTestId: '',
  even: false,
  proprietorListStyles: false,
};

export default ScrollerPanelActiveItem;
