import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { enableIframeEvents } from 'common/containers/OskariMap/OskariMapActions';
import MapLayerSelectorComponent from './MapLayerSelectorComponent';
import { toggleMapLayerVisibilityThunk, toggleMapLayerOpacityThunk } from './MapLayerSelectorActions';
import { addBoundaryInaccuracyNotification } from '../AppNotifications/AppNotificationsActions';
import { getOskariLayers, getSelectableLayers } from '../../../oskari/OskariMap';

const onToggleLayer = (mapId, visible) => {
  return dispatch => {
    const layers = getOskariLayers();

    if (visible && (mapId === layers.kiinteistojaotus.toString() || mapId === layers.kiinteistotunnukset.toString())) {
      dispatch(addBoundaryInaccuracyNotification());
    }
    dispatch(toggleMapLayerVisibilityThunk(mapId, visible));
  };
};

function mapStateToProps(state) {
  const allowedLayerIds = getSelectableLayers();

  return {
    layoutMode: state.layout.mode,
    mapLayers: state.mapLayers.filter(layer => allowedLayerIds.includes(layer.id)),
    zoomLevel: state.oskariIframe.zoom,
  };
}

// Map Redux actions to component props
function mapDispatchToProps(dispatch) {
  return {
    enableIframeEvents: bindActionCreators(enableIframeEvents, dispatch),
    onToggleLayer: bindActionCreators(onToggleLayer, dispatch),
    onMapLayerOpacityChange: bindActionCreators(toggleMapLayerOpacityThunk, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapLayerSelectorComponent);
