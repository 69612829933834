import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'common/components/Icon';
import bindClickOutSide from 'react-click-outside';
import cls from 'classnames';
import { API_PATH_CHANGE_ROLE, getRoute } from 'common/constants/Routes';
import useClickOutside from 'common/hooks/useClickOutside';
import LogoutModal from '../LogoutModal/LogoutModal';
import RoleChangeModal from '../RoleChangeModal/RoleChangeModal';

const UserMenuDropDownContent = ({
  handleLogoutClick,
  handleRoleChangeClick,
  close,
  authentication,
  cartItemCount,
}) => {
  const { t } = useTranslation();

  return (
    <section
      id="userMenu"
      role="dialog"
      aria-labelledby="userMenuTitle"
      aria-describedby={authentication?.organizationRepresentative ? 'userMenuSubtitle' : null}
      className={cls('user-menu-dropdown__content', { hidden: close })}
    >
      <header>
        <h2 id="userMenuTitle">
          {authentication?.organizationRepresentative
            ? authentication?.organizationName
            : `${authentication?.firstName} ${authentication?.lastName}`}
        </h2>
        {authentication?.organizationRepresentative && (
          <p id="userMenuSubtitle">{`${authentication?.firstName} ${authentication?.lastName}`}</p>
        )}
      </header>
      {(authentication?.organizationRepresentative || authentication?.publicPurchaseWitnessing) && (
        <p className="margin-b-1">
          <a
            href={getRoute(API_PATH_CHANGE_ROLE)}
            onClick={e => {
              if (cartItemCount > 0) {
                e.preventDefault();
                handleRoleChangeClick();
              }
            }}
          >
            {t('usermenu.changeOrganization')}
          </a>
        </p>
      )}
      <p>
        <button type="button" className="logout-button button button--blue" onClick={handleLogoutClick}>
          <Glyphicon className="glyphicon-lock" glyph="lukko-auki" />
          &nbsp;
          {t('usermenu.logout')}
        </button>
      </p>
    </section>
  );
};

UserMenuDropDownContent.propTypes = {
  close: PropTypes.bool.isRequired,
  authentication: PropTypes.shape({
    uid: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organizationName: PropTypes.string,
    organizationRepresentative: PropTypes.bool,
    publicPurchaseWitnessing: PropTypes.bool,
  }).isRequired,
  cartItemCount: PropTypes.number.isRequired,
  handleLogoutClick: PropTypes.func,
  handleRoleChangeClick: PropTypes.func,
};

UserMenuDropDownContent.defaultProps = {
  handleLogoutClick: () => null,
  handleRoleChangeClick: () => null,
};

const UserMenuDropDown = ({
  floatRight,
  authentication,
  onLogout,
  isMobile,
  goToShoppingCart,
  cartItemCount,
  changeRole,
}) => {
  const dropDownRef = useRef(null);

  const [close, setClose] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);

  const handleLogoutClick = () => {
    setClose(true);
    setOpenModal(true);
  };

  const handleUniversalClick = () => {
    setClose(!close);
  };

  const handleRoleChangeClick = () => {
    setClose(true);
    setOpenRoleModal(true);
  };

  const handleModalClose = () => setOpenModal(false);

  const handleRoleModalClose = () => setOpenRoleModal(false);

  const handleShoppingCart = () => {
    handleModalClose();
    handleRoleModalClose();
    goToShoppingCart();
  };

  useClickOutside(dropDownRef, () => setClose(true));

  return (
    <div ref={dropDownRef} className={cls('full-height', { relative: !isMobile }, { 'user-menu--right': floatRight })}>
      <button
        id="userMenuButton"
        type="button"
        onClick={handleUniversalClick}
        className="user-menu__item-block button button--flexible user-menu__dropdown"
        aria-haspopup="dialog"
        aria-controls="userMenu"
        aria-expanded={!close}
      >
        <span className={cls('mobile-hide', 'user-menu___user-name')}>
          <span className="ellipsis">
            {authentication?.organizationRepresentative ? authentication?.organizationName : authentication?.firstName}
          </span>
        </span>
        <Glyphicon glyph="kayttaja2" className="indent-block-2" />
        <Glyphicon glyph={close ? 'nuoli-alas' : 'nuoli-ylos'} className="dropdown__arrow-icon" />
      </button>
      <UserMenuDropDownContent
        handleLogoutClick={handleLogoutClick}
        handleRoleChangeClick={handleRoleChangeClick}
        changeRole={changeRole}
        close={close}
        authentication={authentication}
        cartItemCount={cartItemCount}
      />
      {openModal && (
        <LogoutModal
          logout={onLogout}
          cancelLogout={handleModalClose}
          cartItemCount={cartItemCount}
          goToShoppingCart={handleShoppingCart}
        />
      )}
      {openRoleModal && (
        <RoleChangeModal
          changeRole={changeRole}
          cancelRoleChange={handleRoleModalClose}
          cartItemCount={cartItemCount}
          goToShoppingCart={handleShoppingCart}
        />
      )}
    </div>
  );
};

UserMenuDropDown.propTypes = {
  authentication: PropTypes.shape({
    uid: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organizationName: PropTypes.string,
    organizationRepresentative: PropTypes.bool,
  }).isRequired,
  cartItemCount: PropTypes.number.isRequired,
  goToShoppingCart: PropTypes.func.isRequired,
  onLogout: PropTypes.func,
  changeRole: PropTypes.func,
  floatRight: PropTypes.bool,
  isMobile: PropTypes.bool,
};

UserMenuDropDown.defaultProps = {
  onLogout: () => null,
  changeRole: () => null,
  floatRight: true,
  isMobile: false,
};

export { UserMenuDropDown as UserMenuDropDownTest };
export default bindClickOutSide(UserMenuDropDown);
