import { REQUEST_KEYBOARD_FOCUS, CLEAR_KEYBOARD_FOCUS_REQUEST } from './AutomaticKeyboardFocusActionTypes';

export default function automaticKeyboardFocusReducer(state = {}, action = {}) {
  switch (action.type) {
    case REQUEST_KEYBOARD_FOCUS: {
      return {
        selector: action.selector,
      };
    }
    case CLEAR_KEYBOARD_FOCUS_REQUEST: {
      return {};
    }
    default:
      return state;
  }
}
