/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';

import { formatDateDMY, formatISO } from 'utils/datetime-utils';
import { Row, Block, ExternalLink } from 'common/components';
import { setNotificationReadTime } from 'common/containers/MllBulletin/MllBulletinActions';
import { Glyphicon } from 'common/components/Icon';
import CloseButtonComponent from 'common/components/Button/CloseButtonComponent';
import { hideNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';
import { ASSERTIVE } from '../AccessibleNotifications/AccessibleNotificationsConstants';
import { accessibleNotificationsCleared } from '../AccessibleNotifications/accessibleNotificationsSlice';

export const MESSAGE_AUTO_HIDE_TIME = 15000;

const NotificationComponent = ({
  autoHide,
  id,
  disableCloseButton,
  isBulletinMessage,
  type,
  message,
  visible,
  isValidationMessage,
  date,
  link,
}) => {
  let timeout = null;
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    if (!disableCloseButton) {
      if (isBulletinMessage) {
        setNotificationReadTime(formatISO(new Date()));
      }
      dispatch(hideNotificationAction(id));
      dispatch(accessibleNotificationsCleared({ type: ASSERTIVE }));
    }
  }, [disableCloseButton, isBulletinMessage, id]);

  useEffect(() => {
    if (autoHide) {
      timeout = setTimeout(() => {
        handleClick();
      }, MESSAGE_AUTO_HIDE_TIME);
    }

    return () => {
      if (autoHide) {
        clearTimeout(timeout);
      }
    };
  }, [autoHide, handleClick]);

  const notificationClassNames = classNames('notification__item', type, {
    open: visible,
    closed: !visible,
    bulletin: isBulletinMessage,
  });

  const renderCloseButton = () => (
    <Block className="notification__close-icon notification-item--right">
      <CloseButtonComponent testId="close-notification" onClick={handleClick} glyph="sulje-pallo-outline" />
    </Block>
  );

  const renderNormalMessage = () => (
    <Block className="notification-message">
      <span>{message}</span>
    </Block>
  );

  const renderBulletinMessage = () => {
    return (
      <Block className="notification-bulletin">
        <div>
          <span className="bold">{date ? formatDateDMY(new Date(date)) : ''}</span>
          {` - ${message}`}
        </div>
        <ExternalLink className="notification__link" href={link}>
          <strong>{t('readMore.link.text.title')}</strong>
        </ExternalLink>
      </Block>
    );
  };

  return (
    <Row className={notificationClassNames}>
      <Block className="notification__type-icon">
        {isValidationMessage ? <Glyphicon glyph="huomio" /> : <Glyphicon glyph="info" />}
      </Block>
      {isBulletinMessage ? renderBulletinMessage() : renderNormalMessage()}
      {!disableCloseButton && renderCloseButton()}
    </Row>
  );
};

NotificationComponent.propTypes = {
  autoHide: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  disableCloseButton: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isBulletinMessage: PropTypes.bool.isRequired,
  link: PropTypes.string,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  isValidationMessage: PropTypes.bool,
};

NotificationComponent.defaultProps = {
  date: new Date(),
  link: '',
  autoHide: false,
  disableCloseButton: false,
  isValidationMessage: false,
};

export default NotificationComponent;
