import React from 'react';

import { LoaderSpinner } from 'common/components';

const SearchLoader = React.forwardRef((props, ref) => (
  <ul ref={ref} className="search-result-list" data-testid="SearchLoader">
    <LoaderSpinner color="lightBlue" className="margin-t-1 margin-b-1" />
  </ul>
));

export default SearchLoader;
