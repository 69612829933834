import { mapValues } from 'lodash';
import ConfigurationApi from 'common/api/ConfigurationApi';
import saveConfigurations from './ConfigurationsActions';

let dispatch;
let getState;

/*
 * Is this a DAO or redux component?
 *
 */

export const init = (dispatchFn, getStateFn) => {
  dispatch = dispatchFn;
  getState = getStateFn;

  return ConfigurationApi.getConfigurations()
    .then(
      data =>
        new Promise(resolve => {
          const conf = {
            features: mapValues(data.features, val => val === 'true'),
          };
          conf.configurations = data.configurations;
          dispatch(saveConfigurations(conf));
          resolve();
        })
    )
    .catch(error => {
      console.error('getConfigurations threw error:', error);
    });
};

export const getFeatures = () => {
  const state = getState();
  return state.conf.features;
};

export const getConfiguration = key => {
  const state = getState();
  return state.conf.configurations[key];
};

export const getConfigurationAsInteger = key => parseInt(getConfiguration(key), 10);

export const getConfigurationAsIntegerList = key =>
  getConfiguration(key)
    .split(',')
    .map(id => parseInt(id, 10));
