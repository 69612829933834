import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FlexGrid, Row, Block, Modal } from 'common/components';
import { CUSTOM_MARKER_TITLE_MAX_LENGTH, CUSTOM_MARKER_DESC_MAX_LENGTH } from './CustomMarkerConstants';

const CustomMarkerEditor = ({ save, close, item }) => {
  const { t } = useTranslation();
  const inputTitleRef = useRef(null);
  const inputDescRef = useRef(null);

  const isEditing = item != null && item.featureType === 'CUSTOM_MARKER';

  const saveItem = () => {
    const formattedItem = {
      ...item,
      title: inputTitleRef.current.value,
      desc: inputDescRef.current.value,
    };

    save(formattedItem);
  };

  const closeEditor = () => {
    close(item.id, isEditing);
  };

  const focusOnTitleInput = () => inputTitleRef.current.focus();

  return (
    <Modal
      isOpen={!!item}
      title={t(`mapToolbar.customMarker.modal.${isEditing ? 'editTitle' : 'newTitle'}`)}
      closeHandler={closeEditor}
      onAfterOpen={focusOnTitleInput}
    >
      <FlexGrid className="custom-marker-editor">
        <Row>
          <Block size={2}>
            <label className="label label--text-input" htmlFor="custom-marker-modal-form-title">
              <strong>{t('mapToolbar.customMarker.modal.form.title')}</strong>
            </label>
          </Block>
          <Block size={4}>
            <input
              className="input-text text-input-block"
              type="text"
              maxLength={CUSTOM_MARKER_TITLE_MAX_LENGTH}
              id="custom-marker-modal-form-title"
              defaultValue={isEditing ? item.title : null}
              ref={inputTitleRef}
            />
          </Block>
        </Row>
        <Row>
          <Block size={2}>
            <label className="label label--text-input" htmlFor="custom-marker-modal-form-desc">
              <strong>{t('mapToolbar.customMarker.modal.form.desc')}</strong>
            </label>
          </Block>
          <Block size={4}>
            <textarea
              className="textarea"
              maxLength={CUSTOM_MARKER_DESC_MAX_LENGTH}
              id="custom-marker-modal-form-desc"
              defaultValue={isEditing ? item.desc : null}
              ref={inputDescRef}
            />
          </Block>
        </Row>
        <Row className="margin-t-2">
          <Block size={3}>
            <button type="button" onClick={closeEditor} className="button button--light button--full-width">
              {t('button.cancel')}
            </button>
          </Block>
          <Block size={3}>
            <button type="button" onClick={saveItem} className="button button--orange button--full-width">
              {t('mapToolbar.customMarker.modal.save')}
            </button>
          </Block>
        </Row>
      </FlexGrid>
    </Modal>
  );
};

CustomMarkerEditor.propTypes = {
  close: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    featureType: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
  }),
  save: PropTypes.func.isRequired,
};

CustomMarkerEditor.defaultProps = {
  item: {
    featureType: undefined,
    title: null,
    desc: null,
  },
};

export default CustomMarkerEditor;
