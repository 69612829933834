import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RemoveButton } from 'common/components/Button/QuantityButtons';

const RemovableTargetTag = ({ onClick, targetIdentifier, isRemovable, children, className }) => {
  const { t } = useTranslation();
  return (
    <div className="removable-data">
      {children}
      {isRemovable && (
        <RemoveButton
          onClick={() => {
            onClick(targetIdentifier);
          }}
          className={className}
          text={t('button.remove')}
        />
      )}
    </div>
  );
};

RemovableTargetTag.propTypes = {
  onClick: PropTypes.func.isRequired,
  targetIdentifier: PropTypes.string.isRequired,
  isRemovable: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

RemovableTargetTag.defaultProps = {
  isRemovable: true,
  className: null,
};

export default RemovableTargetTag;
