import { addErrorNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';
import { accessibleNotificationAdded } from 'common/containers/AccessibleNotifications/accessibleNotificationsSlice';
import { ASSERTIVE } from 'common/containers/AccessibleNotifications/AccessibleNotificationsConstants';
import { t } from 'i18next';
import { LAYER_PARCELS } from 'oskari/layers/VectorLayerIds';
import * as OskariMap from '../../oskari/OskariMap';
import {
  ADD_NEIGHBOUR_REAL_ESTATE_FEATURES,
  REMOVE_PARCEL,
  ZOOM_TO_PARCELS,
} from '../../common/geometries/geometriesActionTypes';
import {
  geometryTypeCleared,
  drawGeometriesThunk,
  fetchGeometries,
  shownOnMapResetted,
} from '../containers/Realty/common/geometries/geometriesSlice';
import { NEIGHBOUR_REAL_ESTATES } from '../containers/Realty/common/geometries/geometriesConstants';
import { REGISTER_UNIT_TYPE } from '../constants/RealEstate';
import { hideAllInfoboxes } from '../../oskari/oskariModules/InfoBox';

export const handleRealEstateInfoboxEvent = data => async (dispatch, getState) => {
  if (data.actionParams.actionId === ADD_NEIGHBOUR_REAL_ESTATE_FEATURES) {
    const realEstates = data.actionParams.neighbourParcels.map(({ realEstateId }) => ({
      id: realEstateId,
      type: REGISTER_UNIT_TYPE,
    }));
    dispatch(geometryTypeCleared(NEIGHBOUR_REAL_ESTATES));
    try {
      await fetchGeometries(realEstates, NEIGHBOUR_REAL_ESTATES, false, false)(dispatch, getState);
    } catch (error) {
      dispatch(addErrorNotificationAction(t('error.map.neighbourRealEstates')));
      dispatch(accessibleNotificationAdded({ text: t('error.map.neighbourRealEstates'), type: ASSERTIVE }));
    }
    dispatch(drawGeometriesThunk(NEIGHBOUR_REAL_ESTATES));
    hideAllInfoboxes();
  }
};

export const handleMapSiteParcelInfoBoxEvent = data => async dispatch => {
  if (data.actionParams.actionId === REMOVE_PARCEL) {
    dispatch(shownOnMapResetted());
    OskariMap.clearMapRealestatesLayers();
    hideAllInfoboxes();
  }
  if (data.actionParams.actionId === ZOOM_TO_PARCELS) {
    OskariMap.zoomToFeatures([], LAYER_PARCELS);
  }
};
