import resolveLocale from 'common/i18n/helpers/resolveLocale';
import { formatDateDMY, lightFormat } from 'utils/datetime-utils';
import { t } from 'i18next';

/* NOTE: these methods can be supplemented with a locale parameter that is a string code (ie. 'fi') */

const formatDay = d => formatDateDMY(d);

const formatMonthTitle = d => lightFormat(d, 'M');

const formatWeekdayShort = i => resolveLocale().localize.day(i).slice(0, 2);

const formatWeekdayLong = i => resolveLocale().localize.day(i);

const getFirstDayOfWeek = () => resolveLocale().options.weekStartsOn;

const getMonths = () => Array.from({ length: 12 }, (_, i) => resolveLocale().localize.month(i));

const localeUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
};

const labelMonthDropdown = () => t('date.picker.label.month.dropdown');

const labelYearDropdown = () => t('date.picker.label.year.dropdown');

// eslint-disable-next-line no-unused-vars
const labelNext = (month, { locale }) => t('date.picker.label.next');

// eslint-disable-next-line no-unused-vars
const labelPrevious = (month, { locale }) => t('date.picker.label.previous');

const dayOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
// eslint-disable-next-line no-unused-vars
const labelDay = (day, activaModifiers, { locale }) => day.toLocaleDateString(resolveLocale().code, dayOptions);

const weekDayOptions = { weekday: 'long' };
// eslint-disable-next-line no-unused-vars
const labelWeekday = (day, { locale }) => day.toLocaleDateString(resolveLocale().code, weekDayOptions);

// eslint-disable-next-line no-unused-vars
const labelWeekNumber = (n, { locale }) => t('date.picker.label.week.number').replace('%1', n);

const ariaLabels = {
  labelMonthDropdown,
  labelYearDropdown,
  labelNext,
  labelPrevious,
  labelDay,
  labelWeekday,
  labelWeekNumber,
};

export default localeUtils;
export { resolveLocale, ariaLabels };
