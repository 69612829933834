import React, { useEffect, useCallback } from 'react';
import { useField, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import Analytics from 'common/helpers/Analytics';
import {
  CATEGORIES,
  SPATIAL_DATA_FILES_ACTIONS,
  SPATIAL_DATA_FILES_ERRORS,
  trackSpatialDataFilesFormValidationErrors,
} from 'common/helpers/Analytics/Analytics_eventmap';
import * as OskariMap from 'oskari/OskariMap';

import {
  FIELD_NAMES,
  YEAR_SELECTION_PRODUCTS,
  CADASTRAL_INDEX_MAP_VECTOR,
  LASER_SCANNING_DATA_5P,
  AREA_SELECTION_TYPES,
} from '../FileDownloadServiceConstants';

import AreaSelectionComponent from './AreaSelection/AreaSelectionComponent';
import FileFormatSelectionComponent from './FileFormatSelectionComponent';
import { Block, Row, Ruler, LoaderSpinner } from '../../../components';
import MetadataComponent from './MetadataComponent';
import PreviewImage from './PreviewImage';
import ProductTypeSelectionComponent from './ProductTypeSelectionComponent';
import { clearMapSheetSelectionThunk, stopAreaSelectionThunk } from '../FileDownloadServiceAction';
import {
  getDefaultAreaSelectionType,
  getDefaultProductTypeOption,
  getProduct,
  toFileFormatOptions,
  useProductParameters,
} from '../FileDownloadServiceHelpers';
import YearSelectionComponent from './YearSelectionComponent';
import CrsSelectionComponent from './CrsSelectionComponent';
import LevelOfDetailSelectionComponent from './LevelOfDetailSelectionComponent';
import useFileDownloadServiceLayer from '../useFileDownloadServiceLayer';
import FileDownloadErrorMessage from '../FileDownloadErrorMessage';

function trackValidationErrors(errors) {
  if (errors) {
    const errorsToReport = [];

    if (!isEmpty(errors.areaSelection)) {
      errorsToReport.push(SPATIAL_DATA_FILES_ERRORS.areaSelection);
    }

    Object.keys(errors)
      .filter(key => key !== 'areaSelection')
      .forEach(key => errorsToReport.push(SPATIAL_DATA_FILES_ERRORS[key] || key));

    errorsToReport.forEach(trackSpatialDataFilesFormValidationErrors);
  }
}

const FileDownloadServiceStep1Component = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { change, submit, resetFieldState, getFieldState, getState } = useForm();
  const dispatch = useDispatch();
  const administrativeAreaYears = useSelector(state => state.fileDownloadService.administrativeAreaYears);
  const showLoader = useSelector(state => state.fileDownloadService.laser5pProductionAreaMapSheetsLoading);
  const isError = useSelector(state => state.fileDownloadService.productParametersError);
  const isParametersLoaded = useSelector(state => state.fileDownloadService.isProductParametersLoaded);

  useFileDownloadServiceLayer();
  const {
    input: { value: productId },
  } = useField(FIELD_NAMES.PRODUCT_ID);

  const {
    input: { value: type },
  } = useField(FIELD_NAMES.PRODUCT_TYPE);

  const parameters = useProductParameters();
  const defaultAreaSelectionType = getDefaultAreaSelectionType(productId);
  const product = getProduct(productId);

  const resetStep1Values = () => {
    change(
      FIELD_NAMES.FILE_FORMAT,
      toFileFormatOptions(parameters.fileTypes[defaultAreaSelectionType])[
        productId === CADASTRAL_INDEX_MAP_VECTOR ? 1 : 0
      ]
    );
    change(
      FIELD_NAMES.PRODUCT_TYPE,
      getDefaultProductTypeOption(productId, parameters.datasets[defaultAreaSelectionType])
    );
    change(FIELD_NAMES.CRS, getProduct(productId).defaultCRS);
    change(
      FIELD_NAMES.LEVEL_OF_DETAIL,
      parameters?.levelOfDetail[defaultAreaSelectionType] && {
        value: parameters?.levelOfDetail[defaultAreaSelectionType][0],
      }
    );
    change(FIELD_NAMES.YEAR_SELECTION_TYPE, getProduct(productId).defaultYearSelectionType);
    change(
      FIELD_NAMES.YEAR,
      ['hallinnolliset_aluejaot_rasteri', 'hallinnolliset_aluejaot_vektori'].includes(productId)
        ? {
            value: !isEmpty(administrativeAreaYears) && administrativeAreaYears[0].toString(),
          }
        : undefined
    );
    // Clear any previous selected values
    [
      FIELD_NAMES.AREA_SELECTION,
      FIELD_NAMES.AREA_SELECTION_TYPE,
      FIELD_NAMES.SELECTED_MUNICIPALITY,
      FIELD_NAMES.SELECTED_MAP_SHEETS,
      FIELD_NAMES.THEME_FREE_SELECTION,
      FIELD_NAMES.THEME_MUNICIPALITY,
      FIELD_NAMES.THEME_ENTIRE_FINLAND,
      FIELD_NAMES.FREE_SELECTION_TYPE,
      FIELD_NAMES.SELECTED_PRODUCTION_AREA,
    ].forEach(field => {
      change(field, undefined);
      if (getFieldState(field)) resetFieldState(field);
    });

    dispatch(stopAreaSelectionThunk);

    // Because Oskari RPC function that clears drawn area also forces turning off drawing tool
    // we need to wait that RPC call is handled and then activate tool again by setting field value which
    // triggers useEffect in AreaSelectionComponent.
    // And this RPC call does not accept callbacks, so we have to use timeout hack...
    setTimeout(
      () =>
        change(
          FIELD_NAMES.AREA_SELECTION_TYPE,
          productId === LASER_SCANNING_DATA_5P
            ? AREA_SELECTION_TYPES.SELECT_SHEETS
            : getProduct(productId).defaultAreaSelectionType
        ),
      100
    );
  };

  const reset = () => {
    resetStep1Values();
    dispatch(clearMapSheetSelectionThunk());
  };

  useEffect(() => {
    Analytics().trackEvent({
      category: CATEGORIES.SPATIAL_DATA_FILES,
      action: SPATIAL_DATA_FILES_ACTIONS.SELECT_PRODUCT,
      label: productId,
    });
    return () => {
      change(FIELD_NAMES.FREE_SELECTION_TYPE, undefined);
      OskariMap.hideAllInfoboxes();
    };
  }, [change, productId]);

  // We need to reset the form state when language changes so that the map layer tools are working correctly.
  useEffect(() => {
    if (isParametersLoaded && !isError) resetStep1Values();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, isParametersLoaded, isError]);

  const addToBasketClick = useCallback(() => {
    trackValidationErrors(getState().errors);
    submit();
  }, [submit, getState]);

  return showLoader ? (
    <Row className="margin-t-1">
      <LoaderSpinner />
    </Row>
  ) : (
    <>
      <section>
        <PreviewImage productId={productId} type={type} />
        {product.metadata && <MetadataComponent product={product} />}

        {!isError && (
          <>
            <ProductTypeSelectionComponent />
            {YEAR_SELECTION_PRODUCTS.includes(productId) && <YearSelectionComponent />}
            <FileFormatSelectionComponent />
            <LevelOfDetailSelectionComponent />
            <AreaSelectionComponent />
            <CrsSelectionComponent />
          </>
        )}

        {isError && <FileDownloadErrorMessage />}
      </section>
      {!isError && (
        <Row className="margin-t-1">
          <Ruler />
          <Block size={6} className="navigation__block margin-t-1">
            <div className="float-left text-left">
              <button className="button button--light" type="button" onClick={reset}>
                {t('fileDownloadService.clearAll')}
              </button>
            </div>
            <div className="float-right text-right">
              <button className="button button--orange" type="button" onClick={addToBasketClick}>
                {t('button.shoppingCart.add')}
              </button>
            </div>
          </Block>
        </Row>
      )}
    </>
  );
};

export default FileDownloadServiceStep1Component;
