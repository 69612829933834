import {
  ADD_FEATURE,
  REMOVE_FEATURE,
  REMOVE_ALL_FEATURES,
  REMOVE_FEATURES_BY_TYPE,
  SET_LAYER_VISIBILITY,
  SET_REAL_ESTATE_SITE_FEATURES_REMOVED,
  RESET_REAL_ESTATE_SITE_FEATURES_REMOVED,
  REAL_ESTATE_SITE_FEATURE_TYPES,
  SET_INFOBOX,
} from './geometriesActionTypes';

const { APARTMENTS, BOUNDARY_MARKERS, REAL_ESTATES } = REAL_ESTATE_SITE_FEATURE_TYPES;

const initialState = {
  features: [],
  infoBox: {},
  layerVisibility: {},
  removedRealEstateSiteFeatures: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_FEATURE:
      return { ...state, features: [action.feature, ...state.features] };
    case REMOVE_FEATURE:
      return { ...state, features: state.features.filter(feature => feature.id !== action.id) };
    case REMOVE_ALL_FEATURES:
      return {
        ...state,
        features: [],
        removedRealEstateSiteFeatures: { [APARTMENTS]: true, [BOUNDARY_MARKERS]: true, [REAL_ESTATES]: true },
      };
    case REMOVE_FEATURES_BY_TYPE:
      return { ...state, features: state.features.filter(feature => feature.featureType !== action.featureType) };
    case SET_INFOBOX:
      return { ...state, infoBox: { id: action.infoBox, type: action.featureType } };
    case SET_LAYER_VISIBILITY:
      return { ...state, layerVisibility: { ...state.layerVisibility, [action.layer]: action.isVisible } };
    case SET_REAL_ESTATE_SITE_FEATURES_REMOVED:
      return {
        ...state,
        removedRealEstateSiteFeatures: {
          ...state.removedRealEstateSiteFeatures,
          [action.featureType]: action.isRemoved,
        },
      };
    case RESET_REAL_ESTATE_SITE_FEATURES_REMOVED:
      return {
        ...state,
        removedRealEstateSiteFeatures: { [REAL_ESTATES]: false, [APARTMENTS]: false, [BOUNDARY_MARKERS]: false },
      };
    default:
      return state;
  }
}
