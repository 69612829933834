import {
  ADD_SELECTED_MAP_SHEETS,
  REMOVE_SELECTED_MAP_SHEET,
  SET_FILE_PRODUCT_PARAMETERS,
  SET_MAP_SHEET_LAYER,
  SET_SELECTED_MAP_SHEETS,
  SET_FILE_PRODUCT_PARAMETERS_ERROR,
  SET_ACTIVE_AREA_SELECTION,
  SET_FILE_PRODUCT_PARAMETERS_LOADED,
  SET_ADMINISTRATIVE_AREA_YEARS,
  SET_SELECTED_PRODUCTION_AREA,
  SET_LASER5P_PRODUCTION_AREA_MAP_SHEETS_LOADING,
  SET_LASER5P_PRODUCTION_AREAS,
} from './FileDownloadServiceActionTypes';

const initialState = {
  productParameters: {},
  productParametersError: false,
  selectedMapSheets: [],
  mapSheetLayer: undefined,
  activeAreaSelectionTool: '',
  isProductParametersLoaded: false,
  administrativeAreaYears: [],
  selectedProductionArea: '',
  laser5pProductionAreas: {},
  laser5pProductionAreaMapSheetsLoading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FILE_PRODUCT_PARAMETERS:
      return {
        ...state,
        productParameters: action.parameters,
        productParametersError: false,
      };
    case SET_SELECTED_MAP_SHEETS:
      return { ...state, selectedMapSheets: action.mapSheets };
    case ADD_SELECTED_MAP_SHEETS:
      return { ...state, selectedMapSheets: [...new Set([...state.selectedMapSheets, ...action.mapSheets])] };
    case REMOVE_SELECTED_MAP_SHEET:
      return {
        ...state,
        selectedMapSheets: state.selectedMapSheets.filter(mapSheet => mapSheet.id !== action.mapSheet.id),
      };
    case SET_MAP_SHEET_LAYER:
      return { ...state, mapSheetLayer: action.id };
    case SET_FILE_PRODUCT_PARAMETERS_ERROR:
      return { ...state, productParameters: {}, productParametersError: action.isError };
    case SET_ACTIVE_AREA_SELECTION:
      return { ...state, activeAreaSelectionTool: action.tool };
    case SET_FILE_PRODUCT_PARAMETERS_LOADED:
      return { ...state, isProductParametersLoaded: true };
    case SET_ADMINISTRATIVE_AREA_YEARS:
      return { ...state, administrativeAreaYears: action.years };
    case SET_SELECTED_PRODUCTION_AREA:
      return { ...state, selectedProductionArea: action.productionArea };
    case SET_LASER5P_PRODUCTION_AREAS:
      return { ...state, laser5pProductionAreas: action.productionAreas };
    case SET_LASER5P_PRODUCTION_AREA_MAP_SHEETS_LOADING:
      return { ...state, laser5pProductionAreaMapSheetsLoading: action.isLoading };
    default:
      return state;
  }
}
