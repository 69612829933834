import * as types from './SearchActionTypes';

export const initialState = {
  params: {
    searchString: '',
    useFiltering: false,
    addressSearch: false,
    municipalitySearch: false,
  },
  results: [],
  resultsOnMap: [],
  loading: false,
  hasMore: false,
  isCadastralUnitSearch: false,
  drawnRealEstateMarkers: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SEARCH_ACTION:
      return { ...state, params: action.params };
    case types.SEARCH_RESULTS:
      return { ...state, results: action.results, hasMore: action.hasMore || false };
    case types.CLEAR_SEARCH_RESULTS:
      return { ...state, results: [], hasMore: false };
    case types.SEARCH_LOADING:
      return { ...state, loading: action.loading };
    case types.IS_CADASTRAL_UNIT_SEARCH:
      return { ...state, isCadastralUnitSearch: action.cadastralUnitSearch };
    case types.ADD_DRAWN_REAL_ESTATE_MARKERS:
      return { ...state, drawnRealEstateMarkers: [...state.drawnRealEstateMarkers, action.drawnRealEstateMarker] };
    case types.CLEAR_DRAWN_REAL_ESTATE_MARKERS:
      return { ...state, drawnRealEstateMarkers: [] };
    default:
      return state;
  }
}
