import { SET_MAP_TOOL_BUTTON_ACTIVE } from './MapToolbarActionTypes';
import { MAP_TOOL_AREA } from './MapToolNames';

const initialState = { active: false, dirty: false };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MAP_TOOL_BUTTON_ACTIVE:
      if (action.toolId === MAP_TOOL_AREA) {
        return { ...state, active: action.active, dirty: true };
      }
      return state;

    case 'CLEAN_AREA':
      return initialState;

    default:
      return state;
  }
}
