import React from 'react';
import { t } from 'i18next';
import MapStoreColumn from 'common/components/MapStoreColumn/MapStoreColumn';
import { ScrollerPanelPage } from 'common/components/ScrollerPanel/index';
import HeadingRow from 'common/containers/Forms/Blocks';
import { MAP_STORE_TITLE } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';

const MapStoreIndexComponent = () => {
  return (
    <ScrollerPanelPage>
      <HeadingRow
        text={t('businessPanel.mapStore.title')}
        className="my-map-store-column margin-b-1 with-divider"
        headerClassName={MAP_STORE_TITLE}
      />
      <MapStoreColumn />
    </ScrollerPanelPage>
  );
};

export default MapStoreIndexComponent;
