import React from 'react';
import { FlexGrid, Row, Block } from 'common/components';
import SearchBarContainer from './SearchBarComponent';
import SearchResultContainer from './SidebarSearchResultsController';

const SearchMobileComponent = () => (
  <FlexGrid>
    <Row className="main-toolbar-container">
      <Block size={6}>
        <SearchBarContainer id="search-mobile" />
      </Block>
      <SearchResultContainer scrollHeight={320} />
    </Row>
  </FlexGrid>
);

export default SearchMobileComponent;
