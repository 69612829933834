import { getRoute } from '../../constants/Routes';

const cache = new Map();

const fetchJson = url => fetch(url).then(res => res.json());

const fetchWithCache = url => {
  if (cache.has(url)) return Promise.resolve(cache.get(url));

  return fetchJson(url).then(data => {
    cache.set(url, data);
    return data;
  });
};

export const fetchAdministrativeAreas = () => fetchJson(getRoute(`api/spatialDataFiles/administrativeAreas`));
export const fetchMapSheetsForYear = year => fetchWithCache(getRoute(`api/spatialDataFiles/sheets/${year}`));
export const fetchMapSheetAreaInfo = () => fetchJson(getRoute(`api/spatialDataFiles/sheets`));
export const fetchCity3dMapSheets = () => fetchJson(getRoute(`api/spatialDataFiles/city3dMapsheets`));
export const fetchYearOfPhotography = mapSheetNumber =>
  fetchJson(getRoute(`api/spatialDataFiles/sheets/yearOfPhotography/${mapSheetNumber}`));
export const fetchLaser5pMapSheetInfo = mapSheetNumber =>
  fetchJson(getRoute(`api/spatialDataFiles/laser5pMapsheetInfo/${mapSheetNumber}`));
export const fetchLaser5pProductionAreas = () => fetchJson(getRoute(`api/spatialDataFiles/laser5pProductionAreas`));
export const fetchLaser5pMapSheets = areaId => fetchJson(getRoute(`api/spatialDataFiles/laser5pMapsheets/${areaId}`));
