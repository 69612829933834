import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import { Glyphicon } from 'common/components/Icon';

const toggleSearchPropTypes = {
  showing: PropTypes.bool.isRequired,
  toggleAction: PropTypes.func.isRequired,
};

export const ToggleSearchResults = ({ showing, toggleAction }) => (
  <button
    data-testid="ToggleSearchResults"
    type="button"
    title={showing ? t('sidebar.toggle.tooltip.close') : t('sidebar.toggle.tooltip.open')}
    onClick={toggleAction}
    className="button button--clear search-results__toggle-button mobile-hide"
  >
    <Glyphicon glyph={showing ? 'nuoli-ylos' : 'nuoli-alas'} />
  </button>
);

ToggleSearchResults.propTypes = toggleSearchPropTypes;

const ToggleSearchResultsBlock = ({ showing, toggleAction }) => (
  <div className="search-results__toggle-block desktop-hide">
    <div className="search-results__show-results-label">{!showing && t('search.showResults')}</div>
    <button
      type="button"
      title={showing ? t('sidebar.toggle.tooltip.close') : t('sidebar.toggle.tooltip.open')}
      onClick={toggleAction}
      className="search-results__toggle-button"
    >
      <Glyphicon glyph={showing ? 'nuoli-ylos' : 'nuoli-alas'} />
    </button>
  </div>
);

ToggleSearchResultsBlock.propTypes = toggleSearchPropTypes;

export default ToggleSearchResultsBlock;
