import { t } from 'i18next';

export function getEuroSign() {
  return '€';
}

export function formatPrice(price, decimals = 2) {
  return price.toFixed(decimals).replace('.', ',');
}

export function priceInEuro(number, decimals) {
  return `${formatPrice(number, decimals)} ${getEuroSign()}`;
}

export function formatTotalPrice({ itemCount, price, vat, excludeTotalIfManyItems, excludeVAT }) {
  const vatString = t('realEstate.legalConfirmation.application.step4.vat');
  if (itemCount < 1) {
    // throw new Error('itemCount for price calculation is 0 or negative');
    return priceInEuro(0);
  }
  const vatPart = excludeVAT ? '' : ` (${vatString} ${vat} %)`;
  if (itemCount === 1) {
    return `${priceInEuro(price)}${vatPart}`;
  }
  const totalAndVat = excludeTotalIfManyItems ? '' : ` = ${priceInEuro(itemCount * price)}${vatPart}`;
  return `${itemCount} X ${priceInEuro(price)}${totalAndVat}`;
}
