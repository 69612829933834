import PropTypes from 'prop-types';
import cls from 'classnames';
import div from '../../helpers/Html/divHelper';

const MapView = ({ children }) => {
  return div(cls('desktop__middle-view', 'desktop-map-view', 'mobile-map-view'), children);
};

MapView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MapView;
