/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ASSERTIVE, POLITE } from './AccessibleNotificationsConstants';

const accessibleNotificationsSlice = createSlice({
  name: 'accessibleNotifications',
  initialState: {
    polite: null,
    assertive: null,
  },
  reducers: {
    // Can "mutate" state thanks to Immer!
    accessibleNotificationAdded(slice, action) {
      const { text, type } = action.payload;

      switch (type) {
        case POLITE:
          slice.polite = text;
          break;
        case ASSERTIVE:
        default:
          slice.assertive = text;
          break;
      }
    },
    accessibleNotificationsCleared(slice, action) {
      const { type } = action.payload;

      switch (type) {
        case POLITE:
          slice.polite = null;
          break;
        case ASSERTIVE:
          slice.assertive = null;
          break;
        default:
          slice.polite = null;
          slice.assertive = null;
          break;
      }
    },
  },
});

export const { accessibleNotificationAdded, accessibleNotificationsCleared } = accessibleNotificationsSlice.actions;

export default accessibleNotificationsSlice.reducer;
/* eslint-enable no-param-reassign */
