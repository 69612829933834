import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const usePrevLang = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const prevLang = localStorage.getItem('prevLang');

  useEffect(() => {
    const currentLang = language;
    return () => {
      if (prevLang !== currentLang) {
        localStorage.setItem('prevLang', currentLang);
      }
    };
  }, [language, prevLang]);

  return { prevLang };
};

export default usePrevLang;
