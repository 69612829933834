const getMarginTop = (activeMenuItemTop, viewportTop, viewportHeight) => {
  const viewportBottom = viewportTop + viewportHeight;

  // Active content is close enough to top of column:
  if (activeMenuItemTop < viewportHeight - 100) {
    return 0;
  }

  // Active content is before or after current viewport:
  if (activeMenuItemTop < viewportTop || activeMenuItemTop > viewportBottom) {
    return Math.max(0, activeMenuItemTop - 300);
  }

  // Active content is within current viewport:
  return Math.min(viewportTop, activeMenuItemTop);
};

export default getMarginTop;
