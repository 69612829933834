import React from 'react';
import FooterComponent from 'common/containers/Footer/FooterComponent';
import { useTranslation } from 'react-i18next';

const MapSiteFooter = () => {
  const { t } = useTranslation();

  const leftBlockLinks = [
    { href: t('footer.info.terms.url'), text: t('footer.info.terms.linkText') },
    { href: t('footer.info.delivery.url'), text: t('footer.info.delivery.linkText') },
    { href: t('footer.info.privacy.url'), text: t('footer.info.privacy.linkText') },
    { href: t('footer.info.prices.url'), text: t('footer.info.prices.linkText') },
    { href: t('footer.info.briefing.url'), text: t('footer.info.briefing.linkText') },
    { href: t('footer.info.tips.url'), text: t('footer.info.tips.linkText') },
    { href: t('footer.info.faq.mapsiteurl'), text: t('footer.info.faq.linkText'), disabledLangs: ['en'] },
  ];

  return <FooterComponent leftBlockLinks={leftBlockLinks} leftHeading={t('footer.info.title')} />;
};

export default MapSiteFooter;
