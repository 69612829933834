import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIVE_MENU_ITEM } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';
import useAutomaticKeyboardFocus from 'common/containers/AutomaticKeyboardFocus/useAutomaticKeyboardFocus';

const SimpleTitle = ({ id, title, TagName = 'h2', className }) => {
  const dispatch = useDispatch();
  const titleRef = useRef(null);
  const autoFocus = useSelector(state => state.automaticKeyboardFocus);
  const focusTitle = autoFocus?.selector?.length > 0 && className.includes(autoFocus?.selector);

  useAutomaticKeyboardFocus(focusTitle, titleRef);

  const onKeyDown = event => {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      dispatch(requestKeyboardFocusAction({ selector: ACTIVE_MENU_ITEM }));
    }
  };

  return (
    <TagName id={id} ref={titleRef} tabIndex="-1" onKeyDown={onKeyDown} className={className}>
      <span>{title}</span>
    </TagName>
  );
};

SimpleTitle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  TagName: PropTypes.string,
  className: PropTypes.string,
};

SimpleTitle.defaultProps = {
  id: null,
  TagName: 'h2',
  className: '',
};

export default SimpleTitle;
