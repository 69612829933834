import React from 'react';
import { LoaderSpinner } from 'common/components/index';

const LayoutVeilComponent = () => {
  return (
    <div className="layout-veil">
      <LoaderSpinner size="xl" color="lightBlue" />
    </div>
  );
};

export default LayoutVeilComponent;
