import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { COLUMN_LAYOUT, STACKED_LAYOUT, isDesktop, isMobile } from 'common/constants/Layout';
import classNames from 'classnames';
import VerticalScroller from 'common/components/VerticalScroller';
import * as Routes from 'common/constants/Routes';
import FooterComponent from 'mapSite/containers/Footer/MapSiteFooter';
import UserMenuContainer from 'common/containers/UserMenu/UserMenuContainer';
import { MOBILE_BUSINESS_PANEL_VIEW } from 'common/containers/MobileTools/MobileViewNames';
import { Glyphicon } from 'common/components/Icon';
import changeMobileViewAction from 'common/containers/MobileTools/MobileViewActions';
import { updateShowCurtainThunk } from 'common/containers/KasiApp/KasiAppActions';
import BusinessPanelErrorBoundary from './BusinessPanelErrorBoundary';
import withRouter from './withRouter';
import { businessPanelToggleAction } from './BusinessPanelActions';

export const CURTAIN_HANG_CLASS = 'main-view-curtain-hang';
export const CURTAIN_HALF_CLASS = 'main-view-curtain-half';
export const CURTAIN_CLOSED_CLASS = 'main-view-curtain-closed';
export const VIEW_SIZE_FULL = 'main-view-full';
export const VIEW_SIZE_CONTENT = 'main-view-content';
export const VIEW_SIZES = {
  full: VIEW_SIZE_FULL,
  content: VIEW_SIZE_CONTENT,
};

export const CURTAIN_SIZES = {
  full: CURTAIN_HANG_CLASS,
  half: CURTAIN_HALF_CLASS,
};

const DESKTOP_HEADER_HEIGHT = 50;
const MOBILE_HEADER_HEIGHT = 110;
const CURTAIN_HANG_HEIGHT = 121;
const CURTAIN_FULL_HEIGHT = 161;

const getHeightOffset = (showCurtain, layoutMode, curtainSize) => {
  let curtainOffset = 0;
  if (showCurtain) {
    curtainOffset = curtainSize === 'hang' ? CURTAIN_HANG_HEIGHT : CURTAIN_FULL_HEIGHT;
  }
  return layoutMode === COLUMN_LAYOUT ? DESKTOP_HEADER_HEIGHT : MOBILE_HEADER_HEIGHT + curtainOffset;
};

const BusinessPanelComponent = ({
  showCurtain,
  autoScroll,
  className,
  children,
  shouldScrollToTop,
  curtainSize,
  scrollTopTrigger,
  layout,
  navigate,
  viewSize,
  columnCount,
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.businessPanel.isOpen);
  const layoutMode = useSelector(state => state.layout.mode);
  const [curtainIsOpen, setCurtainIsOpen] = useState(true);

  const curtainClasses = [CURTAIN_CLOSED_CLASS, CURTAIN_SIZES[curtainSize]];

  useEffect(() => {
    const openBusinessPanel = () => {
      dispatch(businessPanelToggleAction(true));
      dispatch(changeMobileViewAction(MOBILE_BUSINESS_PANEL_VIEW));
    };

    const handleBusinessPanelInitialToggleState = () => {
      let path = window.location.pathname;
      path = path.slice(-1) !== '/' ? `${path}/` : path;

      const isSubRoute = path.split('/').length - 1 > 2;
      const businessPanelMustBeOpen = path.includes(Routes.ROUTE_SERVICES_SITE_ID);

      if (isSubRoute || businessPanelMustBeOpen) {
        openBusinessPanel();
      }
    };

    handleBusinessPanelInitialToggleState();
    dispatch(updateShowCurtainThunk(showCurtain));
    if (isDesktop(layout)) {
      navigate('/kiinteistoasiat/omat');
    }
    if (isMobile(layout)) {
      navigate('/kiinteistoasiat/etusivu');
    }
  }, [dispatch, layout, navigate, showCurtain]);

  const handleCurtainClick = () => {
    setCurtainIsOpen(!curtainIsOpen);
  };

  const getViewClass = () => {
    if (showCurtain) {
      const curtainClassIndex = curtainIsOpen ? 1 : 0;
      return curtainClasses[curtainClassIndex];
    }

    return VIEW_SIZES[viewSize];
  };

  const getPanelClasses = () => {
    const viewClass = getViewClass();
    return classNames(
      'business-panel__wrapper',
      viewClass,
      {
        'desktop__right-view': layoutMode === COLUMN_LAYOUT,
        'mobile-main-view': layoutMode === STACKED_LAYOUT,
        open: isOpen,
        closed: !isOpen,
        curtain: !!showCurtain,
      },
      ['expand-width-by', columnCount, 'columns'].join('-')
    );
  };

  const innerPanelClasses = classNames('business-panel', className);
  const h = getHeightOffset(showCurtain, layoutMode, curtainSize);

  if (layoutMode === STACKED_LAYOUT) {
    const glyph = curtainIsOpen ? 'nuoli-ylos' : 'nuoli-alas';
    return (
      <div className={getPanelClasses()}>
        <div className={innerPanelClasses} id="businessPanel">
          <UserMenuContainer displayLayoutMode={STACKED_LAYOUT} />
          <BusinessPanelErrorBoundary>
            {!autoScroll && children}
            {autoScroll && (
              <VerticalScroller
                heightOffset={h}
                shouldScrollToTop={shouldScrollToTop}
                scrollTopTrigger={scrollTopTrigger}
                className="vertical-scroller"
              >
                <Outlet />
                {children}
              </VerticalScroller>
            )}
          </BusinessPanelErrorBoundary>
        </div>
        {showCurtain && (
          <button type="button" className="curtain__handle" onClick={handleCurtainClick}>
            <Glyphicon glyph={glyph} />
          </button>
        )}
      </div>
    );
  }
  return (
    <div className={innerPanelClasses} id="rightSidebar">
      <UserMenuContainer displayLayoutMode={COLUMN_LAYOUT} />
      <BusinessPanelErrorBoundary>
        {!autoScroll && <Outlet />}
        {autoScroll && (
          <VerticalScroller
            heightOffset={h}
            shouldScrollToTop={shouldScrollToTop}
            scrollTopTrigger={scrollTopTrigger}
            className="vertical-scroller"
            maincontent
          >
            <Outlet />
            {children}
          </VerticalScroller>
        )}
      </BusinessPanelErrorBoundary>
    </div>
  );
};

BusinessPanelComponent.propTypes = {
  autoScroll: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  columnCount: PropTypes.number,
  curtainSize: PropTypes.string,
  shouldScrollToTop: PropTypes.func,
  showCurtain: PropTypes.bool,
  viewSize: PropTypes.string,
  scrollTopTrigger: PropTypes.string,
  layout: PropTypes.string,
  navigate: PropTypes.func.isRequired,
};

BusinessPanelComponent.defaultProps = {
  // 180 = bottom bar height + top bar height + some spacing;
  autoScroll: true,
  className: null,
  columnCount: 1,
  curtainSize: 'full',
  scrollTopTrigger: null,
  shouldScrollToTop: () => true,
  showCurtain: false,
  viewSize: 'content',
  layout: '',
};

export default withRouter(BusinessPanelComponent);

/*
 * Business Panel Subcomponents:
 *  Footer
 *
 */

export const BusinessPanelSupportFooter = () => (
  <div className="feature-container">
    <FooterComponent />
  </div>
);
