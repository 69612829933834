import React from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Block, Row } from 'common/components';
import { getProduct } from '../FileDownloadServiceHelpers';

const PreviewImage = ({ productId, type }) => {
  const { t } = useTranslation();
  const { previewImage } = getProduct(productId);

  if (!previewImage) return null;

  const url = isString(previewImage) ? previewImage : previewImage[type?.value];

  if (!url) return null;

  return (
    <Row className="margin-t-1">
      <Block>
        <strong>{t('fileDownloadService.product.previewImage')}</strong>
        <div className="margin-t-1">
          <img
            alt={t('fileDownloadService.product.previewImage')}
            className="file-download-service__product-properties__preview-img"
            src={url}
          />
        </div>
      </Block>
    </Row>
  );
};

PreviewImage.propTypes = {
  productId: PropTypes.string.isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ value: PropTypes.string })]),
};

PreviewImage.defaultProps = {
  type: undefined,
};

export default PreviewImage;
