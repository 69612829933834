import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { Glyphicon } from 'common/components/Icon';

const AmountSelectorField = ({ input, className }) => {
  const { t } = useTranslation();
  return (
    <div className={cls('amount-selector__wrapper margin-b-2', className)}>
      <button
        type="button"
        title={t('amountSelector.minus')}
        disabled={input.value <= 1}
        className="button--blue amount-selector__button"
        onClick={() => input.onChange(input.value - 1)}
      >
        <Glyphicon glyph="minus" />
      </button>
      <input
        {...input}
        id={input.name}
        aria-label={t('amountSelector.quantity')}
        type="text"
        className="text-input amount-selector__input"
        required
        pattern="[0-9]*"
        readOnly
      />
      <button
        type="button"
        title={t('amountSelector.plus')}
        className="button--blue amount-selector__button-increase"
        onClick={() => input.onChange(input.value + 1)}
      >
        <Glyphicon glyph="plus" />
      </button>
    </div>
  );
};

AmountSelectorField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

AmountSelectorField.defaultProps = {
  className: '',
};

export default AmountSelectorField;
