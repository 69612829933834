import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import { Glyphicon } from 'common/components/Icon';
import CartBubbleTransition from './CartBubbleTransition';

const CartNotificationBubble = ({ cartBubbleText, trigger, close }) => (
  <div className="user-menu__cart-bubble">
    <CartBubbleTransition trigger={trigger}>
      <div className="cart-bubble__content" data-cy="cart-bubble">
        <button type="button" onClick={close} className="button button--icon float-left" title={t('button.close')}>
          <Glyphicon glyph="sulje" />
        </button>
        <span data-cy="cart-bubble" className="cart-bubble__text">
          {cartBubbleText}
        </span>
      </div>
    </CartBubbleTransition>
  </div>
);

CartNotificationBubble.propTypes = {
  cartBubbleText: PropTypes.string.isRequired,
  trigger: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default CartNotificationBubble;
