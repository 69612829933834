import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Glyphicon } from 'common/components/Icon';
import { useTranslation } from 'react-i18next';
import { businessPanelToggleAction } from './BusinessPanelActions';

const BusinessPanelControlsComponent = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const businessPanelIsOpen = useSelector(state => state.businessPanel.isOpen);

  const isMapSite = useSelector(state => state.application.applicationContext) === 'mapSite';
  const businessPanelControlsTitle = isMapSite
    ? 'businessPanelControls.mapSiteTitle'
    : 'businessPanelControls.realEstateSiteTitle';

  const handleBusinessPanelClick = () => {
    dispatch(businessPanelToggleAction(!businessPanelIsOpen));
  };

  const businessPanelToggleTooltip = businessPanelIsOpen ? t('button.close') : t('button.open');

  const glyph = businessPanelIsOpen ? 'nuoli-oikealle' : 'nuoli-vasemmalle';

  return (
    <div className="business-panel__controls mobile-hide">
      <h1 className="visually-hidden">{t(businessPanelControlsTitle)}</h1>
      <button
        type="button"
        id="business-panel-controls-toggle-button"
        title={businessPanelToggleTooltip}
        className="button button--icon"
        onClick={handleBusinessPanelClick}
        aria-controls="rightSidebar"
        aria-expanded={businessPanelIsOpen}
      >
        <span className="business-panel__controls__content">
          <span className="business-panel__controls__content__first-icon">
            <Glyphicon glyph={glyph} />
          </span>
          {children}
          <span className="business-panel__controls__brand__container">
            <span className="business-panel__controls__brand__text">{t(businessPanelControlsTitle)}</span>
          </span>
          <span className="business-panel__controls__content__last-icon">
            <Glyphicon glyph={glyph} />
          </span>
        </span>
      </button>
    </div>
  );
};

BusinessPanelControlsComponent.propTypes = {
  children: PropTypes.node,
};

BusinessPanelControlsComponent.defaultProps = {
  children: null,
};

export default BusinessPanelControlsComponent;
