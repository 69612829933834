import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { Block } from '../Grid';
import LoaderSpinner from './LoaderSpinner';

const FormLoaderBlock = ({ className, size }) => (
  <Block size={6} className={cls('relative', className)}>
    <LoaderSpinner size={size} />
  </Block>
);

FormLoaderBlock.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['l', 'm', 's', 'xs']),
};

FormLoaderBlock.defaultProps = {
  className: '',
  size: 's',
};

export default FormLoaderBlock;
