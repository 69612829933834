import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatTime, isBefore, subMilliseconds } from 'utils/datetime-utils';
import { t } from 'i18next';
import { Glyphicon } from 'common/components/Icon';
import cls from 'classnames';
import Modal from 'common/components/Modal';
import { FlexGrid, Row, Block } from 'common/components/Grid';
import {
  SERVICES_LEASEHOLD_TRANSFER_APPLICATION,
  SERVICES_LEGAL_CONFIRMATION_APPLICATION,
  SERVICES_OSRA,
  SERVICES_LEASEHOLD_REGISTRATION_APPLICATION,
  SERVICES_CADASTRAL_SURVEYS,
  SERVICES_REGISTRATION_MATTERS,
} from 'realEstateSite/constants/Routes';
import { MAP_STORE_INDEX } from 'common/constants/Routes';
import SessionObserver from './SessionObserver';

const COUNTDOWN_MILLISECONDS = 5 * 60 * 1000;
const NLS_FRONT_PAGE = 'https://www.maanmittauslaitos.fi';

const SessionModal = ({ logout, goToShoppingCart, shoppingCartItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const duration = useRef(null);
  const observer = useRef(null);
  const timerInterval = useRef(null);
  const [, setForceUpdate] = useState(Date.now());
  const location = useLocation();

  const formUrls = [
    SERVICES_LEASEHOLD_TRANSFER_APPLICATION,
    SERVICES_LEGAL_CONFIRMATION_APPLICATION,
    SERVICES_OSRA,
    SERVICES_LEASEHOLD_REGISTRATION_APPLICATION,
    SERVICES_CADASTRAL_SURVEYS,
    SERVICES_REGISTRATION_MATTERS,
    MAP_STORE_INDEX,
  ];

  const isFormUrl = formUrls.some(url => location.pathname.includes(url));

  const stopTimer = () => {
    clearInterval(timerInterval.current);
    timerInterval.current = null;
  };

  const startTimer = () => {
    duration.current = COUNTDOWN_MILLISECONDS;
    timerInterval.current = setInterval(() => {
      if (duration.current < 1000) {
        stopTimer();
        logout();
      } else {
        duration.current -= 1000;
        setForceUpdate(Date.now());
      }
    }, 1000);
  };

  const handleClose = () => {
    setIsOpen(false);
    stopTimer();
    observer?.current?.bindEventListeners().start();
  };

  const handleShoppingCart = () => {
    handleClose();
    goToShoppingCart();
  };

  const continueBrowsing = () => {
    handleClose();
  };

  useEffect(() => {
    observer.current = new SessionObserver({
      warningCallback: lastActive => {
        // ASI-7055 Mobile browsers put inactive tabs to sleep and run timeouts after tab is restored.
        // So skip modal and logout user if last activity timestamp is before wait time + countdown.
        // If session has been timeout on F5 then it's not possible to successfully call logout URL.
        // So we skip calling logout URL and redirect user to NLS front page.
        if (isBefore(lastActive, subMilliseconds(new Date(), SessionObserver.WARNING_TIME + COUNTDOWN_MILLISECONDS))) {
          window.location.assign(NLS_FRONT_PAGE);
        } else {
          observer?.current?.unbindEventListeners();
          setIsOpen(true);
          startTimer();
        }
      },
    });

    observer.current.bindEventListeners().start();

    return () => {
      if (observer.current != null) {
        observer.current.destroy();
      }
      stopTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return duration.current != null ? (
    <Modal isOpen={isOpen} warn title={t('sessionmodal.title.warning')} closeHandler={handleClose}>
      <FlexGrid className="text-center">
        <Row className="margin-t-2">
          <Block size="6">
            <Glyphicon glyph="lepaamassa" className={cls('session-modal__timer-icon', 'text-color-orange-base')} />
            <h2 className="text-center margin-t-1">
              {t('sessionmodal.timer.label')}:&nbsp;
              {formatTime(duration.current)}
            </h2>
            {shoppingCartItems > 0 && <p className="margin-t-2">{t('sessionmodal.warning.shoppingCart')}</p>}
          </Block>
        </Row>
        <Row className="margin-t-2 margin-b-3">
          <Block size="6">
            {shoppingCartItems === 0 && (
              <button type="button" onClick={handleClose} className="button button--orange text-center">
                {t('button.continue')}
              </button>
            )}
            {shoppingCartItems > 0 && isFormUrl && (
              <button type="button" onClick={continueBrowsing} className="button button--orange text-center">
                {t('sessionAlertModal.continueButton')}
              </button>
            )}
            {shoppingCartItems > 0 && !isFormUrl && (
              <button type="button" onClick={handleShoppingCart} className="button button--orange text-center">
                {t('button.shoppingCart.proceed')}
              </button>
            )}
          </Block>
        </Row>
      </FlexGrid>
    </Modal>
  ) : null;
};

SessionModal.propTypes = {
  logout: PropTypes.func.isRequired,
  goToShoppingCart: PropTypes.func,
  shoppingCartItems: PropTypes.number,
};

SessionModal.defaultProps = {
  goToShoppingCart: null,
  shoppingCartItems: 0,
};

export default SessionModal;
