import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import i18next from 'i18next';
import process from 'process';

import idb, { STATE_STORAGE_NAME, GEOMETRY_STORE_NAME } from 'common/helpers/idb';
import { I18NEXT_KEY } from 'common/constants/LocalStorageKeys';

let sessionStorageKey = 'mapSite';
let sessionStorageInUse = true;
let reduxStore;

export function initSessionStorageKey(key) {
  sessionStorageKey = key;
}

const routeListener = () => next => action => next(action);

export function configureReduxStore(initialState, rootReducer) {
  reduxStore = configureStore({
    reducer: rootReducer,
    preloadedState: initialState || {},
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(routeListener);
      if (process.env.DEBUG_REDUX_LOGGING === true) {
        middleware.push(createLogger({ level: 'debug' }));
      }
      return middleware;
    },
  });
  return reduxStore;
}

export const getReduxStore = () => reduxStore;

const wrapToContext = (id, lang) => [sessionStorageKey, id, lang].filter(Boolean).join('_');

export function setToLocalStorage(id, val, lang = i18next.language) {
  localStorage.setItem(wrapToContext(id, lang), val);
}

export function getFromLocalStorage(id, lang = i18next.language) {
  return localStorage.getItem(wrapToContext(id, lang));
}

export function removeFromLocalStorage(id, lang = i18next.language) {
  localStorage.removeItem(wrapToContext(id, lang));
}

const reISODatetime = /^\d{4}-(0[1-9]|1[0-2])-([12]\d|0[1-9]|3[01])([T\s](([01]\d|2[0-3]):[0-5]\d|24:00)(:[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?$/;
export const dateParser = (key, value) =>
  typeof value === 'string' && reISODatetime.exec(value) ? new Date(value) : value;

export async function loadStateFromSessionStorage() {
  const stateAsString = sessionStorage.getItem(sessionStorageKey);

  if (stateAsString) {
    const state = JSON.parse(stateAsString, dateParser);
    const geometry = await idb.state.then(db => db.get(STATE_STORAGE_NAME, GEOMETRY_STORE_NAME));

    return { ...state, geometry };
  }

  return null;
}

export function saveStateToSessionStorage(state) {
  if (!sessionStorageInUse) return;
  const geometryState = state.geometry;
  const stateWithoutGeometries = { ...state, geometry: {} };

  idb.state.then(db => db.put(STATE_STORAGE_NAME, geometryState, GEOMETRY_STORE_NAME));
  sessionStorage.setItem(I18NEXT_KEY, i18next.language || 'fi');
  sessionStorage.setItem(sessionStorageKey, JSON.stringify(stateWithoutGeometries));
}

export function removeStateFromSessionStorage() {
  sessionStorageInUse = false;
  sessionStorage.clear();
  idb.state.then(db => db.clear(STATE_STORAGE_NAME));
}
