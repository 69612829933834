import { isEmpty, isEqual, omit, uniq } from 'lodash';
import { t } from 'i18next';
import { trackTooManyMunicipalitiesInCart } from 'common/helpers/Analytics/Analytics_eventmap';
import { addErrorNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';
import * as types from './ShoppingCartActionTypes';
import {
  CART_ITEM_ADDED,
  ITEM_ALREADY_IN_CART,
  MAX_NUMBER_OF_MUNICIPALITIES_IN_CART,
  SOME_MAP_SHEETS_IN_CART,
} from '../UserMenu/CartNotificationBubbleConstants';
import { ASSERTIVE, POLITE } from '../AccessibleNotifications/AccessibleNotificationsConstants';
import { accessibleNotificationAdded } from '../AccessibleNotifications/accessibleNotificationsSlice';
import { AREA_SELECTION_TYPES } from '../FileDownloadService/FileDownloadServiceConstants';
import { isLaser5pProduct } from './Checkout/CheckoutHelpers';

const MAX_NUMBER_OF_MUNICIPALITY_PRODUCTS = 10;

const addProductToCart = (id, product, price, amount = '1') => ({
  type: types.ADD_PRODUCT_TO_CART,
  product,
  id,
  price,
  amount,
});

export const updateMapSheetAmount = mapSheets => {
  return {
    type: types.UPDATE_MAPSHEET_AMOUNT,
    mapSheets,
  };
};

const updateSessionId = id => ({
  type: types.UPDATE_SESSION_ID,
  id,
});

export const toggleCartNotificationBubble = (bubbleType = null, open = false, reset = false) => ({
  type: types.TOGGLE_CART_NOTIFICATION_BUBBLE,
  bubbleType,
  open,
  reset,
});

const getProductTotalAmountFromShoppingCart = (id, shoppingCartItems) =>
  shoppingCartItems
    .filter(item => item.id === id)
    .map(p => parseInt(p.amount, 10))
    .reduce((total, singleValue) => total + singleValue, 0);

const isCertificateType = productType => productType === 'certificate';

const itemInCart = (id, shoppingCartItems) => !isEmpty(shoppingCartItems.filter(item => item.id === id));

const openNotificationBubble = (dispatch, bubbleIsOpen, bubbleText) =>
  bubbleIsOpen
    ? dispatch(toggleCartNotificationBubble(bubbleText, true, true))
    : dispatch(toggleCartNotificationBubble(bubbleText, true));

const sendAccessibleNotification = (dispatch, bubbleText) => {
  dispatch(accessibleNotificationAdded({ text: t(`togglableUserMenuComponent.${bubbleText}`), type: POLITE }));
};

export const showShoppingCartNotification = (dispatch, shoppingCart, bubbleText) => {
  openNotificationBubble(dispatch, shoppingCart.notificationBubble.isOpen, bubbleText);
  sendAccessibleNotification(dispatch, bubbleText);
};

const addCertificateToCart = (dispatch, id, product, price, shoppingCart) => {
  if (!itemInCart(id, shoppingCart.items)) {
    dispatch(addProductToCart(id, product, price));
    showShoppingCartNotification(dispatch, shoppingCart, CART_ITEM_ADDED);
  } else {
    showShoppingCartNotification(dispatch, shoppingCart, ITEM_ALREADY_IN_CART);
  }
};

const isFileDownloadProduct = item => item.product.productType === 'fileDownload';

const hasMaximumNumberOfMunicipalityProductsInCart = shoppingCart =>
  shoppingCart.items.filter(isFileDownloadProduct).filter(item => item.product.municipality).length >=
  MAX_NUMBER_OF_MUNICIPALITY_PRODUCTS;

export const isPatchDataProductionArea = description => description && description.includes('täydennys');

export const hasOnlyPatchProductionAreasInCart = shoppingCart => {
  const productAreaItems = shoppingCart.items.filter(
    item => item.product.areaSelectionType === AREA_SELECTION_TYPES.PRODUCTION_AREA
  );
  return (
    shoppingCart.items.length > 0 &&
    !isEmpty(productAreaItems) &&
    productAreaItems.every(item => isPatchDataProductionArea(item.product.description))
  );
};

export const productionAreaOrPatchAreaProductInCart = shoppingCart =>
  shoppingCart.items.find(item => item?.product?.productionArea);

export const productionAreaProductInCart = shoppingCart =>
  shoppingCart.items.find(
    item => item?.product?.productionArea && !isPatchDataProductionArea(item.product.description)
  );

export const mapSheetProductInCart = shoppingCart =>
  shoppingCart.items.find(item => item?.product?.areaSelectionType === AREA_SELECTION_TYPES.SELECT_SHEETS);

export const addLaser5pMapsheetsToCart = (dispatch, id, product, price, shoppingCart) => {
  if (!mapSheetProductInCart(shoppingCart)) {
    dispatch(addProductToCart(id, product, price));
    showShoppingCartNotification(dispatch, shoppingCart, CART_ITEM_ADDED);
  } else {
    const shoppingCartMapSheets = mapSheetProductInCart(shoppingCart).product.mapSheets;
    const combinedMapSheets = [...shoppingCartMapSheets, ...product.mapSheets];
    if (product.mapSheets.every(sheet => shoppingCartMapSheets.includes(sheet))) {
      showShoppingCartNotification(dispatch, shoppingCart, ITEM_ALREADY_IN_CART);
    } else if (uniq(combinedMapSheets).length !== combinedMapSheets.length) {
      dispatch(updateMapSheetAmount(uniq(combinedMapSheets)));
      showShoppingCartNotification(dispatch, shoppingCart, SOME_MAP_SHEETS_IN_CART);
    } else {
      dispatch(updateMapSheetAmount(uniq(combinedMapSheets)));
      showShoppingCartNotification(dispatch, shoppingCart, CART_ITEM_ADDED);
    }
  }
};

const isProductAlreadyInCart = (cart, productToAdd) => {
  const toProductWithoutLocalizedProperties = product => omit(product, ['description', 'name']);
  const productWithoutLocalization = toProductWithoutLocalizedProperties(productToAdd);

  return cart.find(item => isEqual(toProductWithoutLocalizedProperties(item.product), productWithoutLocalization));
};

export const addOrUpdateProductThunk = (id, product, price, amount) => (dispatch, getState) => {
  const { shoppingCart } = getState();
  const { authentication } = getState();
  if (shoppingCart.sessionId !== authentication.sessionId) {
    if (shoppingCart.sessionId === undefined) {
      dispatch(updateSessionId(authentication.sessionId));
    } else {
      dispatch(clearCart());
      dispatch(addErrorNotificationAction(t('app.general.error')));
      dispatch(accessibleNotificationAdded({ text: t('app.general.error'), type: ASSERTIVE }));
      return;
    }
  }

  if (isLaser5pProduct(product.name) && product.areaSelectionType === AREA_SELECTION_TYPES.SELECT_SHEETS) {
    addLaser5pMapsheetsToCart(dispatch, id, product, price, shoppingCart);
  } else if (product.productType === 'fileDownload' && !isLaser5pProduct(product.name)) {
    if (product.municipality && hasMaximumNumberOfMunicipalityProductsInCart(shoppingCart)) {
      showShoppingCartNotification(dispatch, shoppingCart, MAX_NUMBER_OF_MUNICIPALITIES_IN_CART);
      trackTooManyMunicipalitiesInCart();
    } else if (isProductAlreadyInCart(shoppingCart.items, product)) {
      showShoppingCartNotification(dispatch, shoppingCart, ITEM_ALREADY_IN_CART);
    } else {
      dispatch(addProductToCart(id, product, price, amount));
      showShoppingCartNotification(dispatch, shoppingCart, CART_ITEM_ADDED);
    }
  } else if (isCertificateType(product.productType)) {
    addCertificateToCart(dispatch, id, product, price, shoppingCart);
  } else {
    dispatch(
      addProductToCart(
        id,
        product,
        price,
        parseInt(amount, 10) + getProductTotalAmountFromShoppingCart(id, shoppingCart.items)
      )
    );
    showShoppingCartNotification(dispatch, shoppingCart, CART_ITEM_ADDED);
  }
};

export function removeProductFromCart(itemId) {
  return {
    type: types.REMOVE_PRODUCT_FROM_CART,
    itemId,
  };
}

export function updateAmount(itemId, amount) {
  return {
    type: types.UPDATE_AMOUNT,
    itemId,
    amount,
  };
}

export function clearCart() {
  return {
    type: types.CLEAR_CART,
  };
}
