export const ADD_SELECTED_MAP_SHEETS = 'ADD_SELECTED_MAP_SHEETS';
export const REMOVE_SELECTED_MAP_SHEET = 'REMOVE_SELECTED_MAP_SHEET';
export const SET_FILE_PRODUCT_PARAMETERS = 'SET_FILE_PRODUCT_PARAMETERS';
export const SET_FILE_PRODUCT_PARAMETERS_ERROR = 'SET_FILE_PRODUCT_PARAMETERS_ERROR';
export const SET_FILE_PRODUCT_PARAMETERS_LOADED = 'SET_FILE_PRODUCT_PARAMETERS_LOADED';
export const SET_SELECTED_MAP_SHEETS = 'SET_SELECTED_MAP_SHEETS';
export const SET_MAP_SHEET_LAYER = 'SET_MAP_SHEET_LAYER';
export const SET_ACTIVE_AREA_SELECTION = 'SET_ACTIVE_AREA_SELECTION';
export const SET_ADMINISTRATIVE_AREA_YEARS = 'SET_ADMINISTRATIVE_AREA_YEARS';
export const SET_LASER5P_PRODUCTION_AREAS = 'SET_LASER5P_PRODUCTION_AREAS';
export const SET_SELECTED_PRODUCTION_AREA = 'SET_SELECTED_PRODUCTION_AREA';
export const SET_LASER5P_PRODUCTION_AREA_MAP_SHEETS_LOADING = 'SET_LASER5P_PRODUCTION_AREA_MAP_SHEETS_LOADING';
