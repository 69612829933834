export const LAYER_MAP_SELECTION = 'layer_map_selection';
export const LAYER_CUSTOM_MARKERS = 'layer_custom_markers';
export const LAYER_MEASUREMENTS = 'layer_measurements';
export const LAYER_PLACE_NAMES = 'layer_place_names';
export const LAYER_REAL_ESTATE_SEARCH = 'layer_real_estate_search';
export const LAYER_ADDRESSES = 'layer_addresses';
export const LAYER_BENCHMARKS = 'layer_benchmarks';
export const LAYER_PROPERTY_TRANSACTIONS = 'layer_property_transactions';
export const LAYER_APARTMENTS = 'layer_apartments';
export const LAYER_BUILDINGS = 'layer_buildings';
export const LAYER_FACILITIES = 'layer_facilities';
export const LAYER_USUFRUCTS = 'layer_usufructs';
export const LAYER_PROPERTIES = 'layer_properties';
export const LAYER_REAL_ESTATE_IDS = 'layer_real_estate_ids';
export const LAYER_BOUNDARY_MARKERS = 'layer_boundary_markers';
export const LAYER_REAL_ESTATE_BORDERS = 'layer_real_estate_borders';
export const LAYER_3D_ABOVE = 'layer_3d_above';
export const LAYER_PARCELS = 'layer_parcels';
export const LAYER_3D_BELOW = 'layer_3d_below';
export const LAYER_BUILDING_PROHIBITIONS = 'layer_building_prohibitions';
export const LAYER_DETAILED_PLAN_AREAS = 'layer_detailed_plan_areas';
export const LAYER_MAP_SHEETS = 'layer_map_sheets';
export const LAYER_SELECTED_MAP_SHEET = 'layer_selected_map_sheets';
export const LAYER_ORTHOPHOTO_SHEETS = 'layer_orthophoto_sheets';
export const LAYER_3D_BUILDINGS_SHEETS = 'layer_3d_buildings_sheets';
export const LAYER_MAP_SHEET_AREAS = 'layer_map_sheet_areas';
export const LAYER_LASER5P_PRODUCTION_AREAS = 'layer_laser5p_production_areas';
export const LAYER_LASER5P_MAP_SHEET_AVAILABILITY = 'layer_laser5p_map_sheet_availability';

// Layer to put stuff if layer id is missing. Use something from above instead of this.
export const LAYER_TEMPORARY_MARKERS = 'layer_temporary_markers';
