import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { LAYOUT_BREAKPOINT, STACKED_LAYOUT, COLUMN_LAYOUT } from 'common/constants/Layout';
import { getWindowDimensions } from './layoutHelpers';

const THROTTLE_TIME = 500;

const LayoutListener = ({ layout, onWindowResize }) => {
  const updateDimensions = throttle(() => {
    const dims = getWindowDimensions();
    const newLayout = dims.width < LAYOUT_BREAKPOINT ? STACKED_LAYOUT : COLUMN_LAYOUT;
    if (newLayout !== layout) {
      onWindowResize(newLayout, dims);
    }
  }, THROTTLE_TIME);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [updateDimensions]);

  return null;
};

LayoutListener.propTypes = {
  layout: PropTypes.string.isRequired,
  onWindowResize: PropTypes.func.isRequired,
};

export default LayoutListener;
