import React, { Suspense, lazy } from 'react';

import usePageTitle from 'common/hooks/usePageTitle';
import Footer from 'mapSite/containers/Footer/MapSiteFooter';
import { LoaderSpinner } from 'common/components/index';
import BusinessPanelComponent from 'common/containers/BusinessPanel/BusinessPanelComponent';

const PaymentCancel = lazy(() =>
  import('common/containers/ShoppingCart/ConfirmationComponents/PaymentCancelComponent')
);

function PaymentCancelPage() {
  usePageTitle({
    title: 'shoppingCart.checkout.cancel.documentTitle',
    template: 'app.documentTitle.template',
    trackPageView: false,
  });

  return (
    <BusinessPanelComponent viewSize="full">
      <Suspense fallback={<LoaderSpinner size="s" className="full-panel-loader margin-t-2" />}>
        <PaymentCancel>
          <Footer />
        </PaymentCancel>
      </Suspense>
    </BusinessPanelComponent>
  );
}

export default PaymentCancelPage;
