import { DISABLE_ALL_MAP_TOOLS, DISABLE_MAP_TOOLS } from './MapToolbarActionTypes';
import { allMapTools, cleanMeasurementsThunk } from './MapToolbarActions';
import { MAP_TOOL_ADDRESS, MAP_TOOL_DISTANCE } from './MapToolNames';
import { turnCustomMarkerOff } from './CustomMarkerActions';
import { turnAddressOff } from './AddressActions';

export const disableAllMapTools = disable => ({ type: DISABLE_ALL_MAP_TOOLS, disable });

const disableAllMapToolsThunk = disable => dispatch => {
  if (disable) {
    dispatch(cleanMeasurementsThunk());
    turnCustomMarkerOff();
    turnAddressOff();
  }

  dispatch(disableAllMapTools(disable));
};

export const disableMapTools = (toolsToDisable, disable) => ({
  type: DISABLE_MAP_TOOLS,
  toolsToDisable,
  disable,
});

export const disableMapToolsThunk = (toolsToDisable, disable) => dispatch => {
  toolsToDisable.forEach(tool => dispatch(allMapTools[tool](false)));

  if (toolsToDisable.includes(MAP_TOOL_ADDRESS) || toolsToDisable.includes(MAP_TOOL_DISTANCE))
    dispatch(cleanMeasurementsThunk());

  dispatch(disableMapTools(toolsToDisable, disable));
};

export default disableAllMapToolsThunk;
