import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Glyphicon } from 'common/components/Icon';
import Modal from 'common/components/Modal';
import { FlexGrid, Row, Block } from 'common/components/Grid';

const ServiceClosedModal = ({ isOpen, close }) => (
  <Modal warn isOpen={isOpen} title={t('serviceclosed.title')} closeHandler={close}>
    <FlexGrid className="text-center">
      <Row>
        <Block size="6">
          <Glyphicon glyph="huomio" className="service-closed-icon margin-t-3" />
          <h2 className="margin-t-4 margin-b-3">
            <a
              onClick={() => {
                close();
                return true;
              }}
              href={window.location.href}
            >
              {t('serviceclosed.notice')}
            </a>
          </h2>
        </Block>
      </Row>
    </FlexGrid>
  </Modal>
);

ServiceClosedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default ServiceClosedModal;
