import * as types from './AutomaticKeyboardFocusActionTypes';

export function requestKeyboardFocusAction({ selector } = {}) {
  return {
    type: types.REQUEST_KEYBOARD_FOCUS,
    selector,
  };
}

export function clearKeyboardFocusRequestAction() {
  return {
    type: types.CLEAR_KEYBOARD_FOCUS_REQUEST,
  };
}
