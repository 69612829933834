import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';

import { enterOrSpacePressed, enterPressed } from 'common/helpers/A11y/keyPressDetectors';
import { Glyphicon } from 'common/components/Icon';

const noop = () => null;

const SearchBar = React.forwardRef(
  (
    {
      error,
      id,
      ariaDescribedby,
      maxLength,
      onChange,
      onReset,
      onSubmit,
      placeholder,
      value,
      title,
      noSearchOnBlur,
      name,
      required,
    },
    ref
  ) => {
    const { t } = useTranslation();
    // allow only one call to onSubmit so triggering onSubmit through the various means (click, enter-press, blur) does not trigger multiple submits
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedOnSubmit = useCallback(
      throttle(e => onSubmit(e), 2000, { leading: true, trailing: false }),
      [value]
    );
    return (
      <div
        className={cls('search__wrapper', 'search__bar', {
          'search--active': value,
        })}
      >
        <input
          ref={ref}
          name={name}
          id={id}
          aria-describedby={ariaDescribedby}
          value={value}
          placeholder={placeholder}
          type="search"
          autoComplete="off"
          className={cls('search-bar__input', { 'error-on-search': error })}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={!noSearchOnBlur && value ? memoizedOnSubmit : noop}
          {...(required ? { 'aria-required': true } : {})}
          onKeyDown={enterPressed(e => {
            e.preventDefault();
            if (!value) return;
            memoizedOnSubmit(e);
          })}
        />
        <button
          title={title || t('search.searchbar.button.label')}
          aria-label={title || t('search.searchbar.button.label')}
          className="button button--icon search-bar__submit"
          onClick={value ? memoizedOnSubmit : noop}
          type="button"
        >
          <Glyphicon glyph="hae" />
        </button>
        <span
          role="button"
          aria-label={t('search.searchbar.clearButton.label')}
          title={t('search.searchbar.clearButton.label')}
          tabIndex="-1"
          className={cls('button', 'button--icon', 'search-bar__reset')}
          onClick={() => onReset()}
          onKeyDown={enterOrSpacePressed(onReset)}
          onMouseDown={e => e.preventDefault()} // so above input.onBlur does not trigger
        >
          <Glyphicon glyph="sulje-pallo-outline" />
        </span>
      </div>
    );
  }
);

SearchBar.propTypes = {
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  ariaDescribedby: PropTypes.string,
  maxLength: PropTypes.number,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  noSearchOnBlur: PropTypes.bool,
  required: PropTypes.bool,
};

SearchBar.defaultProps = {
  onChange: noop,
  onReset: noop,
  onSubmit: noop,
  value: '',
  id: null,
  ariaDescribedby: null,
  maxLength: 1000,
  error: false,
  placeholder: '',
  title: '',
  noSearchOnBlur: false,
  required: false,
};

export default SearchBar;
