import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Paragraph = ({ className, children }) => <p className={cls('nls-paragraph', className)}>{children}</p>;

Paragraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Paragraph.defaultProps = {
  className: '',
  children: null,
};

export default Paragraph;
