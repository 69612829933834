/* eslint-disable compat/compat */
import React, { startTransition, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { ScrollerPanelProvider } from './ScrollerPanelContext';

const ScrollerPanel = ({ activeColumn, fullWidth, children }) => {
  const isMapSite = useSelector(state => state.application.applicationContext) === 'mapSite';
  const [scrollerHeight, setScrollerHeight] = useState(0);
  const scrollerRef = useRef(null);

  const [resizeObserver] = useState(() => {
    return typeof ResizeObserver !== 'undefined'
      ? new ResizeObserver(([entry]) =>
          startTransition(() => {
            if (
              entry.contentRect?.height != null &&
              typeof entry.contentRect.height === 'number' &&
              Math.abs(entry.contentRect.height - scrollerHeight) > 2
            ) {
              setScrollerHeight(entry.contentRect.height);
            }
          })
        )
      : null;
  });

  useEffect(() => {
    if (!isMapSite && !fullWidth && scrollerRef.current && resizeObserver != null) {
      resizeObserver.observe(scrollerRef.current);
    }

    return () => {
      if (resizeObserver != null) resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="scroller-container">
      <ScrollerPanelProvider activeColumnNumber={activeColumn} fullWidth={fullWidth} scrollerHeight={scrollerHeight}>
        <div ref={scrollerRef} className={cls('scroller', `column-${activeColumn}`, { 'full-width': fullWidth })}>
          {children}
        </div>
      </ScrollerPanelProvider>
    </div>
  );
};

ScrollerPanel.propTypes = {
  activeColumn: PropTypes.number.isRequired,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ScrollerPanel.defaultProps = {
  fullWidth: false,
};

export default ScrollerPanel;
