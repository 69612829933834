import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearKeyboardFocusRequestAction } from './AutomaticKeyboardFocusActions';

const useAutomaticKeyboardFocus = (focusTitle, titleRef) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (focusTitle && !window.Cypress) {
      setTimeout(() => {
        titleRef?.current?.focus();
        dispatch(clearKeyboardFocusRequestAction());
      }, 500); // We need to wait for the scroller animation to finish.
    }
  }, [titleRef, focusTitle, dispatch]);
};

export default useAutomaticKeyboardFocus;
