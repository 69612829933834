// app starter App modes (ordered list):
export const APP_MODE_NORMAL = '';
export const APP_MODE_CUSTOM_MAPS = 'APP_MODE_CUSTOM_MAPS';
export const APP_MODE_CUSTOM_MAPS_LAYERS = [
  {
    id: 2,
    name: 'Maastokartta',
    visible: true,
    opacity: 70,
  },
];

export const START_PHASE_INIT = 0; // REDUX READY
export const START_PHASE_PRERENDER_READY = 1;
export const START_PHASE_FIRST_RENDER_READY = 2;
export const START_PHASE_CHANNEL_READY = 3;
export const START_PHASE_POST_RENDER_READY = 4;
export const START_PHASE_UI_VISIBLE = 5;
export const START_PHASE_BG_TASKS_READY = 6;

export const START_PHASE_DESCRIPTORS = [
  'Käynnistysvirhe vaiheessa: init',
  'Käynnistysvirhe vaiheessa: prerender ready',
  'Käynnistysvirhe vaiheessa: first render ready',
  'Käynnistysvirhe vaiheessa: channel ready',
  'Käynnistysvirhe vaiheessa: post render ready',
  'Käynnistysvirhe vaiheessa: ui visible',
  'Käynnistysvirhe vaiheessa: bt tasks ready',
];
