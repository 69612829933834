import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useLangQueryParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get('lang');

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (!lang) {
      searchParams.set('lang', language);
      setSearchParams(searchParams, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, lang]);
};

export default useLangQueryParam;
