import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { shareCloseAction } from './ShareActions';
import ShareComponent from './ShareComponent';

function mapStateToProps(state) {
  return {
    url: state.share.url,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: bindActionCreators(shareCloseAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareComponent);
