import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon } from 'common/components/Icon';
import { t } from 'i18next';
import cls from 'classnames';

export const QuantityButtonTemplate = ({ onClick, glyph, text, ariaLabel, className }) => (
  <button type="button" onClick={onClick} className={cls('button__quantity', className)} aria-label={ariaLabel}>
    <Glyphicon glyph={glyph} />
    {text}
  </button>
);

QuantityButtonTemplate.propTypes = {
  onClick: PropTypes.func.isRequired,
  glyph: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
};

QuantityButtonTemplate.defaultProps = {
  className: null,
};

export const RemoveButton = ({ onClick, className, text }) => (
  <QuantityButtonTemplate glyph="poista" onClick={onClick} text={text} ariaLabel={text} className={className} />
);

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

RemoveButton.defaultProps = {
  className: null,
};

export const AddButton = ({ onClick, className, text }) => (
  <QuantityButtonTemplate glyph="lisaa" onClick={onClick} text={t(text)} ariaLabel={t(text)} className={className} />
);

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
};

AddButton.defaultProps = {
  className: null,
  text: 'button.add',
};
