import React, { Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from 'mapSite/containers/Footer/MapSiteFooter';
import BusinessPanelComponent from 'common/containers/BusinessPanel/BusinessPanelComponent';
import { LoaderSpinner } from 'common/components';

const Checkout = lazy(() => import('common/containers/ShoppingCart/Checkout/CheckoutComponent'));

function CheckoutPage() {
  const navigate = useNavigate();
  return (
    <BusinessPanelComponent viewSize="full">
      <Suspense fallback={<LoaderSpinner size="s" className="full-panel-loader margin-t-2" />}>
        <Checkout navigate={navigate}>
          <Footer />
        </Checkout>
      </Suspense>
    </BusinessPanelComponent>
  );
}

export default CheckoutPage;
