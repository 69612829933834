import React from 'react';
import PropTypes from 'prop-types';

import { ScrollerColumn } from 'common/components';
import MapSiteIndex from 'mapSite/components/MapSiteIndex/MapSiteIndexComponent';
import usePageTitle from 'common/hooks/usePageTitle';

const FrontPage = ({ hidden }) => {
  usePageTitle({ title: 'businessPanel.index.documentTitle', template: 'app.documentTitle.template' });

  return (
    <ScrollerColumn columnNumber={1}>
      <MapSiteIndex hidden={hidden} />
    </ScrollerColumn>
  );
};

FrontPage.propTypes = {
  hidden: PropTypes.bool,
};

FrontPage.defaultProps = {
  hidden: false,
};

export default FrontPage;
