import { getLastNotification } from 'common/api/CommonApi';
import { addMmlBulletinNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';
import i18next from 'i18next';
import { isBefore, isAfter } from 'utils/datetime-utils';
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from 'common/helpers/storage';
import { BULLETIN_NOTIFICATION_READ_TIME, BULLETIN_NOTIFICATION_STORAGE_KEY } from 'common/constants/LocalStorageKeys';

// last notification received -> date from rss feed
export function setLastNotificationDate(date) {
  setToLocalStorage(BULLETIN_NOTIFICATION_STORAGE_KEY, date, i18next.language);
}

// when user has clicked the close button from the bulletin notification:
export function setNotificationReadTime(date) {
  setToLocalStorage(BULLETIN_NOTIFICATION_READ_TIME, date, i18next.language);
}

const removeNotificationReadTime = () => removeFromLocalStorage(BULLETIN_NOTIFICATION_READ_TIME, i18next.language);

const currentNotificationIsClicked = currentNotificationDate => {
  // if never clicked, check if current notification is older than read time
  if (getFromLocalStorage(BULLETIN_NOTIFICATION_READ_TIME, i18next.language)) {
    return isBefore(
      new Date(getFromLocalStorage(BULLETIN_NOTIFICATION_READ_TIME, i18next.language)),
      new Date(currentNotificationDate)
    );
  }
  return false;
};

const currentNotificationIsNew = (previousMoment, currentMoment) =>
  !previousMoment ? true : isAfter(new Date(currentMoment), new Date(previousMoment));

const isShowNotification = currentNotificationDate =>
  !currentNotificationIsClicked(currentNotificationDate) &&
  currentNotificationIsNew(
    getFromLocalStorage(BULLETIN_NOTIFICATION_STORAGE_KEY, i18next.language),
    currentNotificationDate
  );

const currentNotificationisReadWithLanguage = () =>
  getFromLocalStorage(BULLETIN_NOTIFICATION_READ_TIME, i18next.language) !== null;

export function getLastNotificationThunk(isLanguageChange = false) {
  return dispatch => {
    getLastNotification()
      .then(data => {
        if (data) {
          const dataLocalized = data[i18next.language];
          if (dataLocalized) {
            const { title, link, date } = dataLocalized;
            if (isShowNotification(date) || (isLanguageChange && !currentNotificationisReadWithLanguage())) {
              return new Promise(resolve => {
                removeNotificationReadTime();
                setLastNotificationDate(date);
                dispatch(addMmlBulletinNotificationAction(title, date, link));
                resolve();
              });
            }
            return Promise.resolve();
          }
        }
        return Promise.resolve();
      })
      .catch(error => {
        console.error('Fetching notifications failed', error);
      });
  };
}
