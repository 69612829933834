import SAVE_CONFIGURATIONS from './ConfigurationsActionTypes';

const initialState = {
  configurations: {},
  features: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_CONFIGURATIONS:
      return action.conf;
    default:
      return state;
  }
}
