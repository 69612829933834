import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isMobile } from 'common/constants/Layout';
import ScrollerPanel from './ScrollerPanel';

const resolveActiveColumnDefault = pathname => pathname.split('/').filter(Boolean).length;

const ScrollerWrapper = ({ components, fullWidth, resolveActiveColumnCustom }) => {
  const { pathname } = useLocation();
  const { item, secondItem, thirdItem } = useParams();
  const onMobile = useSelector(state => isMobile(state.layout?.mode));
  const resolveActiveColumn = resolveActiveColumnCustom || resolveActiveColumnDefault;

  // nasty repetition/duplication for compatibility, TODO: cleanup
  const memoizedRouterProps = useMemo(() => {
    return { params: { item, secondItem, thirdItem }, routeParams: { item }, route: { path: pathname } };
  }, [item, pathname, secondItem, thirdItem]);

  const renderedComponents = components.map(compItem => {
    const Component = compItem.component;
    // relevant only for mobile, active column is the column with biggest columnNumber
    const activeColumn = components.length;
    return (
      <Component
        key={compItem.columnNumber}
        columnNumber={compItem.columnNumber}
        isRealEstateProperty={compItem.isRealEstateProperty}
        isLocationRealEstate={compItem.isLocationRealEstate}
        hidden={onMobile || fullWidth ? compItem.columnNumber !== activeColumn : compItem.hidden}
        realtyNumber={compItem?.realtyNumber}
        {...(compItem.needsRouterProps ? memoizedRouterProps : null)}
      />
    );
  });
  return (
    <ScrollerPanel activeColumn={resolveActiveColumn(pathname)} fullWidth={fullWidth || onMobile}>
      {renderedComponents}
    </ScrollerPanel>
  );
};

ScrollerWrapper.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      columnNumber: PropTypes.number.isRequired,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
      hidden: PropTypes.bool,
      needsRouterProps: PropTypes.bool,
    })
  ).isRequired,
  fullWidth: PropTypes.bool,
  resolveActiveColumnCustom: PropTypes.func,
};

ScrollerWrapper.defaultProps = {
  fullWidth: false,
  resolveActiveColumnCustom: null,
};

export default ScrollerWrapper;
