export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const RERENDER_STICKY_FOOTER = 'RERENDER_STICKY_FOOTER';
export const USE_SESSION = 'USE_SESSION';
export const UPDATE_SHOW_CURTAIN = 'UPDATE_SHOW_CURTAIN';
export const CLOSE_MAPTOOLS_SIDEBAR = 'CLOSE_SIDE_PANEL';
export const OPEN_MAPTOOLS_SIDEBAR = 'OPEN_SIDE_PANEL';
export const TOGGLE_MAPTOOLS_SIDEBAR = 'TOGGLE_MAPTOOLS_SIDEBAR';
export const UPDATE_OSKARI_CHANNEL_STATUS = 'UPDATE_OSKARI_CHANNEL_STATUS';
