import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { Block } from 'common/components/Grid';
import { getLastNotificationThunk } from 'common/containers/MllBulletin/MllBulletinActions';
import { hideNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';

const KARTTAPAIKKA_LANGUAGES = ['fi', 'sv', 'en'];
export const ASIOINTI_LANGUAGES = ['fi', 'sv'];

const getAppNotificationIds = createSelector(
  state => state.appNotifications,
  appNotifications => appNotifications?.map(notification => notification.id)
);

const LanguageSelector = ({ className }) => {
  const isMapSite = useSelector(state => state.application.applicationContext) === 'mapSite';
  const appNotificationIds = useSelector(getAppNotificationIds);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const changeLanguage = async lng => {
    i18n.changeLanguage(lng);
    searchParams.set('lang', lng);
    setSearchParams(searchParams, { replace: true });
    if (!isEmpty(appNotificationIds)) await appNotificationIds.forEach(id => dispatch(hideNotificationAction(id)));
    dispatch(getLastNotificationThunk(true));
  };
  const languages = isMapSite ? KARTTAPAIKKA_LANGUAGES : ASIOINTI_LANGUAGES;

  useEffect(() => {
    const localStorageLang = localStorage.getItem('i18nextLng');
    if (!isMapSite && !ASIOINTI_LANGUAGES.includes(localStorageLang)) {
      i18n.changeLanguage('fi');
    }
  }, [i18n, isMapSite, language]);

  return (
    <div className={className}>
      <Block className={cls('user-menu__language-selector', { 'mapSite-selector': isMapSite })}>
        {languages.map((lang, index) => (
          <div key={`lang-link-${lang}`}>
            <button
              tabIndex={language === lang ? -1 : 0}
              title={t(`app.language.${lang}`)}
              type="button"
              className={cls('language-selector__button', { active: language === lang })}
              onClick={() => changeLanguage(lang)}
              data-cy={`language-selector__button-${lang}`}
            >
              {lang.toUpperCase()}
            </button>
            {index !== languages.length - 1 && <span className="language-selector__slash">/</span>}
          </div>
        ))}
      </Block>
    </div>
  );
};

LanguageSelector.propTypes = {
  className: PropTypes.string,
};

LanguageSelector.defaultProps = {
  className: '',
};

export default LanguageSelector;
