import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Routes from 'common/constants/Routes';
import { logoutThunk } from 'common/helpers/Authentication/authenticationSlice';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import { CHECKOUT } from 'common/containers/Authorization/privileges';
import { UserMenu } from 'common/components';
import getCartCount from 'common/containers/UserMenu/cartItemCounter';
import { isMobile } from 'common/constants/Layout';

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    rootUrl: Routes.getRoute(),
    openBubble: state.shoppingCart.notificationBubble.isOpen,
    onMobile: isMobile(state.layout.mode),
    showCartButton: isAuthorized([CHECKOUT]),
    cartItemCount: getCartCount(state.shoppingCart.items),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logoutHandler: bindActionCreators(logoutThunk, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
