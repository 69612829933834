import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isAuthorized } from '../../containers/Authorization/AuthorizationComponent';

const ProtectedRoute = ({ roles, redirectURL, children }) => {
  useEffect(() => {
    const channel = new BroadcastChannel('logout-channel');
    channel.addEventListener('message', event => {
      if (event.data === 'user-logged-out') {
        window.location.replace(redirectURL);
      }
    });

    return () => {
      channel.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthorized(roles)) {
      window.location.replace(redirectURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectURL]);

  return isAuthorized(roles) ? children : null;
};

ProtectedRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  redirectURL: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
