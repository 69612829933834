/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { difference, isEmpty, uniqBy } from 'lodash';
import pLimit from 'p-limit';

import {
  clearMapRealestatesLayers,
  drawGeoJson,
  drawRealEstates,
  hideInfobox,
  mapIsReady,
  OSKARI_CHANNEL_STATUS_IDS,
} from 'oskari/OskariMap';
import { toggleLoadingAnimationThunk } from 'common/containers/OskariMap/OskariMapActions';
import { resetRealEstateSiteFeaturesRemoved, setInfobox } from 'common/geometries/geometriesActions';
import {
  clearMapServiceLayers,
  removeFeaturesFromMap,
  zoomToServiceFeatures,
} from 'oskari/oskariModules/ServicesMapModule';
import { FACILITY_TYPE } from 'realEstateSite/constants/RealEstate';
import ColorProducer from 'oskari/ColorProducer';

import { fetchGeometries as fetchGeometriesFromAPI } from 'common/api/CommonApi';
import * as GeometriesConstants from './geometriesConstants';
import {
  combineGeometries,
  findGeometryFromState,
  getCorrectGeometriesForType,
  isMissingGeometry,
  parseGeometryRequest,
  simplifyGeometry,
} from './geometriesHelpers';
import { setGeometryRetrievals } from './geometryRetrievals/geometryRetrievalsActions';
import { FAILURE, PENDING, SUCCESS } from './geometryRetrievals/geometryRetrievalsConstants';
import {
  LAYER_OPTIONS,
  toFeatureCollection,
} from '../../RealEstate/UsufructsAndRestrictions/UsufructUnits/UsufructUnitsActions';
import { getEarliestRegisterStateDate, setRegisterState } from '../../RealEstate/RealEstateList/RegisterStateActions';
import {
  LAYER_APARTMENTS,
  LAYER_BOUNDARY_MARKERS,
  LAYER_PARCELS,
  LAYER_REAL_ESTATE_BORDERS,
  LAYER_REAL_ESTATE_IDS,
} from '../../../../../oskari/layers/VectorLayers';

const addOrUpdateGeometries = (previousState, action) => {
  const idsToAdd = action.map(({ id }) => id);
  const filteredState = previousState.filter(id => !idsToAdd.includes(id));
  return [...filteredState, ...idsToAdd];
};

const geometriesSlice = createSlice({
  name: 'geometry',
  initialState: {
    own: [],
    selected: [],
    searched: [],
    other: [],
    activityLog: [],
    usufruct: [],
    jpus: [],
    rootRealty: [],
    lca: [],
    deathEstateLca: [],
    partitioningLca: [],
    lcag: [],
    lta: [],
    lra: [],
    osra: [],
    prs: [],
    bd: [],
    es: [],
    ocs: [],
    pss: [],
    ras: [],
    aor: [],
    reta: [],
    neighbourRealEstates: [],
    geometriesLoaded: false,
    ownGeometriesThatAreLoaded: [],
    geometriesById: {},
    simplifiedGeometriesById: {},
    missingGeometries: [],
    shownOnMap: {},
  },
  reducers: {
    realtyGeometryStored(state, action) {
      state.geometriesById = {
        ...state.geometriesById,
        ...action.payload.reduce((acc, geometry) => ({ ...acc, [geometry.id]: geometry }), {}),
      };
    },
    simplifiedGeometryStored(state, action) {
      state.simplifiedGeometriesById = {
        ...state.simplifiedGeometriesById,
        ...{ [action.payload.id]: action.payload },
      };
    },
    shownOnMapUpdated(state, action) {
      const { realtyId, shownOnMap } = action.payload;
      const realty = { [realtyId]: shownOnMap };
      state.shownOnMap = { ...state.shownOnMap, ...realty };
    },
    shownOnMapResetted(state) {
      state.shownOnMap = {};
    },
    geometryAddedToType(state, action) {
      const { geometries, geometryType } = action.payload;
      state[geometryType] = addOrUpdateGeometries(state[geometryType] || [], geometries);
    },
    geometryTypeCleared(state, action) {
      state[action.payload] = [];
    },
    geometryRemovedFromType(state, action) {
      const { geometryType, realtyId } = action.payload;
      state[geometryType] = state[geometryType].filter(id => id !== realtyId);
    },
    geometriesReplaced(state, action) {
      const { geometryType, realtyId } = action.payload;
      state[geometryType] = [realtyId];
    },
    selectedGeometriesReplaced(state, action) {
      state[GeometriesConstants.SELECTED] = action.payload.map(({ id }) => id);
    },
    missingGeometriesAdded(state, action) {
      state.missingGeometries = [...(state.missingGeometries || []), ...action.payload];
    },
    geometriesLoaded(state, action) {
      state.geometriesLoaded = action.payload;
    },
    ownGeometriesLoaded(state, action) {
      state.ownGeometriesThatAreLoaded = [
        ...state.ownGeometriesThatAreLoaded,
        ...(!state.ownGeometriesThatAreLoaded.includes(action.payload) ? [action.payload] : []),
      ];
    },
    ownGeometriesResetted(state) {
      state.ownGeometriesThatAreLoaded = [];
      state.missingGeometries = [];
    },
  },
});

export const {
  realtyGeometryStored,
  simplifiedGeometryStored,
  shownOnMapUpdated,
  shownOnMapResetted,
  geometryAddedToType,
  geometryTypeCleared,
  geometryRemovedFromType,
  selectedGeometriesReplaced,
  geometriesReplaced,
  missingGeometriesAdded,
  geometriesLoaded,
  ownGeometriesLoaded,
  ownGeometriesResetted,
} = geometriesSlice.actions;

const REAL_ESTATES = 'REAL_ESTATES';
const APARTMENTS = 'APARTMENTS';

export function isAllOwnGeometriesLoaded(state) {
  return (
    state.geometry.ownGeometriesThatAreLoaded.includes(REAL_ESTATES) &&
    state.geometry.ownGeometriesThatAreLoaded.includes(APARTMENTS)
  );
}

function isChannelReady(state) {
  return state?.layout?.oskariChannelStatus === OSKARI_CHANNEL_STATUS_IDS.channelReady;
}

export function removeAllRETAGeometries() {
  return dispatch => {
    dispatch(geometryTypeCleared(GeometriesConstants.RETA));
    clearMapRealestatesLayers();
  };
}

function resolveLayer(featureName, featureType) {
  if (featureType === 'apartment' || featureType === 'parkingSpace' || featureType === 'otherApartment') {
    return LAYER_APARTMENTS;
  }
  if (featureName === 'rajamerkki') {
    return LAYER_BOUNDARY_MARKERS;
  }
  if (featureName === 'palsta.tunnus' || featureName === 'maaraalan_osa.tunnus') {
    return LAYER_REAL_ESTATE_IDS;
  }
  if (featureName === 'kiinteiston_raja') {
    return LAYER_REAL_ESTATE_BORDERS;
  }
  return LAYER_PARCELS;
}

function removeGeometry(realtyId, geometryType, removeFromType, zoomMap = true) {
  return (dispatch, getState) => {
    if (!isChannelReady(getState())) return;

    const realty = getState().geometry.geometriesById[realtyId];

    const features = realty?.features || [];

    const featureIdsWithLayers = features.map(f => ({
      id: f.id,
      layer: resolveLayer(f.properties.name, realty.type),
    }));

    removeFeaturesFromMap(featureIdsWithLayers);
    if (removeFromType) {
      dispatch(geometryRemovedFromType({ realtyId, geometryType }));
      dispatch(shownOnMapUpdated({ realtyId, shownOnMap: false }));
    }
    if (zoomMap) {
      dispatch(zoomToGeometriesThunk(geometryType));
    }
  };
}

export function removeGeometryFromType(realtyId, geometryType) {
  return removeGeometry(realtyId, geometryType, true);
}

export function removeGeometryFromMap(realtyId, geometryType, zoomMap = true) {
  return removeGeometry(realtyId, geometryType, false, zoomMap);
}

export function clearRealEstateLayers(clearUsufructUnits = false) {
  return dispatch => {
    dispatch(geometryTypeCleared(GeometriesConstants.SELECTED));
    dispatch(shownOnMapResetted());
    if (clearUsufructUnits) clearMapServiceLayers();
    else clearMapRealestatesLayers();
  };
}

const fetchRealtyGeometry = (
  dispatch,
  getState,
  geometryType,
  isApartment,
  drawGeometries,
  storePointsSeparately = false
) => async realty => {
  dispatch(setGeometryRetrievals([{ id: realty.id, status: PENDING }]));
  try {
    let realtyGeometry = findGeometryFromState(getState().geometry, realty.id);
    if (isEmpty(realtyGeometry)) {
      const { geometries, hasError } = await fetchGeometriesFromAPI(parseGeometryRequest([realty], isApartment));
      if (hasError) {
        throw new Error('Geometry API responded with error');
      }

      !isEmpty(geometries) &&
        dispatch(
          setRegisterState(getEarliestRegisterStateDate(geometries), getState()?.realEstateList?.registerStateDate)
        );
      realtyGeometry = combineGeometries(geometries).map(feature => ({
        ...feature,
        color: ColorProducer.getNextFillColor(),
      }));

      // Store first only points for complex geometry:
      if (storePointsSeparately) {
        const simplifiedGeometry = simplifyGeometry(Array.isArray(realtyGeometry) ? realtyGeometry[0] : realtyGeometry);
        if (simplifiedGeometry != null) {
          dispatch(simplifiedGeometryStored(simplifiedGeometry));
        }
      }

      dispatch(realtyGeometryStored(realtyGeometry));
    }

    dispatch(geometryAddedToType({ geometries: realtyGeometry, geometryType }));
    if (drawGeometries) dispatch(drawGeometriesThunk(geometryType));

    // If for some reason realty does not have geometry store realtyId to redux
    const missingGeometries = difference(
      [realty].map(({ id, shareGroupId }) => id || shareGroupId),
      realtyGeometry.map(({ id }) => id)
    );

    if (missingGeometries.length > 0) {
      dispatch(missingGeometriesAdded(missingGeometries));
    }

    dispatch(setGeometryRetrievals([{ id: realty.id, status: SUCCESS }]));
  } catch (GeometryError) {
    dispatch(setGeometryRetrievals([{ id: realty.id, status: FAILURE }]));
    throw GeometryError;
  }
};

export function fetchGeometries(
  realties,
  geometryType,
  isApartment = false,
  drawGeometries = true,
  storePointsSeparately = false
) {
  return async (dispatch, getState) => {
    if (isEmpty(realties)) {
      dispatch(ownGeometriesLoaded(isApartment ? APARTMENTS : REAL_ESTATES));
      return;
    }

    if (!isChannelReady(getState())) return;

    if (!isApartment && geometryType === GeometriesConstants.OWN && isAllOwnGeometriesLoaded(getState())) return;

    const filteredRealties = realties.filter(realty => !isMissingGeometry(getState().geometry, realty?.id));
    if (!isApartment && isEmpty(filteredRealties)) return;

    // Limit to max 3 concurrent geometry API requests.
    const limit = pLimit(3);
    const fetcher = fetchRealtyGeometry(
      dispatch,
      getState,
      geometryType,
      isApartment,
      drawGeometries,
      storePointsSeparately
    );

    dispatch(toggleLoadingAnimationThunk(true));
    dispatch(geometriesLoaded(false));

    try {
      await Promise.all(filteredRealties.filter(Boolean).map(realty => limit(() => fetcher(realty))));
    } catch (geometryError) {
      console.error(`Cannot fetch and render real estate geometries: ${geometryError}`);
      throw geometryError;
    } finally {
      dispatch(geometriesLoaded(true));
      dispatch(ownGeometriesLoaded(isApartment ? APARTMENTS : REAL_ESTATES));
      dispatch(toggleLoadingAnimationThunk(false));
    }
  };
}

function zoomToGeometries(geometries) {
  if (geometries.length > 0) {
    let featureIds = [];

    geometries.forEach(g => {
      featureIds = g.features ? featureIds.concat(g.features.map(f => f.id)) : featureIds;
    });

    setTimeout(() => zoomToServiceFeatures(featureIds), 1);
  }
}

export function focusOnRealEstate({ realEstate, geometryType = GeometriesConstants.SEARCHED }) {
  return (dispatch, getState) => {
    if (!isChannelReady(getState())) return;
    const allGeometries = getCorrectGeometriesForType(geometryType, getState().geometry);
    const realtyId = realEstate.type === FACILITY_TYPE ? realEstate.locations[0].id : realEstate.id;
    const geometries = allGeometries.filter(geo => geo.id === realtyId);
    zoomToGeometries(geometries);
  };
}

export function zoomToGeometriesThunk(geometryTypes, selectedRealtyId) {
  const isGeometryIncluded = geo =>
    Array.isArray(selectedRealtyId) ? selectedRealtyId.includes(geo.id) : geo.id === selectedRealtyId;
  return (dispatch, getState) => {
    if (!isChannelReady(getState())) return;
    if (geometryTypes === GeometriesConstants.ALL) {
      setTimeout(() => zoomToServiceFeatures(), 1);
      return;
    }
    const geometries = getCorrectGeometriesForType(geometryTypes, getState().geometry);
    const zoomedGeometries = selectedRealtyId ? geometries.filter(isGeometryIncluded) : geometries;
    zoomToGeometries(zoomedGeometries);
  };
}

export function drawGeometriesThunk(geometryType, simplifyGeometries = false) {
  return async (dispatch, getState) => {
    if (!isChannelReady(getState())) return;
    await mapIsReady();
    dispatch(toggleLoadingAnimationThunk(true));
    const geometryState = structuredClone(getState().geometry);
    const zoomMap = geometryType !== GeometriesConstants.NEIGHBOUR_REAL_ESTATES;
    const geometries = getCorrectGeometriesForType(geometryType, geometryState, simplifyGeometries);
    const realtyIds = geometries.length ? geometries.map(g => g.id) : [];
    const shownOnMapList = geometryState?.shownOnMap || [];
    const boundaryMarkerInfoBox = getState().geometries?.infoBox?.id;
    const realtiesToDraw = !isEmpty(shownOnMapList)
      ? geometries?.filter(geometry => shownOnMapList[geometry.id] !== true)
      : geometries;

    const realtiesToRemove = Object.keys(shownOnMapList)
      .filter(id => shownOnMapList[id] === true)
      .filter(r => !realtyIds.includes(r));

    if (!isEmpty(realtiesToRemove)) {
      // hide parcel infobox
      if (!Array.isArray(geometryType)) {
        realtiesToRemove.forEach(geometry => hideInfobox([geometry]));
      }
      realtiesToRemove.forEach(geometry => dispatch(removeGeometryFromMap(geometry, 'realtyGeometries', zoomMap)));
      realtiesToRemove.forEach(geometry => dispatch(shownOnMapUpdated({ realtyId: geometry, shownOnMap: false })));
    }

    if (!isEmpty(realtiesToDraw)) {
      // hide boundary marker infobox when something new is added to the map
      if (boundaryMarkerInfoBox) {
        hideInfobox([boundaryMarkerInfoBox]);
        dispatch(setInfobox([]));
      }
      drawRealEstates(realtiesToDraw);
      realtiesToDraw.forEach(geometry => dispatch(shownOnMapUpdated({ realtyId: geometry.id, shownOnMap: true })));
    }

    dispatch(toggleLoadingAnimationThunk(false));
    dispatch(resetRealEstateSiteFeaturesRemoved());
  };
}

export const drawReloadedGeometriesThunk = (geometryType, usufructUnitRealtyId, usufructUnitId) => async (
  dispatch,
  getState
) => {
  if (!isChannelReady(getState())) return;
  const shownOnMapList = getState().geometry?.shownOnMap || [];
  if (isEmpty(shownOnMapList) && usufructUnitRealtyId) return;

  await mapIsReady();
  dispatch(toggleLoadingAnimationThunk(true));
  const geometryState = structuredClone(getState().geometry);
  const geometries = getCorrectGeometriesForType(geometryType, geometryState);
  const realtiesToDraw = !isEmpty(shownOnMapList)
    ? geometries?.filter(geometry => shownOnMapList[geometry.id] === true)
    : geometries;
  const uniQRealties = uniqBy(realtiesToDraw, 'id');

  const [firstGeometry] = geometries;
  const {
    apartmentList: { apartments },
    realEstateList: { realEstates },
  } = getState();

  // Don't draw geometries even if they exist if there are no corresponding realties
  const geometriesHaveCorrespondingRealties =
    firstGeometry && !![...apartments, ...realEstates].find(realty => realty.id === firstGeometry.id);

  if (geometries.length < 1 || !geometriesHaveCorrespondingRealties) {
    dispatch(toggleLoadingAnimationThunk(false));
    return;
  }

  if (!isEmpty(uniQRealties)) {
    drawRealEstates(uniQRealties);
    uniQRealties.forEach(geometry => dispatch(shownOnMapUpdated({ realtyId: geometry.id, shownOnMap: true })));
  }

  const selectedUsufructUnitGeos = getState().usufructUnits?.units.find(({ id }) => id.toString() === usufructUnitId)
    ?.geometry;
  const usufructUnitGeos = getState().usufructUnits?.units.map(({ geometry }) => geometry);
  const { loadedForRealty } = getState().usufructUnits;

  if (!isEmpty(usufructUnitGeos) && loadedForRealty === usufructUnitRealtyId) {
    if (selectedUsufructUnitGeos) {
      selectedUsufructUnitGeos.features.forEach(feature => {
        drawGeoJson(feature.id, toFeatureCollection([feature]), LAYER_OPTIONS[feature.geometry.type]);
      });
    } else {
      usufructUnitGeos
        .flatMap(collection => collection.features)
        .forEach(feature => {
          drawGeoJson(feature.id, toFeatureCollection([feature]), LAYER_OPTIONS[feature.geometry.type]);
        });
    }
  }

  dispatch(toggleLoadingAnimationThunk(false));
  dispatch(resetRealEstateSiteFeaturesRemoved());
};

export default geometriesSlice.reducer;
