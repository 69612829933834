import { getOskariMapCadastralLayerIds } from 'oskari/OskariMap';
import { includes } from 'lodash';
import {
  CADASTRAL_WARNING_SHOWN,
  CHANGE_BASE_MAP_LAYER_OPACITY,
  CHANGE_BASE_MAP_LAYER_VISIBILITY,
  LAYERS,
  DISABLE_BASE_MAP_LAYER,
  ENABLE_ALL_BASE_MAP_LAYERS,
  DISABLE_ALL_LAYERS,
  DISABLE_ALL_OTHER_LAYERS,
} from './MapLayerSelectorActionTypes';

const initialState = [];

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CADASTRAL_WARNING_SHOWN:
      return state.map(layer =>
        includes(getOskariMapCadastralLayerIds(), layer.id) ? { ...layer, cadastralWarningShown: true } : layer
      );

    case CHANGE_BASE_MAP_LAYER_VISIBILITY:
      return state.map(layer => (layer.id === action.mapId ? { ...layer, visible: action.isVisible } : layer));
    case CHANGE_BASE_MAP_LAYER_OPACITY:
      return state.map(layer => (layer.id === action.mapId ? { ...layer, opacity: action.opacity } : layer));

    case LAYERS:
      return action.layers.map(layer => ({ ...layer, disabled: false }));

    case DISABLE_BASE_MAP_LAYER:
      return state.map(layer =>
        layer.id === action.mapId ? { ...layer, disabled: true, visible: action.leaveVisible } : layer
      );

    case ENABLE_ALL_BASE_MAP_LAYERS:
      return state.map(layer => ({ ...layer, disabled: false, visible: true }));

    case DISABLE_ALL_OTHER_LAYERS:
      return state.map(layer => ({ ...layer, disabled: layer.id !== action.layerId }));

    case DISABLE_ALL_LAYERS:
      return state.map(layer => ({ ...layer, disabled: true }));

    default:
      return state;
  }
}
