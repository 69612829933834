import React, { Suspense, lazy } from 'react';

import usePageTitle from 'common/hooks/usePageTitle';
import BusinessPanelComponent, {
  BusinessPanelSupportFooter,
} from 'common/containers/BusinessPanel/BusinessPanelComponent';
import { LoaderSpinner } from 'common/components/index';

const SessionExpiredComponent = lazy(() => import('../components/SessionExpiredComponent'));

function SessionExpiredPage() {
  usePageTitle({
    title: 'sessionExpired.title',
    template: 'app.documentTitle.template',
  });

  return (
    <BusinessPanelComponent viewSize="full">
      <Suspense fallback={<LoaderSpinner size="s" className="full-panel-loader margin-t-2" />}>
        <SessionExpiredComponent />
      </Suspense>
      <BusinessPanelSupportFooter />
    </BusinessPanelComponent>
  );
}

export default SessionExpiredPage;
