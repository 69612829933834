import React, { useId, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cls from 'classnames';
import Select, { createFilter } from 'react-select';
import makeAnimated from 'react-select/animated';
import NumberFormat from 'react-number-format';
import CheckBox from 'common/components/Checkbox';
import RadioButton from 'common/components/RadioButton';
import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'common/components/Icon';
import { enterPressed } from 'common/helpers/A11y/keyPressDetectors';
import INPUT_ERROR from 'common/constants/InputValidationConstants';
import { isDesktop } from 'common/constants/Layout';
import {
  defaultAriaLiveMessages,
  fieldHasErrorOnBlur,
  getSearchResults,
  handleOnChange,
  fieldHasErrorOnSubmit,
  validateNonEmptyValues,
} from './inputValidationHelpers';

const fieldInputPropTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  value: PropTypes.any,
  multiple: PropTypes.bool,
};

const fieldMetaPropTypes = {
  active: PropTypes.bool,
  data: PropTypes.object,
  dirty: PropTypes.bool,
  dirtySinceLastSubmit: PropTypes.bool,
  error: PropTypes.any,
  initial: PropTypes.any,
  invalid: PropTypes.bool,
  length: PropTypes.number,
  modified: PropTypes.bool,
  modifiedSinceLastSubmit: PropTypes.bool,
  pristine: PropTypes.bool,
  submitError: PropTypes.any,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  touched: PropTypes.bool,
  valid: PropTypes.bool,
  validating: PropTypes.bool,
  visited: PropTypes.bool,
};

export const ValidatedTextInput = React.forwardRef(
  (
    {
      input: { name, value, onChange, onBlur },
      id,
      placeholder,
      meta,
      className,
      maxLength,
      onChange: customOnChange,
      onBlur: customOnBlur,
      disabled,
      withErrorText,
      required,
      autoComplete,
    },
    ref
  ) => {
    const errorId = useId();

    return (
      <>
        {withErrorText && validateNonEmptyValues(meta, value) && meta.error !== INPUT_ERROR && (
          <ValidationErrorText id={errorId} errorText={meta.error} />
        )}
        <input
          id={id}
          name={name}
          value={value}
          autoComplete={autoComplete}
          placeholder={placeholder}
          type="text"
          className={cls('input-text', validateNonEmptyValues(meta, value) ? 'input-text--error' : null, className)}
          maxLength={maxLength}
          ref={ref}
          onChange={e => handleOnChange(e, onChange, customOnChange)}
          onBlur={() => {
            onBlur();
            if (customOnBlur) customOnBlur();
          }}
          onKeyDown={enterPressed(event => event.preventDefault())}
          disabled={disabled}
          aria-invalid={validateNonEmptyValues(meta, value)}
          {...(required ? { 'aria-required': true } : {})}
          aria-describedby={validateNonEmptyValues(meta, value) ? errorId : null}
        />
      </>
    );
  }
);

ValidatedTextInput.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  id: PropTypes.string,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  withErrorText: PropTypes.bool,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
};

ValidatedTextInput.defaultProps = {
  id: null,
  placeholder: '',
  className: '',
  maxLength: null,
  onChange: null,
  onBlur: null,
  disabled: false,
  withErrorText: false,
  required: false,
  autoComplete: 'one-time-code',
};

export const ValidatedValidationErrorText = props => {
  const { meta, errorId } = props;
  return meta.error ? <ValidationErrorText id={errorId} errorText={meta.error} /> : null;
};

ValidatedValidationErrorText.propTypes = {
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  errorId: PropTypes.string,
};

ValidatedValidationErrorText.defaultProps = {
  errorId: null,
};

export const ValidatedCheckbox = field => {
  const { className, meta, withErrorText } = field;
  const errorId = useId();

  return (
    <>
      <CheckBox
        {...field}
        aria-describedby={errorId}
        className={cls(className, fieldHasErrorOnSubmit(meta) && 'error')}
      />
      {withErrorText && fieldHasErrorOnSubmit(meta) && <ValidationErrorText id={errorId} errorText={meta.error} />}
    </>
  );
};

export const ValidatedRadioButton = React.forwardRef(({ ...field }, ref) => {
  const { className, meta, errorId } = field;
  return (
    <RadioButton
      {...field}
      ref={ref}
      ariaDescribedby={errorId}
      className={cls(className, { error: meta.submitFailed && meta.error })}
      dataCy={field.dataCy}
    />
  );
});

const animatedComponents = makeAnimated();

export const ValidatedSelect = ({
  id,
  meta,
  options,
  multi,
  input: { name, onChange, value },
  onCustomChange,
  clearable,
  disabled,
  isSearchable,
  filterOption,
  required,
  menuPlacement,
  ariaLabel,
  withErrorText,
  selectorPosition,
  title,
}) => {
  const { t } = useTranslation();
  const layoutMode = useSelector(state => state.layout.mode);
  const isPortal = selectorPosition && isDesktop(layoutMode);
  const selectRef = useRef();

  const portalStyles = {
    menu: base => ({
      ...base,
      minHeight: 0,
      maxHeight: '40vh',
    }),
    menuList: base => ({
      ...base,
      minHeight: 0,
      maxHeight: '40vh',
    }),
    menuPortal: base => ({
      ...base,
      left: selectorPosition.left || 0,
      top: selectorPosition.top || 0,
      position: 'absolute',
    }),
  };

  // We have to set the required like this for ReactSelect:
  if (required && selectRef?.current?.inputRef) {
    selectRef.current.inputRef.ariaRequired = required;
  }

  return (
    <>
      {withErrorText && fieldHasErrorOnBlur(meta) && meta.error !== INPUT_ERROR && (
        <ValidationErrorText errorText={meta.error} />
      )}
      {title && <strong className="inline-block margin-b-0-5">{title}</strong>}
      <Select
        inputId={id}
        name={name}
        components={animatedComponents}
        className={cls('select', fieldHasErrorOnBlur(meta) ? 'select--error' : null)}
        classNamePrefix="select"
        options={options}
        isMulti={multi}
        onChange={e => handleOnChange(e, onChange, onCustomChange)}
        menuPlacement={menuPlacement}
        // added to translate label on language change: ASI-8709 and to reset select values: ASI-8744
        value={multi ? value : options.find(option => option.value === value.value) || ''}
        placeholder={t('form.select.placeholder.choose.text')}
        noOptionsMessage={() => t('form.select.search.noResults.text')}
        isClearable={clearable}
        isSearchable={isSearchable}
        isDisabled={disabled}
        filterOption={filterOption && createFilter(filterOption)}
        aria-label={ariaLabel}
        aria-invalid={fieldHasErrorOnSubmit(meta)} // Should be set invalid only on submit!
        ariaLiveMessages={defaultAriaLiveMessages}
        screenReaderStatus={getSearchResults}
        menuPortalTarget={isPortal && document.querySelector('#maincontent')}
        styles={isPortal && portalStyles}
        ref={selectRef}
      />
    </>
  );
};

ValidatedSelect.propTypes = {
  id: PropTypes.string,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired || PropTypes.number.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  multi: PropTypes.bool,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  onCustomChange: PropTypes.func,
  clearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  disabled: PropTypes.bool,
  filterOption: PropTypes.shape({
    ignoreCase: PropTypes.bool,
    ignoreAccents: PropTypes.bool,
    trim: PropTypes.bool,
    stringify: PropTypes.func,
    matchFrom: PropTypes.string,
  }),
  required: PropTypes.bool,
  menuPlacement: PropTypes.string,
  ariaLabel: PropTypes.string,
  withErrorText: PropTypes.bool,
  selectorPosition: PropTypes.shape({
    id: PropTypes.string,
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  title: PropTypes.string,
};

ValidatedSelect.defaultProps = {
  id: null,
  multi: false,
  clearable: false,
  isSearchable: false,
  disabled: false,
  onCustomChange: null,
  filterOption: undefined,
  required: false,
  menuPlacement: undefined,
  ariaLabel: undefined,
  withErrorText: false,
  selectorPosition: undefined,
  title: undefined,
};

export const ValidatedTextArea = ({ input, id, rows, cols, meta, className, maxLength, withErrorText, required }) => {
  const errorId = useId();

  return (
    <>
      {withErrorText && validateNonEmptyValues(meta, input.value) && meta.error !== INPUT_ERROR && (
        <ValidationErrorText id={errorId} errorText={meta.error} />
      )}
      <textarea
        {...input}
        id={id}
        rows={rows}
        cols={cols}
        className={cls(validateNonEmptyValues(meta, input.value) && 'textarea--error', className)}
        maxLength={maxLength}
        aria-invalid={validateNonEmptyValues(meta, input.value)}
        {...(required ? { 'aria-required': true } : {})}
        aria-describedby={validateNonEmptyValues(meta, input.value) ? errorId : null}
      />
    </>
  );
};

ValidatedTextArea.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  id: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  withErrorText: PropTypes.bool,
  required: PropTypes.bool,
};

ValidatedTextArea.defaultProps = {
  id: null,
  rows: null,
  cols: null,
  className: '',
  maxLength: null,
  withErrorText: false,
  required: false,
};

export const ValidatedNumberFormatInput = ({
  input,
  meta,
  className,
  ariaDescribedby,
  allowDecimals,
  required,
  withErrorText,
  ...other
}) => {
  const errorId = useId();

  const isError = withErrorText && fieldHasErrorOnBlur(meta) && meta.error !== INPUT_ERROR;

  return (
    <>
      {isError && <ValidationErrorText id={errorId} errorText={meta.error} />}
      <NumberFormat
        {...other}
        aria-describedby={isError ? errorId : ariaDescribedby}
        {...(required ? { 'aria-required': true } : {})}
        value={input.value.formattedValue}
        name={input.name}
        onValueChange={e => handleOnChange(e, input.onChange)}
        className={cls('input-text', fieldHasErrorOnBlur(meta) ? 'input-text--error' : null, className)}
        thousandSeparator=" "
        decimalSeparator=","
        decimalScale={allowDecimals ? 2 : 0}
        allowNegative={false}
      />
    </>
  );
};

ValidatedNumberFormatInput.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  className: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  allowDecimals: PropTypes.bool,
  required: PropTypes.bool,
  withErrorText: PropTypes.bool,
};

ValidatedNumberFormatInput.defaultProps = {
  className: '',
  ariaDescribedby: '',
  allowDecimals: true,
  required: false,
  withErrorText: false,
};

export const ValidationErrorText = ({ id, errorText, className }) => (
  <div className={cls('validation-message', 'validation-message--error', 'flex-container', className)}>
    <Glyphicon glyph="huomio" />
    <div id={id} className="indent-block-1">
      {errorText}
    </div>
  </div>
);

ValidationErrorText.propTypes = {
  id: PropTypes.string,
  errorText: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ValidationErrorText.defaultProps = {
  id: null,
  className: '',
};
