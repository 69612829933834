import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';

import { Glyphicon } from 'common/components/Icon';
import { Row, Block } from 'common/components/Grid';

export const ErrorMessage = ({ message }) => (
  <div className="validation-message validation-message--error flex-container">
    <Glyphicon glyph="huomio" />
    <div className="indent-block-1">{message}</div>
  </div>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

const Error = ({ name }) => {
  const {
    meta: { touched, error },
  } = useField(name, { subscription: { touched: true, error: true } });

  return touched && error ? (
    <Row>
      <Block>
        <ErrorMessage message={error} />
      </Block>
    </Row>
  ) : null;
};

Error.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Error;
