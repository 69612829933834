import {
  UPDATE_SNAPSHOT_AREA_IMAGE,
  SNAPSHOT_AREA_PREVIEW_LOADING,
  ENABLE_SNAPSHOT_AREA,
  ENABLE_SNAPSHOT_PRINT_PREVIEW_AREA,
} from './SnapshotPreviewAreaActionTypes';

const initialState = {
  snapshotSectionEnabled: false,
  snapshotPrevImg: '',
  snapshotAreaPreviewLoading: false,
  innerAreaIsEnabled: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SNAPSHOT_AREA_IMAGE:
      return { ...state, snapshotPrevImg: action.img };
    case SNAPSHOT_AREA_PREVIEW_LOADING:
      return { ...state, snapshotAreaPreviewLoading: action.loading };
    case ENABLE_SNAPSHOT_AREA:
      return { ...state, snapshotSectionEnabled: action.enabled };
    case ENABLE_SNAPSHOT_PRINT_PREVIEW_AREA:
      return { ...state, innerAreaIsEnabled: action.enabled };
    default:
      return state;
  }
}
