import React from 'react';
import PropTypes from 'prop-types';

import { getResultNameParts, getSearchStringArray } from '../groupingHelpers';
import { validateRealEstateIdentifier } from '../../Forms/FormValidation';

const SearchResultName = ({ searchString, resultName }) => {
  const searchStringArray = getSearchStringArray(searchString);
  const filterParts = () =>
    getResultNameParts(searchString, resultName).map((part, i) => {
      if (part?.toLowerCase() === searchStringArray[0]) {
        searchStringArray.shift();
        return (
          // eslint-disable-next-line react/no-array-index-key
          <span className="result-name-match" key={`${part}-${i}`}>
            {part}
          </span>
        );
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <span key={`${part}-${i}`}>{part}</span>
      );
    });
  // cadastral searches results are never partial and the result is always highlighted
  // though it may differ from the search string
  return (
    <span className="search-result-name">
      {validateRealEstateIdentifier(resultName) ? (
        <span key={`${resultName}-0`} className="result-name-match">
          {resultName}
        </span>
      ) : (
        [...filterParts(), <span key={`${resultName}-2`} />]
      )}
    </span>
  );
};

SearchResultName.propTypes = {
  resultName: PropTypes.string.isRequired,
  searchString: PropTypes.string.isRequired,
};

export default SearchResultName;
