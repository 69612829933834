/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { makeTitle } from 'common/hooks/usePageTitle';

class ErrorBoundaryBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.resetState = this.resetState.bind(this);
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
      errorLocation: window.location.href,
    };
  }

  componentDidUpdate() {
    // Reset error if user navigates to another url
    // TODO: https://jira.nls.fi/browse/ASI-6907
    // Some state reset should be done here also
    if (this.state.hasError && window.location.href !== this.state.errorLocation) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    // TODO: https://jira.nls.fi/browse/ASI-6908
    // Send information about the error to a logging service
    console.error('Something went wrong in the Business panel', error);
    console.error('Error info', errorInfo);
  }

  // TODO: https://jira.nls.fi/browse/ASI-6907
  // further develop state resetting to better recover from errors. For instance reset redux state etc.
  resetState() {
    this.props.resetState();
    this.setState({ hasError: false });
  }

  render() {
    const { fallbackView, children } = this.props;
    if (this.state.hasError) {
      document.title = makeTitle(t('notFound.title'), t('app.documentTitle.template'));
      return fallbackView(this.resetState);
    }

    return children;
  }
}

ErrorBoundaryBase.propTypes = {
  children: PropTypes.node.isRequired,
  fallbackView: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
};

export default ErrorBoundaryBase;
