import React from 'react';
import PropTypes from 'prop-types';
import { Block } from '../Grid';
import RadioButton from './RadioButton';

// Selected radio-button is controlled by the parent
const SimpleRadioGroup = ({ options, name, selected, onChange }) =>
  options.map(option => (
    <Block key={`simple-radio-group-key-${option.id}`} size={6}>
      <RadioButton
        id={`${option.id}`}
        label={option.text}
        input={{
          name,
          value: option.value,
          onChange: () => onChange(option.value),
          checked: selected === option.value,
        }}
      />
    </Block>
  ));

SimpleRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SimpleRadioGroup.defaultProps = {
  onChange: () => null,
  selected: null,
};

export default SimpleRadioGroup;
