import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import { throttle } from 'lodash';

import { START_PHASE_FIRST_RENDER_READY } from 'common/containers/KasiAppMode/KasiAppModes';
import { getWindowDimensions } from './layoutHelpers';
import LayoutListener from './LayoutListener';

const LAYOUT_CLASS = 'layout';

const Root = ({ children, className, mode, onWindowResize }) => {
  const [, setState] = useState(getWindowDimensions());
  // NOTE: this is done so children do not render before the app knows if layout should be mobile or not
  const isAppReady = useSelector(state => state.appMode.startPhase >= START_PHASE_FIRST_RENDER_READY);

  const handleWindowResize = throttle((layout, dimensions) => {
    setState(dimensions);
    onWindowResize(layout);
  }, 200);

  return (
    <div className={cls(LAYOUT_CLASS, className)}>
      <LayoutListener onWindowResize={handleWindowResize} layout={mode} />
      {isAppReady && children}
    </div>
  );
};

Root.propTypes = {
  mode: PropTypes.string.isRequired,
  onWindowResize: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Root.defaultProps = {
  onWindowResize: () => null,
  className: '',
};

export default Root;
