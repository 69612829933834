import { useSelector } from 'react-redux';
import { useField } from 'react-final-form';
import { intersectionWith, isEmpty, isString } from 'lodash';
import { t } from 'i18next';

import {
  AREA_SELECTION_TYPES,
  CADASTRAL_INDEX_MAP_VECTOR,
  ELEVATION_MODEL,
  FIELD_NAMES,
  FILE_FORMAT_OPTIONS,
  LASER_SCANNING_DATA_5P,
  POLYGON,
  TOPOGRAPHIC_DATABASE,
} from './FileDownloadServiceConstants';
import PRODUCT_LIST from './ProductList';
import { getConfigurationAsInteger } from '../../helpers/Configurations/Configurations';

export const isProductDetailsPage = pathname =>
  PRODUCT_LIST.map(p => p.id).some(productId => pathname.split('/').pop().includes(productId));

export const getLocalizedName = ({ id }) => t(`fileDownloadService.product.${id}.name`);

export const containsMatch = (types, pattern) => {
  return types.some(type => type.endsWith(pattern));
};

/**
 * Get file format options for ValidatedSelect
 * @param {Array<String>} fileFormats
 * @returns {Array} options
 */
export const toFileFormatOptions = fileFormats => {
  return intersectionWith(
    FILE_FORMAT_OPTIONS,
    fileFormats,
    (option, productFormat) => option.value === productFormat
  ).map(option => ({ ...option, label: t(option.label) }));
};

/**
 * @function
 * @param {FileDownloadProduct.id} productId
 * @returns {FileDownloadProduct}
 */
export const getProduct = productId => PRODUCT_LIST.find(({ id }) => id === productId);

export const getDefaultAreaSelectionType = productId => {
  const { defaultAreaSelectionType } = getProduct(productId);
  return defaultAreaSelectionType === AREA_SELECTION_TYPES.FREE_SELECTION ? POLYGON : defaultAreaSelectionType;
};

export const getDefaultFileFormat = productId => {
  const { defaultFileFormat } = getProduct(productId);
  return defaultFileFormat;
};

export const getProductSelectorPosition = (productId, fieldName) => {
  const product = getProduct(productId);
  return product?.selectors && product?.selectors.find(({ id }) => id === fieldName);
};

export const useProductParameters = field => {
  const {
    input: { value: productId },
  } = useField(FIELD_NAMES.PRODUCT_ID);

  const {
    input: { value: productType },
  } = useField(FIELD_NAMES.PRODUCT_TYPE);

  const {
    input: { value: areaSelectionType },
  } = useField(FIELD_NAMES.AREA_SELECTION_TYPE);

  const {
    input: { value: freeSelectionType },
  } = useField(FIELD_NAMES.FREE_SELECTION_TYPE);

  const productParameters = useSelector(state => state.fileDownloadService.productParameters);
  const { value } = productType;

  const matchingProductParams =
    productId === ELEVATION_MODEL ? productParameters[productId.concat('_', value)] : productParameters[productId];

  if ([CADASTRAL_INDEX_MAP_VECTOR, TOPOGRAPHIC_DATABASE].includes(productId) && field === 'fileTypes') {
    return [...new Set(Object.values(matchingProductParams[field]).flat())];
  }

  if (field) {
    if (!Object.hasOwn(matchingProductParams, field)) return undefined;

    const selectionType = productId === LASER_SCANNING_DATA_5P ? AREA_SELECTION_TYPES.SELECT_SHEETS : areaSelectionType;

    const type = selectionType === AREA_SELECTION_TYPES.FREE_SELECTION ? freeSelectionType : selectionType;
    const key = Object.keys(matchingProductParams[field]).find(k => k.endsWith(type));
    return matchingProductParams[field][key];
  }

  return matchingProductParams;
};

export const useAreaSelectionTypes = () => {
  const {
    input: {
      value: { value: productType },
    },
  } = useField(FIELD_NAMES.PRODUCT_TYPE);

  const {
    input: {
      value: { value: fileFormat },
    },
  } = useField(FIELD_NAMES.FILE_FORMAT);

  const {
    input: { value: productId },
  } = useField(FIELD_NAMES.PRODUCT_ID);

  const { areaSelectionTypes, datasets, fileTypes } = useProductParameters();

  if ([CADASTRAL_INDEX_MAP_VECTOR, TOPOGRAPHIC_DATABASE].includes(productId)) {
    return areaSelectionTypes.filter(type => containsMatch(fileTypes[type], fileFormat));
  }

  if ([LASER_SCANNING_DATA_5P].includes(productId)) {
    return [AREA_SELECTION_TYPES.PRODUCTION_AREA, AREA_SELECTION_TYPES.SELECT_SHEETS];
  }

  if (productType != null) {
    return areaSelectionTypes.filter(type => isEmpty(datasets[type]) || containsMatch(datasets[type], productType));
  }

  return areaSelectionTypes;
};

export const toThemeOption = theme => ({
  label: t(`fileDownloadService.theme.${theme}`),
  value: theme,
});

/**
 * @function
 * @param {FileDownloadProduct.id} productId
 * @param {Array.<FileDownloadProduct.types>} productTypes
 * @returns Available product type options (label and value) for given product.
 */
export const getProductTypeOptions = (productId, productTypes = []) => {
  const types = getProduct(productId).types || productTypes;
  return types.map(type => ({
    label: t(`fileDownloadService.product.${productId}.${type}.name`),
    value: type,
  }));
};

/**
 * @function
 * @param {FileDownloadProduct.id} productId
 * @param {Array} productTypes
 * @returns The default product type option (label and value) for given product.
 */
export const getDefaultProductTypeOption = (productId, productTypes = []) => {
  const types = getProduct(productId).types || productTypes;
  const defaultType = getProduct(productId).defaultProductType;

  if (types === undefined) return undefined;

  return getProductTypeOptions(productId, types).find(option => option.value === defaultType);
};

/**
 * @function
 * @param {Array.<String>} themes product themes
 * @returns Available theme options (label and value) for product theme values.
 */
export const getThemeOptions = (themes = []) => {
  return themes.map(toThemeOption);
};

export const getAreaSelectionType = values =>
  values[FIELD_NAMES.AREA_SELECTION_TYPE] === AREA_SELECTION_TYPES.FREE_SELECTION
    ? values[FIELD_NAMES.FREE_SELECTION_TYPE]
    : values[FIELD_NAMES.AREA_SELECTION_TYPE];

export const getFeatureStyle = productId => ({
  fill: {
    color: 'rgba(100, 100, 100, 0)',
  },
  stroke: {
    color: '#FF8C00',
    width: 3,
  },
  text: {
    labelProperty: productId === LASER_SCANNING_DATA_5P ? 'karttalehtitunnus' : 'mapSheetNumber',
  },
});

export const getMapLayerId = mapLayer => {
  if (isString(mapLayer) && mapLayer.includes('oskari.layers.mapSheet')) {
    return getConfigurationAsInteger(mapLayer);
  }

  return mapLayer;
};

export const formatProductionAreaName = productionArea =>
  productionArea.properties.nimi.replaceAll('_', ' ').replaceAll('paikko', 'täydennys');

export const multiPolygonToMultiLineString = multiPolygon => {
  return {
    type: 'MultiLineString',
    coordinates: multiPolygon.coordinates.map(p => p[0]),
  };
};
