import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import ReactModal from 'react-modal';

import CloseButton from '../Button/CloseButtonComponent';

const Modal = ({ isOpen, warn, title, hideClose, closeHandler, onAfterOpen, children }) => (
  <ReactModal
    isOpen={isOpen}
    className={cls('modal', { warn })}
    overlayClassName="modal-overlay"
    onRequestClose={closeHandler}
    parentSelector={() => document.querySelector('#js-app')}
    onAfterOpen={onAfterOpen}
    closeTimeoutMS={150}
  >
    <header className="modal__header">
      <h1 className="modal-header__title">{title}</h1>
      {!hideClose && !warn && <CloseButton onClick={closeHandler} />}
    </header>
    <main className="modal__body">{children}</main>
  </ReactModal>
);

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  warn: PropTypes.bool,
  title: PropTypes.string.isRequired,
  hideClose: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  onAfterOpen: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  onAfterOpen: () => null,
  hideClose: false,
  warn: false,
};

export default Modal;
