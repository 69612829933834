import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';

const isInteger = val => /^\d*$/.test(val);

const PortionInput = React.forwardRef(
  (
    {
      id,
      label,
      input: { onChange, value: parsedValue },
      meta: { error, touched, modified, active, dirty },
      required,
      isFirst,
      ariaDescribedby,
      numeratorMaxLength,
      denominatorMaxLength,
      setShouldValidateTotalSumOfPortions,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [existingNum, existingDen] = parsedValue.split('/');
    const [numerator, setNumerator] = useState(existingNum || '');
    const [denominator, setDenominator] = useState(existingDen || '');
    const [inputActive, setInputActive] = useState(active);

    // validate the total sum of portions only when portion input is not active
    useEffect(() => {
      if (setShouldValidateTotalSumOfPortions !== null) {
        if (inputActive) {
          setShouldValidateTotalSumOfPortions(false);
        } else {
          setShouldValidateTotalSumOfPortions(true);
        }
      }
    }, [inputActive, setShouldValidateTotalSumOfPortions]);

    useEffect(() => {
      const newValue = `${numerator}/${denominator}`;
      if (!numerator || !denominator) {
        onChange('');
      } else {
        onChange(newValue);
      }
    }, [numerator, denominator, onChange]);

    return (
      <div>
        <span className="input-text__portion-input">
          <label htmlFor={`${id}-numerator`}>
            <span className="visually-hidden">
              {label ? `${label} - ` : ''}
              {t('portionInput.numerator')}
            </span>
            <input
              ref={ref}
              id={`${id}-numerator`}
              value={numerator}
              className={cls('input-text', 'input-text--small', {
                'input-text--error': isFirst ? modified && error : (touched && error) || (modified && dirty && error),
              })}
              onFocus={() => setInputActive(true)}
              onBlur={() => setInputActive(false)}
              onChange={({ target: { value } }) => {
                setNumerator(prev => (isInteger(value) ? value : prev));
              }}
              autoComplete="off"
              maxLength={numeratorMaxLength}
              {...(required ? { 'aria-required': true } : {})}
              aria-describedby={ariaDescribedby}
            />
          </label>
          <span className={cls('input-text__portion-slash', { error: touched && error })}>/</span>
          <label htmlFor={`${id}-denominator`}>
            <span className="visually-hidden">
              {' '}
              {label ? `${label} - ` : ''}
              {t('portionInput.denominator')}
            </span>
            <input
              id={`${id}-denominator`}
              value={denominator}
              className={cls('input-text', 'input-text--small', {
                'input-text--error': isFirst ? modified && error : (touched && error) || (modified && dirty && error),
              })}
              onFocus={() => setInputActive(true)}
              onBlur={() => setInputActive(false)}
              onChange={({ target: { value } }) => {
                setDenominator(prev => (isInteger(value) ? value : prev));
              }}
              autoComplete="off"
              maxLength={denominatorMaxLength}
              {...(required ? { 'aria-required': true } : {})}
              aria-describedby={ariaDescribedby}
            />
          </label>
        </span>
      </div>
    );
  }
);

PortionInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    modified: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
  }).isRequired,
  required: PropTypes.bool,
  isFirst: PropTypes.bool,
  ariaDescribedby: PropTypes.string,
  numeratorMaxLength: PropTypes.number,
  denominatorMaxLength: PropTypes.number,
  setShouldValidateTotalSumOfPortions: PropTypes.func,
};

PortionInput.defaultProps = {
  label: null,
  required: false,
  isFirst: false,
  ariaDescribedby: null,
  numeratorMaxLength: 9,
  denominatorMaxLength: 9,
  setShouldValidateTotalSumOfPortions: null,
};

export default PortionInput;
