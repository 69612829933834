import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { drawPrevRealtyResultMarkers } from 'common/containers/SidebarSearch/SearchActions';

const getFeatureIds = createSelector(
  state => state.geometries?.features,
  features => features?.map(feature => feature.name)
);

const useDrawPrevRealtyMarkers = () => {
  const dispatch = useDispatch();
  const { drawnRealEstateMarkers, isCadastralUnitSearch } = useSelector(state => state.search);
  const RealEstateMarkerIds = useSelector(getFeatureIds);
  const layerVisibility = useSelector(state => state.geometries?.layerVisibility);

  const missingFeatureMarkers = drawnRealEstateMarkers?.filter(marker => !RealEstateMarkerIds.includes(marker.id));
  const isRealEstateLayerVisible = layerVisibility?.layer_parcels !== undefined ? layerVisibility.layer_parcels : true;

  useEffect(() => {
    if (isCadastralUnitSearch && !isEmpty(missingFeatureMarkers) && isRealEstateLayerVisible) {
      dispatch(drawPrevRealtyResultMarkers(missingFeatureMarkers));
    }
  }, [dispatch, isCadastralUnitSearch, isRealEstateLayerVisible, missingFeatureMarkers]);
};

export default useDrawPrevRealtyMarkers;
