import { openDB } from 'idb';

export const STATE_STORAGE_NAME = 'stateStorage';
export const GEOMETRY_STORE_NAME = 'geometries';

const idb = {
  print: openDB('print', 1, {
    upgrade(db) {
      db.createObjectStore('printmap');
    },
  }),
  state: openDB('stateStorage', 1, {
    upgrade(db) {
      db.createObjectStore(STATE_STORAGE_NAME);
    },
  }),
};

export default idb;
