/* eslint-disable global-require */
// Application Bootstrap
import Analytics, { getAccount } from 'common/helpers/Analytics';
import { Spinner } from 'spin.js';
import { APP_LOADER_CONFIG } from 'common/components';
import { setDefaultSitePath, ROUTE_MAP_SITE_ID } from 'common/constants/Routes';
import 'styles/mapsite.styl';
import { addToLibrary as loadIcons } from 'common/components/Icon';

loadIcons();
setDefaultSitePath(ROUTE_MAP_SITE_ID);

const { id, url } = getAccount(window.location);
setDefaultSitePath(ROUTE_MAP_SITE_ID);

Analytics().init({ id, url, disableSend: false });

// Make sure all resources are reliably available before firing up.
document.addEventListener('DOMContentLoaded', () => {
  new Spinner(APP_LOADER_CONFIG).spin(document.getElementById('js-spinner-container'));
  require('./helpers/Application').default();
});
