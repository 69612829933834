import { getConfiguration } from 'common/helpers/Configurations/Configurations';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import { initDiscountsThunk } from 'common/containers/MapStore/MapStoreActions';
import { getLastNotificationThunk } from 'common/containers/MllBulletin/MllBulletinActions';
import * as Privileges from 'common/containers/Authorization/privileges';

const runMllBulletinThunk = store => {
  if (isAuthorized([Privileges.GET_NOTIFICATIONS])) {
    return store.dispatch(getLastNotificationThunk(false));
  }
  return Promise.resolve();
};

const runDiscountsThunk = store => store.dispatch(initDiscountsThunk());

const startMllBulletinPoll = store => {
  if (isAuthorized([Privileges.GET_NOTIFICATIONS])) {
    // check for new notifications:
    setInterval(() => {
      runMllBulletinThunk(store);
    }, getConfiguration('mmlBulletin.pollFrequencyMilliseconds'));
  }
};

const commonBgTasks = () => [startMllBulletinPoll, runMllBulletinThunk, runDiscountsThunk];

export default commonBgTasks;
