import React from 'react';
import PropTypes from 'prop-types';

import { enterPressed } from 'common/helpers/A11y/keyPressDetectors';

const Form = ({ children, className }) => (
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  <form
    className={className}
    onKeyDown={enterPressed(event => event.preventDefault())} // <===== This stops the form from reloading
  >
    {children}
  </form>
);

Form.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Form.defaultProps = {
  className: '',
};

export default Form;
