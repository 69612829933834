import { isEmpty } from 'lodash';
import { clearLayer, mapIsReady, removeFeature } from 'oskari/oskariModules/MapModule';
import { config, serviceLayersInOrder } from '../ServicesFeatureConfig';
import { initClickHandlers, setDisableClickSelect } from './clickHandlers';
import {
  LAYER_APARTMENTS,
  LAYER_BOUNDARY_MARKERS,
  LAYER_PARCELS,
  LAYER_REAL_ESTATE_BORDERS,
  LAYER_REAL_ESTATE_IDS,
} from '../../layers/VectorLayers';

const FEAT_TYPE_REGISTER_UNIT_ID = 'registerUnitId';
const FEAT_TYPE_REGISTER_UNIT_BORDER_LINE = LAYER_REAL_ESTATE_BORDERS;
const FEAT_TYPE_REGISTER_UNIT_BOUNDARYMARK = LAYER_BOUNDARY_MARKERS;
const FEAT_TYPE_REGISTER_UNIT_LOT = 'registerUnitLot';
const FEAT_TYPE_REGISTER_PROJECTED_LOT_BELOW = 'projectedLotBelow';
const FEAT_TYPE_REGISTER_PROJECTED_LOT_ABOVE = 'projectedLotAbove';
const FEAT_TYPE_PROPERTY_ID = 'propertyId';
const FEAT_TYPE_PROPERTY_ID_PROJECTED_ABOVE = 'projectedPropertyIdAbove';
const FEAT_TYPE_PROPERTY_ID_PROJECTED_BELOW = 'projectedPropertyIdBelow';
const FEAT_TYPE_PROPERTY_AREA = 'propertyArea';
const FEAT_TYPE_FACILITY = 'facility';
const FEAT_TYPE_APARTMENT = 'apartment';
const FEAT_TYPE_OTHER_APARTMENT = 'otherApartment';
const FEAT_TYPE_PARKING_SPACE = 'parkingSpace';

let _channel;

const FILL_FEATURE_TYPES = [
  FEAT_TYPE_REGISTER_UNIT_LOT,
  FEAT_TYPE_PROPERTY_AREA,
  FEAT_TYPE_REGISTER_PROJECTED_LOT_BELOW,
  FEAT_TYPE_REGISTER_PROJECTED_LOT_ABOVE,
];

const STROKE_FEATURE_TYPES = [
  FEAT_TYPE_REGISTER_UNIT_ID,
  FEAT_TYPE_REGISTER_UNIT_BORDER_LINE,
  FEAT_TYPE_REGISTER_UNIT_BOUNDARYMARK,
  FEAT_TYPE_PROPERTY_ID,
  FEAT_TYPE_PROPERTY_ID_PROJECTED_ABOVE,
  FEAT_TYPE_PROPERTY_ID_PROJECTED_BELOW,
  FEAT_TYPE_FACILITY,
  FEAT_TYPE_APARTMENT,
  FEAT_TYPE_PARKING_SPACE,
  FEAT_TYPE_OTHER_APARTMENT,
];

export { setDisableClickSelect };

export default function initServicesMapModule(channel) {
  _channel = channel;
  initClickHandlers();
}

function resolveFeatureType(realestateType, feature) {
  if (realestateType === FEAT_TYPE_FACILITY) return FEAT_TYPE_FACILITY;
  if (realestateType === FEAT_TYPE_APARTMENT) return FEAT_TYPE_APARTMENT;
  if (realestateType === FEAT_TYPE_PARKING_SPACE) return FEAT_TYPE_PARKING_SPACE;
  if (realestateType === FEAT_TYPE_OTHER_APARTMENT) return FEAT_TYPE_OTHER_APARTMENT;

  const featureName = feature && feature.properties && feature.properties.name;
  const featureType = feature && feature.geometry && feature.geometry.type;
  return {
    'palsta.tunnus': FEAT_TYPE_REGISTER_UNIT_ID,
    'projisoitu_palstatunnus.alapuolinen': FEAT_TYPE_REGISTER_UNIT_ID,
    'projisoitu_palstatunnus.ylapuolinen': FEAT_TYPE_REGISTER_UNIT_ID,
    kiinteiston_raja: FEAT_TYPE_REGISTER_UNIT_BORDER_LINE,
    rajamerkki: FEAT_TYPE_REGISTER_UNIT_BOUNDARYMARK,
    palsta: FEAT_TYPE_REGISTER_UNIT_LOT,
    'projisoitu_palsta.alapuolinen': FEAT_TYPE_REGISTER_PROJECTED_LOT_BELOW,
    'projisoitu_palsta.ylapuolinen': FEAT_TYPE_REGISTER_PROJECTED_LOT_ABOVE,
    'maaraalan_osa.tunnus': FEAT_TYPE_PROPERTY_ID,
    maaraalan_osa: featureType === 'Point' ? FEAT_TYPE_PROPERTY_ID : FEAT_TYPE_PROPERTY_AREA,
    'maaraalan_osa.alapuolinen': FEAT_TYPE_PROPERTY_ID_PROJECTED_BELOW,
    'maaraalan_osa.ylapuolinen': FEAT_TYPE_PROPERTY_ID_PROJECTED_ABOVE,
  }[featureName];
}

function params(featureType, shouldDrawOtherRealEstates, color) {
  const featuresConf = config(featureType, shouldDrawOtherRealEstates, color);

  return {
    layerId: featuresConf.layer,
    clearPrevious: false,
    centerTo: false,
    featureStyle: featuresConf.style,
    cursor: 'pointer',
    prio: featuresConf.priority,
  };
}

function geoJsonObject(features) {
  return {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: {
        name: 'EPSG:3067',
      },
    },
    features,
  };
}

const drawFeature = (featuretype, features, shouldDrawOtherRealEstates, color) => {
  const reqParams = [geoJsonObject(features), { ...params(featuretype, shouldDrawOtherRealEstates, color) }];
  _channel.postRequest('MapModulePlugin.AddFeaturesToMapRequest', reqParams);
};

const fillFeatures = (realEstates, shouldDrawOtherRealEstates) => {
  const areaFeaturesById = {};
  const featureTypesById = {};
  const realEstateColorsById = realEstates.reduce((prev, item) => ({ ...prev, [item.id]: item.color }), {});

  realEstates.forEach(realEstate => {
    if (!realEstate.features) return;
    realEstate.features.forEach(feature => {
      const featureType = resolveFeatureType(realEstate.type, feature);
      if (FILL_FEATURE_TYPES.includes(featureType)) {
        if (areaFeaturesById[realEstate.id]) {
          areaFeaturesById[realEstate.id].push(feature);
        } else {
          areaFeaturesById[realEstate.id] = [feature];
          featureTypesById[realEstate.id] = featureType;
        }
      }
    });
  });

  if (shouldDrawOtherRealEstates) {
    Object.keys(areaFeaturesById).forEach((id, i) => {
      const shouldDraw = i !== 0;
      drawFeature(featureTypesById[id], areaFeaturesById[id], shouldDraw, realEstateColorsById[id]);
    });
  } else {
    Object.keys(areaFeaturesById).forEach(id =>
      drawFeature(featureTypesById[id], areaFeaturesById[id], false, realEstateColorsById[id])
    );
  }
};

export function zoomToServiceFeatures(ids) {
  const layers = { layer: serviceLayersInOrder };
  const features = isEmpty(ids) ? {} : { id: ids };
  const req = [layers, features];

  _channel.postRequest('MapModulePlugin.ZoomToFeaturesRequest', req);
}

export function layersOnMap(withFeatures = false) {
  return new Promise((resolve, reject) => {
    _channel.getFeatures(
      [withFeatures],
      data => {
        resolve(data);
      },
      error => {
        reject(error);
      }
    );
  });
}

const setFeaturePropertiesDescription = (feature, type) => {
  if (feature.properties.facilityId && type !== FEAT_TYPE_APARTMENT) return feature.properties.facilityId;
  if (feature.properties.description) return `${feature.properties.description}`;
  return '';
};

const strokeFeatures = realestates => {
  const sortedfeatures = {};
  realestates.forEach(realEstate => {
    if (!realEstate.features) return;
    realEstate.features.forEach(feature => {
      const type = resolveFeatureType(realEstate.type, feature);
      if (STROKE_FEATURE_TYPES.includes(type)) {
        // eslint-disable-next-line no-param-reassign
        feature.properties.description = setFeaturePropertiesDescription(feature, type);
        if (sortedfeatures[type]) {
          sortedfeatures[type].push(feature);
        } else {
          sortedfeatures[type] = [];
          sortedfeatures[type].push(feature);
        }
      }
    });
  });
  Object.entries(sortedfeatures).forEach(([featureType, feature]) => drawFeature(featureType, feature));
};

export function drawRealEstates(realestates, shouldDrawOtherRealEstates) {
  strokeFeatures(realestates);
  fillFeatures(realestates, shouldDrawOtherRealEstates);
}

async function resetLayer(layer) {
  await mapIsReady();
  clearLayer(layer);
}

function removeFeatureFromMap(featureId, layer) {
  removeFeature(featureId, layer);
}

export function removeFeaturesFromMap(featureIdsWithLayers) {
  const layers = [...new Set(featureIdsWithLayers.map(feature => feature.layer))];
  layers.forEach(layer => {
    const idList = featureIdsWithLayers.filter(feature => feature.layer === layer).map(f => f.id);
    removeFeatureFromMap(idList, layer);
  });
}

/**
 * Clear all real estate layers, including usufruct unit layer.
 */
export function clearMapServiceLayers() {
  serviceLayersInOrder.forEach(layerId => resetLayer(layerId));
}

export function clearMapRealestatesLayers() {
  clearMapRealEstates();
  clearMapBoundaryMarkersLayer();
  clearMapApartmentsLayer();
}

export function clearMapRealEstates() {
  resetLayer(LAYER_PARCELS);
  resetLayer(LAYER_REAL_ESTATE_IDS);
  resetLayer(LAYER_REAL_ESTATE_BORDERS);
}

export function clearMapBoundaryMarkersLayer() {
  resetLayer(LAYER_BOUNDARY_MARKERS);
}

export function clearMapApartmentsLayer() {
  resetLayer(LAYER_APARTMENTS);
}

export { resolveFeatureType as resolveFeatureTypeTest };
