/* eslint-disable import/prefer-default-export */
// Minimum significant distances in meters. Index corresponds to the zoom level.
export const SIGNIFICANT_DISTANCES = [
  200000,
  100000,
  50000,
  20000,
  10000,
  5000,
  3000,
  2000,
  1000,
  500,
  200,
  100,
  50,
  20,
];
