import i18next from 'i18next';
import { isEmpty } from 'lodash';
import firstBy from 'thenby';

import { formatDateDMYYYY, isPast } from 'utils/datetime-utils';
import { ASIOINTI_LANGUAGES } from 'common/components/UserMenu/LanguageSelector';
import { UNLEGALIZED_USUFRUCT_TYPE, USUFRUCTS_TYPE } from 'realEstateSite/containers/Realty/common/RealEstateTypeIcon';
import { ROAD_UNIT_TYPE, UNLEGALIZED_RIGHT_OF_WAY_TYPE } from './UsufructUnitTypes';

export const getUnitTitle = ({ hasPrivateRoadAssociation, usufructTypeText, usufructType }) =>
  hasPrivateRoadAssociation && usufructType === ROAD_UNIT_TYPE
    ? i18next.t('usufructUnit.title')
    : usufructTypeText[i18next.language] || '';

export const getUnitLocationMunicipalityName = ({ municipality, hasPrivateRoadAssociation, usufructType }) => {
  return hasPrivateRoadAssociation && usufructType === ROAD_UNIT_TYPE
    ? `${municipality.municipalityName[i18next.language]} ${i18next.t('usufructUnit.locationMunicipalities.length', {
        length: municipality.calculatedLength,
      })}`
    : municipality.municipalityName[i18next.language];
};

export const getUnitPrivateRoadHomeMunicipalityName = ({ homeMunicipalityName, homeMunicipalityendDate }) =>
  homeMunicipalityendDate && isPast(new Date(homeMunicipalityendDate))
    ? `${i18next.t('usufructUnit.homeMunicipalityName.prefix', {
        name: homeMunicipalityName[i18next.language],
      })} ${i18next.t('usufructUnit.homeMunicipalityName.ended', {
        date: formatDateDMYYYY(new Date(homeMunicipalityendDate)),
      })}`
    : `${i18next.t('usufructUnit.homeMunicipalityName.prefix', {
        name: homeMunicipalityName[i18next.language],
      })}`;

export const hasUnitPartyRegulation = (usufructUnitParts, relationType, realestateId) =>
  !isEmpty(
    usufructUnitParts
      .map(up => up.userParties)
      .flat()
      .filter(
        obj => obj.relationType === relationType && obj.registerUnit.some(unit => unit.realestateId === realestateId)
      )
  );

export const hasUnitPartyRegulatedItems = (usufructUnitParts, type) =>
  usufructUnitParts
    .map(({ userParties }) => userParties)
    .flat()
    .filter(({ relationType }) => relationType === type);

const compareRealEstates = (a, b) => {
  if (a.realestateId != null) {
    return b.realestateId != null ? a.realestateId.localeCompare(b.realestateId, undefined, { numeric: true }) : -1;
  }
  return b.realestateId != null ? 1 : 0;
};

const compareProperties = (a, b) => {
  if (a.propertyId != null) {
    return b.propertyId != null ? a.propertyId.localeCompare(b.propertyId, undefined, { numeric: true }) : -1;
  }
  return b.propertyId != null ? 1 : 0;
};

const compareNames = (a, b) => {
  if (a.name != null) {
    return b.name != null ? a.name.localeCompare(b.name) : -1;
  }
  return b.name != null ? 1 : 0;
};

export const getUnitPartyRegulatedItems = (usufructUnitParts, type) =>
  hasUnitPartyRegulatedItems(usufructUnitParts, type)
    .map(obj => {
      if (obj.registerUnit.length > 0) {
        return {
          party: {
            ...obj.registerUnit[0],
            description: obj.description,
          },
        };
      }
      if (obj.property.length > 0) {
        return {
          party: {
            ...obj.property[0],
            description: obj.description,
          },
        };
      }
      if (obj.municipality.length > 0) {
        return {
          party: {
            name: i18next.language === 'fi' ? obj.municipality[0].finnishName : obj.municipality[0].swedishName,
            description: obj.description,
          },
        };
      }
      return {
        party: {
          name: '',
          realestateId: '',
          propertyId: '',
          description: obj.description,
        },
      };
    })
    .map(({ party }) => party)
    .sort(
      firstBy(compareRealEstates)
        .thenBy(compareProperties)
        .thenBy(compareNames)
        .thenBy((a, b) => a.description.localeCompare(b.description))
    );

export const getAllRegulatedUnitParts = (usufructUnit, realEstateId) =>
  usufructUnit.usufructUnitParts.filter(({ userParties }) =>
    userParties.some(({ registerUnit }) => registerUnit.some(({ realestateId }) => realestateId === realEstateId))
  );

export const getRegulatedUnitPartsByNumber = (regulatedUsufructUnitParts, number) =>
  regulatedUsufructUnitParts.filter(({ partNumber }) => partNumber === number);

export const getUsufructCertficateDescription = usufructUnit => {
  return `${getUnitTitle(usufructUnit)} ${usufructUnit.displayId}${
    usufructUnit.name && !isEmpty(usufructUnit.name) && `, ${usufructUnit.name}`
  }`;
};

export const getUsufructTypeDescription = ({ usufructTypeText }) => {
  return usufructTypeText[i18next.language] || '';
};

export const getUsufructUnitDescription = description => {
  const unSelectedLanguage = ASIOINTI_LANGUAGES.find(lang => lang !== i18next.language);
  return !isEmpty(description[i18next.language]) ? description[i18next.language] : description[unSelectedLanguage];
};

export const formatUsufructDate = value => (value ? formatDateDMYYYY(new Date(value)) : null);

export const formatFeatures = features => {
  if (features[0]?.properties?.kayttooikeusyksikkolaji === UNLEGALIZED_RIGHT_OF_WAY_TYPE) {
    // eslint-disable-next-line no-param-reassign
    features[0].properties.unlegalizedId = `${
      features[0].properties.kayttooikeusyksikonOsanTunnuksenEsitysmuoto
    } (${i18next.t('usufructUnit.unlegalizedRightOfWay.text')})`;
  }
  return features;
};

export const getUsufructUnitIcon = unit => {
  if (unit.usufructType === UNLEGALIZED_RIGHT_OF_WAY_TYPE) return UNLEGALIZED_USUFRUCT_TYPE;
  return USUFRUCTS_TYPE;
};
