import React from 'react';
import PropTypes from 'prop-types';

const OrderedItem = ({ children }) => <li className="nls-ordered-item">{children}</li>;

OrderedItem.propTypes = {
  children: PropTypes.node,
};

OrderedItem.defaultProps = {
  children: null,
};

export default OrderedItem;
