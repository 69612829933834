import React from 'react';
import PropTypes from 'prop-types';
import Glyphicon from 'common/components/Icon/Glyphicon';

const OskariMapVeilComponent = ({ title, message }) => {
  return (
    <div className="oskari-map-veil">
      <Glyphicon glyph="huomio" />
      <h2 className="margin-t-1 margin-b-1">{title}</h2>
      <p>{message}</p>
    </div>
  );
};

OskariMapVeilComponent.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default OskariMapVeilComponent;
