import { turnCustomMarkerOn } from 'common/containers/MapToolbar/CustomMarkerActions';
import { turnAddressOn } from 'common/containers/MapToolbar/AddressActions';
import { turnFeedbackOn } from 'common/containers/MapToolbar/FeedbackMarkerActions';
import {
  reloadMeasurementFeatures,
  startAreaTool,
  startDistanceTool,
} from 'common/containers/MapToolbar/MeasurementActions';
import { turnSelectorOn } from 'common/containers/MapToolbar/SelectorActions';
import { trackUserLocationThunk } from 'common/containers/OskariMap/OskariMapActions';
import { clearSearchThunk } from 'common/containers/SidebarSearch/SearchActions';
import { shareCloseAction } from 'common/containers/Share/ShareActions';
import { updateOskariChannelStatus } from 'common/containers/KasiApp/KasiAppActions';
import { GEOMETRY_TYPES } from 'common/constants/MapConstants';
import { reloadMarker } from 'common/geometries/geometriesActions';
import { saveStateToSessionStorage } from 'common/helpers/storage';
import { shownOnMapResetted } from 'realEstateSite/containers/Realty/common/geometries/geometriesSlice';
import * as OskariMap from 'oskari/OskariMap';
import { OSKARI_CHANNEL_STATUS_IDS } from 'oskari/OskariMap';

const resetOskariChannelStatus = () => updateOskariChannelStatus(OSKARI_CHANNEL_STATUS_IDS.startingChannel);

const cleanUpActions = [clearSearchThunk, shareCloseAction, resetOskariChannelStatus];

let cleanUpAndSaveStateToSessionStorage = () => null;

export const addCleanUpAction = action => cleanUpActions.push(action);

function cleanUpState(dispatch, getState) {
  cleanUpActions.forEach(action => dispatch(action()));
  return { ...getState(), routing: undefined };
}

export function addReloadListener(store, windowObject) {
  cleanUpAndSaveStateToSessionStorage = () => {
    saveStateToSessionStorage(cleanUpState(store.dispatch, store.getState));
  };
  windowObject.addEventListener('pagehide', cleanUpAndSaveStateToSessionStorage);
  windowObject.addEventListener('beforeunload', cleanUpAndSaveStateToSessionStorage);
}

export function removeReloadListener(windowObject) {
  windowObject.removeEventListener('pagehide', cleanUpAndSaveStateToSessionStorage);
  windowObject.removeEventListener('beforeunload', cleanUpAndSaveStateToSessionStorage);
}

function reloadCustomMarker(dispatch, getState) {
  if (getState().mapToolbar.customMarker.active) {
    turnCustomMarkerOn(dispatch);
  }
}

function reloadFeedbackMarker(dispatch, getState) {
  if (getState().mapToolbar.feedbackMarker.active) {
    turnFeedbackOn(dispatch, getState);
  }
}

function reloadDistance(dispatch, getState) {
  if (getState().mapToolbar.distance.active) {
    startDistanceTool(dispatch);
  }
}

function reloadArea(dispatch, getState) {
  if (getState().mapToolbar.area.active) {
    startAreaTool(dispatch);
  }
}

function reloadAddress(dispatch, getState) {
  if (getState().mapToolbar.address.active) {
    turnAddressOn(dispatch, getState);
  }
}

function reloadSelector(dispatch, getState) {
  if (getState().mapToolbar.selector.active) {
    turnSelectorOn(dispatch, getState);
  }
}

function reloadOskariIframe() {
  return (dispatch, getState) => {
    OskariMap.moveMap(
      getState().oskariIframe.coords.centerX,
      getState().oskariIframe.coords.centerY,
      getState().oskariIframe.coords.zoom
    );
  };
}

function reloadMeasurements(dispatch) {
  return dispatch(reloadMeasurementFeatures);
}

function reloadMapToolbar() {
  return (dispatch, getState) => {
    reloadMeasurements(dispatch);
    reloadCustomMarker(dispatch, getState);
    reloadDistance(dispatch, getState);
    reloadArea(dispatch, getState);
    reloadAddress(dispatch, getState);
    reloadFeedbackMarker(dispatch, getState);
    reloadSelector(dispatch, getState);
  };
}

function reloadGeometries(oskariReload) {
  return (dispatch, getState) => {
    if (!oskariReload) dispatch(shownOnMapResetted());
    getState().geometries.features.forEach(geom => {
      if (geom.geometryType === GEOMETRY_TYPES.MARKER) reloadMarker(geom);
    });
  };
}

function reloadLayers() {
  return (dispatch, getState) => {
    getState().mapLayers.forEach(layer => {
      OskariMap.changeMapLayerOpacity(layer.id, layer.opacity);
      OskariMap.changeMapLayerVisibility(layer.id, layer.visible);
    });
  };
}

function reloadLayerVisibilities() {
  return (dispatch, getState) => {
    Object.entries(getState().geometries.layerVisibility).forEach(([layer, visibility]) => {
      OskariMap.changeMapLayerVisibility(layer, visibility);
    });
  };
}

function restoreLocationTracking() {
  return (dispatch, getState) => {
    if (getState().oskariIframe.trackingUser) {
      dispatch(trackUserLocationThunk(true));
    }
  };
}

const oskariActions = [
  reloadLayers,
  reloadMapToolbar,
  reloadOskariIframe,
  reloadLayerVisibilities,
  restoreLocationTracking,
];

export const addOskariAction = action => oskariActions.push(action);

export function reloadOskariState(oskariReload = false) {
  return dispatch =>
    new Promise(resolve => {
      dispatch(reloadGeometries(oskariReload));
      oskariActions.forEach(action => dispatch(action()));
      resolve();
    });
}
