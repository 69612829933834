import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'common/components/Modal';
import { FlexGrid, Row, Block } from 'common/components/Grid';

const LogoutModal = ({ logout, cancelLogout, cartItemCount, goToShoppingCart }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen closeHandler={cancelLogout} title={t('logoutmodal.title')}>
      <FlexGrid className="text-center">
        <Row className="margin-t-2">
          <Block size="6">
            <h2>{cartItemCount > 0 ? t('roleChangeModal.text.title') : t('logoutmodal.text.title')}</h2>
            {cartItemCount > 0 && <p className="margin-t-1">{t('logoutmodal.text.infoWithCartItems')}</p>}
            <p className="margin-t-1">{t('logoutmodal.text.info')}</p>
          </Block>
        </Row>
        <Row className="margin-t-2 margin-b-3">
          <Block size="6">
            {cartItemCount > 0 ? (
              <button
                type="button"
                className="button button--light"
                onClick={() => {
                  goToShoppingCart();
                }}
              >
                {t('roleChangeModal.button.goToShoppingCart')}
              </button>
            ) : (
              <button
                type="button"
                className="button button--light"
                onClick={() => {
                  cancelLogout();
                }}
              >
                {t('button.continue')}
              </button>
            )}
            <button
              type="button"
              className="button button--orange indent-block-2"
              onClick={() => {
                logout();
              }}
            >
              {t('logoutmodal.button.logout')}
            </button>
          </Block>
        </Row>
      </FlexGrid>
    </Modal>
  );
};

LogoutModal.propTypes = {
  logout: PropTypes.func.isRequired,
  cancelLogout: PropTypes.func.isRequired,
  cartItemCount: PropTypes.number.isRequired,
  goToShoppingCart: PropTypes.func.isRequired,
};

export default LogoutModal;
