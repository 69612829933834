import React from 'react';

import { ScrollerColumn } from 'common/components';
import usePageTitle from 'common/hooks/usePageTitle';
import ShareMarker from 'common/containers/Share/ShareMarkerIndexComponent';

const ShareMarkerPage = () => {
  usePageTitle({
    title: 'share.documentTitle',
    template: 'app.documentTitle.template',
  });

  return (
    <ScrollerColumn columnNumber={2} lastColumn>
      <ShareMarker />
    </ScrollerColumn>
  );
};

export default ShareMarkerPage;
