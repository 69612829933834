import React, { Suspense, lazy } from 'react';

import Footer from 'mapSite/containers/Footer/MapSiteFooter';
import usePageTitle from 'common/hooks/usePageTitle';
import BusinessPanelComponent from 'common/containers/BusinessPanel/BusinessPanelComponent';
import { LoaderSpinner } from 'common/components';

const ConfirmationComponent = lazy(() =>
  import('common/containers/ShoppingCart/ConfirmationComponents/ConfirmationComponent')
);

export default function ConfirmationPage() {
  usePageTitle({
    title: 'shoppingCart.checkout.confirmation.documentTitle',
    template: 'app.documentTitle.template',
  });

  return (
    <BusinessPanelComponent viewSize="full">
      <Suspense fallback={<LoaderSpinner size="s" className="full-panel-loader margin-t-2" />}>
        <ConfirmationComponent>
          <Footer />
        </ConfirmationComponent>
      </Suspense>
    </BusinessPanelComponent>
  );
}
