/*
 * portrait/landscape size (mm) = dims minus paper margins
 * print size (mm) = for the paper print
 * */
export function Paper(portraitWidth, portraitHeight, landscapeWidth, landscapeHeight, printWidth, printHeight) {
  const _portraitWidth = portraitWidth;
  const _portraitHeight = portraitHeight;
  const _landscapeHeight = landscapeHeight;
  const _landscapeWidth = landscapeWidth;
  const _printWidth = printWidth;
  const _printHeight = printHeight;
  const getPaper = portrait => (portrait ? [_portraitWidth, _portraitHeight] : [_landscapeWidth, _landscapeHeight]);
  const getPrintPaper = portrait => (portrait ? [_printWidth, _printHeight] : [_printHeight, _printWidth]);
  return {
    getPortrait: () => getPaper(true),
    getLandscape: () => getPaper(false),
    getPrintPortrait: () => getPrintPaper(true),
    getPrintLandscape: () => getPrintPaper(false),
  };
}

//  A0-4
export const paperSizes = [
  new Paper(783, 1070, 1131, 720, 841, 1189), // a0
  new Paper(548, 742, 793, 493, 594, 841), // a1
  new Paper(382, 516, 554, 334.5, 420, 594), // a2
  new Paper(265, 358, 388, 232, 297, 420), // a3
  new Paper(178, 233, 265, 153, 210, 297), // a4
];
