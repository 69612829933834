import * as types from './BusinessPanelActionTypes';

const initialState = {
  isOpen: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.CLOSE_BUSINESS_PANEL:
      return { ...state, isOpen: false };
    case types.OPEN_BUSINESS_PANEL:
      return { ...state, isOpen: true };
    case types.TOGGLE_BUSINESS_PANEL: {
      const actualIsOpen = typeof action.isOpen === 'boolean' ? action.isOpen : !state.isOpen;
      return { ...state, isOpen: actualIsOpen };
    }
    default:
      return state;
  }
}
