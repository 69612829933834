/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const readyMapsFormSlice = createSlice({
  name: 'readyMapsForm',
  initialState: {
    selectedSheets: [],
    selectedScale: '1:25000',
    sheetsLoaded: false,
    sheetsLoading: false,
  },
  reducers: {
    selectScale(state, action) {
      state.selectedScale = action.payload.scale;
    },
    setReadyMapsLoading(state, action) {
      state.sheetsLoading = action.payload.isLoading;
    },
    setReadyMapSheetsLoaded(state, action) {
      state.sheetsLoaded = action.payload.isLoaded;
    },
    readyMapSelectSheet(state, action) {
      const selectedSheet = { ...action.payload.sheet, amount: 1 };
      state.selectedSheets.push(selectedSheet);
    },
    updateReadyMapsSheetAmount(state, action) {
      state.selectedSheets = state.selectedSheets.map(sheet =>
        sheet.karttalehdenNumero === action.payload.sheet.karttalehdenNumero
          ? { ...sheet, amount: action.payload.amount }
          : sheet
      );
    },
    removeReadyMapsSheet(state, action) {
      state.selectedSheets = state.selectedSheets.filter(
        sheet => sheet.karttalehdenNumero !== action.payload.sheet.karttalehdenNumero
      );
    },
    resetReadyMaps(state) {
      state.selectedSheets = [];
      state.selectedScale = '1:25000';
    },
  },
});

export const {
  selectScale,
  setReadyMapsLoading,
  setReadyMapSheetsLoaded,
  readyMapSelectSheet,
  updateReadyMapsSheetAmount,
  removeReadyMapsSheet,
  resetReadyMaps,
} = readyMapsFormSlice.actions;

export default readyMapsFormSlice.reducer;
