/* eslint-disable prefer-promise-reject-errors */
import $ from 'common/zepto.ajax';

import { dispatch } from 'common/helpers/ReduxAware';
import { COOKIE_XSRF_TOKEN, DEFAULT_DELAY, DEFAULT_RETRIES, getCookieValue } from 'common/helpers/cookieHelpers';

import { showServiceClosedModal } from 'common/containers/ServiceClosedModal/ServiceClosedModalActions';
import wait from 'common/helpers/asyncHelpers';

const handleExpiredSession = resp => {
  if (resp.redirected && resp.url.endsWith('/karttapaikka/istunto-paattynyt')) {
    window.location.href = '/karttapaikka/istunto-paattynyt';
  }
};

const resolveHTTPExceptions = resp => {
  switch (resp.status) {
    case 503: {
      dispatch(showServiceClosedModal());
      break;
    }
    case 0: {
      // service closed
      window.location.reload();
      break;
    }
    default:
  }
};

export const postJson = async (
  service,
  content,
  delay = DEFAULT_DELAY,
  retries = DEFAULT_RETRIES,
  signal = null,
  done = null,
  error = null
) => {
  return fetch(
    service,
    {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(content),
      headers: { 'Content-Type': 'application/json', 'X-XSRF-TOKEN': getCookieValue(COOKIE_XSRF_TOKEN) },
      credentials: 'include',
    },
    { signal }
  ).then(resp => {
    handleExpiredSession(resp);
    if (!resp.ok) {
      if (resp.status === 403 && retries > 0) {
        return wait(delay).then(() => postJson(service, content, delay, retries - 1, signal, done, error));
      }
      resolveHTTPExceptions(resp);
      if (error instanceof Function) {
        return error(resp);
      }
      throw new Error(resp.statusText);
    }
    if (done instanceof Function) {
      return resp.json().then(data => done(data));
    }
    if (resp.headers.get('Content-Length') !== '0') return resp.json();
    return Promise.resolve(null);
  });
};

export const getJson = async (service, signal = null) => {
  return fetch(service, { signal }).then(resp => {
    handleExpiredSession(resp);
    if (!resp.ok) {
      resolveHTTPExceptions(resp);
      if (resp.status === 504 || resp.status === 404)
        return Promise.reject(`Error: ${resp.status} (${resp.statusText})`);
      throw new Error(resp.statusText);
    } else if (resp.headers.get('Content-Length') !== '0') return resp.json();
    else return Promise.resolve(null);
  });
};

export function postFile({ data, url, beforeSend }) {
  return $.ajax({
    // Settings
    type: 'POST',
    contentType: false,
    cache: false,
    processData: false,
    beforeSend,
    url,
    data,
    headers: { 'X-XSRF-TOKEN': getCookieValue(COOKIE_XSRF_TOKEN) },
    crossDomain: true,
    xhrFields: {
      withCredentials: true,
    },
  });
}
