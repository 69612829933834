import { DISABLE_ALL_MAP_TOOLS, DISABLE_MAP_TOOLS } from './MapToolbarActionTypes';

const initialState = {
  customMarker: false,
  distance: false,
  area: false,
  address: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DISABLE_ALL_MAP_TOOLS:
      return {
        ...state,
        customMarker: action.disable,
        distance: action.disable,
        area: action.disable,
        address: action.disable,
      };
    case DISABLE_MAP_TOOLS:
      return {
        ...state,
        ...action.toolsToDisable.reduce((tools, name) => ({ ...tools, [name]: action.disable }), {}),
      };
    default:
      return state;
  }
}
