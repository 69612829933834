import PropTypes from 'prop-types';

export const SERVIENT_TYPE = 1;
export const DOMINANT_TYPE = 2;
export const PARTY_TYPE = 3;
export const ROAD_UNIT_TYPE = 301;
export const UNLEGALIZED_RIGHT_OF_WAY_TYPE = 320;

export const UsufructUnitPartType = PropTypes.shape({
  id: PropTypes.number,
  description: PropTypes.shape({
    fi: PropTypes.string,
    sv: PropTypes.string,
  }),
  endDate: PropTypes.string,
  partNumber: PropTypes.string,
  userParties: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      relationType: PropTypes.number,
      registerUnit: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          realestateId: PropTypes.string,
          propertyId: PropTypes.string,
        })
      ),
    })
  ),
  validityDescription: PropTypes.shape({
    fi: PropTypes.string,
    sv: PropTypes.string,
  }),
  width: PropTypes.string,
});

export const UsufructType = {
  usufructTypeText: PropTypes.shape({
    fi: PropTypes.string.isRequired,
    sv: PropTypes.string.isRequired,
  }).isRequired,
  usufructId: PropTypes.string.isRequired,
  displayId: PropTypes.string.isRequired,
  registrationDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  name: PropTypes.string,
  roadMunicipalities: PropTypes.arrayOf(
    PropTypes.shape({
      homeMunicipalityendDate: PropTypes.string,
      homeMunicipalityName: PropTypes.shape({
        fi: PropTypes.string.isRequired,
        sv: PropTypes.string.isRequired,
      }),
      privateRoadMaintenanceAssociationName: PropTypes.string,
      privateRoadMaintenanceAssociationId: PropTypes.number,
    })
  ),
  hasPrivateCoastAssociation: PropTypes.bool,
  hasPrivateRoadAssociation: PropTypes.bool,
  regulatoryDecisions: PropTypes.arrayOf(
    PropTypes.shape({
      authority: PropTypes.string.isRequired,
      decisionId: PropTypes.string.isRequired,
      decisionDate: PropTypes.string,
    })
  ),
  roadAssociationRules: PropTypes.number,
  usufructUnitParts: PropTypes.arrayOf(UsufructUnitPartType),
  geometry: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  }),
  locationMunicipalities: PropTypes.arrayOf(
    PropTypes.shape({
      calculatedLength: PropTypes.string,
      municipalityName: PropTypes.shape({
        fi: PropTypes.string.isRequired,
        sv: PropTypes.string.isRequired,
      }),
    })
  ),
  hasContactPersonWithAddress: PropTypes.bool,
};
