/*
 * Scroller docs:
 * https://github.com/malte-wessel/react-custom-scrollbars
 *
 * Related Stylesheed: components.scrollbars.styl
 * Contains styles for .scrollbar
 */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

const DEFAULT_OFFSET = 180;

const renderScrollVerticalThumb = ({ style, ...props }) => (
  <div {...props} className="scrollbar scrollbar-thumb vertical-scrollbar" style={style} />
);

const renderScrollVerticalTrack = ({ style, ...props }) => <div {...props} className="scrollbar-track" style={style} />;

const renderMainScrollView = ({ style, ...props }) => (
  <div {...props} id="maincontent" tabIndex="-1" className="scrollbar-view" style={style} />
);

const renderScrollView = ({ style, ...props }) => <div {...props} className="scrollbar-view" style={style} />;

function resizeObserverExists() {
  return typeof ResizeObserver !== 'undefined';
}

const VerticalScrollerComponent = ({
  heightOffset,
  shouldScrollToTop,
  scrollTopTrigger,
  className,
  children,
  maincontent = false,
}) => {
  const scrollbarRef = useRef();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [, setChildrenHeight] = useState();

  // Window and child resize listeners/observers
  useEffect(() => {
    const ro = resizeObserverExists()
      ? // eslint-disable-next-line compat/compat
        new ResizeObserver(entries => entries.forEach(entry => setChildrenHeight(entry.contentRect.height)))
      : null;
    const updateDimensions = () => setWindowHeight(window.innerHeight);

    if (ro && scrollbarRef.current.view.childNodes.length) ro.observe(scrollbarRef.current.view.childNodes[0]);
    window.addEventListener('resize', updateDimensions);
    return () => {
      if (ro) ro.disconnect();
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  // Scroll to top triggering
  useEffect(() => {
    (() => {
      if (!scrollbarRef.current) {
        console.error('scrollbar scroll method called before it exists.');
        return;
      }
      if (shouldScrollToTop()) {
        scrollbarRef.current.scrollTop();
      }
    })();
  }, [scrollTopTrigger, shouldScrollToTop]);

  return (
    <Scrollbars
      renderThumbVertical={renderScrollVerticalThumb}
      renderTrackVertical={renderScrollVerticalTrack}
      renderView={maincontent ? renderMainScrollView : renderScrollView}
      className={className}
      style={{ height: `${windowHeight - heightOffset}px` }}
      ref={scrollbarRef}
      data-testid="VerticalScrollerComponent"
      data-maincontent={maincontent ? 1 : 0}
    >
      {children}
    </Scrollbars>
  );
};

VerticalScrollerComponent.propTypes = {
  className: PropTypes.string,
  heightOffset: PropTypes.number,
  scrollTopTrigger: PropTypes.string,
  shouldScrollToTop: PropTypes.func,
  children: PropTypes.node.isRequired,
  maincontent: PropTypes.bool,
};

VerticalScrollerComponent.defaultProps = {
  heightOffset: DEFAULT_OFFSET,
  scrollTopTrigger: null,
  shouldScrollToTop: () => false,
  className: '',
  maincontent: false,
};

export default VerticalScrollerComponent;
