import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

const SnapshotScreenLayerComponent = () => {
  const { t } = useTranslation();
  const iframeHeight = useSelector(state => state.oskariIframe.iframeHeight);
  const iframeWidth = useSelector(state => state.oskariIframe.iframeWidth);
  const pixelMeasuresInScale = useSelector(state => state.oskariIframe.pixelMeasuresInScale, shallowEqual);
  const visuMapMeasuresInScale = useSelector(state => state.oskariIframe.visuMapMeasuresInScale, shallowEqual);
  const isEnabled = useSelector(state => state.snapshotPreviewArea.snapshotSectionEnabled);
  const innerAreaIsEnabled = useSelector(state => state.snapshotPreviewArea.innerAreaIsEnabled);

  const getBoxDimensions = inputBox => ({
    top: (iframeHeight - inputBox.pixelMeasures[1]) / 2.0,
    left: (iframeWidth - inputBox.pixelMeasures[0]) / 2.0,
    width: inputBox.pixelMeasures[0],
    height: inputBox.pixelMeasures[1],
  });

  const buildBoxDimensionsStyle = outerBox => {
    const rawData = outerBox ? getBoxDimensions(pixelMeasuresInScale) : getBoxDimensions(visuMapMeasuresInScale);
    return {
      top: `${rawData.top}px`,
      left: `${rawData.left}px`,
      width: `${rawData.width}px`,
      height: `${rawData.height}px`,
    };
  };

  if (isEnabled) {
    return (
      <>
        <div className="snapshot__area" style={buildBoxDimensionsStyle(true)} data-testid="snapshot-area" />
        {innerAreaIsEnabled && (
          <div className="snapshot__area-inner" style={buildBoxDimensionsStyle(false)}>
            <span>{t('snapshotScreenLayer.preview')}</span>
          </div>
        )}
      </>
    );
  }
  return null;
};

export default SnapshotScreenLayerComponent;
