import { t } from 'i18next';

import { formatDateDM, isToday } from 'utils/datetime-utils';
import { addErrorNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';
import { isEmpty } from 'lodash';
import * as Types from './RealEstateListActionTypes';

export const setRegisterStateDate = registerStateDate => ({
  type: Types.SET_REGISTER_STATE_DATE,
  registerStateDate,
});

export const setRegisterState = (registerStateDate, reduxRegisterStateDate) => dispatch => {
  if (!registerStateDate) return;

  if (
    !isToday(new Date(registerStateDate)) &&
    (reduxRegisterStateDate === null || new Date(registerStateDate) < new Date(reduxRegisterStateDate))
  ) {
    dispatch(addErrorNotificationAction(t('error.registerState', { date: formatDateDM(registerStateDate) })));
    dispatch(setRegisterStateDate(registerStateDate));
  }
};

export const getEarliestRegisterStateDate = realEstates => {
  const dates = realEstates?.map(r => r.registerStateDate);
  const earliest = dates.sort((a, b) => Date.parse(a) - Date.parse(b))[0];
  return !isEmpty(dates) ? earliest : null;
};
