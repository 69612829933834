import { getConfiguration } from '../helpers/Configurations/Configurations';
import { selectedPaperAlign, selectedPaperSize } from '../containers/MapStoreCustomMapsForm/CustomMapsHelpers';
import {
  IS_BORDER_SEPARATORS,
  IS_CONTOURS,
  IS_COORDINATE_CROSS,
  IS_REAL_ESTATE_IDS,
  IS_REAL_ESTATE_INFO,
  MAP_FEATURE_DETAILS,
  PAPER_TYPE,
  REAL_ESTATE_ID_TYPE,
} from '../containers/MapStoreCustomMapsForm/CustomMapsFormConstants';
import { paperSizes } from '../helpers/Paper/Paper';
import * as envs from '../constants/Envs';

const getPrintLanguages = () => {
  const languages = [];
  languages[getConfiguration('map.product.content.settings.printInfoLang.fi')] = getConfiguration(
    'map.product.pdf.layer.languageFi'
  );
  languages[getConfiguration('map.product.content.settings.printInfoLang.sv')] = getConfiguration(
    'map.product.pdf.layer.languageSv'
  );
  languages[getConfiguration('map.product.content.settings.printInfoLang.en')] = getConfiguration(
    'map.product.pdf.layer.languageEn'
  );
  return languages;
};

const getPrintRealEstates = () => {
  const idTypes = [];
  idTypes[getConfiguration('map.product.content.settings.realEstateIdType.long')] = getConfiguration(
    'map.product.pdf.layer.realEstateId'
  );
  idTypes[getConfiguration('map.product.content.settings.realEstateIdType.short')] = getConfiguration(
    'map.product.pdf.layer.realEstateIdShort'
  );
  const borderColors = [];
  borderColors[getConfiguration('map.product.content.settings.realEstateBorderColor.red')] = getConfiguration(
    'map.product.pdf.layer.borderColorRed'
  );
  borderColors[getConfiguration('map.product.content.settings.realEstateBorderColor.black')] = getConfiguration(
    'map.product.pdf.layer.borderColorBlack'
  );

  const borderThicknesses = [];
  borderThicknesses[getConfiguration('map.product.content.settings.realEstateBorderThickness.thin')] = getConfiguration(
    'map.product.pdf.layer.borderThin'
  );
  borderThicknesses[
    getConfiguration('map.product.content.settings.realEstateBorderThickness.normal')
  ] = getConfiguration('map.product.pdf.layer.borderNormal');
  borderThicknesses[
    getConfiguration('map.product.content.settings.realEstateBorderThickness.thick')
  ] = getConfiguration('map.product.pdf.layer.borderThick');
  return { idTypes, borderColors, borderThicknesses };
};

const getAllPrintLayers = () => {
  return [
    getConfiguration('map.product.pdf.layer.terrain1'), // maastotiedot1
    getConfiguration('map.product.pdf.layer.terrain2'), // maastotiedot2
    getConfiguration('map.product.pdf.layer.terrain3'), // maastotiedot3
    getConfiguration('map.product.pdf.layer.terrain4'), // maastotiedot4
    getConfiguration('map.product.pdf.layer.nomenclature'), // nimisto
    getConfiguration('map.product.pdf.layer.realEstateInfo'), // kiinteistorajat
    getConfiguration('map.product.pdf.layer.realEstateId'), // kiinteistotunnukset
    getConfiguration('map.product.pdf.layer.realEstateIdShort'), // kiinteistotunnukset_lyhyt
    getConfiguration('map.product.pdf.layer.contour'), // korkeustiedot
    getConfiguration('map.product.pdf.layer.borderSeparator'), // rajamerkit,
    getConfiguration('map.product.pdf.layer.coordinateCross'), // koordinaattiristit
  ];
};

const getHideParamsForPrint = values => {
  const hideRealEstateIds = values[MAP_FEATURE_DETAILS][IS_REAL_ESTATE_IDS] === false;
  const hideRealEstateIdTypeLong = hideRealEstateIds
    ? true // NOSONAR
    : values[MAP_FEATURE_DETAILS][REAL_ESTATE_ID_TYPE] !== 'real_estate_id_type_long';
  const hideRealEstateIdTypeShort = hideRealEstateIds
    ? true // NOSONAR
    : values[MAP_FEATURE_DETAILS][REAL_ESTATE_ID_TYPE] !== 'real_estate_id_type_short';
  const hideRealEstateBorders = values[MAP_FEATURE_DETAILS][IS_REAL_ESTATE_INFO] === false;
  const hideContours = values[MAP_FEATURE_DETAILS][IS_CONTOURS] === false;
  const hideBorderSeparators = values[MAP_FEATURE_DETAILS][IS_BORDER_SEPARATORS] === false;
  const hideCoordinateCross = values[MAP_FEATURE_DETAILS][IS_COORDINATE_CROSS] === false;
  // true == hidden
  return [
    false, // maastotiedot1
    false, // maastotiedot2
    false, // maastotiedot3
    false, // maastotiedot4
    false, // nimisto
    hideRealEstateBorders, // kiinteistorajat
    hideRealEstateIdTypeLong, // kiinteistotunnukset
    hideRealEstateIdTypeShort, // kiinteistotunnukset_lyhyt
    hideContours, // korkeustiedot,
    hideBorderSeparators, // rajamerkit,
    hideCoordinateCross, // koordinaattiristit
  ];
};

export const getParamsForPrint = (formValues, state) => {
  const { borderColors, borderThicknesses } = getPrintRealEstates();
  const width = envs.VISU_MAP_PRINT_PICTURE_SIZE_PX;
  const height = envs.VISU_MAP_PRINT_PICTURE_SIZE_PX;
  const { scale } = state.oskariIframe.pixelMeasuresInScale;
  const centre = `${state.oskariIframe.coords.centerX},${state.oskariIframe.coords.centerY}`;
  const layers = [
    getConfiguration('map.product.pdf.layer.terrain1'), // maastotiedot1
    getConfiguration('map.product.pdf.layer.terrain2'), // maastotiedot2
    getConfiguration('map.product.pdf.layer.terrain3'), // maastotiedot3
    getConfiguration('map.product.pdf.layer.terrain4'), // maastotiedot4
    getConfiguration('map.product.pdf.layer.nomenclature'), // nimisto
  ];
  const fadeToWhite = [];
  if (formValues.groundInfoHue === getConfiguration('map.product.content.settings.groundInfoHue.pale')) {
    fadeToWhite.push(70); // maastotiedot1
    fadeToWhite.push(70); // maastotiedot2
    fadeToWhite.push(70); // maastotiedot3
    fadeToWhite.push(70); // maastotiedot4
    fadeToWhite.push(70); // nimisto
    fadeToWhite.push(0); // kiinteistorajat
    fadeToWhite.push(0); // kiinteistotunnukset
    fadeToWhite.push(0); // kiinteistotunnukset_lyhyt
    fadeToWhite.push(70); // korkeustiedot
    fadeToWhite.push(70); // rajamerkit
    fadeToWhite.push(70); // koordinattiristit
    // 100 white -> 0 normal
  }
  if (formValues.isRealEstateInfo === true) {
    layers.push(getConfiguration('map.product.pdf.layer.realEstateInfo'));
  }
  // kiinteistotunnukset
  if (formValues.isRealEstateIds === true) {
    layers.push(
      formValues.realEstateIdType === 'real_estate_id_type_short'
        ? getConfiguration('map.product.pdf.layer.realEstateIdShort') // pitka
        : getConfiguration('map.product.pdf.layer.realEstateId') // lyhyt
    );
  }
  const borderColor = borderColors[formValues.realEstateBorderColor.value];
  const borderThickness = borderThicknesses[formValues.realEstateBorderThickness.value];
  if (formValues.isBorderSeparators === true) {
    layers.push(getConfiguration('map.product.pdf.layer.borderSeparator'));
  }
  if (formValues.isContours === true) {
    layers.push(getConfiguration('map.product.pdf.layer.contour'));
  }
  if (formValues.isCoordinateCross === true) {
    layers.push(getConfiguration('map.product.pdf.layer.coordinateCross'));
  }
  return {
    width,
    height,
    scale,
    centre,
    layers,
    fadeToWhite,
    borderColor,
    hide: [],
    borderThickness,
  };
};

export const getParamsForPdf = (values, state) => {
  const paperSizeCode = selectedPaperSize(values[PAPER_TYPE]);
  const paperAlignment = selectedPaperAlign(values[PAPER_TYPE]);
  const paperObject =
    paperAlignment === 'portrait'
      ? paperSizes[paperSizeCode].getPrintPortrait()
      : paperSizes[paperSizeCode].getPrintLandscape();
  return {
    ...getParamsForPrint(values[MAP_FEATURE_DETAILS], state),
    width: paperObject[0],
    height: paperObject[1],
    layers: getAllPrintLayers(),
    hide: getHideParamsForPrint(values),
    header: values.mapFeatureDetails.printHeader,
    lang: getPrintLanguages()[values[MAP_FEATURE_DETAILS].printInfoLang.value],
  };
};
