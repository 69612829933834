import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { postFile } from 'common/api/index';
import { t } from 'i18next';

const noop = () => null;

const getErrorMessage = errorMessage => (errorMessage ? errorMessage[0] : '');

const errorAlert = errorTextKey =>
  // eslint-disable-next-line no-alert
  alert(t(errorTextKey));

const appendFormData = ({ file, id, type, formId }) => {
  const data = new FormData();
  data.append('file', file);
  data.append('guid', id);
  data.append('type', type);
  data.append('formId', formId);
  return data;
};

const FileSender = ({
  upload,
  type,
  formId,
  url,
  onDone,
  fileTypeErrorText,
  onBeforeSend,
  onUploadError,
  removeUpload,
  children,
}) => {
  const [progress, setProgress] = useState({ loaded: 0, total: 0 });
  const [isError, setIsError] = useState(false);

  const handleDone = (data, statusParam, request) => {
    const filename = upload.file.name;
    const responseFilename = data.filename;
    const attachmentType = type;

    onDone({
      responseFilename,
      filename,
      attachmentType,
      statusParam,
      xhr: request,
      id: upload.id,
    });
  };

  const handleError = request => {
    const errorMessage = getErrorMessage(JSON.parse(request.response).globalErrors);
    if (errorMessage === 'file type not supported') {
      errorAlert(fileTypeErrorText);
    } else if (errorMessage === 'file is infected') {
      errorAlert('uploader.fileInfectedError');
    } else {
      errorAlert('uploader.defaultUploadError');
    }
    setIsError(true);
    onUploadError(upload.id);
  };

  const handleProgress = throttle(newProgress => {
    setProgress({ loaded: newProgress.loaded, total: newProgress.total });
  }, 200);

  const handleBeforeSend = (request, settings) => {
    request.upload.addEventListener('progress', handleProgress);
    onBeforeSend(request, settings, upload);
  };

  const handleFileUpload = () => {
    const data = appendFormData({ file: upload.file, id: upload.id, type, formId });
    postFile({ data, url, beforeSend: handleBeforeSend })
      .done(handleDone)
      .fail(handleError)
      .always(() => removeUpload(upload));
  };

  useEffect(() => {
    handleFileUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children(progress.loaded, progress.total, isError);
};

FileSender.propTypes = {
  upload: PropTypes.shape({
    id: PropTypes.string.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
  onBeforeSend: PropTypes.func,
  onUploadError: PropTypes.func,
  onDone: PropTypes.func,
  removeUpload: PropTypes.func.isRequired,
  fileTypeErrorText: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

FileSender.defaultProps = {
  onDone: noop,
  onBeforeSend: noop,
  onUploadError: noop,
  type: '',
};

export default FileSender;
