import { init as initConf } from 'common/helpers/Configurations/Configurations';
import { init as initError } from 'common/helpers/Error';
import { changeToPrerenderReadyPhase } from 'common/containers/KasiAppMode/KasiAppModeActions';
import { init as initAware } from 'common/helpers/ReduxAware';
import requestAuthorization from 'common/helpers/Authorization/Authorization';
import { initAuthorization } from 'common/containers/Authorization/AuthorizationComponent';
import { authenticateThunk } from 'common/helpers/Authentication/authenticationSlice';

const preRenderInit = async store => {
  initError(store.dispatch);

  try {
    await Promise.all([
      initConf(store.dispatch, store.getState),
      initAuthorization(store.getState),
      requestAuthorization(store.dispatch),
      store.dispatch(authenticateThunk()),
    ]);
    store.dispatch(changeToPrerenderReadyPhase());
    initAware(store.dispatch);
  } catch (e) {
    throw new Error(`pre render init failed: ${e}`);
  }

  // add more thunk calls that return promises to the list
  // if you need additional things to be done before React DOM rendering
};

export default preRenderInit;
