import { SHOW_SERVICE_CLOSED_MODAL, HIDE_SERVICE_CLOSED_MODAL } from './ServiceClosedModalActions';

const initialState = { isOpen: false };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_SERVICE_CLOSED_MODAL:
      return { ...state, isOpen: true };

    case HIDE_SERVICE_CLOSED_MODAL:
      return { ...state, isOpen: false };

    default:
      return state;
  }
}
