import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleCartNotificationBubble } from '../ShoppingCart/ShoppingCartActions';
import CartNotificationBubbleComponent from './CartNotificationBubbleComponent';
import { accessibleNotificationsCleared } from '../AccessibleNotifications/accessibleNotificationsSlice';
import { POLITE } from '../AccessibleNotifications/AccessibleNotificationsConstants';

const mapStateToProps = state => ({
  cartBubbleType: state.shoppingCart.notificationBubble.type,
  resetTimeouts: state.shoppingCart.notificationBubble.reset,
});

const mapDispatchToProps = dispatch => ({
  toggleCartBubble: bindActionCreators(toggleCartNotificationBubble, dispatch),
  clearPoliteNotifications: () => dispatch(accessibleNotificationsCleared(POLITE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartNotificationBubbleComponent);
