export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const TOGGLE_CART_NOTIFICATION_BUBBLE = 'TOGGLE_CART_NOTIFICATION_BUBBLE';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const UPDATE_AMOUNT = 'UPDATE_AMOUNT';
export const CLEAR_CART = 'CLEAR_CART';
export const PAYTRAIL_MESSAGE_LOADING = 'PAYTRAIL_MESSAGE_LOADING';
export const INITIALIZE_PURCHASE_ORDER = 'INITIALIZE_PURCHASE_ORDER';
export const READY_TO_PAY = 'READY_TO_PAY';
export const CLEAR_REDIRECT_URL = 'CLEAR_REDIRECT_URL';
export const UPDATE_SESSION_ID = 'UPDATE_SESSION_ID';
export const SESSION_VALIDATION_FAILED = 'SESSION_VALIDATION_FAILED';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const UPDATE_MAPSHEET_AMOUNT = 'UPDATE_MAPSHEET_AMOUNT';
