import {
  MAP_TOOL_ADDRESS,
  MAP_TOOL_DISTANCE,
  MAP_TOOL_AREA,
  MAP_TOOL_SELECTOR,
  MAP_TOOL_CUSTOM_MARKER,
  MAP_TOOL_FEEDBACK_MARKER,
} from './MapToolNames';
import { CLEAN_AREA, CLEAN_DISTANCE, SET_MAP_TOOL_BUTTON_ACTIVE } from './MapToolbarActionTypes';
import { turnSelectorOn, turnSelectorOff } from './SelectorActions';
import { turnAddressOn, turnAddressOff } from './AddressActions';
import {
  turnAreaOn,
  turnDistanceOn,
  turnAreaOff,
  turnDistanceOff,
  startAreaTool,
  startDistanceTool,
} from './MeasurementActions';
import { turnFeedbackOn, turnFeedbackOff } from './FeedbackMarkerActions';
import { turnCustomMarkerOn, turnCustomMarkerOff } from './CustomMarkerActions';
import { removeFeaturesByType } from '../../geometries/geometriesActions';
import * as OskariMap from '../../../oskari/OskariMap';
import { FEATURE_TYPES } from '../../constants/MapConstants';
import { disableClickSelectThunk, enableClickSelectThunk } from '../OskariMap/OskariMapActions';

// Lista eri työkaluista: tool-id -> thunk "factory" call
// Käytännössä ainoastaan turnOn- ja turnOff-parametrit eroavat eri thunkeissa (+ tool-id)
export const allMapTools = {
  [MAP_TOOL_CUSTOM_MARKER]: createEnableMapToolThunk(MAP_TOOL_CUSTOM_MARKER, turnCustomMarkerOn, turnCustomMarkerOff),
  [MAP_TOOL_ADDRESS]: createEnableMapToolThunk(MAP_TOOL_ADDRESS, turnAddressOn, turnAddressOff),
  [MAP_TOOL_DISTANCE]: createEnableMapToolThunk(MAP_TOOL_DISTANCE, startDistanceTool, turnDistanceOff),
  [MAP_TOOL_AREA]: createEnableMapToolThunk(MAP_TOOL_AREA, startAreaTool, turnAreaOff),
  [MAP_TOOL_SELECTOR]: createEnableMapToolThunk(MAP_TOOL_SELECTOR, turnSelectorOn, turnSelectorOff),
  [MAP_TOOL_FEEDBACK_MARKER]: createEnableMapToolThunk(MAP_TOOL_FEEDBACK_MARKER, turnFeedbackOn, turnFeedbackOff),
};

export function switchMapToolThunk(toolId, enable) {
  return (dispatch, getState) => {
    if (enable) {
      // muut työkalut pois päältä
      dispatch(disableClickSelectThunk());
      Object.keys(allMapTools)
        .filter(id => id !== toolId)
        .forEach(id => allMapTools[id](false)(dispatch, getState));
      // tämä työkalu päälle
      allMapTools[toolId](true)(dispatch, getState);
    } else {
      // tämä työkalu pois päältä
      allMapTools[toolId](false)(dispatch, getState);
      // valintatyökalu päälle
      allMapTools[MAP_TOOL_SELECTOR](true)(dispatch, getState);
      dispatch(enableClickSelectThunk());
    }
  };
}

export function switchAllMapToolsThunk(enable) {
  return (dispatch, getState) => {
    Object.keys(allMapTools).forEach(id => allMapTools[id](enable)(dispatch, getState));
  };
}

// one thunk creator for all map tools
function createEnableMapToolThunk(toolId, turnOn, turnOff) {
  return active => (dispatch, getState) => {
    if (stateChanges(active, getState, toolId)) {
      dispatch(setMapToolButtonActive(active, toolId));
      if (active) {
        turnOn(dispatch, getState);
      } else {
        turnOff(dispatch, getState);
      }
    }
  };
}

// resolves if the enabled state is going to change with the current tool
function stateChanges(active, getState, toolId) {
  return active !== getState().mapToolbar[toolId].active;
}

// one button enabler action for all map tools
export function setMapToolButtonActive(active, toolId) {
  return {
    type: SET_MAP_TOOL_BUTTON_ACTIVE,
    toolId,
    active,
  };
}

export const cleanDistance = () => ({ type: CLEAN_DISTANCE });
export const cleanArea = () => ({ type: CLEAN_AREA });

export const cleanMeasurementsThunk = () => dispatch => {
  dispatch(cleanArea());
  dispatch(cleanDistance());
  dispatch(removeFeaturesByType(FEATURE_TYPES.DISTANCE_MEASUREMENT));
  dispatch(removeFeaturesByType(FEATURE_TYPES.AREA_MEASUREMENT));
  OskariMap.removeDistance();
  OskariMap.removeArea();
};

export const hideMeasurementsThunk = () => dispatch => {
  dispatch(cleanArea());
  dispatch(cleanDistance());
  OskariMap.removeDistance();
  OskariMap.removeArea();
};

export const showMeasurementsThunk = () => dispatch => {
  // Measurement geometries needs to be loaded using drawing tools
  dispatch(turnDistanceOn);
  dispatch(turnDistanceOff);
  dispatch(turnAreaOn);
  dispatch(turnAreaOff);
};
