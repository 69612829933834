import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const NameValuePairList = ({ id, list, className }) => (
  <dl className={cls('name-value-pairs', className)}>
    {list.map(({ title, text }, i) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={`content-${id}-${i}`}
        className="pair"
      >
        <dt className="name">{title == null || title.length === 0 ? '\u00A0' : title}</dt>
        <dd className="value">{text}</dd>
      </div>
    ))}
  </dl>
);

NameValuePairList.propTypes = {
  id: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired
  ).isRequired,
  className: PropTypes.string,
};

NameValuePairList.defaultProps = {
  className: '',
};

export default NameValuePairList;
