import { STACKED_LAYOUT, COLUMN_LAYOUT } from 'common/constants/Layout';
import changeMobileViewAction from 'common/containers/MobileTools/MobileViewActions';
import * as mobileViews from 'common/containers/MobileTools/MobileViewNames';
import { businessPanelToggleAction } from 'common/containers/BusinessPanel/BusinessPanelActions';
import { trackLayoutChangeToDesktop, trackLayoutChangeToMobile } from 'common/helpers/Analytics/Analytics_eventmap';
import Analytics from 'common/helpers/Analytics/';
import {
  CHANGE_LAYOUT,
  UPDATE_SHOW_CURTAIN,
  RERENDER_STICKY_FOOTER,
  USE_SESSION,
  CLOSE_MAPTOOLS_SIDEBAR,
  TOGGLE_MAPTOOLS_SIDEBAR,
  OPEN_MAPTOOLS_SIDEBAR,
  UPDATE_OSKARI_CHANNEL_STATUS,
} from './KasiAppActionTypes';

function resolvePanelStates(layout, dispatch, getState) {
  if (layout === STACKED_LAYOUT) {
    // Business panel is open at desktop view
    if (getState().businessPanel.isOpen) {
      // Business panel is not open at mobile view
      if (getState().mobileView.currentMobileView !== mobileViews.MOBILE_BUSINESS_PANEL_VIEW) {
        // Opening business panel on mobile view
        dispatch(changeMobileViewAction(mobileViews.MOBILE_BUSINESS_PANEL_VIEW));
      }
    } else if (getState().mobileView.currentMobileView === mobileViews.MOBILE_BUSINESS_PANEL_VIEW) {
      // Business panel is closed at desktop view
      // Business panel is open at mobile view
      // Closing business panel at mobile view
      dispatch(changeMobileViewAction(mobileViews.MOBILE_MAP_VIEW));
    }
  }

  // Transforming mobile -> desktop
  if (layout === COLUMN_LAYOUT) {
    // Business panel was closed at desktop view
    if (!getState().businessPanel.isOpen) {
      // Getting the business panel toggle status at mobile view
      const businessPanelOpenedAtMobileView =
        getState().mobileView.currentMobileView === mobileViews.MOBILE_BUSINESS_PANEL_VIEW;

      // Toggling business panel based on mobile top tools toggle status
      dispatch(businessPanelToggleAction(businessPanelOpenedAtMobileView));
    }

    // Business panel is not open at mobile view
    if (getState().mobileView.currentMobileView !== mobileViews.MOBILE_BUSINESS_PANEL_VIEW) {
      // Closing business panel at desktop view
      dispatch(businessPanelToggleAction(false));
    }
  }
}

export function changeLayoutAction(layout) {
  return {
    type: CHANGE_LAYOUT,
    layout,
  };
}

export function updateShowCurtainThunk(showCurtain) {
  return dispatch => {
    dispatch(updateShowCurtainAction(showCurtain));
  };
}

function updateShowCurtainAction(showCurtain) {
  return {
    type: UPDATE_SHOW_CURTAIN,
    showCurtain,
  };
}

export function changeLayoutThunk(layout) {
  return (dispatch, getState) => {
    Analytics().ignoreNextPageView();

    if (layout === STACKED_LAYOUT) {
      trackLayoutChangeToMobile();
    } else if (layout === COLUMN_LAYOUT) {
      trackLayoutChangeToDesktop();
    }

    resolvePanelStates(layout, dispatch, getState);
    dispatch(changeLayoutAction(layout));
  };
}

export function reRenderStickyFooter() {
  return {
    type: RERENDER_STICKY_FOOTER,
  };
}

export function useSession() {
  return { type: USE_SESSION };
}

export function closeSidePanel() {
  return { type: CLOSE_MAPTOOLS_SIDEBAR };
}

export function sidebarToggleAction() {
  return {
    type: TOGGLE_MAPTOOLS_SIDEBAR,
  };
}

export function openSidePanel() {
  return { type: OPEN_MAPTOOLS_SIDEBAR };
}

export function updateOskariChannelStatus(oskariChannelStatus) {
  return {
    type: UPDATE_OSKARI_CHANNEL_STATUS,
    oskariChannelStatus,
  };
}
