export const NOT_A_LIST = 'method list must be an array';
// Use in REact classes right after const -> super(props)
export default function bindCollection(list, context) {
  const functionContext = context || this;
  if (Array.isArray(list) === false) {
    throw new Error(NOT_A_LIST);
  }

  list.forEach(func => {
    if (functionContext[func] !== undefined && functionContext[func] instanceof Function) {
      functionContext[func] = functionContext[func].bind(functionContext);
    }
  });

  return functionContext;
}
