import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Glyphicon } from 'common/components/Icon';
import cls from 'classnames';
import { Link } from 'react-router-dom';
import { ACTIVE_MENU_ITEM } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';
import { isEven } from 'realEstateSite/containers/Realty/RealEstate/RealEstateHelpers';
import useAutomaticKeyboardFocus from 'common/containers/AutomaticKeyboardFocus/useAutomaticKeyboardFocus';
import ScrollerPanelActiveItem from './ScrollerPanelActiveItem';

const ScrollerPanelListItem = ({
  active,
  hidden,
  linkUrl,
  className,
  isMainSection,
  hideDivider,
  mortgageDetails,
  frame,
  activityItem,
  selector,
  panelWithLink,
  columnNumber,
  even,
  proprietorListStyles,
  dataTestId,
  ...rest
}) => {
  const dispatch = useDispatch();

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      dispatch(requestKeyboardFocusAction({ selector }));
    }
  };

  return (
    <li
      className={cls('panel-list__item', className, {
        selected: active,
        frame,
        'activity-item-rows': activityItem,
        'mortgage-list__item': mortgageDetails,
        'realty-list__item__with-link': panelWithLink,
      })}
    >
      {active && (
        <ScrollerPanelActiveItem dataTestId={dataTestId} even={even} proprietorListStyles={proprietorListStyles} />
      )}
      {linkUrl && (
        <ItemWithLink
          hidden={hidden}
          className={className}
          linkUrl={linkUrl}
          active={active}
          onKeyDown={onKeyDown}
          isMainSection={isMainSection}
          hideDivider={hideDivider}
          panelWithLink={panelWithLink}
          columnNumber={columnNumber}
          {...rest}
        />
      )}
      {!linkUrl && (
        <ItemWithoutLink tabIndex={hidden ? -1 : 0} className={className} hideDivider={hideDivider} {...rest} />
      )}
    </li>
  );
};

const ItemWithLink = ({
  className,
  hidden,
  children,
  linkUrl,
  onClick,
  onKeyDown,
  active,
  isMainSection,
  hideDivider,
  panelWithLink,
  columnNumber,
}) => {
  const linkRef = useRef(null);
  const autoFocus = useSelector(state => state.automaticKeyboardFocus);
  const focusItem = (active || className.includes(ACTIVE_MENU_ITEM)) && autoFocus?.selector === ACTIVE_MENU_ITEM;

  useAutomaticKeyboardFocus(focusItem, linkRef);

  return (
    <Link
      tabIndex={hidden ? -1 : null}
      ref={linkRef}
      draggable="false"
      onClick={onClick}
      onKeyDown={onKeyDown}
      to={linkUrl}
      relative="path"
      className={cls('panel-list-item__link', active ? ACTIVE_MENU_ITEM : '', {
        'panel-link__even': panelWithLink && isEven(columnNumber),
      })}
    >
      <div className={cls('panel-list-item__content', className, { 'with-divider': !hideDivider })}>{children}</div>
      {!active && isMainSection && <Glyphicon glyph="nuoli-oikealle" className="panel-list-item-link__icon" />}
    </Link>
  );
};

ItemWithLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  linkUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  active: PropTypes.bool,
  hidden: PropTypes.bool,
  isMainSection: PropTypes.bool,
  hideDivider: PropTypes.bool,
  panelWithLink: PropTypes.bool,
  columnNumber: PropTypes.number,
};

ItemWithLink.defaultProps = {
  onClick: () => null,
  onKeyDown: () => null,
  className: '',
  children: null,
  active: false,
  hidden: false,
  isMainSection: true,
  hideDivider: false,
  panelWithLink: false,
  columnNumber: 1,
};

const ItemWithoutLink = ({ className, children, hideDivider }) => (
  <div className={cls('panel-list-item__no-link', className)}>
    <div className={cls('panel-list-item__content', { 'with-divider': !hideDivider })}>{children}</div>
  </div>
);

ItemWithoutLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  hideDivider: PropTypes.bool,
};

ItemWithoutLink.defaultProps = {
  className: '',
  children: null,
  hideDivider: false,
};

ScrollerPanelListItem.propTypes = {
  active: PropTypes.bool,
  hidden: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  linkUrl: PropTypes.string,
  externalUrl: PropTypes.string,
  onClick: PropTypes.func,
  isMainSection: PropTypes.bool,
  hideDivider: PropTypes.bool,
  mortgageDetails: PropTypes.bool,
  frame: PropTypes.bool,
  activityItem: PropTypes.bool,
  selector: PropTypes.string,
  panelWithLink: PropTypes.bool,
  columnNumber: PropTypes.number,
  even: PropTypes.bool,
  proprietorListStyles: PropTypes.bool,
  dataTestId: PropTypes.string,
};

ScrollerPanelListItem.defaultProps = {
  onClick: () => null,
  className: '',
  active: false,
  hidden: false,
  linkUrl: undefined,
  externalUrl: undefined,
  isMainSection: true,
  hideDivider: false,
  mortgageDetails: false,
  frame: false,
  activityItem: false,
  selector: undefined,
  panelWithLink: false,
  columnNumber: 1,
  even: false,
  proprietorListStyles: false,
  dataTestId: '',
};

export default ScrollerPanelListItem;

export { ItemWithoutLink, ItemWithLink };
