export const TOGGLE_MAP_BACK_BUTTON_VISIBILITY = 'TOGGLE_MAP_BACK_BUTTON_VISIBILITY';
export const ENABLE_IFRAME_EVENTS = 'ENABLE_IFRAME_EVENTS';
export const SET_OSKARI_MAP_COORDINATES = 'SET_OSKARI_MAP_COORDINATES';
export const SET_OSKARI_MAP_OFFSET = 'SET_OSKARI_MAP_OFFSET';
export const SET_OSKARI_MAP_PIXEL_MEASURES_IN_SCALE = 'SET_OSKARI_MAP_PIXEL_MEASURES_IN_SCALE';
export const SET_VISU_MAP_MEASURES_IN_SCALE = 'SET_VISU_MAP_MEASURES_IN_SCALE';
export const SET_TRACKING_USER = 'SET_TRACKING_USER';
export const SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL';
export const GET_SCREENSHOT = 'GET_SCREENSHOT';
export const MAP_LOADING = 'MAP_LOADING';
export const SET_LAYERS_LOADED = 'SET_LAYERS_LOADED';
export const TOGGLE_AUTOMATIC_MAP_CENTERING = 'TOGGLE_AUTOMATIC_MAP_CENTERING';
export const SET_OSKARI_MAP_CENTER_COORDINATES = 'SET_OSKARI_MAP_CENTER_COORDINATES';
export const SET_OSKARI_MAP_MOVED_AUTOMATICALLY = 'SET_OSKARI_MAP_MOVED_AUTOMATICALLY';
