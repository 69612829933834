import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { activitiesNeedsToBeLoaded } from 'realEstateSite/containers/Realty/RealEstate/Activity/ActivityComponent/activityLogSlice';
import { geometryTypeCleared } from 'realEstateSite/containers/Realty/common/geometries/geometriesSlice';

import scrollTop from 'common/helpers/Scroller/Scroller';
import { Glyphicon } from 'common/components/Icon';
import { Block } from 'common/components/Grid';
import { ACTIVE_MENU_ITEM } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';
import { SELECTED } from 'realEstateSite/containers/Realty/common/geometries/geometriesConstants';

const UserMenuHomeButton = ({ homeUrl, isMobile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clearGeometries = useCallback(() => {
    dispatch(geometryTypeCleared(SELECTED));
  }, [dispatch]);

  const setLoadActivities = useCallback(() => {
    dispatch(activitiesNeedsToBeLoaded());
  }, [dispatch]);

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      dispatch(requestKeyboardFocusAction({ selector: ACTIVE_MENU_ITEM }));
    }
  };

  return (
    <Link
      to={homeUrl}
      onKeyDown={onKeyDown}
      onClick={() => {
        setLoadActivities();
        clearGeometries();
        setTimeout(scrollTop, 0);
      }}
      className={cls('button', 'button--flexible', 'user-menu__item-block')}
    >
      <Block className="item-block__content">
        <Glyphicon glyph="home" />
        <span className={cls('indent-block-1', { 'visually-hidden': isMobile })}>{t('usermenu.frontpageButton')}</span>
      </Block>
    </Link>
  );
};

UserMenuHomeButton.propTypes = {
  homeUrl: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

UserMenuHomeButton.defaultProps = {
  isMobile: false,
};

export default UserMenuHomeButton;
