import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Timeline = ({ className, children }) => <dl className={cls('nls-timeline', className)}>{children}</dl>;

Timeline.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Timeline.defaultProps = {
  className: '',
  children: null,
};

export default Timeline;
