import React from 'react';
import Glyphicon from 'common/components/Icon/Glyphicon';

const MapSiteIcons = () => (
  <span className="mapsite-icons__container">
    <Glyphicon glyph="ostoskarry" />
    <Glyphicon glyph="download-open-data" className="download-open-data-icon" />
    <Glyphicon glyph="home" />
    <Glyphicon glyph="nayta-kartalla" />
    <Glyphicon glyph="kirjekuori" />
  </span>
);

export default MapSiteIcons;
