import {
  ENABLE_IFRAME_EVENTS,
  SET_OSKARI_MAP_COORDINATES,
  SET_OSKARI_MAP_OFFSET,
  SET_OSKARI_MAP_PIXEL_MEASURES_IN_SCALE,
  SET_VISU_MAP_MEASURES_IN_SCALE,
  SET_TRACKING_USER,
  SET_ZOOM_LEVEL,
  MAP_LOADING,
  SET_LAYERS_LOADED,
  TOGGLE_MAP_BACK_BUTTON_VISIBILITY,
  TOGGLE_AUTOMATIC_MAP_CENTERING,
  SET_OSKARI_MAP_CENTER_COORDINATES,
  SET_OSKARI_MAP_MOVED_AUTOMATICALLY,
} from './OskariMapActionTypes';

const initialState = {
  eventsEnabled: true,
  iframeHeight: 1,
  iframeWidth: 1,
  coords: {
    centerY: 0,
    centerX: 0,
    marker: false,
    scale: 0,
    zoom: 6,
  },
  trackingUser: false,
  snapshotSectionEnabled: false,
  pixelMeasuresInScale: { pixelMeasures: [0, 0], scale: 10000 },
  visuMapMeasuresInScale: { pixelMeasures: [0, 0], scale: 10000 },
  isLoading: false,
  layersLoaded: false,
  backButtonVisible: false,
  zoom: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ENABLE_IFRAME_EVENTS:
      return { ...state, eventsEnabled: action.enabled };
    case SET_OSKARI_MAP_COORDINATES:
      if (action.coords.centerX !== 0 || action.coords.centerY !== 0) {
        return { ...state, coords: action.coords };
      }
      return state;
    case SET_OSKARI_MAP_OFFSET:
      return { ...state, iframeHeight: action.height, iframeWidth: action.width };
    case SET_OSKARI_MAP_PIXEL_MEASURES_IN_SCALE:
      return { ...state, pixelMeasuresInScale: action.measures };
    case SET_VISU_MAP_MEASURES_IN_SCALE:
      return { ...state, visuMapMeasuresInScale: action.measures };
    case MAP_LOADING:
      return { ...state, isLoading: action.isLoading };
    case SET_TRACKING_USER:
      return { ...state, trackingUser: action.trackingUser };
    case SET_ZOOM_LEVEL:
      return { ...state, zoom: action.zoomLevel };
    case SET_LAYERS_LOADED:
      return { ...state, layersLoaded: action.isLoaded };
    case TOGGLE_MAP_BACK_BUTTON_VISIBILITY:
      return { ...state, backButtonVisible: action.visible };
    case TOGGLE_AUTOMATIC_MAP_CENTERING:
      if (action.enabled === true) {
        return { ...state, automaticMapCentering: action.enabled, centerCoords: null };
      }
      return { ...state, automaticMapCentering: action.enabled };
    case SET_OSKARI_MAP_CENTER_COORDINATES:
      return { ...state, centerCoords: action.coords };
    case SET_OSKARI_MAP_MOVED_AUTOMATICALLY:
      return { ...state, mapMovedAutomatically: action.value };
    default:
      return state;
  }
}
