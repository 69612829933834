import { getSnapshotPreviewPicture } from 'common/api/CommonApi';
import { getParamsForPrint } from 'common/api/PrintApi';
import {
  UPDATE_SNAPSHOT_AREA_IMAGE,
  SNAPSHOT_AREA_PREVIEW_LOADING,
  ENABLE_SNAPSHOT_AREA,
  ENABLE_SNAPSHOT_PRINT_PREVIEW_AREA,
} from './SnapshotPreviewAreaActionTypes';

export function enableSnapshotArea(enabled) {
  return {
    type: ENABLE_SNAPSHOT_AREA,
    enabled,
  };
}

export function enableSnapshotPrintPreviewArea(enabled) {
  return {
    type: ENABLE_SNAPSHOT_PRINT_PREVIEW_AREA,
    enabled,
  };
}

function updateSnapshotAreaImage(img) {
  return {
    type: UPDATE_SNAPSHOT_AREA_IMAGE,
    img,
  };
}

const snapshotAreaPreviewIsLoading = isLoading => ({
  type: SNAPSHOT_AREA_PREVIEW_LOADING,
  loading: isLoading,
});

export const getSnapshotAreaImageThunk = values => (dispatch, getState) => {
  if (!getState().snapshotPreviewArea.snapshotAreaPreviewLoading) {
    const params = getParamsForPrint(values, getState());
    dispatch(snapshotAreaPreviewIsLoading(true));
    getSnapshotPreviewPicture(params)
      .then(data => {
        dispatch(updateSnapshotAreaImage(data.imageStream));
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        dispatch(snapshotAreaPreviewIsLoading(false));
      });
  }
};
