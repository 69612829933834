let _channel;
let handleRPCError;

let _searchResultCallback;
let _searchResultHandlerInitialized;

export default function initSearchModule(channel, errorHandler) {
  _channel = channel;
  handleRPCError = errorHandler;
  _searchResultHandlerInitialized = false;
}

function handleSearchResultEvent(data) {
  _searchResultCallback(data);
}

export function search(searchParam, cb, errorCb) {
  _searchResultCallback = cb;
  if (!_searchResultHandlerInitialized) {
    _channel.handleEvent('SearchResultEvent', handleSearchResultEvent, (error, message) => {
      handleRPCError(error, message);
      errorCb();
    });
    _searchResultHandlerInitialized = true;
  }

  _channel.postRequest('SearchRequest', [searchParam.searchString]);
}
