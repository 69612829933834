import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const ScrollerPanelPage = ({ children, className }) => {
  return <div className={cls('scroller-panel__page', className)}>{children}</div>;
};

ScrollerPanelPage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ScrollerPanelPage.defaultProps = {
  children: null,
  className: '',
};

export default ScrollerPanelPage;
