import { GEOMETRY_FILL_COLORS } from './ColorConstants';

const generateRGBAString = ({ color, alpha = 1 }) => `rgba(${color},${alpha})`;

// Picks the next color in the list GEOMETRY_FILL_COLORS and returns an rgba-color based on it
let _currentFillColorPointer = 0;

const getNextFillColor = () => {
  const color = GEOMETRY_FILL_COLORS[_currentFillColorPointer % GEOMETRY_FILL_COLORS.length];
  _currentFillColorPointer += 1;
  return color;
};

const getNextFillColorRGBA = ({ alpha } = {}) => {
  const color = getNextFillColor();
  return generateRGBAString({ color, alpha });
};

const resetFillColor = () => {
  _currentFillColorPointer = 0;
};

const ColorProducer = {
  generateRGBAString,
  getNextFillColor,
  getNextFillColorRGBA,
  resetFillColor,
};

Object.freeze(ColorProducer);
export default ColorProducer;
