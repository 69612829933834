import i18next from 'i18next';

import { isEmpty } from 'common/helpers/isEmpty';
import {
  validateBusinessId,
  validateRealEstateIdentifier,
  validateSocialSecurityNumber,
} from 'common/containers/Forms/FormValidation';
import ServicesApi from 'realEstateSite/api/ServicesApi';
import {
  formatRealEstateId,
  isCeased,
  RESTRICTED_REGISTER_UNIT_TYPES,
} from 'realEstateSite/containers/Realty/RealEstate/RealEstateHelpers';
import INPUT_ERROR from 'common/constants/InputValidationConstants';
import { setRegisterState } from '../../Realty/RealEstate/RealEstateList/RegisterStateActions';

const { t, language } = i18next;

const SEARCH_ERROR = 'error.general.function';

const CLOSED_REGISTER_UNIT_TYPE = '12';
const SUPPLEMENTARY_RIGHT_OF_WAY_REGISTER_UNIT_TYPE = '21';
const SUPPLEMENTARY_PROPRIETORSHIP_REGISTER_UNIT_TYPE = '22';

const rejectInvalidRealEstateId = () =>
  // eslint-disable-next-line prefer-promise-reject-errors
  Promise.reject({ targetSearch: 'realEstate.search.validation.realEstateIdentifier.error' });

const rejectDuplicateRealEstateId = () =>
  // eslint-disable-next-line prefer-promise-reject-errors
  Promise.reject({ targetSearch: 'realEstate.search.duplicateRealEstate.text' });

const rejectDifferentMunicipality = () =>
  // eslint-disable-next-line prefer-promise-reject-errors
  Promise.reject({ targetSearch: 'realEstate.amalgamation.step1.differentMunicipality.error' });

const rejectNotRegisterUnit = () =>
  // eslint-disable-next-line prefer-promise-reject-errors
  Promise.reject({ targetSearch: 'realEstate.amalgamation.step1.notRegisterUnit.error' });

export const searchRealEstates = (searchId, showCeasedRealEstates = false, isRegistrationApplication = false) => {
  const errors = {};
  return ServicesApi.searchRealEstates([searchId])
    .then(realEstates => {
      if (isEmpty(realEstates)) {
        errors.targetSearch = 'realEstate.search.noResults.text';
        throw errors;
      }
      if (!showCeasedRealEstates && realEstates.find(r => isCeased(r.state))) {
        errors.targetSearch = 'realEstate.search.ceased.text';
        throw errors;
      }
      if (
        isRegistrationApplication &&
        realEstates.find(
          r =>
            r.registerUnitType === CLOSED_REGISTER_UNIT_TYPE ||
            r.registerUnitType === SUPPLEMENTARY_RIGHT_OF_WAY_REGISTER_UNIT_TYPE ||
            r.registerUnitType === SUPPLEMENTARY_PROPRIETORSHIP_REGISTER_UNIT_TYPE
        )
      ) {
        errors.targetSearch = 'realEstate.search.terminated.text';
        throw errors;
      } else return Promise.resolve(realEstates);
    })
    .catch(e => {
      if (e instanceof Function && e.includes(504)) {
        console.error(e);
        errors.targetSearch = t('error.general.notResponding');
      }

      if (!errors.targetSearch) errors.targetSearch = t('error.general.notResponding');
      throw errors;
    });
};

export const asyncValidateRealEstateSearch = (targetSearch, acceptFacility = true) => {
  if (!validateRealEstateIdentifier(targetSearch, acceptFacility)) {
    return rejectInvalidRealEstateId();
  }
  const showCeasedRealEstates = true;
  return searchRealEstates(targetSearch, showCeasedRealEstates);
};

export const asyncValidateCadastralSurveyRealEstateSearch = (targetSearch, otherRealEstateIds = []) => {
  if (!validateRealEstateIdentifier(targetSearch, false)) {
    return rejectInvalidRealEstateId();
  }
  if (otherRealEstateIds.includes(formatRealEstateId(targetSearch))) {
    return rejectDuplicateRealEstateId();
  }
  return searchRealEstates(targetSearch);
};

export const asyncValidateAORRealEstateSearch = async (targetSearch, otherRealEstateIds, targetRealEstate) => {
  if (!validateRealEstateIdentifier(targetSearch, true)) {
    return rejectInvalidRealEstateId();
  }
  if (otherRealEstateIds.includes(formatRealEstateId(targetSearch))) {
    return rejectDuplicateRealEstateId();
  }

  const realEstate = await searchRealEstates(targetSearch);

  if (realEstate[0].type !== 'registerUnit') {
    return rejectNotRegisterUnit();
  }

  if (realEstate[0].municipalityNumber !== targetRealEstate.municipalityNumber) {
    return rejectDifferentMunicipality();
  }

  return realEstate;
};

/**
 * @param {Number} lon
 * @param {Number} lat
 * @param {Array<String>} otherRealEstateIds
 * @param targetRealEstate
 * @param {String} registerStateDate
 * @param {Function} dispatch
 */
export const asyncValidateAORRealEstateClickSearch = async (
  lon,
  lat,
  otherRealEstateIds,
  targetRealEstate,
  registerStateDate,
  dispatch
) => {
  const realEstate = await ServicesApi.fetchRegisterUnitForPoint(lat, lon);
  realEstate && dispatch(setRegisterState(realEstate.registerStateDate, registerStateDate));

  if (otherRealEstateIds.includes(realEstate.id)) {
    return rejectDuplicateRealEstateId();
  }

  if (realEstate.municipalityNumber !== targetRealEstate.municipalityNumber) {
    return rejectDifferentMunicipality();
  }

  return realEstate;
};

export const asyncValidateLCAGRealEstateSearch = (
  reId,
  otherRealEstates,
  allowDuplicates,
  isRegistrationApplication = false
) => {
  const otherRealEstateIds = otherRealEstates.map(({ id }) => id);
  if (!validateRealEstateIdentifier(reId, false)) {
    return rejectInvalidRealEstateId();
  }
  if (!allowDuplicates && otherRealEstateIds.includes(reId)) {
    return rejectDuplicateRealEstateId();
  }
  return searchRealEstates(reId, false, isRegistrationApplication);
};

export const asyncValidateLRARealEstateSearch = (reId, otherRealEstates) => {
  const otherRealEstateIds = otherRealEstates.map(({ id }) => id);
  if (!validateRealEstateIdentifier(reId, false)) {
    return rejectInvalidRealEstateId();
  }
  if (otherRealEstateIds.includes(formatRealEstateId(reId))) {
    return rejectDuplicateRealEstateId();
  }
  const isRegistrationApplication = true;
  return searchRealEstates(reId, false, isRegistrationApplication);
};

export const asyncValidateLegalConfirmationSearch = (targetSearch, otherRealEstates = []) => {
  const errors = {};
  const otherRealEstateIds = otherRealEstates.map(or => or.realEstate.id);
  if (!validateRealEstateIdentifier(targetSearch, false)) {
    return rejectInvalidRealEstateId();
  }
  if (otherRealEstateIds.includes(formatRealEstateId(targetSearch))) {
    return rejectDuplicateRealEstateId();
  }

  return ServicesApi.fetchLegalConfirmation(targetSearch)
    .then(legalConfirmation => {
      if (isEmpty(legalConfirmation) || isEmpty(legalConfirmation.realEstate)) {
        errors.targetSearch = 'realEstate.search.noResults.text';
        throw errors;
      }
      if (!isEmpty(legalConfirmation.realEstate.ceaseDate)) {
        errors.targetSearch = 'realEstate.search.ceased.text';
        throw errors;
      }
      if (RESTRICTED_REGISTER_UNIT_TYPES.includes(legalConfirmation.realEstate.registerUnitType)) {
        errors.targetSearch = 'realEstate.search.terminated.reta_text';
        throw errors;
      } else return Promise.resolve(legalConfirmation);
    })
    .catch(() => {
      if (!errors.targetSearch) errors.targetSearch = t('error.general.notResponding');
      throw errors;
    });
};

const duplicatePersonId = (searchId, personList) =>
  !isEmpty(personList) && personList.find(p => p.socialSecurityNumber === searchId.toUpperCase());

const duplicateBusinessId = (searchId, personList) =>
  !isEmpty(personList) && personList.find(p => p.organizationId === searchId.toUpperCase());

export const personSearchIdValidation = (searchId, field, personList) => {
  const errors = {};
  if (!validateSocialSecurityNumber(searchId) && !validateBusinessId(searchId)) {
    errors[field] = 'error.id.invalid';
    return Promise.reject(errors);
  }
  if (duplicatePersonId(searchId, personList)) {
    errors[field] = 'realEstate.person.search.duplicatePerson';
    return Promise.reject(errors);
  }
  if (duplicateBusinessId(searchId, personList)) {
    errors[field] = 'realEstate.person.search.duplicateBusiness';
    return Promise.reject(errors);
  }
  return Promise.resolve();
};

export const asyncValidatePersonSearch = async (field, linkedField, id) => {
  const errors = {};
  try {
    const people = await ServicesApi.getVTJPersons([id]);
    if (people[0].error) {
      errors[field] = t(SEARCH_ERROR);
      errors[linkedField] = INPUT_ERROR;
    }
    if (people[0].personNotFound) {
      errors[field] = field.includes('supportUserClientSearch')
        ? 'realEstate.person.search.supportUser.noResults'
        : 'realEstate.person.search.noResults';
      errors[linkedField] = INPUT_ERROR;
      throw errors;
    } else return Promise.resolve(people[0]);
  } catch {
    if (!errors[field]) errors[field] = t(SEARCH_ERROR);
    throw errors;
  }
};

export const asyncIsValidPerson = async (field, linkedField, id) => {
  const errors = {};
  try {
    const person = await ServicesApi.isValidVTJPerson(id);
    if (person.error) {
      if (person.queryNotAllowed) {
        errors[field] = t('realEstate.form.search.queryNotAllowed');
      } else {
        errors[field] = t(SEARCH_ERROR);
      }
    }
    if (person.personNotFound) {
      errors[field] = t('realEstate.person.search.noResults');
      errors[linkedField] = INPUT_ERROR;
    }
    if (errors[field]) {
      throw errors;
    } else {
      return Promise.resolve(true);
    }
  } catch {
    if (!errors[field]) errors[field] = t(SEARCH_ERROR);
    errors[linkedField] = INPUT_ERROR;
    throw errors;
  }
};

export const asyncValidateOrganizationSearch = async (field, linkedField, id) => {
  const errors = {};
  try {
    const organization = await ServicesApi.fetchOrganization(id, language);
    if (organization?.dissolved) {
      errors[field] = t('realEstate.person.search.dissolvedBusiness');
      errors[linkedField] = INPUT_ERROR;
      throw errors;
    }
    if (!organization) {
      errors[field] = t('realEstate.organization.search.noResults');
      errors[linkedField] = INPUT_ERROR;
      throw errors;
    } else return Promise.resolve(organization);
  } catch {
    if (!errors[field])
      errors[field] = field.includes('supportUserClientSearch')
        ? t('realEstate.person.search.supportUser.noResults')
        : t('realEstate.organization.search.noResults');
    errors[linkedField] = INPUT_ERROR;
    throw errors;
  }
};
