import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Glyphicon } from 'common/components/Icon';
import { Field } from 'react-final-form';
import HeadingWithSteps from 'common/components/HeadingWithSteps';
import { Header } from 'common/components/Header';
import Ruler from 'common/components/Ruler';
import { Block, FlexGrid, Row } from 'common/components/Grid';
import RouterLink from 'common/components/Link/RouterLink';
import { t } from 'i18next';
import { ValidatedRadioButton } from 'common/containers/Forms/InputValidation/InputValidationComponents';
import DisclaimerBox from 'common/components/DisclaimerBox';
import MapButton from 'realEstateSite/containers/Realty/RealEstate/common/MapButtonComponent';
import { requestKeyboardFocusAction } from '../AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';

const HeadingRow = React.forwardRef(({ text, subtitleText, step, numberOfSteps, className, headerClassName }, ref) => (
  <div ref={ref} tabIndex={-1} className={cls('heading-row', className)}>
    <Row className="margin-t-2">
      <Block size={6}>
        {step ? (
          <HeadingWithSteps
            text={text}
            className={headerClassName}
            currentStep={step}
            numberOfSteps={numberOfSteps}
            subtitle={subtitleText}
          />
        ) : (
          <Header text={text} className={headerClassName} />
        )}
      </Block>
    </Row>
    <Ruler />
  </div>
));

HeadingRow.propTypes = {
  text: PropTypes.string.isRequired,
  subtitleText: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  numberOfSteps: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  className: PropTypes.string,
  headerClassName: PropTypes.string,
};

HeadingRow.defaultProps = {
  step: null,
  numberOfSteps: null,
  className: '',
  headerClassName: '',
  subtitleText: undefined,
};

export const InfoRow = ({ title, text, text2, h3 }) => (
  <Row>
    <Block size={6}>{h3 ? <h3 className="h2">{title}</h3> : <h4 className="h2">{title}</h4>}</Block>
    <Block size={6} className="margin-b-1">
      {text && <p>{text}</p>}
      {text2 && <p>{text2}</p>}
    </Block>
  </Row>
);

InfoRow.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  text2: PropTypes.string,
  h3: PropTypes.bool,
};

InfoRow.defaultProps = {
  text: null,
  text2: null,
  h3: false,
};

export const InfoRowWithMapButton = ({ title, paragraph1, paragraph2, paragraph3, Element, className }) => (
  <Row className={className}>
    <Block size={6} className="info-row__with-button">
      <Element className="h2">{title}</Element>
      <MapButton className="button--icon" />
    </Block>
    <Block size={6}>
      {paragraph1 && <p className="margin-b-1">{paragraph1}</p>}
      {paragraph2 && <p className="margin-b-1">{paragraph2}</p>}
      {paragraph3 && <p className="margin-b-0">{paragraph3}</p>}
    </Block>
  </Row>
);

InfoRowWithMapButton.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph1: PropTypes.string,
  paragraph2: PropTypes.string,
  paragraph3: PropTypes.string,
  Element: PropTypes.string,
  className: PropTypes.string,
};

InfoRowWithMapButton.defaultProps = {
  paragraph1: null,
  paragraph2: null,
  paragraph3: null,
  Element: 'h4',
  className: '',
};

export const ContentRow = ({ title, testId, text, children, descriptionId, className, h4, additionalDesc }) => (
  <Row className={cls('margin-b-1', className)}>
    <Block dataTestId={testId} size={6}>
      {h4 ? <h4 className="h3">{title}</h4> : <h5 className="h3">{title}</h5>}
      {text && <p id={descriptionId}>{text}</p>}
      {additionalDesc && <p className="margin-t-1">{additionalDesc}</p>}
      {children}
    </Block>
  </Row>
);

ContentRow.propTypes = {
  title: PropTypes.string.isRequired,
  testId: PropTypes.string,
  text: PropTypes.string,
  descriptionId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  h4: PropTypes.bool,
  additionalDesc: PropTypes.string,
};

ContentRow.defaultProps = {
  text: null,
  testId: null,
  descriptionId: null,
  className: '',
  children: null,
  h4: false,
  additionalDesc: null,
};

const getTextWithSuffix = (text, suffix) => {
  if (suffix === ' m2') {
    return <span> {text} m&sup2; </span>;
  }
  return `${text} ${suffix}`;
};

export const FormStaticContentBlock = ({ label, text, suffix }) => (
  <>
    {label && <p className="bold">{label}</p>}
    <p>{suffix ? getTextWithSuffix(text, suffix) : text}</p>
  </>
);

FormStaticContentBlock.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string.isRequired,
  suffix: PropTypes.string,
};

FormStaticContentBlock.defaultProps = {
  label: '',
  suffix: null,
};

const WrappedComponent = ({ isWrapped, wrapper, className, children }) =>
  isWrapped ? wrapper(children) : <span className={className}>{children}</span>;

WrappedComponent.propTypes = {
  isWrapped: PropTypes.bool.isRequired,
  wrapper: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

WrappedComponent.defaultProps = {
  className: '',
};

export const FormContentGroup = ({
  legend,
  isLegendHidden,
  LegendElement,
  legendClass,
  desc,
  desc2,
  required,
  invalid,
  children,
  noMargin,
  halfLegendBottomMargin,
  className,
  hasCustomRequiredMarker,
}) => (
  <fieldset
    aria-describedby={desc && `${legend.replace(/ /g, '')}-desc`}
    className={cls(className, { 'margin-b-1': !noMargin })}
    /* axe would report this as aria-allowed-attr violation, although it seems to be allowed with fieldset
       and screen reader is reading this as required group.
    */
    aria-invalid={invalid}
    {...(required ? { 'aria-required': true } : {})}
  >
    <legend
      className={cls('grid-block', 'grid-block-6', {
        'visually-hidden': isLegendHidden,
        'margin-b-1': !isLegendHidden && !desc && !halfLegendBottomMargin,
        'margin-b-0-5': halfLegendBottomMargin && !desc && halfLegendBottomMargin,
      })}
    >
      <LegendElement className={cls(legendClass)}>
        {legend}
        {required && !hasCustomRequiredMarker && <Glyphicon glyph="required" className="validation--required" />}
      </LegendElement>
    </legend>
    {desc && (
      <Block size={6}>
        {desc2 && (
          <p id={`${legend.replace(/ /g, '')}-desc2`} className="margin-b-1">
            {desc2}
          </p>
        )}
        <p id={`${legend.replace(/ /g, '')}-desc`} className="margin-b-1">
          {desc}
        </p>
      </Block>
    )}
    {children}
  </fieldset>
);
FormContentGroup.propTypes = {
  children: PropTypes.node.isRequired,
  legend: PropTypes.string.isRequired,
  LegendElement: PropTypes.string,
  legendClass: PropTypes.string,
  isLegendHidden: PropTypes.bool,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  desc: PropTypes.string,
  desc2: PropTypes.string,
  noMargin: PropTypes.bool,
  halfLegendBottomMargin: PropTypes.bool,
  className: PropTypes.string,
  hasCustomRequiredMarker: PropTypes.bool,
};

FormContentGroup.defaultProps = {
  isLegendHidden: false,
  LegendElement: 'strong',
  legendClass: '',
  required: false,
  invalid: null,
  desc: null,
  desc2: null,
  noMargin: false,
  halfLegendBottomMargin: false,
  className: '',
  hasCustomRequiredMarker: false,
};

// Only accepts one child, else throws an error
export const FormContentBlock = ({
  id,
  label,
  LabelElement,
  isLabelHidden,
  size,
  desktopSize,
  desc,
  additionalDesc,
  additionalDesc2,
  heading,
  required,
  className,
  noMargin,
  children,
  additionalDescMargin,
  visualLabel,
  isVisualLabelHidden,
}) => (
  <Block size={size} desktop={desktopSize || size} className={cls({ 'margin-b-1': !noMargin }, className)}>
    <div className="content-header">
      {visualLabel && (
        <LabelElement className={cls({ 'visually-hidden': isVisualLabelHidden })}>
          {visualLabel}
          {required && <Glyphicon glyph="required" className="validation--required" />}
        </LabelElement>
      )}
      <LabelElement className={cls({ 'visually-hidden': isLabelHidden })}>
        <label htmlFor={children.props?.id || id}>{label}</label>
        {required && <Glyphicon glyph="required" className="validation--required" />}
      </LabelElement>
      {additionalDesc2 && <p>{additionalDesc2}</p>}
      {additionalDesc && <p className={(additionalDescMargin && `margin-b-2`) || ''}>{additionalDesc}</p>}
      {desc && (
        <p id={`${id}-desc`} className="margin-b-1">
          {desc}
        </p>
      )}
      {heading && <strong>{heading}</strong>}
    </div>
    {React.cloneElement(React.Children.only(children), {
      id: children.props?.id || id,
      required,
      ...(typeof children.type === 'object'
        ? { ariaDescribedby: desc && `${id}-desc` } // children is Component
        : { 'aria-describedby': desc && `${id}-desc` }), // children is element
    })}
  </Block>
);

FormContentBlock.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  LabelElement: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLabelHidden: PropTypes.bool,
  size: PropTypes.number,
  desktopSize: PropTypes.number,
  desc: PropTypes.string,
  additionalDesc: PropTypes.string,
  additionalDesc2: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  additionalDescMargin: PropTypes.bool,
  visualLabel: PropTypes.string,
  isVisualLabelHidden: PropTypes.bool,
  heading: PropTypes.string,
};

FormContentBlock.defaultProps = {
  isLabelHidden: false,
  LabelElement: 'strong',
  size: 6,
  desktopSize: null,
  desc: null,
  additionalDesc: null,
  additionalDesc2: null,
  required: false,
  className: '',
  noMargin: false,
  additionalDescMargin: false,
  visualLabel: undefined,
  isVisualLabelHidden: true,
  heading: undefined,
};

export const RadioButtonBlock = React.forwardRef(
  (
    {
      id,
      label,
      name,
      value,
      onChange,
      onClick,
      disabled,
      extraBlockContent,
      desktopWidth,
      className,
      errorId,
      dataCy,
    },
    ref
  ) => (
    <Block size={6} desktop={desktopWidth} className={className}>
      <Field
        {...{ id, name, label, value, onChange, onClick, disabled, extraBlockContent }}
        type="radio"
        component={ValidatedRadioButton}
        ref={ref}
        errorId={errorId}
        dataCy={dataCy}
      />
    </Block>
  )
);

RadioButtonBlock.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  extraBlockContent: PropTypes.node,
  desktopWidth: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  className: PropTypes.string,
  errorId: PropTypes.string,
  dataCy: PropTypes.string,
};

RadioButtonBlock.defaultProps = {
  onChange: () => null,
  onClick: () => null,
  disabled: false,
  extraBlockContent: null,
  desktopWidth: 6,
  className: '',
  errorId: null,
  dataCy: '',
};

export const NavigationBlock = ({
  disabled,
  isFirst,
  onAbort,
  onPrevious,
  abortButtonText,
  finalButtonText,
  onSubmit,
  isSubmit,
  previousSelector,
  abortSelector,
  disableSubmittedUsingEnter,
}) => {
  const dispatch = useDispatch();
  const [submittedWithEnter, setSubmittedWithEnter] = useState(false);

  const onAbortKeyDown = event => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      if (abortSelector) {
        dispatch(requestKeyboardFocusAction({ selector: abortSelector }));
      }
      event.currentTarget.click();
    }
  };

  const onPreviousKeyDown = event => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      if (previousSelector) {
        dispatch(requestKeyboardFocusAction({ selector: previousSelector }));
      }
      event.currentTarget.click();
    }
  };

  const onSubmitKeyDown = event => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      setSubmittedWithEnter(true);
      event.currentTarget.click();
    }
  };

  const onSubmitClick = event => {
    if (!submittedWithEnter) {
      disableSubmittedUsingEnter();
    }
    onSubmit(event);
  };

  return (
    <Row>
      <Block size={6}>
        <div className="float-left text-left">
          <button onClick={onAbort} onKeyDown={onAbortKeyDown} type="button" className="button button--light">
            {t(abortButtonText)}
          </button>
        </div>
        <div className="float-right text-right">
          {!isFirst && (
            <Block>
              <button onClick={onPrevious} onKeyDown={onPreviousKeyDown} type="button" className="button button--blue">
                {t('button.previous')}
              </button>
            </Block>
          )}

          {finalButtonText ? (
            <button
              className="button button--orange"
              disabled={disabled}
              onClick={onSubmitClick}
              onKeyDown={onSubmitKeyDown}
              type={isSubmit ? 'submit' : 'button'} // added for PaytrailForm
            >
              {finalButtonText}
            </button>
          ) : (
            <button
              type={isSubmit ? 'submit' : 'button'}
              className="button button--blue"
              disabled={disabled}
              onClick={onSubmit}
              onKeyDown={onSubmitKeyDown}
            >
              {t('button.next')}
            </button>
          )}
        </div>
      </Block>
    </Row>
  );
};

NavigationBlock.propTypes = {
  disabled: PropTypes.bool,
  finalButtonText: PropTypes.string,
  isFirst: PropTypes.bool,
  onAbort: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  abortButtonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmit: PropTypes.bool,
  previousSelector: PropTypes.string,
  abortSelector: PropTypes.string,
  disableSubmittedUsingEnter: PropTypes.func.isRequired,
};

NavigationBlock.defaultProps = {
  disabled: false,
  isFirst: false,
  finalButtonText: null,
  onPrevious: null,
  abortButtonText: 'button.abort',
  isSubmit: false,
  previousSelector: '',
  abortSelector: '',
};

export const ContinueBrowsingBlock = ({ to, selector }) => (
  <Block>
    <p className="continue-browsing">
      <RouterLink to={to} selector={selector}>
        {t('button.continue')}
      </RouterLink>
      <Glyphicon glyph="nuoli-oikealle" />
    </p>
  </Block>
);

ContinueBrowsingBlock.propTypes = {
  to: PropTypes.string.isRequired,
  selector: PropTypes.string,
};

ContinueBrowsingBlock.defaultProps = {
  selector: undefined,
};

export const ErrorBlock = ({ onError }) => (
  <FlexGrid className="form__container-bg">
    <HeadingRow text={t('error.general.function')} />
    <Row>
      <Block size={6} className="margin-t-1">
        <button type="button" onClick={onError} className="button button--link bold">
          <Glyphicon glyph="nuoli-oikealle" />
          <span className="indent-block-1">{t('error.general.tryAgain')}</span>
        </button>
      </Block>
    </Row>
  </FlexGrid>
);

ErrorBlock.propTypes = {
  onError: PropTypes.func.isRequired,
};

export const DataWillBeNotSendingNoticeBox = () => (
  <Row className="margin-b-1">
    <Block size={6} className="">
      <DisclaimerBox className="margin-t-1 margin-b-1">
        <div>
          <p className="margin-b-2" id="data-will-not-be-sending">
            {t('realEstate.form.noticebox.dataWillNotBeSending')}
          </p>
        </div>
      </DisclaimerBox>
    </Block>
  </Row>
);

export default HeadingRow;
