import { SIGNIFICANT_DISTANCES } from './geographicConstants';

// Approximates the distance between two ETRS-TM35FIN points using Pythagorean equation.
export function calculateDistance(lat1, lon1, lat2, lon2) {
  return Math.sqrt((lat2 - lat1) ** 2 + (lon2 - lon1) ** 2);
}

// Selects zoom level based on the accuracy of coordinates.
export function selectAutomaticZoomLevel(accuracy = 1000, currentZoomLevel = 1) {
  if (accuracy > 500) {
    return currentZoomLevel;
  }

  const index = SIGNIFICANT_DISTANCES.findIndex(
    (value, i) => value < accuracy || i === SIGNIFICANT_DISTANCES.length - 1
  );

  const zoomLevel = Math.min(Math.max(index - 1, 0), 10);

  return currentZoomLevel > zoomLevel ? currentZoomLevel : zoomLevel;
}
