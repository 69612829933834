import { changeToUiVisiblePhase } from 'common/containers/KasiAppMode/KasiAppModeActions';
import { trackStartError, LABELS } from 'common/helpers/Analytics/Analytics_eventmap';
import { START_PHASE_DESCRIPTORS, START_PHASE_UI_VISIBLE } from 'common/containers/KasiAppMode/KasiAppModes';
import { ROUTE_ERROR_GENERIC } from 'common/constants/Routes';
import { t } from 'i18next';
import { addErrorNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';
import { isDevelopment } from 'common/helpers/environmentHelpers';
import { accessibleNotificationAdded } from 'common/containers/AccessibleNotifications/accessibleNotificationsSlice';
import { ASSERTIVE } from 'common/containers/AccessibleNotifications/AccessibleNotificationsConstants';
import { addReloadListener } from './Reload';
import renderReactDom from './MountApp';
import validateUrl from './Url';
import preRenderInit from './PreRender';
import postRenderInit from './PostRender';
import executeBackgroundTasks from './BackgroundTasks';

function revealUI(store) {
  return store.dispatch(changeToUiVisiblePhase());
}

const trackError = (err, store) => {
  const message = (err && err.message) || err || LABELS.NO_ERROR_MESSAGE;
  trackStartError(START_PHASE_DESCRIPTORS[store.getState().appMode.startPhase], message);
};

function showAppStartError(err, store) {
  if (store.getState().appMode.startPhase === START_PHASE_UI_VISIBLE) {
    store.dispatch(addErrorNotificationAction(t('app.general.error')));
    store.dispatch(accessibleNotificationAdded({ text: t('app.general.error'), type: ASSERTIVE }));
  } else {
    console.error('app start error!', err);
    if (isDevelopment()) {
      alert(err); // eslint-disable-line no-alert
    } else {
      document.location.replace([ROUTE_ERROR_GENERIC, 'noPwk'].join('?'));
    }
  }
}

/**
 *
 * @param createRouter(store, features) Function reference which creates react router.
 * @param store Redux store.
 * @param isReload If true, reload Oskari state. If false, create Oskari state from scratch.
 * @param bgTasks[function(store)] An array of function references which are executed as background tasks.
 */
export default function start(createRouter, store, isReload, bgTasks, signal = null) {
  validateUrl(window.location.href); // security reasons

  preRenderInit(store)
    .then(() => renderReactDom(store, createRouter))
    .then(() => postRenderInit(store, isReload))
    .then(() => revealUI(store))
    .then(() => executeBackgroundTasks(store, bgTasks, signal))
    .catch(err => {
      trackError(err, store);
      showAppStartError(err, store);
    });

  addReloadListener(store, window);
}
