import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getRoute } from 'common/constants/Routes';

import { MainHeader, FlexGrid, Block, Row, ErrorBoundary } from 'common/components';

const BusinessPanelErrorBoundary = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // TODO: https://jira.nls.fi/browse/ASI-6907 - reset appropriate state with this.
  const resetState = () => navigate(getRoute());

  return (
    <ErrorBoundary
      resetState={resetState}
      fallbackView={reset => (
        <FlexGrid className="form__container-bg relative">
          <MainHeader text={t('notFound.pageHeader')} />
          <Row>
            <Block size={6} className="notfound-text">
              40<span className="notfound-fallen">4</span>
            </Block>
          </Row>
          <Row className="margin-t-4">
            <Block>
              <button type="button" className="button button--blue" onClick={reset}>
                {t('usermenu.frontpageButton')}
              </button>
            </Block>
          </Row>
        </FlexGrid>
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

BusinessPanelErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BusinessPanelErrorBoundary;
