const listeners = {};
let channel;
let handleRPCError;

const FEATURE_EVENT = 'FeatureEvent';
const MAP_CLICKED_EVENT = 'MapClickedEvent';

export function initClickFeatureModule(_channel, _handleRPCError) {
  channel = _channel;
  handleRPCError = _handleRPCError;
}

export function addFeatureListener(name, callback) {
  listeners[name] = callback;
  channel.handleEvent(FEATURE_EVENT, callback, handleRPCError);
}

export function removeFeatureListener(name) {
  if (listeners[name]) channel.unregisterEventHandler(FEATURE_EVENT, listeners[name]);
}

export function addMapClickEventListener(name, callback) {
  listeners[name] = callback;
  channel.handleEvent(MAP_CLICKED_EVENT, callback, handleRPCError);
}

export function removeMapClickEventListener(name) {
  if (listeners[name]) channel.unregisterEventHandler(MAP_CLICKED_EVENT, listeners[name]);
}

/**
 * @typedef OskariEvent
 * @property {String} operation
 * @property {Array} features
 */

/**
 * @callback featureWithCoordinatesCallback
 * @param {OskariEvent} event
 * @param {OskariCoordinates} coords
 */

/**
 * Adds feature (click) event listener, but includes clicked coordinates to
 * the callback function.
 * @param name
 * @param {featureWithCoordinatesCallback} callback
 */
export function addClickFeatureWithCoordinatesListener(name, callback) {
  let clickedCoordinatesReady;

  addMapClickEventListener(`${name}Location`, event => {
    if (clickedCoordinatesReady) clickedCoordinatesReady({ e: event.lon, n: event.lat });
  });

  addFeatureListener(name, event => {
    new Promise(resolve => {
      clickedCoordinatesReady = resolve;
    })
      .then(coords => callback(event, coords))
      .finally(() => {
        clickedCoordinatesReady = undefined;
      });
  });
}
