import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getReadyMapsSheetsThunk } from 'common/containers/MapStoreReadyMapsForm/ReadyMapsFormActions';
import * as Routes from 'common/constants/Routes';
import { ExternalLink } from 'common/components';
import {
  PRINTED_MAPS_TITLE,
  YOUR_MAP_TITLE_1,
} from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import RouterLink from '../Link/RouterLink';

const MapStoreColumn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sheets = useSelector(state => state.mapStore.sheets || [], shallowEqual);

  useEffect(() => {
    if (sheets.length === 0) dispatch(getReadyMapsSheetsThunk(sheets.length));
  }, [dispatch, sheets.length]);

  return (
    <>
      <h3 className="with-divider">{t('mapStoreIndex.readyMapsTitle')}</h3>
      <p>{t('mapStoreIndex.readyMapsDesc')}</p>
      <p>
        <ExternalLink href={t('readyMaps.prices.href')} text={t('readyMaps.prices.title')} />
      </p>
      <p>
        <ExternalLink href={t('readyMaps.info.href')} text={t('readyMaps.info.title')} />
      </p>
      <RouterLink
        className="button button--blue bold margin-b-2"
        to={Routes.getRoute(Routes.MAP_STORE_READY_MAPS)}
        selector={PRINTED_MAPS_TITLE}
      >
        {t('mapStoreIndex.readyMaps.linkText')}
      </RouterLink>
      <h3 className="with-divider">{t('mapStoreIndex.customMapsTitle')}</h3>
      <p>{t('mapStoreIndex.customMapsDesc')}</p>
      <p>
        <ExternalLink href={t('customMapsForm.prices.href')} text={t('customMapsForm.prices.title')} />
      </p>
      <p>
        <ExternalLink href={t('customMapsForm.examples.href')} text={t('customMapsForm.examples.title')} />
      </p>
      <RouterLink
        className="button button--blue bold margin-b-2"
        to={Routes.getRoute(Routes.MAP_STORE_CUSTOM_MAPS)}
        selector={YOUR_MAP_TITLE_1}
      >
        {t('mapStoreIndex.customMaps.linkText')}
      </RouterLink>
    </>
  );
};

export default MapStoreColumn;
