import React, { useEffect, useCallback, useId } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useField, useForm } from 'react-final-form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'common/helpers/isEmpty';
import { RadioButtonBlock } from 'common/containers/Forms/Blocks';
import { ValidationErrorText } from 'common/containers/Forms/InputValidation/InputValidationComponents';
import { Glyphicon } from 'common/components';
import { validateAreaSelectionFeature } from '../step1validate';
import {
  AREA_SELECTION_TYPES,
  BOUNDING_BOX,
  CADASTRAL_INDEX_MAP_VECTOR,
  FIELD_NAMES,
  POLYGON,
  TOPOGRAPHIC_DATABASE,
} from '../../FileDownloadServiceConstants';
import {
  clearAreaSelectionThunk,
  DOWNLOAD_AREA_SELECTION_ID,
  setActiveAreaSelectionType,
  startAreaSelectionThunk,
  resetAreaSelectionThunk,
} from '../../FileDownloadServiceAction';
import { useProductParameters } from '../../FileDownloadServiceHelpers';
import ThemeSelectionComponent from '../ThemeSelectionComponent';

const DrawAreaSelection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { change, getState } = useForm();

  const invalidAreaSelectionErrorId = useId();
  const missingAreaSelectionErrorId = useId();

  const { meta: invalidAreaSelection } = useField(FIELD_NAMES.AREA_SELECTION);
  const { meta: missingAreaSelection } = useField(FIELD_NAMES.VALID_AREA_SELECTION);

  const {
    input: { value: productId },
  } = useField(FIELD_NAMES.PRODUCT_ID);

  const {
    input: { value: areaSelectionType },
  } = useField(FIELD_NAMES.AREA_SELECTION_TYPE);

  const {
    input: { value: areaSelectionGeometry },
  } = useField(FIELD_NAMES.AREA_SELECTION);

  const {
    input: { value: freeSelectionType },
  } = useField(FIELD_NAMES.FREE_SELECTION_TYPE);

  const {
    input: {
      value: { value: fileFormat },
    },
  } = useField(FIELD_NAMES.FILE_FORMAT);

  const selectedArea = useSelector(state =>
    state.geometries.features.find(geom => geom.id === DOWNLOAD_AREA_SELECTION_ID)
  );

  const activeButton = useSelector(state => state.fileDownloadService.activeAreaSelectionTool);
  const productParameters = useProductParameters();
  const buttonClasses = classNames('button', 'button--icon', 'button--area-selection');
  const maxArea =
    productId === TOPOGRAPHIC_DATABASE
      ? productParameters.maxInput.polygon * 1000000
      : productParameters.maxInput[freeSelectionType] * 1000000;

  const { submitFailed } = getState();
  const label =
    productParameters.maxInput?.polygon > 0
      ? `${t('fileDownloadService.areaSelection.selectArea.label.1')} (${t(
          'fileDownloadService.areaSelection.selectArea.label.2'
        )} ${productParameters.maxInput.polygon.toLocaleString()} km\u00B2)`
      : t('fileDownloadService.areaSelection.selectArea.label.1');

  const startDrawingPolygon = () => {
    dispatch(resetAreaSelectionThunk());
    change(FIELD_NAMES.FREE_SELECTION_TYPE, POLYGON);
    dispatch(startAreaSelectionThunk('Polygon', maxArea <= 0 ? !maxArea : maxArea));
    dispatch(setActiveAreaSelectionType('select-polygon'));
  };

  const startDrawingBox = useCallback(() => {
    dispatch(resetAreaSelectionThunk());
    change(FIELD_NAMES.FREE_SELECTION_TYPE, BOUNDING_BOX);
    dispatch(startAreaSelectionThunk('Box', maxArea <= 0 ? !maxArea : maxArea));
    dispatch(setActiveAreaSelectionType('select-rectangle'));
  }, [dispatch, change, maxArea]);

  const getErrorId = () => {
    if (!submitFailed) return null;
    if (missingAreaSelection?.error) return missingAreaSelectionErrorId;
    if (invalidAreaSelection?.error) return invalidAreaSelectionErrorId;
    return null;
  };

  useEffect(() => {
    const feature = selectedArea?.geoJson;

    change(FIELD_NAMES.AREA_SELECTION, feature);

    if (feature && areaSelectionType === AREA_SELECTION_TYPES.FREE_SELECTION) {
      change(FIELD_NAMES.VALID_AREA_SELECTION, true);
    } else {
      change(FIELD_NAMES.VALID_AREA_SELECTION, false);
    }
  }, [change, selectedArea, areaSelectionType, productId]);

  useEffect(() => {
    if (areaSelectionType === AREA_SELECTION_TYPES.FREE_SELECTION && isEmpty(areaSelectionGeometry)) startDrawingBox();
    // areaSelectionGeometry should not be included in deps

    return () => {
      if (areaSelectionType === AREA_SELECTION_TYPES.FREE_SELECTION && isEmpty(areaSelectionGeometry))
        dispatch(clearAreaSelectionThunk());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDrawingBox, areaSelectionType, dispatch]);

  useEffect(() => {
    if (areaSelectionType !== AREA_SELECTION_TYPES.FREE_SELECTION) {
      dispatch(clearAreaSelectionThunk());
      change(FIELD_NAMES.FREE_SELECTION_TYPE, undefined);
    }
  }, [areaSelectionType, dispatch, change]);

  useEffect(() => {
    if ([CADASTRAL_INDEX_MAP_VECTOR, TOPOGRAPHIC_DATABASE].includes(productId)) {
      if (['GPKG', 'GeoJSON'].includes(fileFormat) && areaSelectionType === AREA_SELECTION_TYPES.SELECT_SHEETS) {
        change(FIELD_NAMES.AREA_SELECTION_TYPE, AREA_SELECTION_TYPES.FREE_SELECTION);
      }
    }
  }, [change, productId, fileFormat, areaSelectionType]);

  return (
    <RadioButtonBlock
      id="freeAreaSelection"
      value={AREA_SELECTION_TYPES.FREE_SELECTION}
      name={FIELD_NAMES.AREA_SELECTION_TYPE}
      label={label}
      className="margin-b-1"
      errorId={getErrorId()}
      extraBlockContent={
        <>
          <button
            type="button"
            disabled={areaSelectionType !== AREA_SELECTION_TYPES.FREE_SELECTION}
            onClick={startDrawingBox}
            className={classNames(buttonClasses, { active: activeButton === 'select-rectangle' })}
            title={t('fileDownloadService.areaSelection.selectArea.box.label')}
          >
            <Glyphicon glyph="select-rectangle" />
          </button>
          <button
            type="button"
            disabled={areaSelectionType !== AREA_SELECTION_TYPES.FREE_SELECTION}
            onClick={startDrawingPolygon}
            className={classNames(buttonClasses, 'indent-block-1', { active: activeButton === 'select-polygon' })}
            title={t('fileDownloadService.areaSelection.selectArea.polygon.label')}
          >
            <Glyphicon glyph="select-polygon" />
          </button>
          <Field
            name={FIELD_NAMES.AREA_SELECTION}
            validate={input => validateAreaSelectionFeature(input?.features[0])}
            component={({ meta }) =>
              meta.error ? (
                <ValidationErrorText id={invalidAreaSelectionErrorId} className="margin-t-1" errorText={meta.error} />
              ) : null
            }
          />
          <Field
            name={FIELD_NAMES.VALID_AREA_SELECTION}
            component={({ meta }) =>
              submitFailed && meta.error ? (
                <ValidationErrorText id={missingAreaSelectionErrorId} className="margin-t-1" errorText={meta.error} />
              ) : null
            }
          />
          <ThemeSelectionComponent
            selectedAreaSelectionType={areaSelectionType}
            fieldName={FIELD_NAMES.THEME_FREE_SELECTION}
            fieldType={AREA_SELECTION_TYPES.FREE_SELECTION}
          />
        </>
      }
    />
  );
};

export default DrawAreaSelection;
