import React from 'react';
import PropTypes from 'prop-types';

const SearchResultToggleWrapper = ({ children }) => <div className="search-results">{children}</div>;

SearchResultToggleWrapper.propTypes = {
  children: PropTypes.node,
};

SearchResultToggleWrapper.defaultProps = {
  children: null,
};

export default SearchResultToggleWrapper;
