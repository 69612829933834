import React from 'react';
import { useSelector } from 'react-redux';

const AccessibleNotificationsComponent = () => {
  const politeNotification = useSelector(state => state.accessibleNotifications.polite);
  const assertiveNotification = useSelector(state => state.accessibleNotifications.assertive);
  return (
    <div className="visually-hidden">
      <div role="status">{politeNotification != null && <span>{politeNotification}</span>}</div>
      <div role="alert">{assertiveNotification != null && <span>{assertiveNotification}</span>}</div>
    </div>
  );
};

export default AccessibleNotificationsComponent;
