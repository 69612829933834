import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import disclaimerLogoBlue from 'styles/img/exclamation_mark.svg';
import disclaimerLogoOrange from 'styles/img/exclamation_mark-orange.svg';
import disclaimerLogoRed from 'styles/img/exclamation_mark-red.svg';

const ICONS = {
  info: disclaimerLogoBlue,
  warning: disclaimerLogoOrange,
  error: disclaimerLogoRed,
};

const DisclaimerBox = ({ children, className, noIcon, level, ...rest }) => (
  <section className={cls('disclaimer-box', className, level)} {...rest}>
    {!noIcon && (
      <span className="disclaimer-box__icon">
        <img src={ICONS[level]} alt="" />
      </span>
    )}
    <div className="disclaimer-box__content">{children}</div>
  </section>
);

DisclaimerBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noIcon: PropTypes.bool,
  level: PropTypes.oneOf(['info', 'warning', 'error']),
};

DisclaimerBox.defaultProps = {
  className: '',
  noIcon: false,
  level: 'info',
};

export default DisclaimerBox;
