import { UPDATE_FORM_ERROR_MESSAGE, RESET_FORM_MESSAGE } from './FormActions';

const initialState = {
  errorMsg: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_FORM_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: action.errorMsg,
        formId: action.formId,
      };
    case RESET_FORM_MESSAGE:
      return initialState;
    default:
      return state;
  }
}
