import { SHOW_CONFIRM, BUTTON_PRESSED } from './ConfirmModalActionTypes';

export default function reducer(state = { isOpen: false, selected: '' }, action = {}) {
  switch (action.type) {
    case SHOW_CONFIRM:
      return { ...state, isOpen: true };

    case BUTTON_PRESSED:
      return { ...state, isOpen: false, selected: action.buttonId };

    default:
      return state;
  }
}
