/**
 * Checks whether input string is a number.
 */
const isNumber = inputString => Number.isFinite(parseInt(inputString, 10));

/**
 * Checks whether input string contains a number.
 */
const hasNumber = inputString => /\d/.test(inputString);

/**
 * Rounds input number to next multiple of given base
 */
const roundToNextMultiple = (number, base) => (number % base !== 0 ? number + (base - (number % base)) : number);

export { isNumber, hasNumber, roundToNextMultiple };
