import React from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form';

import { Block, Row } from 'common/components';
import { FormContentGroup } from 'common/containers/Forms/Blocks';
import { useSelector } from 'react-redux';
import {
  AREA_SELECTION_TYPES,
  BOUNDING_BOX,
  FIELD_NAMES,
  LASER_SCANNING_DATA_5P,
  POLYGON,
} from '../../FileDownloadServiceConstants';
import MunicipalitySelection from './MunicipalitySelection';
import MapSheetSelection from './MapSheetSelection';
import DrawAreaSelection from './DrawAreaSelection';
import EntireFinlandSelection from './EntireFinlandSelection';
import { containsMatch, useAreaSelectionTypes } from '../../FileDownloadServiceHelpers';
import ProductionAreaSelection from './ProductionAreaSelection';
import { hasOnlyPatchProductionAreasInCart } from '../../../ShoppingCart/ShoppingCartActions';

const { MUNICIPALITY, ENTIRE_FINLAND, SELECT_SHEETS, PRODUCTION_AREA } = AREA_SELECTION_TYPES;

const AreaSelectionComponent = () => {
  const { t } = useTranslation();
  const areaSelectionTypes = useAreaSelectionTypes();

  const {
    input: { value: productId },
  } = useField(FIELD_NAMES.PRODUCT_ID);

  const isLaser5p = productId === LASER_SCANNING_DATA_5P;

  const shoppingCart = useSelector(state => state.shoppingCart);

  const onlyPatchAreasInCart = hasOnlyPatchProductionAreasInCart(shoppingCart);

  return (
    areaSelectionTypes &&
    areaSelectionTypes.length > 0 && (
      <Row className="margin-t-1">
        <FormContentGroup
          legend={t('fileDownloadService.areaSelection.title')}
          desc={isLaser5p ? t('fileDownloadService.areaSelection.productionArea.infoLabel') : ''}
        >
          {containsMatch(areaSelectionTypes, SELECT_SHEETS) && <MapSheetSelection />}
          {(containsMatch(areaSelectionTypes, POLYGON) || containsMatch(areaSelectionTypes, BOUNDING_BOX)) && (
            <DrawAreaSelection />
          )}
          {containsMatch(areaSelectionTypes, MUNICIPALITY) && <MunicipalitySelection />}
          {containsMatch(areaSelectionTypes, ENTIRE_FINLAND) && <EntireFinlandSelection />}
          {containsMatch(areaSelectionTypes, PRODUCTION_AREA) && <ProductionAreaSelection />}
        </FormContentGroup>
        {isLaser5p && onlyPatchAreasInCart && (
          <Block>{t('fileDownloadService.areaSelection.onlyPatchAreasInCart')}</Block>
        )}
      </Row>
    )
  );
};

export default AreaSelectionComponent;
