import { firstBy } from 'thenby';

export const compareRealEstateIdsByPart = (a, b, index, idName = 'id') =>
  a[idName].split('-')[index] - b[idName].split('-')[index];

export const compareRealEstatesByCustomId = idName =>
  firstBy((a, b) => compareRealEstateIdsByPart(a, b, 0, idName))
    .thenBy((a, b) => compareRealEstateIdsByPart(a, b, 1, idName))
    .thenBy((a, b) => compareRealEstateIdsByPart(a, b, 2, idName))
    .thenBy((a, b) => compareRealEstateIdsByPart(a, b, 3, idName));

export const compareRealEstatesById = compareRealEstatesByCustomId();
