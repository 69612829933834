import React from 'react';
import merge from 'deepmerge';

import { dateParser } from 'common/helpers/storage';
import { debounce, get } from 'lodash';
import { FORM_SUBMIT_FAILED, FORM_SUBMIT_SUCCESS } from 'common/constants/Routes';
import { DATE_PICKER_CLASS_NAME } from '../DatePicker/DatePicker';

const focusOnFirstError = (formId, { mutators }, includedOnlyFields, setErrorsManually) => {
  const focusableInputs = Array.prototype.slice
    .call(document.forms[formId].elements)
    .filter(element => typeof element?.focus === 'function');
  const errorFieldNames = mutators.getErrorFields(includedOnlyFields);
  const [firstErrorElement] = errorFieldNames
    .map(e => document.querySelector(`[name="${e.replace('AsyncError', '')}"]`))
    .filter(Boolean);
  if (setErrorsManually) mutators.setSubmitFailed();
  const firstError = focusableInputs.find(input =>
    errorFieldNames.find(err => input?.name === err || `${input?.name}AsyncError` === err)
  );

  const firstVisibleFocusableError = firstError?.offsetParent ? firstError : firstError?.parentElement;
  const element = firstVisibleFocusableError || firstErrorElement;

  if (element) {
    const hasDatepicker = element.classList.contains(DATE_PICKER_CLASS_NAME);
    const smallDesktopWindow = window.innerHeight < 780 && window.innerWidth >= 992;
    const block = (hasDatepicker || firstError === firstErrorElement) && !smallDesktopWindow ? 'center' : 'end';
    setTimeout(
      () =>
        element.scrollIntoView({
          behavior: 'smooth',
          block,
        }),
      100
    );
  }

  if (firstVisibleFocusableError) firstVisibleFocusableError.focus({ preventScroll: true });
};

const getFormDataFromStorage = formId => {
  try {
    return JSON.parse(sessionStorage.getItem(formId), dateParser) || {};
  } catch (e) {
    console.error(`Failed to parse initial values for form: ${formId}, with error:`, e);
    return {};
  }
};

const storeFormDataInStorage = (formId, values) => {
  sessionStorage.setItem(formId, JSON.stringify(values));
};

const clearFormData = key => sessionStorage.removeItem(key);

// focus on first errored field when validation fails when Next/Submit button is clicked
/* eslint-disable no-param-reassign */
const createSubmitFailedDecorator = formId => form => {
  const originalSubmit = form.submit;
  form.submit = () => {
    const result = originalSubmit.call(form);
    if (result?.then) result.then(() => form.getState().invalid && focusOnFirstError(formId, form));
    else form.getState().submitFailed && focusOnFirstError(formId, form);
    return result;
  };

  return () => {
    form.submit = originalSubmit;
  };
};
/* eslint-enable no-param-reassign */

// Save form state to sessionStorage when the events subscribed to below are triggered
const createStorageDecorator = formId => form =>
  form.subscribe(
    debounce(
      () => {
        // Note: setTimeout ensures that initialValues exist before running this the first time
        setTimeout(() => {
          return storeFormDataInStorage(formId, form.getState().values);
        });
      },
      500,
      { leading: true, trailing: true }
    ),
    {
      reset: true,
      values: true,
    }
  );

const combineMerge = (target, source, options) => {
  const destination = target.slice();

  source.forEach((item, index) => {
    if (typeof destination[index] === 'undefined') {
      destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
    } else if (options.isMergeableObject(item)) {
      destination[index] = merge(target[index], item, options);
    } else if (target.indexOf(item) === -1) {
      destination.push(item);
    }
  });
  return destination;
};

const getErrorFields = ([includedOnlyFields = []], formState) =>
  Object.entries(formState.fields)
    .filter(([field]) =>
      includedOnlyFields.length > 0 ? includedOnlyFields.some(fieldName => field.includes(fieldName)) : true
    )
    .filter(([, value]) => !value.lastFieldState.valid)
    .map(([key]) => key);

/* eslint-disable no-param-reassign */
const setAsyncErrors = ([error, fieldPrefix = ''], { formState }, { setIn }) => {
  const existingErrors = formState.errors;
  const newErrors = setIn(formState.errors, fieldPrefix, error);

  formState.errors = merge(existingErrors, newErrors, { arrayMerge: combineMerge });
  formState.lastSubmittedValues = formState.values;
};

/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-empty-pattern
const setSubmitFailed = ([], { formState }) => {
  formState.submitFailed = true;
};
/* eslint-enable no-param-reassign */

// This is used in conjunction with the above setAsyncErrors mutator to display the error set by the mutator.
const reactToAsyncError = (val, _values, { error, touched }) => val && touched && error;

const focusOnErrorOnSubmitListenerCreator = formId => createSubmitFailedDecorator(formId);
const saveToStorageListenerCreator = formId => createStorageDecorator(formId);

// NOTE: accepts either a string fieldName/formSection OR an array of fieldNames/sectionNames

const blurAllFields = (form, fieldNames) => {
  const blurFieldsForOneName = name =>
    form
      .getRegisteredFields()
      .filter(fieldName => fieldName.includes(name))
      .forEach(field => form.blur(field));

  if (Array.isArray(fieldNames)) fieldNames.forEach(fieldName => blurFieldsForOneName(fieldName));
  else blurFieldsForOneName(fieldNames);
};

const fieldHasNoErrors = ({ errors, fieldName, ignoreField }) => {
  const foundErrors = Object.keys(get(errors, fieldName) || {});
  const filteredErrors = foundErrors.filter(error => {
    return error !== ignoreField;
  });
  return !filteredErrors.length;
};

const checkAndFocusOnSectionErrors = (form, formId) => (
  fieldNames,
  ignoreField,
  onlyCheckErrors,
  setErrorsManually = false
) =>
  new Promise((resolve, reject) => {
    const { errors } = form.getState();
    const hasNoErrors = Array.isArray(fieldNames)
      ? fieldNames.every(field => {
          return fieldHasNoErrors({ errors, fieldName: field, ignoreField });
        })
      : fieldHasNoErrors({ errors, fieldName: fieldNames, ignoreField });
    if (hasNoErrors) {
      resolve();
    } else {
      !onlyCheckErrors && blurAllFields(form, fieldNames);
      !onlyCheckErrors &&
        focusOnFirstError(formId, form, Array.isArray(fieldNames) ? fieldNames : [fieldNames], setErrorsManually);
      reject();
    }
  });

const resolveValidFormStep = (stepParam, formStepCount) => {
  if (stepParam === FORM_SUBMIT_SUCCESS || stepParam === FORM_SUBMIT_FAILED) return stepParam;

  const parsedStep = parseInt(stepParam, 10);
  if (parsedStep && parsedStep > 0 && parsedStep <= formStepCount) return parsedStep;
  return 1;
};

const resolveFormPageCount = children => React.Children.toArray(children).filter(Boolean).length;

const isReceived = currentPageNumber =>
  currentPageNumber === FORM_SUBMIT_SUCCESS || currentPageNumber === FORM_SUBMIT_FAILED;

const isFirst = currentPageNumber => currentPageNumber === 1;

const isLastFormStep = (currentPageNumber, stepCount) => currentPageNumber === stepCount;

const resolveActivePage = (currentStepNumber, children) => {
  const lastPageIndex = resolveFormPageCount(children) - 1;
  return isReceived(currentStepNumber)
    ? React.Children.toArray(children)[lastPageIndex]
    : React.Children.toArray(children)[currentStepNumber - 1];
};

const isOnFailedPage = step => step === FORM_SUBMIT_FAILED;
const isOnSucceededPage = step => step === FORM_SUBMIT_SUCCESS;

export {
  focusOnErrorOnSubmitListenerCreator,
  saveToStorageListenerCreator,
  getFormDataFromStorage,
  clearFormData,
  setAsyncErrors,
  setSubmitFailed,
  reactToAsyncError,
  checkAndFocusOnSectionErrors,
  getErrorFields,
  resolveValidFormStep,
  resolveFormPageCount,
  isReceived,
  isFirst,
  isLastFormStep,
  resolveActivePage,
  isOnFailedPage,
  isOnSucceededPage,
  combineMerge,
};
