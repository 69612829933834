import { SHARE_OPEN, SHARE_CLOSE } from './ShareActionTypes';

const initialState = {
  url: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHARE_OPEN:
      return { url: action.url };
    case SHARE_CLOSE:
      return { url: null };
    default:
      return state;
  }
}
