import React from 'react';
import { t } from 'i18next';
import cls from 'classnames';
import PropTypes from 'prop-types';

import { ExternalLink } from 'common/components';
import * as Routes from 'common/constants/Routes';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import { MAP_FEEDBACK } from 'common/containers/Authorization/privileges';
import RouterLink from 'common/components/Link/RouterLink';
import { PROVIDE_FEEDBACK_ABOUT_MAP_TITLE } from '../AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';

export const FEEDBACK_PAGE_URL = 'https://www.maanmittauslaitos.fi/karttoihin-liittyva-palaute';

// eslint-disable-next-line react/prop-types
const MapFeedbackLink = ({ isRealEstateSite }) => (
  <>
    <h3 className={cls('margin-t-2', { 'with-divider': isRealEstateSite })}>{t('feedback.mapfeedback.title')}</h3>
    <p className="margin-b-1">{t('feedback.mapfeedback.description')}</p>
    <p className={cls({ 'with-divider': !isRealEstateSite })}>
      <RouterLink
        className="button button--light"
        to={Routes.getRoute(Routes.FEEDBACK_MAP)}
        selector={PROVIDE_FEEDBACK_ABOUT_MAP_TITLE}
      >
        {t('feedback.mapfeedback.linktext')}
      </RouterLink>
    </p>
  </>
);

// eslint-disable-next-line react/prop-types
const ApplicationFeedbackLink = ({ isRealEstateSite }) => (
  <>
    <h3 className={cls('margin-t-2', { 'with-divider': isRealEstateSite })}>
      {isRealEstateSite ? t('feedback.appfeedback.realEstate.title') : t('feedback.appfeedback.title')}
    </h3>
    <p>
      <ExternalLink href={t('feedback.appfeedback.link')}>
        {isRealEstateSite ? t('feedback.appfeedback.realEstate.description') : t('feedback.appfeedback.description')}
      </ExternalLink>
    </p>
  </>
);

const FeedbackComponent = ({ isRealEstateSite }) => (
  <>
    {isAuthorized([MAP_FEEDBACK]) && <MapFeedbackLink isRealEstateSite={isRealEstateSite} />}
    <ApplicationFeedbackLink isRealEstateSite={isRealEstateSite} />
  </>
);

FeedbackComponent.propTypes = {
  isRealEstateSite: PropTypes.bool,
};

FeedbackComponent.defaultProps = {
  isRealEstateSite: false,
};

export default FeedbackComponent;
