import React from 'react';
import PropTypes from 'prop-types';
import { ExternalLink, VerticalScrollerComponent as VerticalScroller } from 'common/components';
import MainToolbarHeaderComponent from 'common/containers/Sidebar/MainToolbarHeaderComponent';
import { isMobile } from 'common/constants/Layout';
import { t } from 'i18next';
import LayerControls from './LayerControlsComponent';

const LegendLink = () => (
  <li className="map-layer-selector__legend-link">
    <ExternalLink href={t('mapLayerSelector.mapLegend.link.url')}>
      {t('mapLayerSelector.mapLegend.link.text')}
    </ExternalLink>
  </li>
);

const WrappedWithScroller = ({ isWrapped, children }) =>
  isWrapped ? <VerticalScroller>{children}</VerticalScroller> : children;

WrappedWithScroller.propTypes = {
  isWrapped: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const MapLayerSelectorComponent = ({
  enableIframeEvents,
  layoutMode,
  mapLayers,
  onMapLayerOpacityChange,
  onToggleLayer,
  zoomLevel,
}) => {
  const layerOpacityHandler = layerId => opacity => {
    onMapLayerOpacityChange(layerId, opacity);
  };

  return (
    <>
      <div className="main-toolbar-block">
        <MainToolbarHeaderComponent title={t('mapLayerSelector.title')} />
      </div>
      <WrappedWithScroller isWrapped={isMobile(layoutMode)}>
        <ul className="layer-controls__ul">
          {mapLayers.map(({ id, ...layerProps }) => (
            <LayerControls
              enableIframeEvents={enableIframeEvents}
              onToggleLayer={onToggleLayer}
              id={id}
              key={`lid_${id}`}
              onMapLayerOpacityChange={layerOpacityHandler(id)}
              zoomLevel={zoomLevel}
              {...layerProps}
            />
          ))}
          <LegendLink />
        </ul>
      </WrappedWithScroller>
    </>
  );
};

MapLayerSelectorComponent.propTypes = {
  enableIframeEvents: PropTypes.func.isRequired,
  layoutMode: PropTypes.string,
  mapLayers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onMapLayerOpacityChange: PropTypes.func,
  onToggleLayer: PropTypes.func.isRequired,
  zoomLevel: PropTypes.number,
};

MapLayerSelectorComponent.defaultProps = {
  layoutMode: '',
  onMapLayerOpacityChange: () => false,
  zoomLevel: 0,
};

export default MapLayerSelectorComponent;
