import React from 'react';
import { getFeatures } from 'common/helpers/Configurations/Configurations';
import classNames from 'classnames';
import { changeToFirstRenderReadyPhase } from 'common/containers/KasiAppMode/KasiAppModeActions';
import { createRoot } from 'react-dom/client';
import RenderReady from './RenderReady';
// eslint-disable-next-line no-unused-vars
import i18nConfig from '../../../locales/i18nConfig';

function getAppNode() {
  return document.getElementById('js-app');
}

export default function renderReactDom(store, createRouter) {
  return new Promise(resolve => {
    const ready = () => {
      store.dispatch(changeToFirstRenderReadyPhase());
      resolve();
    };

    const appNode = getAppNode();
    const features = getFeatures();
    const root = createRoot(appNode);

    appNode.className = classNames('loading', 'app', features);
    root.render(<RenderReady ready={ready}>{createRouter(store, features)}</RenderReady>);
    appNode.classList.remove('loading');
  }).catch(e => {
    throw new Error(`rendering react dom failed: ${e}`);
  });
}
