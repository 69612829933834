import React, { useCallback, useEffect, useId } from 'react';
import { Field, useForm, useField } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RadioButtonBlock } from 'common/containers/Forms/Blocks';
import { ValidatedSelect } from 'common/containers/Forms/InputValidation/InputValidationComponents';

import { isEmpty } from 'lodash';
import { FEATURE_TYPES } from 'common/constants/MapConstants';
import useOskari from 'common/hooks/useOskari';
import { ErrorBox } from 'realEstateSite/containers/Forms/InputValidationComponents';
import { displayFeatureOnMap } from 'common/geometries/geometriesActions';
import { AREA_SELECTION_TYPES, FIELD_NAMES, PRODUCTION_AREA_STYLE } from '../../FileDownloadServiceConstants';
import { toggleLoadingAnimationThunk } from '../../../OskariMap/OskariMapActions';
import { fetchLaser5pProductionAreas } from '../../FileDownloadServiceApi';
import {
  removeMapSheetAreas,
  startProductionAreaSelectionThunk,
  changeSelectedProductionAreaThunk,
  addProductionAreas,
  stopProductionAreaSelectionThunk,
  stopSheetSelectionByRectangleThunk,
} from '../../FileDownloadServiceAction';
import useFileDownloadServiceLayer from '../../useFileDownloadServiceLayer';
import { formatProductionAreaName } from '../../FileDownloadServiceHelpers';
import { LAYER_LASER5P_PRODUCTION_AREAS, LAYER_MAP_SELECTION } from '../../../../../oskari/layers/VectorLayerIds';
import { hideProductionAreasThunk } from '../../FileDownloadServiceAction/MapActions';

const { SELECTED_PRODUCTION_AREA, AREA_SELECTION_TYPE, NO_PRODUCTION_AREAS } = FIELD_NAMES;

const ProductionAreaSelection = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const { change, getState } = useForm();
  const { submitFailed } = getState();
  const productionAreaSelectionErrorId = useId();

  useFileDownloadServiceLayer();

  const productionAreas = useSelector(state => state.fileDownloadService.laser5pProductionAreas);
  const selectedProductionArea = useSelector(state => state.fileDownloadService.selectedProductionArea);
  const { channelReady } = useOskari();

  const {
    input: { value: productionAreaSelection },
  } = useField(SELECTED_PRODUCTION_AREA);

  const { meta: noProductionAreas } = useField(NO_PRODUCTION_AREAS);

  const options = !isEmpty(productionAreas)
    ? productionAreas.features
        ?.map(productionArea => ({
          label: formatProductionAreaName(productionArea),
          value: productionArea.properties.tuotantoalueId,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1))
    : [];

  function getCorrectOption(selected) {
    return options.findIndex(option => option.value === selected);
  }

  function getProductionAreaById(id) {
    return productionAreas.features?.filter(area => area.properties.tuotantoalueId === id);
  }

  const changeProductionAreaOnMap = useCallback(
    productionArea => {
      if (productionArea != null) {
        dispatch(changeSelectedProductionAreaThunk(productionArea));
      }
    },
    [dispatch]
  );

  const {
    input: { value: areaSelectionType },
  } = useField(FIELD_NAMES.AREA_SELECTION_TYPE);

  const startProductionAreaClickSelect = useCallback(() => {
    dispatch(startProductionAreaSelectionThunk(areaSelectionType));
  }, [areaSelectionType, dispatch]);

  const drawProductionAreasOnMap = useCallback(() => {
    dispatch(toggleLoadingAnimationThunk(true));
    dispatch(
      displayFeatureOnMap(
        LAYER_LASER5P_PRODUCTION_AREAS,
        FEATURE_TYPES.MAP_SHEET_AREAS,
        productionAreas,
        PRODUCTION_AREA_STYLE
      )
    );
    dispatch(toggleLoadingAnimationThunk(false));
  }, [dispatch, productionAreas]);

  useEffect(() => {
    change(SELECTED_PRODUCTION_AREA, options[getCorrectOption(selectedProductionArea)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change, selectedProductionArea]);

  useEffect(() => {
    if (productionAreaSelection.value && selectedProductionArea !== productionAreaSelection.value) {
      const productionArea = getProductionAreaById(productionAreaSelection.value);
      changeProductionAreaOnMap(productionArea);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeProductionAreaOnMap, productionAreaSelection]);

  useEffect(() => {
    dispatch(toggleLoadingAnimationThunk(true));
    fetchLaser5pProductionAreas()
      .then(data => {
        dispatch(addProductionAreas(data));
      })
      .finally(() => {
        dispatch(toggleLoadingAnimationThunk(false));
      });
  }, [dispatch, language]);

  useEffect(() => {
    if (!isEmpty(productionAreas) && areaSelectionType === AREA_SELECTION_TYPES.PRODUCTION_AREA) {
      dispatch(stopSheetSelectionByRectangleThunk());
      drawProductionAreasOnMap();
    }
    return () => {
      dispatch(removeMapSheetAreas(LAYER_MAP_SELECTION));
    };
  }, [dispatch, productionAreas, drawProductionAreasOnMap, areaSelectionType]);

  useEffect(() => {
    setTimeout(async () => {
      startProductionAreaClickSelect();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, channelReady]);

  useEffect(() => {
    if (areaSelectionType === AREA_SELECTION_TYPES.PRODUCTION_AREA) {
      startProductionAreaClickSelect();
    }
    return () => {
      dispatch(stopProductionAreaSelectionThunk());
      dispatch(hideProductionAreasThunk());
    };
  }, [areaSelectionType, dispatch, startProductionAreaClickSelect]);

  return (
    <RadioButtonBlock
      id="productionAreaSelection"
      value={AREA_SELECTION_TYPES.PRODUCTION_AREA}
      name={AREA_SELECTION_TYPE}
      label={t('fileDownloadService.areaSelection.productionArea.label')}
      className="margin-b-1"
      errorId={submitFailed && noProductionAreas?.error ? productionAreaSelectionErrorId : null}
      extraBlockContent={
        <>
          <Field
            name={SELECTED_PRODUCTION_AREA}
            component={ValidatedSelect}
            options={options}
            ariaLabel={t('fileDownloadService.areaSelection.productionArea.label')}
            menuPlacement="top"
            isSearchable
            disabled={areaSelectionType !== AREA_SELECTION_TYPES.PRODUCTION_AREA}
          />
          <Field
            elementId={productionAreaSelectionErrorId}
            name={`${NO_PRODUCTION_AREAS}`}
            component={ErrorBox}
            className="margin-b-1"
          />
        </>
      }
    />
  );
};

export default ProductionAreaSelection;
