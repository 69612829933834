import React from 'react';
import { t } from 'i18next';

import { DisclaimerBox } from '../../components';

export default () => (
  <DisclaimerBox level="error" className="margin-t-2">
    <p>
      <strong>{t('error.general.function')}</strong>
    </p>
    <p>{t('error.general.tryAgainLater')}</p>
  </DisclaimerBox>
);
