import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  enableIframeEvents,
  getScreenshotThunk,
  toggleCoordinateToolThunk,
  trackUserLocationThunk,
  zoomToThunk,
} from 'common/containers/OskariMap/OskariMapActions';
import DesktopMapControls from './DesktopMapControlsComponent';

function mapStateToProps(state) {
  return {
    trackingUser: state.oskariIframe.trackingUser,
    businessPanelIsOpen: state.businessPanel.isOpen,
    zoomLevel: state.oskariIframe.coords.zoom,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onTrackUserLocation: bindActionCreators(trackUserLocationThunk, dispatch),
    enableIframeEvents: bindActionCreators(enableIframeEvents, dispatch),
    takeScreenshot: bindActionCreators(getScreenshotThunk, dispatch),
    toggleCoordinateTool: bindActionCreators(toggleCoordinateToolThunk, dispatch),
    zoomTo: bindActionCreators(zoomToThunk, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopMapControls);
