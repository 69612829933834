import 'react-fastclick';
import wait from 'common/helpers/asyncHelpers';
import { MAP_TOOL_SELECTOR } from 'common/containers/MapToolbar/MapToolNames';
import { initializeLayersThunk } from 'common/containers/MapLayerSelector/MapLayerSelectorActions';
import { addInfoboxActionListenerThunk } from 'common/containers/MapToolbar/SelectorActions';
import { switchMapToolThunk } from 'common/containers/MapToolbar/MapToolbarActions';
import { processSharedLinksThunk } from 'common/containers/MapToolbar/CustomMarkerActions';
import {
  addMapMovedListenerThunk,
  addUserLocationListenerThunk,
  updateCurrentOskariMapCoordinatesThunk,
  showCrosshairThunk,
  setInitialViewFromLinkThunk,
} from 'common/containers/OskariMap/OskariMapActions';
import { init as initMap, waitForElement, MAP_DOM_ID, OSKARI_CHANNEL_STATUS_IDS } from 'oskari/OskariMap';
import {
  changeToChannelReadyPhase,
  changeToPostRenderReadyPhase,
  setMapLayerInitialVisibilitiesThunk,
} from 'common/containers/KasiAppMode/KasiAppModeActions';
import { updateOskariChannelStatus } from 'common/containers/KasiApp/KasiAppActions';
import { reloadOskariState } from './Reload';
import { processSearchLinksThunk } from '../../containers/SidebarSearch/SearchActions';

export default async function postRenderInit(store, isReload) {
  try {
    await waitForElement(MAP_DOM_ID);
    await wait(500);
    const channelStatus = await initMap(store.getState()?.application?.applicationContext);
    if (channelStatus === OSKARI_CHANNEL_STATUS_IDS.channelReady) {
      store.dispatch(changeToChannelReadyPhase());
      if (isReload) {
        startWithInitialState(store);
      } else {
        await startFromScratch(store);
      }
      await Promise.all([
        store.dispatch(addMapMovedListenerThunk()),
        store.dispatch(addUserLocationListenerThunk()),
        store.dispatch(addInfoboxActionListenerThunk()),
        store.dispatch(showCrosshairThunk()),
      ]);
      // overridaa layeri- ja zoom-asetuksia, siksi viimeinen.
      store.dispatch(processSharedLinksThunk(window.location));
      store.dispatch(processSearchLinksThunk(window.location));
      store.dispatch(setInitialViewFromLinkThunk(window.location));
    }
    store.dispatch(changeToPostRenderReadyPhase());
    return store.dispatch(updateOskariChannelStatus(channelStatus));
  } catch (e) {
    throw new Error(`post render failed: ${e}`);
  }
}

function startWithInitialState(store) {
  return store.dispatch(reloadOskariState());
}

async function startFromScratch(store) {
  return Promise.all([
    store.dispatch(updateCurrentOskariMapCoordinatesThunk()),
    store.dispatch(switchMapToolThunk(MAP_TOOL_SELECTOR)),
    store.dispatch(initializeLayersThunk()),
  ]).then(() => store.dispatch(setMapLayerInitialVisibilitiesThunk()));
}
