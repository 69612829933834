import { useEffect } from 'react';
import PropTypes from 'prop-types';

const Listener = ({ onChange, values }) => {
  useEffect(() => {
    onChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  return null;
};

export default Listener;

Listener.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.any.isRequired,
};
