import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  addProductToCartThunk,
  clearAreaSelectionThunk,
  clearMapSheetSelectionThunk,
} from 'common/containers/FileDownloadService/FileDownloadServiceAction';
import { ScrollerColumn, FormWrapper } from 'common/components';
import { addInfoNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';
import {
  FIELD_NAMES,
  AREA_SELECTION_TYPES,
  BOUNDING_BOX,
  CADASTRAL_INDEX_MAP_VECTOR,
  FILE_FORMAT_OPTIONS,
  YEAR_SELECTION_TYPES,
  LASER_SCANNING_DATA_5P,
} from 'common/containers/FileDownloadService/FileDownloadServiceConstants';
import {
  getDefaultAreaSelectionType,
  getDefaultFileFormat,
  getDefaultProductTypeOption,
  getProduct,
  toFileFormatOptions,
} from 'common/containers/FileDownloadService/FileDownloadServiceHelpers';
import { ROUTE_MAP_SITE_ID } from 'common/constants/Routes';
import { resetFormMessage } from 'common/components/Form/FormActions';
import step1validate from './step1/step1validate';
import FileDownloadServiceStep1Component from './step1/FileDownloadServiceStep1Component';
import PRODUCT_LIST from './ProductList';
import RestrictedProductComponent from './restrictedProducts/RestrictedProductComponent';
import { accessibleNotificationAdded } from '../AccessibleNotifications/accessibleNotificationsSlice';
import { ASSERTIVE } from '../AccessibleNotifications/AccessibleNotificationsConstants';

const getDefaultAreaSelectionTool = productId => {
  if (productId === LASER_SCANNING_DATA_5P) {
    return AREA_SELECTION_TYPES.SELECT_SHEETS;
  }
  return getProduct(productId).defaultAreaSelectionType;
};

const FileDownloadServiceFormComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isMapSite = pathname.includes(ROUTE_MAP_SITE_ID);

  const columnNumber = 3;

  const productParameters = useSelector(state => state.fileDownloadService.productParameters);
  const isParametersLoaded = useSelector(state => state.fileDownloadService.isProductParametersLoaded);

  const selectedMapSheets = useSelector(state => state.fileDownloadService.selectedMapSheets);

  if (PRODUCT_LIST.find(product => product.id === id)?.restricted && isMapSite) {
    return (
      <ScrollerColumn columnNumber={columnNumber} lastColumn>
        <RestrictedProductComponent productId={id} />
      </ScrollerColumn>
    );
  }

  const onSubmit = values => {
    try {
      dispatch(addProductToCartThunk(values));
      dispatch(clearAreaSelectionThunk());
      dispatch(clearMapSheetSelectionThunk());
    } catch (error) {
      dispatch(addInfoNotificationAction(t('app.general.error')));

      dispatch(accessibleNotificationAdded({ text: t('app.general.error'), type: ASSERTIVE }));

      console.error(error);
    }
  };
  const onAbort = () => {
    dispatch(resetFormMessage());
  };
  const formId = 'file-download-form';

  const defaultAreaSelectionType = getDefaultAreaSelectionType(id);
  const defaultFileFormat = getDefaultFileFormat(id);
  const parameters = productParameters[id];

  const initialValues = {
    [FIELD_NAMES.PRODUCT_ID]: id,
    [FIELD_NAMES.PRODUCT_TYPE]: getDefaultProductTypeOption(id, parameters?.datasets[defaultAreaSelectionType]),
    [FIELD_NAMES.AREA_SELECTION_TYPE]: getDefaultAreaSelectionTool(id),
    [FIELD_NAMES.AREA_SELECTION]: undefined,
    [FIELD_NAMES.FILE_FORMAT]: defaultFileFormat
      ? FILE_FORMAT_OPTIONS.find(option => option.value === defaultFileFormat)
      : toFileFormatOptions(parameters?.fileTypes[defaultAreaSelectionType])[id === CADASTRAL_INDEX_MAP_VECTOR ? 1 : 0],
    [FIELD_NAMES.SELECTED_MUNICIPALITY]: undefined,
    [FIELD_NAMES.THEME_FREE_SELECTION]: undefined,
    [FIELD_NAMES.THEME_MUNICIPALITY]: undefined,
    [FIELD_NAMES.THEME_ENTIRE_FINLAND]: undefined,
    [FIELD_NAMES.SELECTED_MAP_SHEETS]: selectedMapSheets || [],
    [FIELD_NAMES.VALID_AREA_SELECTION]: false,
    [FIELD_NAMES.FREE_SELECTION_TYPE]:
      getDefaultAreaSelectionTool(id) === AREA_SELECTION_TYPES.FREE_SELECTION ? BOUNDING_BOX : undefined,
    [FIELD_NAMES.CRS]: getProduct(id).defaultCRS,
    [FIELD_NAMES.LEVEL_OF_DETAIL]: parameters?.levelOfDetail[defaultAreaSelectionType] && {
      value: parameters?.levelOfDetail[defaultAreaSelectionType][0],
    },
    [FIELD_NAMES.YEAR_SELECTION_TYPE]: YEAR_SELECTION_TYPES.CURRENT,
    [FIELD_NAMES.SELECTED_PRODUCTION_AREA]: undefined,
  };

  return (
    <ScrollerColumn columnNumber={columnNumber} lastColumn>
      <FormWrapper
        {...{
          formId,
          onAbort,
          onSubmit,
          initialValues,
          hideNavigation: true,
          hasNoSucceededPage: true,
          singlePage: true,
          dataCy: 'fileDownloadForm',
          showErrorNotifications: true,
          isLoaded: isParametersLoaded,
        }}
      >
        <FormWrapper.Page
          validate={step1validate(productParameters)}
          title={t(`fileDownloadService.product.${id}.name`)}
          headerClassName={`${id}-title`}
        >
          <FileDownloadServiceStep1Component />
        </FormWrapper.Page>
        <FormWrapper.Page>Error</FormWrapper.Page>
      </FormWrapper>
    </ScrollerColumn>
  );
};

export default FileDownloadServiceFormComponent;
