import { SET_MAP_TOOL_BUTTON_ACTIVE, CLEAN_DISTANCE } from './MapToolbarActionTypes';
import { MAP_TOOL_DISTANCE } from './MapToolNames';

const initialState = {
  active: false,
  dirty: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MAP_TOOL_BUTTON_ACTIVE:
      if (action.toolId === MAP_TOOL_DISTANCE) {
        return { ...state, active: action.active, dirty: true };
      }
      return state;

    case CLEAN_DISTANCE:
      return initialState;

    default:
      return state;
  }
}
