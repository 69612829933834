import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { isEmpty, uniqueId } from 'lodash';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { getUnitPartyRegulatedItems } from 'realEstateSite/containers/Realty/RealEstate/UsufructsAndRestrictions/UsufructUnits/UsufructUnitHelpers';
import {
  DOMINANT_TYPE,
  SERVIENT_TYPE,
  PARTY_TYPE,
} from 'realEstateSite/containers/Realty/RealEstate/UsufructsAndRestrictions/UsufructUnits/UsufructUnitTypes';

// Because this component is passed to iframe as HTML string, we have to use inline styling.
const containerStyle = { marginBottom: '0.5rem', marginTop: '0.5rem' };
const titleStyle = { paddingBottom: '0' };
const subtitleStyle = { fontWeight: 'normal' };
const dlStyle = { marginTop: '0.5rem' };
const dtStyle = { fontWeight: 'bold' };
const ddStyle = { marginBottom: '0.5rem' };
const listStyle = { listStyle: 'none', marginLeft: '0', paddingLeft: '0.5em', textIndent: '-0.5em' };
const inlineStyle = { display: 'flex' };

const PartyType = PropTypes.shape({
  realestateId: PropTypes.string,
  propertyId: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
});

const Party = ({ party }) => (
  <li style={listStyle}>
    {party.realestateId || party.propertyId} {party.name || party.description}
  </li>
);

Party.propTypes = {
  party: PartyType.isRequired,
};

const Parties = ({ parties }) => (
  <ul>
    {parties.map(party => (
      <Party key={uniqueId('usufructParty-')} party={party} />
    ))}
  </ul>
);

Parties.propTypes = {
  parties: PropTypes.arrayOf(PartyType).isRequired,
};

export const Usufruct = ({ usufruct, ownFeature }) => {
  const { properties } = usufruct;

  const part = properties.usufructUnitParts.find(
    ({ partNumber }) => partNumber === properties.kayttooikeusyksikonOsanNumero
  );
  const servientParties = getUnitPartyRegulatedItems([part], SERVIENT_TYPE);
  const dominantParties = getUnitPartyRegulatedItems([part], DOMINANT_TYPE);
  const parties = getUnitPartyRegulatedItems([part], PARTY_TYPE);

  return (
    <div style={containerStyle}>
      <h4 style={titleStyle}>
        <div>{properties.usufructId}</div>
        <div style={subtitleStyle}>{properties.name}</div>
        <div style={subtitleStyle}>{`${t('usufructUnit.partTitle')}\u00a0${properties.kayttooikeusyksikonOsanNumero}/${
          properties.usufructUnitParts.length
        }`}</div>
      </h4>
      {ownFeature && (
        <dl style={dlStyle}>
          {!isEmpty(part.width) && (
            <div style={inlineStyle}>
              <dt>{`${t('usufructUnit.width.title')}\u00a0`}</dt>
              <dd style={ddStyle}>{part.width} m</dd>
            </div>
          )}
          {!isEmpty(servientParties) && (
            <>
              <dt style={dtStyle}>{t('usufructUnit.regulationParty.servient.title')}</dt>
              <dd style={ddStyle}>
                <Parties parties={servientParties} />
              </dd>
            </>
          )}
          {!isEmpty(dominantParties) && (
            <>
              <dt style={dtStyle}>{t('usufructUnit.regulationParty.dominant.title')}</dt>
              <dd style={ddStyle}>
                <Parties parties={dominantParties} />
              </dd>
            </>
          )}
          {!isEmpty(parties) && (
            <>
              <dt style={dtStyle}>{t('usufructUnit.regulationParty.parties')}</dt>
              <dd style={ddStyle}>
                <Parties parties={parties} />
              </dd>
            </>
          )}
        </dl>
      )}
    </div>
  );
};

Usufruct.propTypes = {
  usufruct: PropTypes.shape({
    properties: PropTypes.shape({
      usufructTypeText: PropTypes.shape({}),
      usufructId: PropTypes.string,
      name: PropTypes.string,
      usufructUnitParts: PropTypes.arrayOf(PropTypes.shape({})),
      kayttooikeusyksikonOsanNumero: PropTypes.string,
      tiekunnallisuus: PropTypes.number,
    }),
  }).isRequired,
  ownFeature: PropTypes.bool.isRequired,
};

const createUsufructDetails = (usufruct, ownFeature) => {
  return ReactDOMServer.renderToString(<Usufruct usufruct={usufruct} ownFeature={ownFeature} />);
};

export default createUsufructDetails;
