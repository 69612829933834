export const PENDING_ACTIVITY_STATE = '01';
export const RESTING_ACTIVITY_STATE = '02';
export const DEATH_ESTATE_LCA_ACTIVITY_TYPE = 'LH02';
export const ADMINISTRATIVE_AUTHORITIES_TYPE = 'LH04';
export const APPROVED_SOLUTION_TYPE = '01';
export const RESTING_SOLUTION_TYPE = '02';
export const REJECTED_SOLUTION_TYPE = '03';
export const NOT_INVESTIGATED_SOLUTION_TYPE = '04';
export const LEGALLY_BINDING_SOLUTION_TRACKING_INFO = '01';
export const NOT_LEGALLY_BINDING_SOLUTION_TRACKING_INFO = '02';
export const NON_PROBATED_SOLUTION_TRACKING_INFO = '02';
export const LEGAL_CONFIRMATION_CATEGORIES = ['LH', 'EO'];

export const RESTRICTION_OF_PROPERTY_RIGHTS = 'VA';

export const ACTIVITY_TYPE_CADASTRAL_SURVEY = 'cadastralSurvey';
export const ACTIVITY_TYPE_REGISTRATION_ISSUE = 'registrationIssue';

export const PROCESSING_PETITION_TYPE = 'KASITTELYSSA';
export const SUPPLEMENT_PROCESSING_TYPE = 'TP';
export const SUPPLEMENT_RECEIVED_TYPE = 'TS';

export const ACTIVITY_TYPE_PREMISES = {
  LH022: 'realEstate.lcDetails.lh022',
  LH023: 'realEstate.lcDetails.lh023',
  LH024: 'realEstate.lcDetails.lh024',
  LH025: 'realEstate.lcDetails.lh025',
  LH026: 'realEstate.lcDetails.lh026',
  LH027: 'realEstate.lcDetails.lh027',
  LH028: 'realEstate.lcDetails.lh028',
  LH029: 'realEstate.lcDetails.lh029',
  LH0210: 'realEstate.lcDetails.lh0210',
  LH0211: 'realEstate.lcDetails.lh0211',
  LH0212: 'realEstate.lcDetails.lh0212',
};

export const PUBLICITY_CLASS_SECRET = 'Salassa pidettävä';
