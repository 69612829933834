/** Hallinnoiva viranomainen */
export const POSSESSION_ROLE_LHHV = 'LHHV';
/** Lainhuudon saaja */
export const POSSESSION_ROLE_LHHS = 'LHHS';
/** Omistusoikeuden saaja */
export const POSSESSION_ROLE_LHOS = 'LHOS';
/** Luovutuksen saaja */
export const POSSESSION_ROLE_LVSA = 'LVSA';
/** Vuokralainen */
export const POSSESSION_ROLE_EOVU = 'EOVU';
/** Luovuttaja */
export const POSSESSION_ROLE_LHLU = 'LHLU';
/** Luovuttaja */
export const POSSESSION_ROLE_LVLU = 'LVLU';
/** Saantomies */
export const POSSESSION_ROLE_LHSA = 'LHSA';

export const OWNERSHIP_ROLES = [POSSESSION_ROLE_LHHS, POSSESSION_ROLE_LVSA, POSSESSION_ROLE_LHOS];

/** Saajaroolit */
export const GRANTEE_ROLES = [
  'EOHJ',
  'EOIL',
  'EOOP',
  'EOVU',
  'KIOM',
  'KIHJ',
  'LHHS',
  'LHHV',
  'LHOS',
  'LVSA',
  'MUHA',
  'MUHS',
  'MUMO',
  'MUVL',
  'MUVU',
  'KPOS',
];
