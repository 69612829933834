import { uniqueId } from 'lodash';
import * as OskariMap from 'oskari/OskariMap';

import {
  addDrawnFeature,
  removeFeaturesByTypeThunk,
  setMeasurementsLayerVisibility,
} from '../../geometries/geometriesActions';
import { FEATURE_TYPES } from '../../constants/MapConstants';

const { AREA_MEASUREMENT, DISTANCE_MEASUREMENT } = FEATURE_TYPES;

function handleDrawEvent(featureType, dispatch) {
  return event =>
    event.geojson.features.forEach(feature => dispatch(addDrawnFeature(feature.id, featureType, feature)));
}

function getFeaturesByType(getState, featureType) {
  return getState().geometries.features?.filter(feature => feature.featureType === featureType);
}

export function turnAreaOn(dispatch, getState) {
  OskariMap.startArea(handleDrawEvent(AREA_MEASUREMENT, dispatch), getFeaturesByType(getState, AREA_MEASUREMENT));
  dispatch(setMeasurementsLayerVisibility(true));
}

export function turnDistanceOn(dispatch, getState) {
  OskariMap.startDistance(
    handleDrawEvent(DISTANCE_MEASUREMENT, dispatch),
    getFeaturesByType(getState, DISTANCE_MEASUREMENT)
  );
  dispatch(setMeasurementsLayerVisibility(true));
}

export function turnAreaOff() {
  OskariMap.stopArea();
}

export function turnDistanceOff() {
  OskariMap.stopDistance();
}

export function startAreaTool(dispatch) {
  dispatch(turnDistanceOn);
  dispatch(turnDistanceOff);
  dispatch(turnAreaOn);
  dispatch(setMeasurementsLayerVisibility(true));
}

export function startDistanceTool(dispatch) {
  dispatch(turnAreaOn);
  dispatch(turnAreaOff);
  dispatch(turnDistanceOn);
  dispatch(setMeasurementsLayerVisibility(true));
}

export function reloadMeasurementFeatures(dispatch, getState) {
  // Oskari draw tool creates drawn features with id drawnFeature{n}
  // When page is reloaded counter starts again with 0.
  // Then if we add previously drawn features to map those ids will collide and Oskari fails to draw feature on the map.
  // So we need to create new ids for previously drawn features.
  const measurementFeatures = getState().geometries.features.filter(
    geometry => geometry.featureType === DISTANCE_MEASUREMENT || geometry.featureType === AREA_MEASUREMENT
  );

  if (!measurementFeatures) return;

  const featuresWithNewId = measurementFeatures.map(feature => ({ ...feature, id: uniqueId('reloadedFeature') }));

  dispatch(removeFeaturesByTypeThunk(DISTANCE_MEASUREMENT));
  dispatch(removeFeaturesByTypeThunk(AREA_MEASUREMENT));
  featuresWithNewId.forEach(feature =>
    dispatch(addDrawnFeature(feature.id, feature.featureType, { ...feature.geoJson, id: feature.id }))
  );
}
