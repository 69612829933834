import {
  DISABLE_ALL_MAP_TOOLS,
  SET_MAP_TOOL_BUTTON_ACTIVE,
  MAP_TOOL_ADDRESS_REST_CALL_STATUS,
} from './MapToolbarActionTypes';
import { MAP_TOOL_ADDRESS } from './MapToolNames';

const initialState = { active: false };

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MAP_TOOL_BUTTON_ACTIVE:
      if (action.toolId === MAP_TOOL_ADDRESS) {
        return { ...state, active: action.active };
      }
      return state;
    case MAP_TOOL_ADDRESS_REST_CALL_STATUS:
      return { ...state, restCallStatus: action.status, timestamp: action.timestamp };
    case DISABLE_ALL_MAP_TOOLS:
      return { ...state, active: false };

    default:
      return state;
  }
}
